import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import $ from 'jquery';
import { createIcons, icons } from 'lucide';
import CompanyDashboard from '../../dashboard/company_dashboard';
import Sidebar from './partials/sidebar';
import Header from './partials/header';
import TaxReportPage from '../../tax_service/company_tax_report';
import CompanyTaxFilling from '../../tax_service/company_tax_filling';
import CompanyReportPage from '../../reports/company_reports';
import AddBranches from '../../branches/addBranch';
import CompanyBranches from '../../branches/company_branch';
import CompanyAddBranches from '../../branches/companyAddBranch';
import BranchUsers from '../../branch_users/company/branchUser';
import AddBranchUser from '../../branch_users/company/addBranchUser';
import BranchScheduleReport from '../../schedules/branch_schedule';
import NewBranchScheduleReport from '../../schedules/new_branch_schedule';
import CompanyReportDetail from '../../reports/partials/company_report_detail';
import CompanyEditBranches from '../../branches/companyEditBranch';
import EditBranchUser from '../../branch_users/company/editBranchUser';
import CompanyProfile from '../../companies/company_profile';
import BranchProfile from '../../branches/branch_profile';
import CompanyFillings from '../../companies/company_fillings';
import BranchUserChangePassword from '../../branch_users/change_user_password';
import OtherFillings from '../../others/other_fillings';
import OtherFillingView from '../../others/other_filling_data';
import OtherReportDetail from '../../others/other_report_detail';
import { API_URL } from '../../../utils/api_const';
import axios from 'axios';
import moment from 'moment';
import SubscriptionDetails from '../../companies/subscription_details';
import StoragePlansDescription from '../../companies/storage_details';
import EditCompanies from '../../companies/edit_company';
import CompanyAddStep2 from '../../companies/add_form/company_step_2';
import CompanyAddStep3 from '../../companies/add_form/company_step_3';
import { ToastContainer, toast } from 'react-toastify';
import { RAZORPAY_KEY_ID } from '../../../actions/authAction';
import CompanyTransactions from '../../companies/company_transactions';
import { fetchTaxServices } from "../../../features/taxServiceSlice";
import { useDispatch, useSelector } from 'react-redux';

const CompanyMain = ({ onLogout }) => {
  const dispatch = useDispatch();
  const [compactMenu, setCompactMenu] = useState(localStorage.getItem('compactMenu') === 'true');
  const [usertype, setUserType] = useState('');
  const [loader, setLoader] = useState(true);
  const [upgradeLoader, setUpgradeLoader] = useState(false);
  const [subscriptionPopup, setSubscriptionPopup] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null); // To handle any errors
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const taxServices = useSelector((state) => state.taxService.taxServices);


  useEffect(() => {
    dispatch(fetchTaxServices());
  }, [dispatch]);
  // Function to fetch subscriptions from the API
  const fetchSubscriptions = async () => {
    try {
      // Replace with your API endpoint
      const response = await axios.get(`${API_URL}/subscription/plan`);
      setSubscriptions(response.data); // Assuming the data is returned directly as an array
      console.log(response);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching subscriptions:", err);
      setError('Failed to fetch subscription plans.');
      setLoading(false);
    }
  };

  const getCompanySubscription = async (company_id) => {
    try {
      const res = await axios.get(`${API_URL}/companies/subscription/data/${company_id}`);
      if (res.status === 200) {
        let company = JSON.parse(localStorage.getItem('company'));
        if (company) {
          company.subscription = res.data;
          // Set the updated company object back into localStorage as a string
          localStorage.setItem('company', JSON.stringify(company));
        }
        return res.data;
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
      return false;
    }
  }

  // Fetch the data when the component mounts
  useEffect(() => {
    fetchSubscriptions();

  }, []);

  const convertDaysToReadableFormat = (days) => {
    if (days < 30) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (days < 365) {
      const months = Math.floor(days / 30);
      const remainingDays = days % 30;
      return `${months} month${months > 1 ? 's' : ''}${remainingDays ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
    } else {
      const years = Math.floor(days / 365);
      const remainingMonths = Math.floor((days % 365) / 30);
      const remainingDays = days % 365 % 30;
      return `${years} year${years > 1 ? 's' : ''}${remainingMonths ? ` ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}` : ''}${remainingDays ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
    }
  };

  useEffect(() => {
    const getFeacthData = async () => {
      setUserType(localStorage.getItem('login_type'));
      setLoader(false);
      const company = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : null;
      if (usertype == 'company' && company) {
        const subdata = await getCompanySubscription(company.id);
        if (subdata) {
          const subscriptionEndDate = moment(subdata.end_at);
          if (subscriptionEndDate.isBefore(moment())) {
            setSubscriptionPopup(true);
          }
          if (company.subscription) {
            setCurrentSubscription(company.subscription.subscription_id);
            console.log('current subscription', company.subscription.subscription_id);
          }
        } else {
          setSubscriptionPopup(true);
        }

      }
    }

    getFeacthData();

  }, [usertype]);


  const loadRazorpay = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => reject(false);
      document.body.appendChild(script);
    });
  };

  const upgradePlan = async (planId, planPrice) => {
    // Set the loading state to true
    setUpgradeLoader(true);

    const company = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : null;

    // Check if the company data exists
    if (company) {

      try {
        const razorpayLoaded = await loadRazorpay();
        if (!razorpayLoaded) {
          toast.error('Failed to load Razorpay SDK');
          setUpgradeLoader(false);
          return;
        }

        // Razorpay payment options
        const paymentOptions = {
          key: RAZORPAY_KEY_ID, // Replace with your Razorpay Key ID
          amount: planPrice, // Replace with the amount in the smallest currency unit (e.g., 100000 for ₹1000)
          currency: 'INR',
          name: 'Taxgem',
          description: 'Upgrade Storage',
          handler: async function (response) {
            console.log('Payment Successful:', response);

            try {
              // Make the API call to upgrade the subscription
              const res = await axios.post(`${API_URL}/companies/subscription/upgrade/${company.id}`, {
                plan_id: planId,
                payment_id: response.razorpay_payment_id,
              });

              // Check if the response status is 200 (success)
              if (res.status === 200) {
                console.log('Subscription upgraded successfully!', res.data);

                // Simulate a delay before reloading the page to show the success state to the user
                setTimeout(() => {
                  setUpgradeLoader(false);  // Stop the loader
                  location.reload(); // Reload the page to reflect the new subscription
                }, 2000);
              } else {
                // Log an error message if the status is not 200
                console.error('Failed to upgrade subscription', res.data);
                setUpgradeLoader(false);  // Stop the loader on failure
              }
            } catch (error) {
              // Handle any errors from the API call
              console.error('Error upgrading subscription:', error);
              setUpgradeLoader(false);  // Stop the loader on error
            }
          },
          prefill: {
            name: company.name, // Prefill with company name
            email: company.email, // Prefill with company email
          },
          theme: {
            color: '#5398E3', // Match your app's theme color
          },
        };

        // Initialize Razorpay
        const rzp = new Razorpay(paymentOptions);
        rzp.on('payment.failed', function (response) {
          console.error('Payment Failed:', response.error);
          setUpgradeLoader(false);
          toast.error('Payment failed. Please try again.');
        });

        // Open Razorpay modal
        rzp.open();
      } catch (error) {
        console.error('Error during payment initialization:', error);
        setUpgradeLoader(false);
        toast.error('Error initializing payment.');
      }

    } else {
      // If company data is missing from local storage
      console.error('Company data not found in localStorage');
      setUpgradeLoader(false);  // Stop the loader if company is null
    }
  };


  useEffect(() => {

    createIcons({ icons });
    const loadingTimeout = setTimeout(() => {
      $('.loading-page').addClass('loading-page--hide');
      setTimeout(() => {
        $('.loading-page').removeClass('loading-page--before-hide loading-page--hide');
      }, 300);
    }, 1000);

    // Handle scroll behavior
    const handleScroll = () => {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        $('.background').addClass('background--hidden');
        $('.top-bar').addClass('top-bar--active');
        $('.content.mode').removeClass('mode--light');
      } else {
        $('.background').removeClass('background--hidden');
        $('.top-bar').removeClass('top-bar--active');
        $('.content.mode').addClass('mode--light');
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    // Handle menu collapse
    const toggleCompactMenu = (e) => {
      e.preventDefault();
      setCompactMenu((prev) => {
        const newCompactMenu = !prev;
        localStorage.setItem('compactMenu', newCompactMenu ? 'true' : 'false');
        return newCompactMenu;
      });
    };

    $('.toggle-compact-menu').on('click', toggleCompactMenu);

    // Handle mobile menu open/close
    const openMobileMenu = (e) => {
      e.preventDefault();
      $('.side-menu').addClass('side-menu--mobile-menu-open');
      $('.close-mobile-menu').addClass('close-mobile-menu--mobile-menu-open');
    };

    const closeMobileMenu = (e) => {
      e.preventDefault();
      $('.side-menu').removeClass('side-menu--mobile-menu-open');
      $('.close-mobile-menu').removeClass('close-mobile-menu--mobile-menu-open');
    };

    $('.open-mobile-menu').on('click', openMobileMenu);
    $('.close-mobile-menu').on('click', closeMobileMenu);

    // Handle full screen request
    const requestFullScreen = (e) => {
      e.preventDefault();
      const docEl = document.documentElement;
      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      }
    };

    $('.request-full-screen').on('click', requestFullScreen);

    // Handle window resize
    const handleResize = () => {
      $('.side-menu').removeClass('side-menu--mobile-menu-open');
      $('.close-mobile-menu').removeClass('close-mobile-menu--mobile-menu-open');
      if (window.innerWidth <= 1600) {
        localStorage.setItem('compactMenu', 'true');
        setCompactMenu(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(loadingTimeout);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      $('.toggle-compact-menu').off('click', toggleCompactMenu);
      $('.open-mobile-menu').off('click', openMobileMenu);
      $('.close-mobile-menu').off('click', closeMobileMenu);
      $('.request-full-screen').off('click', requestFullScreen);
    };
  }, []);



  return (
    <div>
      {subscriptionPopup && (
        <div className="unclosedModal">

          <div className="modal-overlay modal-active">
            <div className="modal-content plans-page-style" style={{
              overflow: "scroll",
              textAlign: "left",
            }}>
              <div className="main " style={{ marginTop: "30px" }}>
                <table className="price-table">
                  <tbody>

                    <tr className="price-table-head">
                      <td > Particular</td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td key={index}>
                          {data.name}

                        </td>
                      )}
                    </tr>
                    <tr>
                      <td />
                      {subscriptions && subscriptions.map((plan, index) =>
                        <td key={index} className="price">
                          ₹{(plan.price)}<br />
                          {/* <Link to={`/register?plan=${plan.id}`} class="button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7">Get Started</Link> */}
                        </td>
                      )}

                    </tr>
                    <tr>
                      <td>
                        <a href="#wordpress-asset-updates" className="price-table-help">
                          <i className="far fa-fw fa-question-circle" />
                        </a>{" "}
                        Registration Charges (One Time)
                      </td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td>₹{data.registration_charge || 0}</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <a href="#wordpress-asset-updates" className="price-table-help">
                          <i className="far fa-fw fa-question-circle" />
                        </a>{" "}
                        Plan type
                      </td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td>{data.plan_type == 'Other' ? data.start_from + ' days' : (data.plan_type || '')}</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <a href="#wordpress-asset-updates" className="price-table-help">
                          <i className="far fa-fw fa-question-circle" />
                        </a>{" "}
                        Branches & Charges
                      </td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td>{data.branches_charge ? '₹' + data.branches_charge + '/ Branches' : <span className='text-danger'>X</span>}</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <a href="#wordpress-asset-updates" className="price-table-help">
                          <i className="far fa-fw fa-question-circle" />
                        </a>{" "}
                        24X7 Support
                      </td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td>{data.support_24x7 == 'Yes' ?
                          <span className='text-success'>✔</span> : <span className='text-danger'>X</span>}</td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <a href="#wordpress-asset-updates" className="price-table-help">
                          <i className="far fa-fw fa-question-circle" />
                        </a>{" "}
                        Regular Updates
                      </td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td><span className='text-success'>✔</span></td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <a href="#wordpress-asset-updates" className="price-table-help">
                          <i className="far fa-fw fa-question-circle" />
                        </a>{" "}
                        Due Date Reminder
                      </td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td><span className='text-success'>✔</span></td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <a href="#wordpress-asset-updates" className="price-table-help">
                          <i className="far fa-fw fa-question-circle" />
                        </a>{" "}
                        Custom & Secure Business data
                      </td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td><span className='text-success'>✔</span></td>
                      )}
                    </tr>
                    <tr>
                      <td>
                        <a href="#wordpress-asset-updates" className="price-table-help">
                          <i className="far fa-fw fa-question-circle" />
                        </a>{" "}
                        Security & Management Console
                      </td>
                      {subscriptions && subscriptions.map((data, index) =>
                        <td><span className='text-success'>✔</span></td>
                      )}
                    </tr>
                    {taxServices?.map((taxData, ind) => (
                      <tr key={`tax-${ind}`}>
                        <td>
                          <a href="#wordpress-asset-updates" className="price-table-help">
                            <i className="far fa-fw fa-question-circle" />
                          </a>{" "}
                          {taxData.service_name}
                        </td>
                        {subscriptions?.map((data, index) => {
                          let selectedServices = [];
                          try {
                            selectedServices = JSON.parse(data.selected_services);
                          } catch (e) {
                            console.error('Error parsing selected_services:', e);
                          }
                          const isSelected = selectedServices?.includes(taxData.id);
                          return (
                            <td key={`sub-${ind}-${index}`}>
                              {isSelected ? (
                                <span className='text-success'>✔</span>
                              ) : (
                                <span className='text-danger'>X</span>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td><strong style={{ fontSize: "20px" }}>Total</strong></td>
                      {subscriptions?.map((plan, index) =>
                        <td className="price">
                          ₹{currentSubscription && currentSubscription === plan.id?(plan.price):(plan.price + plan.registration_charge)} <small style={{ fontSize: "12px" }}>(+{(plan.price + plan.registration_charge) * 0.18} GST)</small><br /> {plan.branches_charge ? <small className='text-black' style={{ fontSize: "10px" }}>(₹250 for per Branches will be Additional)</small> : ""}
                        </td>
                      )}

                    </tr>
                    <tr>
                      <td></td>
                      {subscriptions?.map((plan, index) =>
                        <td className="price">
                          <button key={plan.id} href={`/register?plan=${plan.id}`} onClick={() => upgradePlan(plan.id, ((currentSubscription && currentSubscription === plan.id?(plan.price * 1.18):((plan.price + plan.registration_charge) * 1.18)) * 100).toFixed(2))} className="button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7">
                            {currentSubscription && currentSubscription === plan.id ? 'Renew' : 'Upgrade'}
                          </button>
                          {/* <Link to={`/register?plan=${plan.id}`} class="button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7">Get Started</Link> */}
                        </td>
                      )}

                    </tr>
                    <tr>
                      <td colSpan={4} style={{ textAlign: 'center' }}><p>All rates are exclusive of GST <br /><a href='https://taxgem.co.in/terms-and-conditions' target='_blank'>Terms & Condition apply</a></p></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      {loader ? <div className="[&amp;.loading-page--before-hide]:h-screen [&amp;.loading-page--before-hide]:relative loading-page loading-page--before-hide [&amp;.loading-page--before-hide]:before:block [&amp;.loading-page--hide]:before:opacity-0 before:content-[''] before:transition-opacity before:duration-300 before:hidden before:inset-0 before:h-screen before:w-screen before:fixed before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:z-[60] [&amp;.loading-page--before-hide]:after:block [&amp;.loading-page--hide]:after:opacity-0 after:content-[''] after:transition-opacity after:duration-300 after:hidden after:h-16 after:w-16 after:animate-pulse after:fixed after:opacity-50 after:inset-0 after:m-auto after:bg-loading-puff after:bg-cover after:z-[61]"></div> : ''}
      {loader == false ? <div className="echo group bg-gradient-to-b from-slate-200/70 to-slate-50 background relative min-h-screen before:content-[''] before:h-[370px] before:w-screen before:bg-gradient-to-t before:from-theme-1/80 before:to-theme-2 [&amp;.background--hidden]:before:opacity-0 before:transition-[opacity,height] before:ease-in-out before:duration-300 before:top-0 before:fixed after:content-[''] after:h-[370px] after:w-screen [&amp;.background--hidden]:after:opacity-0 after:transition-[opacity,height] after:ease-in-out after:duration-300 after:top-0 after:fixed after:bg-texture-white after:bg-contain after:bg-fixed after:bg-[center_-13rem] after:bg-no-repeat">
        <div className="side-menu xl:ml-0 shadow-xl transition-[margin,padding] duration-300 xl:shadow-none fixed top-0 left-0 z-20 group inset-y-0 xl:py-3.5 xl:pl-3.5 after:content-[''] after:fixed after:inset-0 after:bg-black/80 after:xl:hidden [&.side-menu--mobile-menu-open]:ml-0 [&.side-menu--mobile-menu-open]:after:block -ml-[275px] after:hidden">
          <Sidebar />
          <Header />
        </div>
        <div className="content transition-[margin,width] duration-100 xl:pl-3.5 pt-[54px] pb-16 relative z-10 group mode xl:ml-[275px] [&.content--compact]:xl:ml-[91px] mode--light">
          <div className="mt-16 px-5">
            {usertype == 'company' ? <Routes>
              <Route path="company/dashboard" exact element={<CompanyDashboard />} />
              <Route path='company/branch' exact element={<CompanyBranches />} />
              <Route path='company/branch/schedule' exact element={<BranchScheduleReport />} />
              <Route path='company/branch/schedule/add' exact element={<NewBranchScheduleReport />} />
              <Route path='company/branch/users/:branch_id' exact element={<BranchUsers />} />
              <Route path='company/branch/user/change-password' exact element={<BranchUserChangePassword />} />
              <Route path='company/branch/users/add/:branch_id' exact element={<AddBranchUser />} />
              <Route path='company/branch/users/edit/:branch_id/:userid' exact element={<EditBranchUser />} />
              <Route path='company/branch/add' exact element={<CompanyAddBranches />} />
              <Route path='company/branch/edit/:id' exact element={<CompanyEditBranches />} />
              <Route path="company/tax/:filling" exact element={<CompanyTaxFilling />} />
              <Route path="company/report/:filling" exact element={<CompanyReportPage />} />
              <Route path="company/tax/:filling/:id/:tax_service_id/:due_date/:check" exact element={<TaxReportPage />} />
              <Route path="company/report-detail" exact element={<CompanyReportDetail />} />
              <Route path="company/profile/:company_id" exact element={<CompanyProfile />} />
              <Route path="company/fillings/:company_id" exact element={<CompanyFillings />} />
              <Route path="company/other/filling/view" exact element={<OtherFillingView />} />
              <Route path="company/other/:type" exact element={<OtherFillings />} />
              <Route path="company/other-report-detail" exact element={<OtherReportDetail />} />
              <Route path="company/subscription" exact element={<SubscriptionDetails />} />
              <Route path="company/storages" exact element={<StoragePlansDescription />} />
              <Route path="company/edit/step2/:id" exact element={<CompanyAddStep2 />} />
              <Route path="company/edit/step3/:id" exact element={<CompanyAddStep3 />} />
              <Route path="company/transactions" exact element={<CompanyTransactions />} />

            </Routes> : ''}
            {usertype == 'branch' ? <Routes>
              <Route path="branch/dashboard" exact element={<CompanyDashboard />} />
              <Route path="branch/tax/:filling" exact element={<CompanyTaxFilling />} />
              <Route path="branch/report/:filling" exact element={<CompanyReportPage />} />
              <Route path="branch/tax/:filling/:id/:tax_service_id/:due_date/:check" exact element={<TaxReportPage />} />
              <Route path="branch/report-detail" exact element={<CompanyReportDetail />} />
              <Route path="branch/profile/:user_id" exact element={<BranchProfile />} />
              <Route path="branch/other/filling/view" exact element={<OtherFillingView />} />
              <Route path="branch/other/:type" exact element={<OtherFillings />} />
              <Route path="branch/other-report-detail" exact element={<OtherReportDetail />} />
            </Routes> : ''}
          </div>
        </div>
      </div> : ''}
    </div>
  );
};

export default CompanyMain;
