import React, { useEffect, useRef, useState } from "react";
import TableHeader from "../../../utils/table_headings";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranches, deleteBranch } from "../../../features/branchSlice";
import { MoreVertical } from "lucide";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { Delete, PenSquare } from "lucide-react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { API_URL } from "../../../utils/api_const";
import moment from 'moment';


const BranchTable = () => {
    const dispatch = useDispatch();
    const companyHeadings = ['Branch Name', 'Company Name', 'Users', 'Location', 'Status', 'Actions'];
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRefs = useRef({});
    const { branches, status, error } = useSelector((state) => state.branches);

    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        Object.values(dropdownRefs.current).forEach(ref => {
            if (ref && !ref.contains(event.target)) {
                setOpenDropdownId(null);
            }
        });
    };

    useEffect(() => {
        dispatch(fetchBranches());
    }, [dispatch]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleStatusChange = async (checked, rowid) => {
        const newStatus = checked ? 1 : 0;

        try {
            // Make the POST request to update the status
            const response = await axios.post(`${API_URL}/companies/branches/update-status`, {
                id: rowid,         // Send the row id
                status: newStatus,  // Send the updated status
            });

            // Check for successful response
            if (response.status === 200) {
                dispatch(fetchBranches());

            } else {
                console.error("Failed to update status");
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }

    };


    const handleDelete = (id) => () => {
        console.log(id);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteBranch(id));
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Successfully Deleted',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                // setScheduleReports(prevReports =>
                //     prevReports.filter(report => report.id !== id)
                // );
            }
        });
    }


    const [createdDateFrom, setCreatedDateFrom] = useState('');
    const [createdDateTo, setCreatedDateTo] = useState('');
    const [location, setLocation] = useState('');
    const [company, setCompany] = useState('');

    // Filtered branches
    const filteredBranches = branches.filter((branch) => {
        // Created Date From filter
        const matchesCreatedDateFrom = createdDateFrom
            ? moment(branch.createdAt).isSameOrAfter(moment(createdDateFrom, 'YYYY-MM-DD').startOf('day'))
            : true;

        // Created Date To filter
        const matchesCreatedDateTo = createdDateTo
            ? moment(branch.createdAt).isSameOrBefore(moment(createdDateTo, 'YYYY-MM-DD').endOf('day'))
            : true;

        // Location filter
        const matchesLocation = location ? branch?.branch_location?.toLowerCase().includes(location.toLowerCase()) : true;

        // Company filter
        const matchesCompany = company ? branch?.Company?.company_name.toLowerCase().includes(company.toLowerCase()) : true;

        return matchesCreatedDateFrom && matchesCreatedDateTo && matchesLocation && matchesCompany;
    });

    return (
        <>
            <div className="flex flex-col gap-4 p-5 sm:flex-row items-center sm:gap-x-4">

                {/* Created Date From Filter */}
                <div className="flex flex-col m-2">
                    <label className="mb-1">Start Date:</label>
                    <input
                        type="date"
                        value={createdDateFrom}
                        onChange={(e) => setCreatedDateFrom(e.target.value)}
                        className="border rounded px-2 py-1  border-slate-200"
                    />
                </div>

                {/* Created Date To Filter */}
                <div className="flex flex-col m-2">
                    <label className="mb-1">End Date:</label>
                    <input
                        type="date"
                        min={createdDateFrom}
                        value={createdDateTo}
                        onChange={(e) => setCreatedDateTo(e.target.value)}
                        className="border rounded px-2 py-1  border-slate-200"
                    />
                </div>

                {/* Location Filter */}
                <div className="flex flex-col m-2">
                    <label className="mb-1">Location:</label>
                    <input
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="Enter location"
                        className="border rounded px-2 py-1  border-slate-200"
                    />
                </div>

                {/* Company Filter */}
                <div className="flex flex-col m-2">
                    <label className="mb-1">Company:</label>
                    <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Enter company name"
                        className="border rounded px-2 py-1  border-slate-200"
                    />
                </div>

                {/* Reset Button */}
                <div className="flex flex-col mt-2">
                    <button
                        onClick={() => {
                            setCreatedDateFrom('');
                            setCreatedDateTo('');
                            setLocation('');
                            setCompany('');
                        }}
                        type="button"
                        className="bg-success mt-4 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-200 ease-in-out"
                    >
                        Reset
                    </button>
                </div>
            </div>
            <table
                data-tw-merge=""
                className="w-full text-left border-b border-slate-200/60"
            >
                <TableHeader headings={companyHeadings} />
                <tbody>
                    {filteredBranches && filteredBranches.map((row, index) => (
                        <tr key={row.id} data-tw-merge="" className="[&_td]:last:border-b-0">
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                {index + 1}
                            </td>

                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <a className="whitespace-nowrap font-medium" href="">
                                    {row.branch_name}
                                </a>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                    {row.Company && row.Company ? (row.Company.company_name) : ''}

                                </div>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">

                                    {row.BranchUsers?.length} Users
                                </div>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                    {row.branch_location}
                                </div>
                            </td>

                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">
                                <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                    <Switch
                                        onChange={(checked) => handleStatusChange(checked, row.id)} // Event when the switch is toggled
                                        checked={row.status == 1} // Current state of the switch
                                        offColor="#888" // Customize off color
                                        onColor="#0f0" // Customize on color
                                        uncheckedIcon={false} // Remove unchecked icon
                                        checkedIcon={false} // Remove checked icon
                                    />
                                </div>
                            </td>


                            <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 relative border-dashed py-4 dark:bg-darkmode-600">
                                <div className="flex">
                                    {/* <Link to={`branches/edit/${row.id}`} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                        <Pencil />
                                    </Link>
                                    <button className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item text-danger">
                                        <Delete />
                                    </button> */}
                                    <Link to={`edit/${row.id}`} state={{ branchData: row }} className="mr-4">
                                        <PenSquare />
                                    </Link>

                                    <Link onClick={handleDelete(row.id)}>
                                        <Delete />
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default BranchTable;
