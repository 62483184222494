import React, { useEffect, useState } from "react";
import { API_URL } from "../../utils/api_const";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { RAZORPAY_KEY_ID } from "../../actions/authAction";
import { ToastContainer, toast } from 'react-toastify';

const StoragePlansDescription = () => {
    const [upgradeLoader, setUpgradeLoader] = useState(false);
    const [storagePlan, setStoragePlan] = useState([]);
    const dispatch = useDispatch();

    const fetchSubscriptionsStorage = async () => {
        try {
            // Replace with your API endpoint
            const response = await axios.get(`${API_URL}/subscription/storage`);
            setStoragePlan(response.data); // Assuming the data is returned directly as an array
            
            console.log(response);
        } catch (err) {
            console.error("Error fetching subscriptions:", err);
        }
    };

    useEffect(() => {
        fetchSubscriptionsStorage();
    }, [dispatch]);

    const loadRazorpay = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true);
            script.onerror = () => reject(false);
            document.body.appendChild(script);
        });
    };


    const upgradePlan = async (planId, planPrice) => {
        // Set the loading state to true
        setUpgradeLoader(true);

        const company = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : null;

        // Check if the company data exists
        if (company) {
            // try {
            //     // Make the API call to upgrade the subscription
            //     const res = await axios.post(`${API_URL}/companies/update-storage/${company.id}`, {
            //         plan_id: planId
            //     });

            //     // Check if the response status is 200 (success)
            //     if (res.status === 200) {
            //         console.log('Storage upgraded successfully!', res.data);

            //         // Simulate a delay before reloading the page to show the success state to the user
            //         setTimeout(() => {
            //             setUpgradeLoader(false);  // Stop the loader
            //             location.reload(); // Reload the page to reflect the new subscription
            //         }, 2000);
            //     } else {
            //         // Log an error message if the status is not 200
            //         console.error('Failed to upgrade subscription', res.data);
            //         setUpgradeLoader(false);  // Stop the loader on failure
            //     }
            // } catch (error) {
            //     // Handle any errors from the API call
            //     console.error('Error upgrading subscription:', error);
            //     setUpgradeLoader(false);  // Stop the loader on error
            // }
            try {
                const razorpayLoaded = await loadRazorpay();
                if (!razorpayLoaded) {
                    toast.error('Failed to load Razorpay SDK');
                    setUpgradeLoader(false);
                    return;
                }
    
                // Razorpay payment options
                const paymentOptions = {
                    key: RAZORPAY_KEY_ID, // Replace with your Razorpay Key ID
                    amount: planPrice, // Replace with the amount in the smallest currency unit (e.g., 100000 for ₹1000)
                    currency: 'INR',
                    name: 'Taxgem',
                    description: 'Upgrade Storage',
                    handler: async function (response) {
                        console.log('Payment Successful:', response);
    
                        // Make the API call to upgrade the subscription
                        try {
                            const res = await axios.post(`${API_URL}/companies/update-storage/${company.id}`, {
                                plan_id: planId,
                                payment_id: response.razorpay_payment_id, // Include payment ID
                            });
    
                            if (res.status === 200) {
                                console.log('Storage upgraded successfully!', res.data);
    
                                // Simulate a delay before reloading the page to show the success state to the user
                                setTimeout(() => {
                                    setUpgradeLoader(false); // Stop the loader
                                    toast.success('Plan upgraded successfully!');
                                    location.reload(); // Reload the page to reflect the new subscription
                                }, 2000);
                            } else {
                                console.error('Failed to upgrade subscription', res.data);
                                setUpgradeLoader(false);
                                toast.error('Failed to upgrade subscription.');
                            }
                        } catch (error) {
                            console.error('Error upgrading subscription:', error);
                            setUpgradeLoader(false);
                            toast.error('Error upgrading subscription.');
                        }
                    },
                    prefill: {
                        name: company.name, // Prefill with company name
                        email: company.email, // Prefill with company email
                    },
                    theme: {
                        color: '#5398E3', // Match your app's theme color
                    },
                };
    
                // Initialize Razorpay
                const rzp = new Razorpay(paymentOptions);
                rzp.on('payment.failed', function (response) {
                    console.error('Payment Failed:', response.error);
                    setUpgradeLoader(false);
                    toast.error('Payment failed. Please try again.');
                });
    
                // Open Razorpay modal
                rzp.open();
            } catch (error) {
                console.error('Error during payment initialization:', error);
                setUpgradeLoader(false);
                toast.error('Error initializing payment.');
            }
        } else {
            // If company data is missing from local storage
            console.error('Company data not found in localStorage');
            setUpgradeLoader(false);  // Stop the loader if company is null
        }
    };

    return (
        <div className="container">
            <ToastContainer />
            <div className=" plans-page-style" style={{
                background: "linear-gradient(16deg, #0c466d, #03075e)",
                textAlign: "left",
                color: "#fff",
                borderRadius: "10px"
            }}>
                <div className="scroll-container justify-center" style={{
                    paddingTop: "10%",
                    paddingBottom: "10%",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                }}>
                    <div className="scroll-content">
                        <div className="pricing-plan">
                            {/* {upgradeLoader && (
                                <div class="rl-loading-container">
                                    <div class="rl-loading-thumb rl-loading-thumb-1"></div>
                                    <div class="rl-loading-thumb rl-loading-thumb-2"></div>
                                    <div class="rl-loading-thumb rl-loading-thumb-3"></div>
                                </div>
                            )} */}
                            {storagePlan && storagePlan.data?.map((plan) => (
                                <div className="plan" key={plan.id}>
                                    <div className="first-cut">
                                        <div>
                                            <h2 className='mb-4' style={{ color: "#fff", fontWeight: "bold", fontSize: "30px" }}>{plan.name}</h2>
                                            <div className="price">
                                                <p className="whole">₹{plan.price}</p>
                                                <div className="price-details">
                                                    <p className="cent">.00</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p>{plan.size} {plan.type} Storage</p>
                                        <button
                                            onClick={() => upgradePlan(plan.id, (plan.price * 100))}
                                            key={plan.id}
                                            className={` mt-4 button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7 `}
                                        >
                                            {'Buy Now'}
                                        </button>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StoragePlansDescription;