import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { clearScheduleReports, getPendingBranchScheduleReports, getPendingScheduleReports, getScheduleReportById, getScheduleReportsByCompanyId, getUpcomingBranchScheduleReports, getUpcomingScheduleReports } from "../../features/scheduleReportSlice";
import { submitTaxReport } from "../../features/submitTaxReportSlice";
import { getCompanyById } from "../../features/companySlice";
import generateFiscalYears from "../dashboard/partials/generate_financial_year";
import { API_URL } from "../../utils/api_const";


const TaxReportAdminPage = () => {

    const location = useLocation();
    const { company_id, branch_id } = location.state || {};

    console.log(company_id);
    console.log(branch_id);

    const { filling } = useParams();
    const { id } = useParams();
    const { tax_service_id } = useParams();
    const { due_date } = useParams();
    const { check } = useParams();
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const [loader, setLoader] = useState(true);
    const { currentScheduleReport } = useSelector((state) => state.scheduleReports);
    const [companyId, setCompanyId] = useState(company_id);
    const [useridsub, setUserIdSub] = useState(0);
    const [branchId, setBranchId] = useState(branch_id);
    const [storageData, setStorageData] = useState(null);
    const { singleCompany } = useSelector((state) => state.companies);
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [taxReports, setTaxReports] = useState([{
        id: 1,
        registration_id: '',
        file: ''
    }]);


    const fiscalYears = generateFiscalYears();

    const [selectedFiscalYear, setSelectedFiscalYear] = useState(
        localStorage.getItem('selectedFiscalYear') || fiscalYears[fiscalYears.length - 1]
    );

    useEffect(() => {
        localStorage.setItem('selectedFiscalYear', selectedFiscalYear);
    }, [selectedFiscalYear]);

    useEffect(() => {

        setLoader(true);

        dispatch(getScheduleReportById({ id, type: check })).finally(() => setLoader(false));
        // Set loader state based on action completion
    }, [dispatch, id]);

    const parseStorageSize = (storageString) => {
        if (!storageString || typeof storageString !== 'string') {
            return 0; // Return 0 if it's undefined or not a valid string
        }

        const [value, unit] = storageString.split(' '); // Split the value and unit
        const size = parseFloat(value); // Convert the numeric part to a float

        if (isNaN(size)) {
            return 0; // Return 0 if the value part is not a valid number
        }

        // Convert to MB based on the unit
        switch (unit.toUpperCase()) {
            case 'TB':
                return size * 1024 * 1024; // TB to MB
            case 'GB':
                return size * 1024; // GB to MB
            case 'MB':
            default:
                return size; // Already in MB or unknown unit
        }
    };

    const handleTaxReportChange = (index, e) => {
        const { name, type, files } = e.target;
        const updatedTaxReports = [...taxReports];

        if (type === 'file') {
            const file = files[0];

            // Check if a file was selected
            if (file) {
                // Check file type
                const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                if (!allowedTypes.includes(file.type)) {
                    alert('Please upload a PDF or XLSX file.');
                    return;
                }

                // Check file size (1 MB limit)
                const maxSizeInMB = 1.5;
                if (file.size > maxSizeInMB * 1024 * 1024) {
                    alert(`File size should not exceed ${maxSizeInMB} MB.`);
                    e.target.value = '';
                    return;
                }

                // const usedStorageMB = parseStorageSize(storageData?.usedStorage); // Convert to MB
                // const totalStorageMB = parseStorageSize(storageData?.availableStorage); // Convert to MB

                // console.log(storageData?.availableStorage);

                // // Calculate new total used storage including the new file
                // const newTotalUsedStorage = usedStorageMB + file.size / (1024 * 1024); // Convert file size to MB

                // console.log(newTotalUsedStorage);
                // console.log(totalStorageMB);
                // // Check if new total used storage exceeds total storage
                // if (newTotalUsedStorage > totalStorageMB) {
                //     alert('Insufficient storage. Cannot upload this file.');
                //     e.target.value = ''; // Reset file input
                //     return;
                // }

                // If validation passes, update the file in the state
                updatedTaxReports[index][name] = file;
            }
        } else {
            updatedTaxReports[index][name] = e.target.value;
        }

        setTaxReports(updatedTaxReports);
    };


    const handleRemoveFile = (index) => {
        const updatedTaxReports = [...taxReports];
        updatedTaxReports[index].file = null;
        if (fileInputRef.current) {
            fileInputRef.current.value = '';  // Reset the file input field
        }  // Remove the file for the given index
        setTaxReports(updatedTaxReports);
    };
    // const handleTaxReportChange = (index, e) => {
    //     const { name, value, type, files } = e.target;
    //     const updatedTaxReports = [...taxReports];

    //     if (type === 'file') {
    //         updatedTaxReports[index][name] = files[0];
    //     } else {
    //         updatedTaxReports[index][name] = value;
    //     }

    //     setTaxReports(updatedTaxReports);
    // };

    const addNewVariant = () => {
        const company = JSON.parse(localStorage.getItem('company'));

        setTaxReports([
            ...taxReports,
            { id: taxReports.length + 1, registration_id: company?.registration, file: '' }, // Add default values
        ]);
    };

    const removeVariant = (id) => {
        setTaxReports(taxReports.filter(detail => detail.id !== id));
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log(companyId);
    //     console.log(branchId);
    //     console.log(taxReports);

    //     const formData = new FormData();
    //     formData.append('schedule_report_id', id);
    //     formData.append('companyId', companyId);
    //     formData.append('branchId', branchId);
    //     formData.append('tax_service_id', tax_service_id);
    //     formData.append('submitted_by', 'company');
    //     formData.append('user_id', companyId);
    //     formData.append('due_date', due_date);
    //     // Convert taxReports to a JSON string and append
    //     // formData.append('taxReports', JSON.stringify(taxReports));

    //     // Append each file
    //     taxReports.forEach((report, index) => {
    //         formData.append(`taxReports[${index}][registration_id]`, report.registration_id);
    //         if (report.file) {
    //             formData.append(`taxReports[${index}][file]`, report.file);
    //         }
    //     });
    //     dispatch(submitTaxReport(formData));
    //     dispatch(clearScheduleReports());
    //     dispatch(getPendingScheduleReports({id: companyId, year: selectedFiscalYear}));
    //     dispatch(getUpcomingScheduleReports({id: companyId, year: selectedFiscalYear}));

    //     navigator(`/company/tax/${filling}`);
    //     // Handle form submission here
    // };


    // Function to fetch storage data
    const fetchStorageData = async () => {
        let login_type = localStorage.getItem('login_type');
        const company = JSON.parse(localStorage.getItem('company'));
        if (company) {
            try {
                let companyId = 0;
                if (login_type == 'company') {
                    companyId = company.id;
                } else {
                    companyId = company.company_id;
                }
                const response = await fetch(`${API_URL}/companies/storage-data/${companyId}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch storage data");
                }
                const data = await response.json();
                setStorageData(data);
            } catch (err) {
                console.log(err);
            }
        }
    };

    // UseEffect to call the fetchStorageData function when component mounts
    useEffect(() => {
        fetchStorageData();
    }, []);

    useEffect(() => {
        dispatch(getCompanyById(company_id));
    },[dispatch]);

    // useEffect(() => {
    //     if(singleCompany){}
    // },[singleCompany]);

    useEffect(() => {
        if (currentScheduleReport) {

            if (currentScheduleReport?.TaxService?.service_name) {
            
                if (singleCompany) {
                    if (currentScheduleReport?.TaxService?.service_name.includes('ITR')) {
                        setTaxReports(prevReports => prevReports.map(report =>
                            report.id === 1 ? { ...report, registration_id: singleCompany?.pan_number } : report
                        ));
                    } else if (currentScheduleReport?.TaxService?.service_name.includes('TDS') || currentScheduleReport?.TaxService?.service_name.includes('TCS')) {
                        setTaxReports(prevReports => prevReports.map(report =>
                            report.id === 1 ? { ...report, registration_id: singleCompany?.tan_number } : report
                        ));
                    } else if (currentScheduleReport?.TaxService?.service_name.includes('ROC')) {
                        setTaxReports(prevReports => prevReports.map(report =>
                            report.id === 1 ? { ...report, registration_id: singleCompany?.cin_number } : report
                        ));
                    } else {
                        setTaxReports(prevReports => prevReports.map(report =>
                            report.id === 1 ? { ...report, registration_id: singleCompany.registration } : report
                        ));
                    }
                }
            }
        }
    }, [currentScheduleReport]);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const isValid = taxReports.every(report =>
            report.registration_id && report.file
        );

        if (!isValid) {
            alert('Please ensure all tax reports have a registration number and a file.');
            return;
        }
        console.log(taxReports);
        const logintype = localStorage.getItem('login_type');
        const formData = new FormData();

        if (check == 'company') {
            formData.append('schedule_report_id', id);
            formData.append('branch_schedule_report_id', 0);
        } else {
            formData.append('schedule_report_id', 0);
            formData.append('branch_schedule_report_id', id);
        }
        formData.append('companyId', companyId);
        formData.append('branchId', branchId);
        formData.append('tax_service_id', tax_service_id);
        formData.append('submitted_by', logintype);
        formData.append('user_id', useridsub);
        formData.append('due_date', due_date);

        // Append each file
        taxReports.forEach((report, index) => {
            formData.append(`taxReports[${index}][registration_id]`, report.registration_id);
            if (report.file) {
                formData.append(`taxReports[${index}][file]`, report.file);
            }
        });

        // Set loading to true before the async actions
        setLoading(true);

        try {
            await dispatch(submitTaxReport(formData));
            dispatch(clearScheduleReports());
            if (logintype == 'company') {
                await Promise.all([
                    dispatch(getPendingScheduleReports({ id: companyId, year: selectedFiscalYear })),
                    dispatch(getUpcomingScheduleReports({ id: companyId, year: selectedFiscalYear }))
                ]);
            } else {
                await Promise.all([
                    dispatch(getPendingBranchScheduleReports({ id: companyId, year: selectedFiscalYear })),
                    dispatch(getUpcomingBranchScheduleReports({ id: companyId, year: selectedFiscalYear }))
                ]);
            }

            // Navigate to another page
            if(filling == 'pending-filling'){
                navigator(`/report/return-filling`);
            }else{
                navigator(`/report/annual-filling`);
            }
        } finally {
            // Set loading to false after the async actions complete
            setLoading(false);
        }
    };

    return (
        <div className="container">
            {loading && <div className="[&amp;.loading-page--before-hide]:h-screen [&amp;.loading-page--before-hide]:relative loading-page loading-page--before-hide [&amp;.loading-page--before-hide]:before:block [&amp;.loading-page--hide]:before:opacity-0 before:content-[''] before:transition-opacity before:duration-300 before:hidden before:inset-0 before:h-screen before:w-screen before:fixed before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:z-[60] [&amp;.loading-page--before-hide]:after:block [&amp;.loading-page--hide]:after:opacity-0 after:content-[''] after:transition-opacity after:duration-300 after:hidden after:h-16 after:w-16 after:animate-pulse after:fixed after:opacity-50 after:inset-0 after:m-auto after:bg-loading-puff after:bg-cover after:z-[61]"></div>}
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            {/* Add content here if needed */}
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col p-5 box box--stacked">
                                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                                        <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                                            <i
                                                data-tw-merge=""
                                                data-lucide="chevron-down"
                                                className="w-5 h-5 mr-2 stroke-[1.3]"
                                            />
                                            Compliances: {currentScheduleReport?.TaxService?.service_name} ({currentScheduleReport?.session})
                                        </div>
                                        {taxReports.map((detail, index) => {

                                            return (
                                                <div key={index} className="mt-5 pb-5">
                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                        <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">Registration Number</div>
                                                                    <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                        Required
                                                                    </div>
                                                                </div>
                                                                <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                                    Enter Registration Number
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 w-full mt-3 xl:mt-0">
                                                            <input
                                                                type="text"
                                                                name="registration_id"
                                                                value={detail.registration_id}
                                                                onChange={(e) => handleTaxReportChange(index, e)}
                                                                placeholder="Registration Number"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                        <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">Upload Files </div>
                                                                    <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                        Required
                                                                    </div>
                                                                </div>
                                                                <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                                    Upload Files <span className="text-danger">( Max File Size 1.5 MB )</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 flex w-full mt-3 xl:mt-0 ml-2">
                                                            <input

                                                                type="file"
                                                                name="file"
                                                                accept=".pdf"
                                                                onChange={(e) => handleTaxReportChange(index, e)}
                                                                placeholder="Upload File"
                                                                ref={fileInputRef}
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 dark:bg-darkmode-800 dark:border-transparent dark:placeholder:text-slate-500/80"
                                                                style={{
                                                                    border: "2px solid #eee",
                                                                    borderRadius: "0px"
                                                                }}
                                                            />
                                                            {detail.file && (
                                                                <button
                                                                    type="button"
                                                                    onClick={(e) => handleRemoveFile(index, e)}
                                                                    className="btn bg-danger text-white pl-2 pr-2 text-center"
                                                                >
                                                                    &times;
                                                                </button>
                                                            )}

                                                        </div>
                                                        {index != 0 && (
                                                            <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                                                <button
                                                                    data-tw-merge=""
                                                                    className="btn-danger transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-primary text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 w-full border-dashed btn-danger"
                                                                    onClick={() => removeVariant(detail.id)}
                                                                >
                                                                    <i data-tw-merge="" data-lucide="x" className="mr-2 h-4 w-4 stroke-[1.3]" />
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">
                                    {/* <button
                                    type="button"
                                    onClick={addNewVariant}
                                    className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                                >
                                    <i
                                        data-tw-merge=""
                                        data-lucide="pen-line"
                                        className="mr-2 h-4 w-4 stroke-[1.3]"
                                    />
                                    Add More
                                </button> */}
                                    <button
                                        type="submit"

                                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                                    >
                                        <i
                                            data-tw-merge=""
                                            data-lucide="pen-line"
                                            className="mr-2 h-4 w-4 stroke-[1.3]"
                                        />
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TaxReportAdminPage;