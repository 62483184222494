import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanyData } from "../../features/companySlice";
import { fetchBranches, fetchBranchesByCompanyId } from "../../features/branchSlice";
import { useNavigate, useParams } from "react-router-dom";
import { addBranchScheduleReport, getScheduleReportById, updateBranchScheduleReport } from "../../features/scheduleReportSlice";
import { fetchTaxServices, fetchTaxServicesCompany } from "../../features/taxServiceSlice";
import $ from 'jquery';
import moment from "moment";
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';


const generateFiscalYears = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const startYear = 2019;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push(`${year}-${year + 1}`);
    }

    // if (currentMonth > 2) { // If current month is after March (index 2)
    //   years.push(`${currentYear}-${currentYear + 1}`);
    // }

    return years;
};

const NewBranchScheduleReport = () => {
    const { id } = useParams();
    const selectRef = useRef();
    const fiscalYears = generateFiscalYears();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentScheduleReport, status } = useSelector((state) => state.scheduleReports);
    const { taxServices } = useSelector((state) => state.taxService);
    const { branches, error } = useSelector((state) => state.branches);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [type, setServiceType] = useState('');
    const [tax_service_id, setTaxServiceType] = useState('');
    const [session, setSessionData] = useState('');
    const [branch_id, setBranchData] = useState('');
    const [period, setPeriodData] = useState('');


    // const [selectedServices, setSelectedServices] = useState([]);

    const handleSelect = (id) => {
        setTaxServiceType((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((serviceId) => serviceId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    const selectAllSubmissions = () => {
        if (tax_service_id.length === taxServices.length) {
            setTaxServiceType([]); // Deselect all if already selected
        } else {
            setTaxServiceType(taxServices.map((service) => service.id)); // Select all
        }
    };


    useEffect(() => {
        const $select = $(selectRef.current);
        $select.select2();

        $select.on('change', function (e) {
            const value = $(this).val();
            setTaxServiceType(value);
        });

        return () => {
            $select.select2('destroy');
        };
    }, [setTaxServiceType]);

    useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        if (company) {
            const companyId = company.id;
            // console.log('companydata $data ', company);
            dispatch(fetchBranchesByCompanyId(companyId));
        }
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(getScheduleReportById(id)).then((action) => {
                if (action.payload) {
                    const scheduleReport = action.payload;
                    setSelectedCompanyId(scheduleReport.company_id);
                    setServiceType(scheduleReport.type);
                    setTaxServiceType(scheduleReport.tax_service_id);
                    setSessionData(scheduleReport.session);
                    setBranchData(scheduleReport.branch_id);
                    setPeriodData(scheduleReport.period);
                }
            });
        }
    }, [dispatch, id]);

    useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        if (company) {
            const companyId = company.id;
            dispatch(fetchTaxServicesCompany({ id: companyId }));
        }
    }, [type, dispatch]);


    // useEffect(() => {
    //     if (currentScheduleReport) {
    //       setFormData(currentScheduleReport);
    //     }
    //   }, [currentScheduleReport]);

    const handleServiceType = (e) => {
        setServiceType(e.target.value);
        // dispatch(fetchTaxServicesCompany({ service_type: type }));
    }

    function getFrequencyText(row) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (row.frequency === 'monthly') {
            return `${row.day}th of Every Month`;
        } else if (row.frequency === 'quarterly') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const quarterMonths = [startMonthIndex, (startMonthIndex + 3) % 12, (startMonthIndex + 6) % 12, (startMonthIndex + 9) % 12];
                const quarterMonthNames = quarterMonths.map(index => months[index]);
                return `${row.day} of ${quarterMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency === 'semi-annually') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const halfYearMonths = [startMonthIndex, (startMonthIndex + 6) % 12];
                const halfYearMonthNames = halfYearMonths.map(index => months[index]);
                return `${row.day} of ${halfYearMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency == 'annually') {
            const date = moment(row.date);
            const day = date.format('D'); // Day of the month
            const month = date.format('MMMM'); // Full month name
            return `${day} of Every ${month}`;
        } else {
            return '';
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            company_id: selectedCompanyId,
            branch_id: branch_id,
            tax_service_id: JSON.stringify(tax_service_id),
            type: type,
            session: session,
            period: period,
            status: 0,
        };


        console.log(formData);
        try {
            if (id) {
                // Update existing schedule report
                const resultAction = await dispatch(updateBranchScheduleReport({ id, scheduleReportData: formData }));
                if (updateBranchScheduleReport.fulfilled.match(resultAction)) {
                    navigate('/schedule');
                } else {
                    console.error('Failed to update schedule report:', resultAction.payload);
                }
            } else {
                // Create new schedule report
                const resultAction = await dispatch(addBranchScheduleReport(formData));
                if (addBranchScheduleReport.fulfilled.match(resultAction)) {
                    navigate('/company/branch/schedule');
                } else {
                    console.error('Failed to create schedule report:', resultAction.payload);
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    console.log(branches);
    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Add Schedule Report
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col p-5 box box--stacked">
                                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                                        <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                                            <i
                                                data-tw-merge=""
                                                data-lucide="chevron-down"
                                                className="w-5 h-5 mr-2 stroke-[1.3]"
                                            />
                                            Schedule Information
                                        </div>
                                        <div className="mt-5">
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Report Type</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Schedule Sevice type.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <select
                                                        data-tw-merge=""
                                                        onChange={handleServiceType}
                                                        name="type"
                                                        value={type}
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                                    >
                                                        <option value=""> -- All Filling --</option>
                                                        <option value="return_filling">Periodic Fillings</option>
                                                        <option value="annual_filling">Annual Fillings</option>

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Tax Report Type</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Schedule Tax Sevice type.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    {/* <select
                                                        data-tw-merge=""
                                                        ref={selectRef}
                                                        multiple
                                                        name="tax_service_id"
                                                        value={tax_service_id}
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                                    >
                                                        <option value=""> -- Select Tax Service --</option>
                                                        {taxServices && taxServices
                                                            .filter(service => 
                                                                type === 'annual_filling' ? service.service_type === 'annual_filling' :
                                                                type === 'return_filling' ? service.service_type === 'return_filling' :
                                                                true // default case to include all services if the type doesn't match 'annual_filling' or 'return_filling'
                                                            )
                                                            .map((service) => (
                                                                <option key={service.id} value={service.id}>
                                                                    {service.service_name}
                                                                </option>
                                                            ))
                                                        }


                                                    </select> */}
                                                     <div className="flex flex-wrap block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                        <span
                                                            key={`all`}
                                                            onClick={selectAllSubmissions}
                                                            className={`badge m-2 p-3`}
                                                            style={{ border: `1px solid`, borderRadius: '20px', cursor: 'pointer' }}
                                                        >
                                                            Select All
                                                        </span>
                                                        {taxServices && taxServices
                                                            .filter(service => 
                                                                type === 'annual_filling' ? service.service_type === 'annual_filling' :
                                                                type === 'return_filling' ? service.service_type === 'return_filling' :
                                                                true // Include all services if type doesn't match
                                                            )
                                                            .map((service, index) => (
                                                                <span
                                                                    key={index}
                                                                    onClick={() => handleSelect(service.id)}
                                                                    className={`badge m-2 p-3 ${tax_service_id.includes(service.id) ? 'bg-success text-white' : 'bg-light text-dark'}`}
                                                                    style={{ border: `1px solid ${tax_service_id.includes(service.id) ? 'green' : 'black'}`, borderRadius: '20px', cursor: 'pointer' }}
                                                                >
                                                                    {service.service_name}<br/>
                                                                    <small style={{fontSize: "8px"}}>{getFrequencyText(service)}</small>
                                                                </span>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Branch</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Branch.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <select
                                                        data-tw-merge=""
                                                        id="branch_data"
                                                        onChange={(e) => setBranchData(e.target.value)}
                                                        name="branch_id"
                                                        value={branch_id}
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                                    >
                                                        <option value=""> -- Select Branch --</option>
                                                        {branches && branches.map((branch) => (
                                                            <option key={branch.id} value={branch.id}>
                                                                {branch.branch_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Period</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Period.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <select
                                                        data-tw-merge=""
                                                        onChange={(e) => setSessionData(e.target.value)}
                                                        name="session"
                                                        value={session}
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                                    >
                                                        <option value=""> -- Select Period --</option>
                                                        {/* <option value="2019-20">2019-2020</option>
                                                        <option value="2020-21">2020-2021</option>
                                                        <option value="2021-22">2021-2022</option>
                                                        <option value="2022-23">2022-2023</option>
                                                        <option value="2023-24">2023-2024</option>
                                                        <option value="2024-25">2024-2025</option> */}
                                                        {fiscalYears.map((year, index) => (
                                                            <option key={index} value={year}>
                                                                {year.replace('-', ' - ')}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">

                                    <button
                                        type="submit"
                                        data-tw-merge=""
                                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                                    >
                                        <i
                                            data-tw-merge=""
                                            data-lucide="pen-line"
                                            className="mr-2 h-4 w-4 stroke-[1.3]"
                                        />
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewBranchScheduleReport;