import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';


// Async actions
export const addCompanyTaxInfo = createAsyncThunk(
  'companyTaxInfo/add',
  async (data) => {
    const response = await axios.post(`${API_URL}/company-tax-info/add`, data);
    return response.data;
  }
);

export const updateCompanyTaxInfo = createAsyncThunk(
  'companyTaxInfo/update',
  async ({ id, data }) => {
    const response = await axios.put(`${API_URL}/update/${id}`, data);
    return response.data;
  }
);

export const deleteCompanyTaxInfo = createAsyncThunk(
  'companyTaxInfo/delete',
  async (id) => {
    await axios.delete(`${API_URL}/delete/${id}`);
    return id;
  }
);

const companyTaxInfoSlice = createSlice({
  name: 'companyTaxInfo',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCompanyTaxInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCompanyTaxInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.push(action.payload);
      })
      .addCase(addCompanyTaxInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateCompanyTaxInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCompanyTaxInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.data.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(updateCompanyTaxInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteCompanyTaxInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCompanyTaxInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = state.data.filter((item) => item.id !== action.payload);
      })
      .addCase(deleteCompanyTaxInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default companyTaxInfoSlice.reducer;
