import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TableHeader from "../../../utils/table_headings";
import { useDispatch, useSelector } from "react-redux";
import { deleteBranchUser, fetchBranchUsers } from "../../../features/branchUserSlice";
import { Delete, PenSquare } from "lucide-react";
import Swal from "sweetalert2";

const BranchUsers = () => {
    const dispatch = useDispatch();
    const { branch_id }  = useParams();
    const { branchUsers, status, error } = useSelector((state) => state.branchUsers);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRefs = useRef({});
    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        Object.values(dropdownRefs.current).forEach(ref => {
            if (ref && !ref.contains(event.target)) {
                setOpenDropdownId(null);
            }
        });
    };
    const companyHeadings = ['User Name', 'Branch Name', 'Email address', 'Actions'];
    useEffect(() => {
        dispatch(fetchBranchUsers(branch_id));
    }, [branch_id]);

    const handleDelete = (id) => () => {
        console.log(id);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteBranchUser(id));
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Successfully Deleted',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                // setScheduleReports(prevReports =>
                //     prevReports.filter(report => report.id !== id)
                // );
            }
        });
    }

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Branch Users
                        </div>
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            <Link
                                to={`/company/branch/users/add/${branch_id}`}
                                data-tw-merge=""
                                className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200"
                            >
                                <i
                                    data-tw-merge=""
                                    data-lucide="pen-line"
                                    className="mr-2 h-4 w-4 stroke-[1.3]"
                                />
                                Add New User
                            </Link>
                        </div>
                    </div>
                    <div className="mt-3.5 flex flex-col gap-8">

                        <div className="box box--stacked flex flex-col">
                            <div className="flex flex-col gap-y-2 p-5 sm:flex-row sm:items-center">

                            </div>
                            <div className="overflow-auto xl:overflow-visible">
                                <table
                                    data-tw-merge=""
                                    className="w-full text-left border-b border-slate-200/60"
                                >
                                    <TableHeader
                                     headings={companyHeadings} />
                                    <tbody>
                                        {branchUsers && branchUsers.map((row, index) => (
                                            <tr key={row.id} data-tw-merge="" className="[&_td]:last:border-b-0">
                                                <td
                                                    data-tw-merge=""
                                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                                                >
                                                    {index + 1}
                                                </td>
                                                <td
                                                    data-tw-merge=""
                                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                                                >
                                                    <a className="whitespace-nowrap font-medium" href="">
                                                        {row.username}
                                                    </a>
                                                </td>
                                                <td
                                                    data-tw-merge=""
                                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                                                >
                                                    <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                                    {row.Branch?.branch_name}

                                                    </div>
                                                </td>
                                                <td
                                                    data-tw-merge=""
                                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                                                >
                                                    <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                                        {row.email}
                                                    </div>
                                                </td>


                                                <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 relative border-dashed py-4 dark:bg-darkmode-600">
                                                    <div className="flex">
                                                        <Link className="mr-5 border-2 p-1 border-radius-2 bg-primary text-white" to={`/company/branch/user/change-password`}
                                                            style={{    borderRadius: "22px",
                                                                padding: "4px 10px"
                                                            }}
                                                            state={row.email}
                                                        >Change Password</Link>
                                                        <Link className="mr-5" to={`/company/branch/users/edit/${row.branch_id}/${row.id}`} state={{ branchUser: row}}>
                                                            <PenSquare/>
                                                        </Link>
                                                       
                                                        <Link onClick={handleDelete(row.id)}>
                                                            <Delete/>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex-reverse flex flex-col-reverse flex-wrap items-center gap-y-2 p-5 sm:flex-row">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BranchUsers;