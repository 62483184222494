import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import '../plans.css';
import { fetchTaxServices } from "../../features/taxServiceSlice";
import { useDispatch, useSelector } from 'react-redux';
const PlansSubscription = () => {
    const dispatch = useDispatch();
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true); // To handle loading state
    const [error, setError] = useState(null); // To handle any errors
    const taxServices = useSelector((state) => state.taxService.taxServices);


    useEffect(() => {
        dispatch(fetchTaxServices());
    }, [dispatch]);

    // Function to fetch subscriptions from the API
    const fetchSubscriptions = async () => {
        try {
            // Replace with your API endpoint
            const response = await axios.get('https://app.taxgem.co.in/backend/api/subscription/plan');
            setSubscriptions(response.data); // Assuming the data is returned directly as an array
            setLoading(false);
        } catch (err) {
            console.error("Error fetching subscriptions:", err);
            setError('Failed to fetch subscription plans.');
            setLoading(false);
        }
    };

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const convertDaysToReadableFormat = (days) => {
        if (days < 30) {
            return `${days} day${days > 1 ? 's' : ''}`;
        } else if (days < 365) {
            const months = Math.floor(days / 30);
            const remainingDays = days % 30;
            return `${months} month${months > 1 ? 's' : ''}${remainingDays ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
        } else {
            const years = Math.floor(days / 365);
            const remainingMonths = Math.floor((days % 365) / 30);
            const remainingDays = days % 365 % 30;
            return `${years} year${years > 1 ? 's' : ''}${remainingMonths ? ` ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}` : ''}${remainingDays ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
        }
    };


    return (
        <div className="plans-page-style">
            <img data-placement="top" src="/logo_ne.png" alt="TaxGem" style={{ position: "absolute", left: "5px", top: "5px", width: "100px" }} />

            <div className="main " style={{ marginTop: "30px" }}>
                <table className="price-table">
                    <tbody>

                        <tr className="price-table-head">
                            <td > Particular</td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td key={index}>
                                    {data.name}

                                </td>
                            )}
                        </tr>
                        <tr>
                            <td />
                            {subscriptions && subscriptions.map((plan, index) =>
                                <td key={index} className="price">
                                    ₹{(plan.price)}<br />
                                    <Link to={`/register?plan=${plan.id}`} class="button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7">Get Started</Link>
                                </td>
                            )}

                        </tr>
                        <tr>
                            <td>
                                <a href="#wordpress-asset-updates" className="price-table-help">
                                    <i className="far fa-fw fa-question-circle" />
                                </a>{" "}
                                Registration Charges (One Time)
                            </td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td>₹{data.registration_charge || 0}</td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                <a href="#wordpress-asset-updates" className="price-table-help">
                                    <i className="far fa-fw fa-question-circle" />
                                </a>{" "}
                                Plan type
                            </td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td>{data.plan_type == 'Other' ? data.start_from + ' days' : (data.plan_type || '')}</td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                <a href="#wordpress-asset-updates" className="price-table-help">
                                    <i className="far fa-fw fa-question-circle" />
                                </a>{" "}
                                Branches & Charges
                            </td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td>{data.branches_charge ? '₹' + data.branches_charge + '/ Branches' : <span className='text-danger'>X</span>}</td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                <a href="#wordpress-asset-updates" className="price-table-help">
                                    <i className="far fa-fw fa-question-circle" />
                                </a>{" "}
                                24X7 Support
                            </td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td>{data.support_24x7 == 'Yes' ?
                                    <span className='text-success'>✔</span> : <span className='text-danger'>X</span>}</td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                <a href="#wordpress-asset-updates" className="price-table-help">
                                    <i className="far fa-fw fa-question-circle" />
                                </a>{" "}
                                Regular Updates
                            </td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td><span className='text-success'>✔</span></td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                <a href="#wordpress-asset-updates" className="price-table-help">
                                    <i className="far fa-fw fa-question-circle" />
                                </a>{" "}
                                Due Date Reminder
                            </td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td><span className='text-success'>✔</span></td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                <a href="#wordpress-asset-updates" className="price-table-help">
                                    <i className="far fa-fw fa-question-circle" />
                                </a>{" "}
                                Custom & Secure Business data
                            </td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td><span className='text-success'>✔</span></td>
                            )}
                        </tr>
                        <tr>
                            <td>
                                <a href="#wordpress-asset-updates" className="price-table-help">
                                    <i className="far fa-fw fa-question-circle" />
                                </a>{" "}
                                Security & Management Console
                            </td>
                            {subscriptions && subscriptions.map((data, index) =>
                                <td><span className='text-success'>✔</span></td>
                            )}
                        </tr>
                        {taxServices?.map((taxData, ind) => (
                            <tr key={`tax-${ind}`}>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    {taxData.service_name}
                                </td>
                                {subscriptions?.map((data, index) => {
                                    let selectedServices = [];
                                    try {
                                        selectedServices = JSON.parse(data.selected_services);
                                    } catch (e) {
                                        console.error('Error parsing selected_services:', e);
                                    }
                                    const isSelected = selectedServices?.includes(taxData.id);
                                    return (
                                        <td key={`sub-${ind}-${index}`}>
                                            {isSelected ? (
                                                <span className='text-success'>✔</span>
                                            ) : (
                                                <span className='text-danger'>X</span>
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                        <tr>
                            <td><strong style={{ fontSize: "20px"}}>Total</strong></td>
                            {subscriptions?.map((plan, index) =>
                                <td className="price">
                                    ₹{(plan.price + plan.registration_charge)} <small style={{ fontSize:"12px"}}>(+{(plan.price + plan.registration_charge) * 0.18} GST)</small><br/> {plan.branches_charge?<small className='text-black' style={{ fontSize:"10px"}}>(₹250 for per Branches will be Additional)</small>:""}
                                </td>
                            )}

                        </tr>
                        <tr>
                            <td></td>
                            {subscriptions?.map((plan, index) =>
                                <td className="price">
                                    <Link to={`/register?plan=${plan.id}`} class="button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7">Get Started</Link>
                                </td>
                            )}

                        </tr>
                        <tr>
                            <td colSpan={4} style={{ textAlign: 'center'}}><p>All rates are exclusive of GST <br/><a href='https://taxgem.co.in/terms-and-conditions' target='_blank'>Terms & Condition apply</a></p></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

    );
}
export default PlansSubscription;