// src/features/submitTaxReportSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';

// Define the initial state
const initialState = {
  taxReports: [],
  fetchedTaxReports: [],
  status: 'idle',
  fetchStatus: 'idle',
  error: null,
  fetchError: null
};

// Define the async thunk for submitting tax reports
export const submitTaxReportOther = createAsyncThunk(
  'submitTaxReport/submitOther',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/submit-tax/submit-other`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define the async thunk for submitting tax reports
export const submitTaxReport = createAsyncThunk(
  'submitTaxReport/submit',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/submit-tax/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define the async thunk for fetching tax reports
export const fetchTaxReports = createAsyncThunk(
  'submitTaxReport/fetch',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/submit-tax`, { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


// Define the async thunk for fetching tax reports
export const fetchTaxOtherReports = createAsyncThunk(
  'submitTaxReport/fetchOther',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_URL}/submit-tax/other-fillings`, { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTaxReport = createAsyncThunk(
  'submitTaxReport/delete',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${API_URL}/submit-tax/${id}`);
      return response.data; // return the deleted item or success message
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



// Create the slice
const submitTaxReportSlice = createSlice({
  name: 'submitTaxReport',
  initialState,
  reducers: {
    resetState(state) {
      state.status = 'idle';
      state.error = null;
      state.fetchStatus = 'idle';
      state.fetchError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitTaxReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitTaxReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.taxReports = action.payload; // Update with response data
      })
      .addCase(submitTaxReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(submitTaxReportOther.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitTaxReportOther.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.taxReports = action.payload; // Update with response data
      })
      .addCase(submitTaxReportOther.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchTaxReports.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(fetchTaxReports.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        state.fetchedTaxReports = action.payload; // Update with response data
      })
      .addCase(fetchTaxReports.rejected, (state, action) => {
        state.fetchStatus = 'failed';
        state.fetchError = action.payload;
      })
      .addCase(fetchTaxOtherReports.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(fetchTaxOtherReports.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        state.fetchedTaxReports = action.payload; // Update with response data
      })
      .addCase(fetchTaxOtherReports.rejected, (state, action) => {
        state.fetchStatus = 'failed';
        state.fetchError = action.payload;
      })
      .addCase(deleteTaxReport.pending, (state) => {
        state.deleteStatus = 'loading';
      })
      .addCase(deleteTaxReport.fulfilled, (state, action) => {
        state.deleteStatus = 'succeeded';
        // Remove the deleted report from the taxReports array
        // state.taxReports = state.taxReports.filter(report => report.id !== action.payload.id);
      })
      .addCase(deleteTaxReport.rejected, (state, action) => {
        state.deleteStatus = 'failed';
        state.deleteError = action.payload;
      });
  }
});

// Export the actions and reducer
export const { resetState } = submitTaxReportSlice.actions;
export default submitTaxReportSlice.reducer;
