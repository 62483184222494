import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { fetchTaxServices } from "../../features/taxServiceSlice";
import { addPlan, fetchSinglePlan, fetchStorageSubscriptions, updatePlan } from '../../features/subscriptionsSlice';
import Swal from "sweetalert2";
import Switch from 'react-switch';

const EditSubscription = () => {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { id } = useParams();
    const taxServices = useSelector((state) => state.taxService.taxServices);
    const [is_branches, setIsBranches] = useState(false);
    const [selectedServices, setSelectedServices] = useState([]);


    const [formData, setFormData] = useState({
        name: '',
        users: '',
        image: null,
        price: '',
        description: [],
        storage_id: '',
        plan_type: '',
        start_from: '',
        registration_charge: '0',
        branches_charge: '0',
        support_24x7: '',
        due_date_reminder: '',

    });

    const [descriptionPoint, setDescriptionPoint] = useState('');


    // Toggle the selection of a tax service
    const handleToggle = (id) => {
        if (selectedServices.includes(id)) {
            // If the ID is already selected, remove it
            setSelectedServices((prev) => prev.filter((serviceId) => serviceId !== id));
        } else {
            // If the ID is not selected, add it
            setSelectedServices((prev) => [...prev, id]);
        }
    };



    const handleSelectAll = () => {
        if (selectedServices.length === taxServices.length) {
            // Deselect all
            setSelectedServices([]);
        } else {
            // Select all
            const allServiceIds = taxServices.map((service) => service.id);
            setSelectedServices(allServiceIds);
        }
    };



    const handleChange = (e) => {
        if (e.target.type === 'file') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.files[0]
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleAddDescription = () => {
        if (descriptionPoint) {
            setFormData({
                ...formData,
                description: [...formData.description, descriptionPoint],
            });
            setDescriptionPoint(''); // Clear the input after adding
        }
    };

    const handleRemoveDescription = (index) => {
        const newDescriptions = formData.description.filter((_, i) => i !== index);
        setFormData({ ...formData, description: newDescriptions });
    };


    const { storageSubscriptions, singlePlan, loading, error } = useSelector((state) => state.subscriptions);

    useEffect(() => {
        dispatch(fetchStorageSubscriptions());
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchTaxServices());
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            dispatch(fetchSinglePlan(id));
        }
    }, [dispatch]);


    useEffect(() => {
        if (singlePlan) {
            setFormData({
                name: singlePlan.name,
                users: singlePlan.users,
                price: singlePlan.price,
                description: singlePlan.description ? singlePlan.description.split(',').map(item => item.trim()) : [],
                storage_id: singlePlan.storage_id,
                plan_type: singlePlan.plan_type,
                start_from: singlePlan.start_from,
                image: null,
                registration_charge: singlePlan.registration_charge,
                branches_charge: singlePlan.branches_charge,
                support_24x7: singlePlan.support_24x7,
                due_date_reminder: singlePlan.due_date_reminder,
            });
            setIsBranches(singlePlan.users === 1);

            setSelectedServices(JSON.parse(singlePlan.selected_services) || []);
        }
    }, [singlePlan]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }
        formDataObj.append('selected_services', JSON.stringify(selectedServices));
        console.log(formDataObj);

        const resultAction = await dispatch(updatePlan({ id: id, plan: formDataObj }));

        if (updatePlan.fulfilled.match(resultAction)) {
            Swal.fire({
                icon: 'success',
                title: '',
                text: 'Successfully Updated',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            navigator(`/subscriptions/plan`); // Navigate after successful submission
        } else {
            Swal.fire("Error!", resultAction.error.message, "error");
        }


    };


    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium">
                            Add Storage Item
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit} className="mt-3.5">
                                <div className="p-5 border rounded-m box box--stacked">
                                    <h2 className="font-medium">Information</h2>

                                    {/* Total Users */}
                                    <div className="flex flex-col mt-5">
                                        <div className='flex flex-col w-full'>
                                            <label className="font-medium">Plan Name <span className='text-danger'>*</span></label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder="Plan Name"
                                                required
                                                className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                            />
                                        </div>
                                        <div className='flex flex-col w-80 mt-4'>
                                            <label className="font-medium">Is this plan contain Branches?</label>
                                            <input
                                                type="checkbox"
                                                name="users"
                                                checked={is_branches}
                                                value={is_branches}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        ['users']: e.target.checked ? 1 : 0
                                                    });
                                                    setIsBranches(e.target.checked);
                                                }}
                                                placeholder="Enter total number of users"
                                                
                                                className="transition duration-200 mt-2 ease-in-out text-sm border-slate-200 shadow-sm rounded-md"
                                            />
                                        </div>
                                        <div className='flex flex-col w-full mt-4'>
                                            {is_branches && (<input
                                                type="number"
                                                name="branches_charge"
                                                value={formData.branches_charge}
                                                onChange={handleChange}
                                                placeholder="Cost of Single Branch *"
                                                required
                                                className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                            />)}
                                        </div>
                                    </div>

                                    <div className='flex m-auto mt-5'>
                                        <div className='flex flex-col w-full mr-2'>
                                            <label className="font-medium">Plan Price <span className='text-danger'>*</span></label>
                                            <input
                                                type="number"
                                                name="price"
                                                onChange={handleChange}
                                                value={formData.price}
                                                placeholder="Enter Plan Price"
                                                required
                                                className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                            />
                                        </div>
                                        <div className='flex flex-col w-full mr-2'>
                                            <label className="font-medium">Storage <span className='text-danger'>*</span></label>
                                            <select name='storage_id' value={formData.storage_id} className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md mr-2" onChange={handleChange}>
                                                <option value="">Selected Option</option>
                                                {storageSubscriptions && storageSubscriptions.map((sto) => (
                                                    <option key={sto.id} value={sto.id}>{sto.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='flex flex-col w-full mr-2'>
                                            <label className='font-medium'>Plan Type <span className='text-danger'>*</span></label>
                                            <select name='plan_type' value={formData.plan_type} className='transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md' onChange={handleChange}>
                                                <option value="">Select Plan Type</option>
                                                <option value="Lifetime">Lifetime</option>
                                                <option value="Annually">Annually</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div className='flex flex-col w-full mr-2'>
                                            {formData.plan_type == 'Other' && (
                                                <>
                                                    <label className="font-medium">Days <span className='text-danger'>*</span></label>
                                                    <input
                                                        type="number"
                                                        min={1}
                                                        name="start_from"
                                                        value={formData.start_from}
                                                        onChange={handleChange}
                                                        required
                                                        className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                                    />
                                                </>)}
                                        </div>
                                    </div>

                                    <div className="flex mt-5">
                                        <div className='flex flex-col w-full mr-2'>
                                            <label className="font-medium">Image Upload</label>
                                            <input
                                                type="file"
                                                onChange={handleChange}
                                                accept="image/*"

                                                name='image'
                                                className="mt-1 border rounded-md p-2"
                                            />
                                        </div>
                                        <div className='flex flex-col w-full mr-2'>
                                            <label className="font-medium mb-2">Registration Charges</label>
                                            <input
                                                type="number"
                                                min={0}
                                                name="registration_charge"
                                                value={formData.registration_charge}
                                                onChange={handleChange}
                                                required
                                                className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                            />
                                        </div>

                                    </div>

                                    <div className='flex mt-5'>
                                        <div className='flex flex-col w-full mr-2'>
                                            <label className='w-2/3 font-medium'>24 x 7 Support</label>
                                            <select
                                                name="support_24x7"
                                                value={formData.support_24x7}
                                                className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                                onChange={handleChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>

                                        <div className='flex flex-col w-full mr-2'>
                                            <label className='w-2/3 font-medium'>Due Date Reminder</label>
                                            <select
                                                name="due_date_reminder"
                                                value={formData.due_date_reminder}
                                                className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                                onChange={handleChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='mt-5'>
                                        <div className="flex flex-wrap gap-4">
                                            <div className="flex justify-between items-center border p-3 rounded-md shadow-sm w-1/4">
                                                <Switch
                                                    onChange={handleSelectAll}
                                                    checked={selectedServices.length === taxServices.length ? true : false}
                                                    onColor="#86d3ff"
                                                    offColor="#ccc"
                                                    onHandleColor="#2693e6"
                                                    handleDiameter={20}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={20}
                                                    width={48}
                                                />
                                                <span className="ml-2 font-medium text-sm">{selectedServices.length === taxServices.length ? 'Deselect All' : 'Select All'}</span>
                                            </div>
                                            {taxServices.map((service) => (
                                                <div
                                                    key={service.id}
                                                    className="flex justify-between items-center border p-3 rounded-md shadow-sm w-1/4" // w-1/4 ensures 4 items per row on larger screens
                                                >
                                                    <Switch
                                                        onChange={() => handleToggle(service.id)}
                                                        checked={selectedServices.includes(service.id)}
                                                        onColor="#86d3ff"
                                                        offColor="#ccc"
                                                        onHandleColor="#2693e6"
                                                        handleDiameter={20}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        height={20}
                                                        width={48}
                                                    />
                                                    <span className="ml-2 font-medium text-sm">{service.service_name}</span>

                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Description */}
                                    <div className="flex flex-col mt-5">
                                        <div className="flex">
                                            <div className='flex flex-col w-full mr-2'>
                                                <label className="font-medium">Additional Description</label>
                                                <input
                                                    type="text"
                                                    value={descriptionPoint}
                                                    onChange={(e) => setDescriptionPoint(e.target.value)}
                                                    placeholder="Add a description point"
                                                    className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md mr-2"
                                                />
                                            </div>
                                            <div className='flex flex-col'>
                                                <label className="font-medium">&nbsp;</label>
                                                <button
                                                    type="button"
                                                    onClick={handleAddDescription}
                                                    className="p-2 bg-primary text-center text-white rounded"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={24}
                                                        height={24}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="lucide lucide-plus"
                                                    >
                                                        <path d="M5 12h14" />
                                                        <path d="M12 5v14" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex flex-col mt-2">
                                            {formData.description.map((point, index) => (
                                                <div key={index} className="flex justify-between mt-1 border rounded-md p-2">
                                                    <span className='flex flex-col w-full transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md mr-2'>{point}</span>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveDescription(index)}
                                                        className="p-2 bg-danger text-center text-white rounded"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="lucide lucide-minus"
                                                        >
                                                            <path d="M5 12h14" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>


                                    <button type="submit" className="mt-5 p-2 bg-primary text-white rounded-md">
                                        Update Subscription
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditSubscription;
