import React from "react";
import { Route, Routes } from "react-router-dom";
import Branches from "./branches";
import AddBranches from "./addBranch";
import EditBranch from "./editBranch";

const BranchesRoutes = () => {
    return(
        <Routes>
            <Route path="/" element={<Branches/>} />
            <Route path="/add"  element={<AddBranches />} />
            <Route path="/edit/:id"  element={<EditBranch />} />
        </Routes> 
    );
}
export default BranchesRoutes;