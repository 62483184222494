import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { CalendarCheck } from 'lucide-react';
import 'simplebar-react/dist/simplebar.min.css';
import { signout } from '../../../features/auth/authSlice';

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isActive = (path) => location.pathname === path;
    const handleSignoutClick = () => {
        dispatch(signout());
    };

    return (
        <>
            <div className="close-mobile-menu fixed ml-[275px] w-10 h-10 items-center justify-center xl:hidden z-50 [&.close-mobile-menu--mobile-menu-open]:flex hidden">
                <a className="ml-5 mt-5" href="">
                    <i
                        data-tw-merge=""
                        data-lucide="x"
                        className="stroke-[1] h-8 w-8 text-white"
                    />
                </a>
            </div>
            <div className="side-menu__content h-full box bg-white/[0.95] rounded-none xl:rounded-xl z-20 relative w-[275px] duration-300 transition-[width] group-[.side-menu--collapsed]:xl:w-[91px] group-[.side-menu--collapsed.side-menu--on-hover]:xl:shadow-[6px_0_12px_-4px_#0000000f] group-[.side-menu--collapsed.side-menu--on-hover]:xl:w-[275px] overflow-hidden flex flex-col">
                <div className="flex-none hidden xl:flex items-center z-10 px-5 h-[65px] w-[275px] overflow-hidden relative duration-300 group-[.side-menu--collapsed]:xl:w-[91px] group-[.side-menu--collapsed.side-menu--on-hover]:xl:w-[275px]">
                    <Link
                        className="flex items-center transition-[margin] duration-300 group-[.side-menu--collapsed.side-menu--on-hover]:xl:ml-0 group-[.side-menu--collapsed]:xl:ml-2"
                        to="/dashboard"
                    >
                        <div className="font-medium transition-opacity group-[.side-menu--collapsed.side-menu--on-hover]:xl:opacity-100 group-[.side-menu--collapsed]:xl:opacity-0 w-70">
                            <img data-placement="top" src="/logo.png" alt="TaxGem" style={{ width: "85px" }} />
                        </div>
                    </Link>
                    <Link
                        className="toggle-compact-menu ml-auto hidden h-[20px] w-[20px] items-center justify-center rounded-full border border-slate-600/40 transition-[opacity,transform] hover:bg-slate-600/5 group-[.side-menu--collapsed]:xl:rotate-180 group-[.side-menu--collapsed.side-menu--on-hover]:xl:opacity-100 group-[.side-menu--collapsed]:xl:opacity-0 3xl:flex"
                        href="#0"
                    >
                        <i
                            data-tw-merge=""
                            data-lucide="arrow-left"
                            className="h-3.5 w-3.5 stroke-[1.3]"
                        />
                    </Link>
                </div>
                <SimpleBar className="scrollable-ref w-full h-full z-20 px-5 overflow-y-auto overflow-x-hidden pb-3 [-webkit-mask-image:-webkit-linear-gradient(top,rgba(0,0,0,0),black_30px)] [&:-webkit-scrollbar]:w-0 [&:-webkit-scrollbar]:bg-transparent [&_.simplebar-content]:p-0 [&_.simplebar-track.simplebar-vertical]:w-[10px] [&_.simplebar-track.simplebar-vertical]:mr-0.5 [&_.simplebar-track.simplebar-vertical_.simplebar-scrollbar]:before:bg-slate-400/30">
                    <ul className="scrollable">
                        {/* BEGIN: First Child */}

                        <li className='mt-2 pt-2'>
                            <Link
                                to="/dashboard"
                                className={`side-menu__link ${isActive('/dashboard') ? 'side-menu__link--active' : ''}`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-layout-dashboard"><rect width="7" height="9" x="3" y="3" rx="1" /><rect width="7" height="5" x="14" y="3" rx="1" /><rect width="7" height="9" x="14" y="12" rx="1" /><rect width="7" height="5" x="3" y="16" rx="1" /></svg>
                                <div className="side-menu__link__title">Dashboard</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/submit-tax" className={`side-menu__link`}>
                                <CalendarCheck />
                                <div className="side-menu__link__title">Submissions Dashboard</div>
                            </Link>
                        </li>
                        <li className="side-menu__divider">Company Mangement</li>
                        <li>
                            <Link to="/companies" className={`side-menu__link ${isActive('/companies') ? 'side-menu__link--active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-building-2"><path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" /><path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" /><path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" /><path d="M10 6h4" /><path d="M10 10h4" /><path d="M10 14h4" /><path d="M10 18h4" /></svg>
                                <div className="side-menu__link__title">Companies</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/branches" className={`side-menu__link ${isActive('/branches') ? 'side-menu__link--active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-git-branch"><line x1="6" x2="6" y1="3" y2="15" /><circle cx="18" cy="6" r="3" /><circle cx="6" cy="18" r="3" /><path d="M18 9a9 9 0 0 1-9 9" /></svg>
                                <div className="side-menu__link__title">Branches</div>
                            </Link>
                        </li>
                        {/* <li className="side-menu__divider">User Management</li>
                        <li>
                            <Link to="/users" className={`side-menu__link ${isActive('/users') ? 'side-menu__link--active' : ''}`}>
                                <i
                                    data-tw-merge=""
                                    data-lucide="user-square"
                                    className="stroke-[1] w-5 h-5 side-menu__link__icon"
                                />
                                <div className="side-menu__link__title">Users</div>
                            </Link>
                        </li>
            
                        <li>
                            <Link to="/departments" className={`side-menu__link ${isActive('/departments') ? 'side-menu__link--active' : ''}`}>
                                <i
                                    data-tw-merge=""
                                    data-lucide="user-square"
                                    className="stroke-[1] w-5 h-5 side-menu__link__icon"
                                />
                                <div className="side-menu__link__title">Departments</div>
                            </Link>
                        </li> */}

                        <li className="side-menu__divider">Tax Services</li>
                        <li>
                            <Link to="/tax-service/add" className={`side-menu__link ${isActive('/tax-service/add') ? 'side-menu__link--active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                                <div className="side-menu__link__title">Create New</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/tax-service/return-filling" className={`side-menu__link ${isActive('/tax-service/return-filling') ? 'side-menu__link--active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-fuel"><line x1="3" x2="15" y1="22" y2="22" /><line x1="4" x2="14" y1="9" y2="9" /><path d="M14 22V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v18" /><path d="M14 13h2a2 2 0 0 1 2 2v2a2 2 0 0 0 2 2a2 2 0 0 0 2-2V9.83a2 2 0 0 0-.59-1.42L18 5" /></svg>
                                <div className="side-menu__link__title">Periodic Fillings</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/tax-service/annual-filling" className={`side-menu__link ${isActive('/tax-service/annual-filling') ? 'side-menu__link--active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-fuel"><line x1="3" x2="15" y1="22" y2="22" /><line x1="4" x2="14" y1="9" y2="9" /><path d="M14 22V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v18" /><path d="M14 13h2a2 2 0 0 1 2 2v2a2 2 0 0 0 2 2a2 2 0 0 0 2-2V9.83a2 2 0 0 0-.59-1.42L18 5" /></svg>
                                <div className="side-menu__link__title">Annual Fillings</div>
                            </Link>
                        </li>
                        <li className="side-menu__divider">Schedule Reports</li>
                        <li>
                            <Link to="/schedule" className={`side-menu__link ${isActive('/schedule') ? 'side-menu__link--active' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-cloud-upload"><path d="M12 13v8" /><path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" /><path d="m8 17 4-4 4 4" /></svg>
                                <div className="side-menu__link__title">Schedule Report </div>
                            </Link>
                        </li>
                        <li className="side-menu__divider">Reports</li>
                        <li>
                            <Link to="/report/return-filling" className={`side-menu__link`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-flag-triangle-left"><path d="M17 22V2L7 7l10 5" /></svg>
                                <div className="side-menu__link__title">Periodic Fillings Reports</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="report/annual-filling" className={`side-menu__link`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-flag-triangle-right"><path d="M7 22V2l10 5-10 5" /></svg>
                                <div className="side-menu__link__title">Annual Fillings Reports</div>
                            </Link>
                        </li>
                        <li className="side-menu__divider">Subscriptions</li>
                        <li>
                            <Link to="/subscriptions/plan" className={`side-menu__link`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-orbit"><circle cx="12" cy="12" r="3"/><circle cx="19" cy="5" r="2"/><circle cx="5" cy="19" r="2"/><path d="M10.4 21.9a10 10 0 0 0 9.941-15.416"/><path d="M13.5 2.1a10 10 0 0 0-9.841 15.416"/></svg>
                                <div className="side-menu__link__title">Plans</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/subscriptions/storage" className={`side-menu__link`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-podcast"><path d="M16.85 18.58a9 9 0 1 0-9.7 0" /><path d="M8 14a5 5 0 1 1 8 0" /><circle cx="12" cy="11" r="1" /><path d="M13 17a1 1 0 1 0-2 0l.5 4.5a.5.5 0 1 0 1 0Z" /></svg>
                                <div className="side-menu__link__title">Storage</div>
                            </Link>
                        </li>
                        <li className="side-menu__divider">Other</li>
                       
                        <li>
                            <Link to="#" className={`side-menu__link`} onClick={handleSignoutClick}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1="21" x2="9" y1="12" y2="12" /></svg>
                                <div className="side-menu__link__title">Signout</div>
                            </Link>
                        </li>
                        {/* END: First Child */}
                    </ul>
                </SimpleBar>
            </div>
        </>

    );
}

export default Sidebar;