import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { addPlan, fetchStorageSubscriptions } from '../../features/subscriptionsSlice';
import Swal from "sweetalert2";

const AddSubscription = () => {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        users: '',
        image: null,
        price: '',
        description: [],
        storage_id: '',
        start_from: '',
    });

    const [descriptionPoint, setDescriptionPoint] = useState('');

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.files[0]
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleAddDescription = () => {
        if (descriptionPoint) {
            setFormData({
                ...formData,
                description: [...formData.description, descriptionPoint],
            });
            setDescriptionPoint(''); // Clear the input after adding
        }
    };

    const handleRemoveDescription = (index) => {
        const newDescriptions = formData.description.filter((_, i) => i !== index);
        setFormData({ ...formData, description: newDescriptions });
    };


    const { storageSubscriptions, loading, error } = useSelector((state) => state.subscriptions);

    useEffect(() => {
        dispatch(fetchStorageSubscriptions());
    }, [dispatch]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }
        console.log(formDataObj);

        const resultAction = await dispatch(addPlan(formDataObj));

        if (addPlan.fulfilled.match(resultAction)) {
            Swal.fire({
                icon: 'success',
                title: '',
                text: 'Successfully Added',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            navigator(`/subscriptions/plan`); // Navigate after successful submission
        } else {
            Swal.fire("Error!", resultAction.error.message, "error");
        }


    };


    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium">
                            Add Storage Item
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit} className="mt-3.5">
                                <div className="p-5 border rounded-m box box--stacked">
                                    <h2 className="font-medium">Information</h2>

                                    {/* Total Users */}
                                    <div className="flex flex-col mt-5">
                                        <label className="font-medium">Plan Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            onChange={handleChange}
                                            placeholder="Plan Name"
                                            required
                                            className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                        />
                                    </div>

                                    <div className="flex flex-col mt-5">
                                        <label className="font-medium">Branches</label>
                                        <input
                                            type="number"
                                            name="users"
                                            onChange={handleChange}
                                            placeholder="Enter total number of users"
                                            required
                                            className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                        />
                                    </div>

                                    {/* Image Upload */}
                                    <div className="flex flex-col mt-5">
                                        <label className="font-medium">Image Upload</label>
                                        <input
                                            type="file"
                                            onChange={handleChange}
                                            accept="image/*"
                                            required
                                            name='image'
                                            className="mt-1 border rounded-md p-2"
                                        />
                                    
                                    </div>

                                    {/* Price */}
                                    <div className="flex flex-col mt-5">
                                        <label className="font-medium">Price</label>
                                        <input
                                            type="number"
                                            name="price"
                                            onChange={handleChange}
                                            placeholder="Enter price"
                                            required
                                            className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                        />
                                    </div>

                                    {/* Description */}
                                    <div className="flex flex-col mt-5">
                                        <label className="font-medium">Description</label>
                                        <div className="flex items-center">
                                            <input
                                                type="text"
                                                value={descriptionPoint}
                                                onChange={(e) => setDescriptionPoint(e.target.value)}
                                                placeholder="Add a description point"
                                                className="transition duration-200 ease-in-out w-[90%] text-sm border-slate-200 shadow-sm rounded-md mr-2"
                                            />
                                            <button
                                                type="button"
                                                onClick={handleAddDescription}
                                                className="p-1 bg-primary text-center text-white rounded"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="lucide lucide-plus"
                                                >
                                                    <path d="M5 12h14" />
                                                    <path d="M12 5v14" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="mt-2">
                                            {formData.description.map((point, index) => (
                                                <div key={index} className="flex items-center justify-between mt-1 border rounded-md p-2">
                                                    <span className='transition duration-200 ease-in-out w-[90%] text-sm border-slate-200 shadow-sm rounded-md mr-2'>{point}</span>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveDescription(index)}
                                                        className="p-1 bg-danger text-center text-white rounded"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="lucide lucide-minus"
                                                        >
                                                            <path d="M5 12h14" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>


                                    {/* Storage ID */}
                                    <div className="flex flex-col mt-5">
                                        <label className="font-medium">Storage</label>
                                        <select name='storage_id' className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md mr-2" onChange={handleChange}>
                                            <option value="">Selected Option</option>
                                            {storageSubscriptions && storageSubscriptions.map((sto) => (
                                                <option key={sto.id} value={sto.id}>{sto.name}</option>
                                            ))}
                                        </select>
                                        {/* <input
                                            type="text"
                                            name="storage_id"
                                            onChange={handleChange}
                                            placeholder="Enter storage ID"
                                            required
                                            className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                        /> */}
                                    </div>

                                    {/* Start From */}
                                    <div className="flex flex-col mt-5">
                                        <label className="font-medium">Days</label>
                                        <input
                                            type="number"
                                            min={1}
                                            name="start_from"
                                            onChange={handleChange}
                                            required
                                            className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                        />
                                    </div>

                                  

                                    <button type="submit" className="mt-5 p-2 bg-primary text-white rounded-md">
                                        Add Subscription
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSubscription;
