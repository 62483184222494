import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../dashboard/dashboard';
import ThemeSwitch from './partials/theme_switch';
import Sidebar from './partials/sidebar';
import Header from './partials/header';
import $ from 'jquery';
// import { createIcons, icons } from 'lucide';
import Companies from '../companies/companies';
import Branches from '../branches/branches';
import Departments from '../departments/departments';
import Users from '../users/users';
import AddCompanies from '../companies/add_company';
import CompanyRoutes from '../companies/company_routes';
import { createIcons, icons } from 'lucide';
import BranchesRoutes from '../branches/branches_routes';
import TaxServiceRoutes from '../tax_service/tax_service_routes';
import ScheduleRoutes from '../schedules/schedule_routes';
import AdminReportPage from '../reports/admin_report';
import AdminReportDetail from '../reports/partials/admin_report_detail';
import SubscriptionRoutes from '../subscriptions/subscriptionRoutes';
import AdminFillings from '../tax_service/admin_filling';
import TaxReportAdminPage from '../tax_service/admin_tax_report';
import SupportPage from '../support/support';

const Main = () => {
    const [compactMenu, setCompactMenu] = useState(localStorage.getItem('compactMenu') === 'true');

    useEffect(() => {
        
        createIcons({ icons });
        const loadingTimeout = setTimeout(() => {
          $('.loading-page').addClass('loading-page--hide');
          setTimeout(() => {
            $('.loading-page').removeClass('loading-page--before-hide loading-page--hide');
          }, 300);
        }, 1000);

        // Handle scroll behavior
        const handleScroll = () => {
          if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
            $('.background').addClass('background--hidden');
            $('.top-bar').addClass('top-bar--active');
            $('.content.mode').removeClass('mode--light');
          } else {
            $('.background').removeClass('background--hidden');
            $('.top-bar').removeClass('top-bar--active');
            $('.content.mode').addClass('mode--light');
          }
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);

        // Handle menu collapse
        const toggleCompactMenu = (e) => {
          e.preventDefault();
          setCompactMenu((prev) => {
            const newCompactMenu = !prev;
            localStorage.setItem('compactMenu', newCompactMenu ? 'true' : 'false');
            return newCompactMenu;
          });
        };

        $('.toggle-compact-menu').on('click', toggleCompactMenu);

        // Handle mobile menu open/close
        const openMobileMenu = (e) => {
          e.preventDefault();
          $('.side-menu').addClass('side-menu--mobile-menu-open');
          $('.close-mobile-menu').addClass('close-mobile-menu--mobile-menu-open');
        };

        const closeMobileMenu = (e) => {
          e.preventDefault();
          $('.side-menu').removeClass('side-menu--mobile-menu-open');
          $('.close-mobile-menu').removeClass('close-mobile-menu--mobile-menu-open');
        };

        $('.open-mobile-menu').on('click', openMobileMenu);
        $('.close-mobile-menu').on('click', closeMobileMenu);

        // Handle full screen request
        const requestFullScreen = (e) => {
          e.preventDefault();
          const docEl = document.documentElement;
          if (docEl.requestFullscreen) {
            docEl.requestFullscreen();
          }
        };

        $('.request-full-screen').on('click', requestFullScreen);

        // Handle window resize
        const handleResize = () => {
          $('.side-menu').removeClass('side-menu--mobile-menu-open');
          $('.close-mobile-menu').removeClass('close-mobile-menu--mobile-menu-open');
          if (window.innerWidth <= 1600) {
            localStorage.setItem('compactMenu', 'true');
            setCompactMenu(true);
          }
        };

        window.addEventListener('resize', handleResize);

        return () => {
          clearTimeout(loadingTimeout);
          window.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', handleResize);
          $('.toggle-compact-menu').off('click', toggleCompactMenu);
          $('.open-mobile-menu').off('click', openMobileMenu);
          $('.close-mobile-menu').off('click', closeMobileMenu);
          $('.request-full-screen').off('click', requestFullScreen);
        };
    }, []);

    return (
        <div>
            <div className="echo group bg-gradient-to-b from-slate-200/70 to-slate-50 background relative min-h-screen before:content-[''] before:h-[370px] before:w-screen before:bg-gradient-to-t before:from-theme-1/80 before:to-theme-2 [&amp;.background--hidden]:before:opacity-0 before:transition-[opacity,height] before:ease-in-out before:duration-300 before:top-0 before:fixed after:content-[''] after:h-[370px] after:w-screen [&amp;.background--hidden]:after:opacity-0 after:transition-[opacity,height] after:ease-in-out after:duration-300 after:top-0 after:fixed after:bg-texture-white after:bg-contain after:bg-fixed after:bg-[center_-13rem] after:bg-no-repeat">
                <div className="side-menu xl:ml-0 shadow-xl transition-[margin,padding] duration-300 xl:shadow-none fixed top-0 left-0 z-50 group inset-y-0 xl:py-3.5 xl:pl-3.5 after:content-[''] after:fixed after:inset-0 after:bg-black/80 after:xl:hidden [&.side-menu--mobile-menu-open]:ml-0 [&.side-menu--mobile-menu-open]:after:block -ml-[275px] after:hidden">
                    <Sidebar />
                    <Header />
                </div>
                <div className="content transition-[margin,width] duration-100 xl:pl-3.5 pt-[54px] pb-16 relative z-10 group mode xl:ml-[275px] [&.content--compact]:xl:ml-[91px] mode--light">
                    <div className="mt-16 px-5">
                        <Routes>
                            <Route path="/" exact element={<Dashboard />} />
                            <Route path="/dashboard" exact element={<Dashboard />} />
                            <Route path="/companies/*" exact element={<CompanyRoutes />} />
                            <Route path="/subscriptions/*" exact element={<SubscriptionRoutes/>}/>
                            <Route path="/branches/*" exact element={<BranchesRoutes />} />
                            <Route path="/users" exact element={<Users />} />
                            <Route path="/departments" exact element={<Departments />} />
                            <Route path="/tax-service/*" exact element={<TaxServiceRoutes />} />
                            <Route path="/schedule/*" exact element={<ScheduleRoutes />} />
                            <Route path="/report/:filling" exact element={<AdminReportPage />} />
                            <Route path="/report-detail" exact element={<AdminReportDetail />} />
                            <Route path="/submit-tax" exact element={<AdminFillings/>}/>
                            <Route path="/support" exact element={<SupportPage/>}/>
                            <Route path="/admin/tax/:filling/:id/:tax_service_id/:due_date/:check" exact element={<TaxReportAdminPage />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
