import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { API_URL } from "../../../utils/api_const";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = ({ pdfUrl }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = 'document.pdf'; // Customize the file name if needed
    link.click();
  };

  return (
    <div style={{ width: '800px', height: '600px', position: 'relative', overflow: 'hidden', border: "1px solid #eee",}}>
      <div style={{ position: 'absolute', top: '0px', left: '50%', transform: 'translateX(-50%)', display: 'flex', gap: '10px', zIndex: 1, backgroundColor: '#000000c9',  width: '100%', justifyContent:'center', }}>
        <button style={{ color: '#fff'}} onClick={goToPrevPage} disabled={pageNumber <= 1}>Previous</button>
        <span style={{ color: '#fff'}}>Page {pageNumber} of {numPages}</span>
        <button style={{ color: '#fff'}}  onClick={goToNextPage} disabled={pageNumber >= numPages}>Next</button>
        <button style={{ color: '#fff'}}  onClick={downloadPdf}>Download</button>
      </div>
      <div style={{ width: '100%', height: 'calc(100% - 50px)', overflow: 'auto', marginTop:'30px' }}>
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{ workerSrc: pdfjs.GlobalWorkerOptions.workerSrc }}
        >
          <Page pageNumber={pageNumber} width={800} /> {/* Set the width as per your design */}
        </Document>
      </div>
    </div>
  );
};


const CompanyReportDetail = () => {
    const location = useLocation();
    const { rowData } = location.state || {};
    const [fillings, setFillingsData] = useState({});

    useEffect(() => {
        if (rowData) {
            setFillingsData(rowData);
        }
    }, [rowData]); // Update state when rowData changes
    console.log(fillings);

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            {fillings.taxService?.service_name || ''} ( {fillings.schedule_report_id ? fillings.scheduleReport?.session : fillings.branchSchedule?.session} )
                        </div>
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            <span className="text-white font-bold mr-2">Due Date:  {fillings.due_date || ''}</span>

                        </div>
                    </div>
                    <div className="mt-3.5 flex flex-col gap-8">

                        {fillings.form && fillings.form.length > 0 && (
                            fillings.form.map((item, index) => (
                                <div key={index} className="box box--stacked flex flex-col">
                                    <div className="overflow-auto xl:overflow-visible">

                                        <div className="m-5">
                                            <p><strong>Registration ID: {item.registration_id}</strong></p>
                                        </div>
                                    </div>
                                    <div className="flex-reverse flex flex-col-reverse items-center gap-y-2 p-5 sm:flex-row justify-center">
                                        {item.file_path && <PdfViewer pdfUrl={`${API_URL}/${item.file_path}`} />}
                                    </div>
                                </div>
                            ))
                        )}


                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyReportDetail;
