import { Database } from "lucide-react";
import React, { useEffect, useState } from "react";
import generateFiscalYears from "./partials/generate_financial_year";
import { useDispatch, useSelector } from "react-redux";
import { fetchTaxReports } from "../../features/submitTaxReportSlice";
import moment from 'moment';
import { getAllCompanyData } from "../../features/companySlice";
import { API_URL } from "../../utils/api_const";


const Dashboard = () => {
    const dispatch = useDispatch();
    const fiscalYears = generateFiscalYears();
    const [loader, setLoader] = useState(true);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(
        localStorage.getItem('selectedFiscalYear') || fiscalYears[fiscalYears.length - 1]
    );
    const { fetchedTaxReports, fetchStatus, fetchError } = useSelector((state) => state.submitTaxReport);
    const { companies, status, error } = useSelector((state) => state.companies);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [countsData, setCountData] = useState([]);


    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);
        if(e.target.value != ''){
        }
    }

    useEffect(() => {
        // Define an async function inside useEffect
        const fetchCounts = async () => {
          try {
            const response = await fetch(`${API_URL}/auth/dashboard/counts`, {
              method: 'GET', // HTTP method to be used
            });
    
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            setCountData(data);
          } catch (error) {
            console.error('Error fetching counts:', error);
          }
        };
    
        fetchCounts(); // Call the async function
      }, []); // 

    useEffect(() => {
        localStorage.setItem('selectedFiscalYear', selectedFiscalYear);
        const payload = {
            session: selectedFiscalYear
        };
        dispatch(fetchTaxReports(payload)).finally(() => {
            setLoader(false);
        });
    }, [selectedFiscalYear, dispatch]);

    useEffect(() => {
        dispatch(getAllCompanyData());
    }, [dispatch]);

    console.log(countsData);

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Dashboard
                        </div>
                        {/* <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            <div className="relative">
                                <i data-tw-merge data-lucide="calendar-check2" className="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4 stroke-[1.3] group-[.mode--light]:!text-slate-200" />
                                <select data-tw-merge className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:bg-chevron-white group-[.mode--light]:!text-slate-200 sm:w-44">
                                    <option value="custom-date">Custom Date</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                            </div>
                            <div className="relative">
                                <i data-tw-merge data-lucide="calendar" className="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4 stroke-[1.3] group-[.mode--light]:!text-slate-200" />
                                <input data-tw-merge type="text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 sm:w-64" />
                            </div>
                        </div> */}
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-5">

                        <div className="box box--stacked col-span-12 flex flex-col p-5 md:col-span-6 2xl:col-span-3">
                            <div className="flex items-center">
                                <div className="flex h-12 w-12 items-center justify-center rounded-full border border-primary/10 bg-primary/10">
                                    <Database />
                                </div>
                                <div className="ml-4">
                                    <div className="text-base font-medium">Companies</div>
                                    <div className="mt-0.5 text-slate-500">{countsData?.totalCompanies} Companies</div>
                                </div>
                            </div>
                        </div>
                        <div className="box box--stacked col-span-12 flex flex-col p-5 md:col-span-6 2xl:col-span-3">
                            <div className="flex items-center">
                                <div className="flex h-12 w-12 items-center justify-center rounded-full border border-primary/10 bg-primary/10">
                                    <Database />
                                </div>
                                <div className="ml-4">
                                    <div className="text-base font-medium">Branches</div>
                                    <div className="mt-0.5 text-slate-500">{countsData?.totalBranch} branches</div>
                                </div>
                            </div>
                        </div>
                        <div className="box box--stacked col-span-12 flex flex-col p-5 md:col-span-6 2xl:col-span-3">
                            <div className="flex items-center">
                                <div className="flex h-12 w-12 items-center justify-center rounded-full border border-primary/10 bg-primary/10">
                                    <Database />
                                </div>
                                <div className="ml-4">
                                    <div className="text-base font-medium">Branch Users</div>
                                    <div className="mt-0.5 text-slate-500">{countsData?.totalUsers} users</div>
                                </div>
                            </div>
                        </div>
                        <div className="box box--stacked col-span-12 flex flex-col p-5 md:col-span-6 2xl:col-span-3">
                            <div className="flex items-center">
                                <div className="flex h-12 w-12 items-center justify-center rounded-full border border-primary/10 bg-primary/10">
                                    <Database />
                                </div>
                                <div className="ml-4">
                                    <div className="text-base font-medium">Submission</div>
                                    <div className="mt-0.5 text-slate-500">{countsData?.totalReports} Submissions</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium">Recent Submission</div>
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            <div className="relative">
                                <span className="text-white font-bold mr-2">Company </span>
                               
                                <select data-tw-merge
                                    onChange={handleCompanyChange}
                                    value={selectedCompany}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:bg-chevron-white group-[.mode--light]:!text-slate-200 sm:w-44">
                                    <option value="">All Company</option>
                                    {companies.map((company, index) => (
                                        <option key={index} value={company.id}>
                                            {company.company_name}
                                        </option>
                                    ))}

                                </select>
                            </div>
                           
                        </div>
                    </div>
                    <div className="mt-2 overflow-auto lg:overflow-visible">
                        <table data-tw-merge className="w-full text-left border-separate border-spacing-y-[10px]">
                            <tbody>
                                {fetchedTaxReports && fetchedTaxReports.map((data, index) => <tr data-tw-merge style={{ display: selectedCompany === '' || Number(selectedCompany) === Number(data.company?.id) ? 'table-row' : 'none' }} >
                                    <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        <div className="flex items-center">
                                        
                                            <div className="ml-3.5">
                                                <a className="whitespace-nowrap font-medium" href="#">
                                                    {data.taxService?.service_name}
                                                </a>
                                                <div className="mt-1 whitespace-nowrap text-xs text-slate-500">
                                                    {data.company?.company_name}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 box w-60 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        <div className="mb-1 whitespace-nowrap text-xs text-slate-500">
                                            {data.taxService?.service_type == 'return_filling'?'Periodic Filling':'Annual Filling'}
                                        </div>
                                        <a className="flex items-center text-primary" href="#">
                                            <div className="whitespace-nowrap">
                                                {data.scheduleReport?data.scheduleReport?.session:data.branchSchedule?data.branchSchedule?.session:''}
                                            </div>
                                        </a>
                                    </td>
                                    <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 box w-44 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        <div className="mb-1.5 whitespace-nowrap text-xs text-slate-500">
                                            {data.taxService?.frequency}
                                        </div>
                                        
                                    </td>
                                    <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 box w-44 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        <div className="mb-1 whitespace-nowrap text-xs text-slate-500">
                                            Due Date
                                        </div>
                                        <div className="flex items-center text-success">
                                            
                                            <div className="whitespace-nowrap">
                                                {data.due_date}
                                            </div>
                                        </div>
                                    </td>
                                    <td data-tw-merge className="px-5 py-3 border-b dark:border-darkmode-300 box w-44 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        <div className="mb-1 whitespace-nowrap text-xs text-slate-500">
                                            Submission Date
                                        </div>
                                        <div className="whitespace-nowrap">{moment(data.createdAt).format('MMM DD, YYYY')}</div>
                                    </td>
                                    {/* <td data-tw-merge className="px-5 border-b dark:border-darkmode-300 box relative w-20 rounded-l-none rounded-r-none border-x-0 py-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                                        <div className="flex items-center justify-center">
                                            <div data-tw-merge data-tw-placement="bottom-end" className="dropdown relative h-5"><button data-tw-toggle="dropdown" aria-expanded="false" className="cursor-pointer h-5 w-5 text-slate-500"><i data-tw-merge data-lucide="more-vertical" className="stroke-[1] w-5 h-5 fill-slate-400/70 stroke-slate-400/70" />
                                            </button>
                                                <div data-transition data-selector=".show" data-enter="transition-all ease-linear duration-150" data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1" data-enter-to="!mt-1 visible opacity-100 translate-y-0" data-leave="transition-all ease-linear duration-150" data-leave-from="!mt-1 visible opacity-100 translate-y-0" data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1" className="dropdown-menu absolute z-[9999] hidden">
                                                    <div data-tw-merge className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40">
                                                        <a className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"><i data-tw-merge data-lucide="wallet-cards" className="stroke-[1] mr-2 h-4 w-4" />
                                                            View Details</a>
                                                        <a className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"><i data-tw-merge data-lucide="file-signature" className="stroke-[1] mr-2 h-4 w-4" />
                                                            Edit Order</a>
                                                        <a className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"><i data-tw-merge data-lucide="printer" className="stroke-[1] mr-2 h-4 w-4" />
                                                            Print Invoice</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td> */}
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;