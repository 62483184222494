// src/features/companySlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';

const initialState = {
  companies: [],
  totalCompanies: 0,
  activeCompanies: 0,
  inactiveCompanies: 0,
  singleCompany: null,
  status: 'idle',
  error: null
};

// Thunks for async actions
export const createCompany = createAsyncThunk(
  'companies/createCompany',
  async (companyData) => {
    const response = await axios.post(`${API_URL}/companies/add`, companyData);
    return response.data;
  }
);

export const registerCompany = createAsyncThunk(
  'companies/registerCompany',
  async (companyData) => {
    const response = await axios.post(`${API_URL}/companies/register`, companyData);
    return response.data;
  }
);

export const fetchCompanyCounts = createAsyncThunk(
  'companies/fetchCompanyCounts',
  async () => {
    const response = await axios.get(`${API_URL}/companies/counts`);
    return response.data;
  }
);

export const updateCompany = createAsyncThunk(
  'companies/updateCompany',
  async ({ id, companyData }) => {
    const response = await axios.put(`${API_URL}/companies/update/${id}`, companyData);
    return response.data;
  }
);

export const deleteCompany = createAsyncThunk(
  'companies/deleteCompany',
  async (id) => {
    await axios.delete(`${API_URL}/companies/delete/${id}`);
    return id;
  }
);

export const updateCompanyStatus = createAsyncThunk(
  'companies/updateCompanyStatus',
  async ({ id, status }) => {
    const response = await axios.patch(`${API_URL}/companies/status/${id}`, { status });
    return response.data;
  }
);

export const getCompanyById = createAsyncThunk(
  'companies/getCompanyById',
  async (id) => {
    const response = await axios.get(`${API_URL}/companies/view/${id}`);
    return response.data;
  }
);

export const getAllCompanyData = createAsyncThunk(
  'companies/getAllCompanyData',
  async () => {
    const response = await axios.get(`${API_URL}/companies`);
    return response.data;
  }
);

const companySlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Company
      .addCase(createCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies.push(action.payload);
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(registerCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registerCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies.push(action.payload);
      })
      .addCase(registerCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Update Company
      .addCase(updateCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.companies.findIndex((company) => company.id === action.payload.id);
        if (index !== -1) {
          state.companies[index] = action.payload;
        }
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Delete Company
      .addCase(deleteCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = state.companies.filter((company) => company.id !== action.payload);
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Update Company Status
      .addCase(updateCompanyStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCompanyStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.companies.findIndex((company) => company.id === action.payload.id);
        if (index !== -1) {
          state.companies[index].status = action.payload.status;
        }
      })
      .addCase(updateCompanyStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getCompanyById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCompanyById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.singleCompany = action.payload;
      })
      .addCase(getCompanyById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getAllCompanyData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllCompanyData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = action.payload;
      })
      .addCase(getAllCompanyData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCompanyCounts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompanyCounts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.totalCompanies = action.payload.totalCompanies;
        state.activeCompanies = action.payload.activeCompanies;
        state.inactiveCompanies = action.payload.inactiveCompanies;
      })
      .addCase(fetchCompanyCounts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default companySlice.reducer;
