import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const ScheduleReportRoutes = ({ scheduleReports = [], check_type }) => {
  // Hook to get the current location
  const location = useLocation();

  // Function to check if the link is active
  const isActive = (path) => location.pathname === path;

  // Check if scheduleReports is an array
  if (!Array.isArray(scheduleReports)) {
    console.error('scheduleReports is not an array:', scheduleReports);
    return <div>Error: Invalid data format</div>;
  }

  // Filter reports based on check_type
  const filteredReports = scheduleReports.filter((report) => report.type === check_type.replace(/-/g, '_'));
  console.log(check_type.replace(/-/g, '_'));
console.log(filteredReports);
  return (
    <ul>
      {filteredReports.length > 0 ? (
        filteredReports.map((report) => (
          <li key={report.id}>
            <Link
              to={`/company/tax/reports/${report.id}`} // Adjust link as needed
              className={`side-menu__link ${isActive(`/company/tax/${report.company_id}/reports/${report.id}`) ? 'side-menu__link--active' : ''}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-arrow-left"><rect width="18" height="18" x="3" y="3" rx="2"/><path d="m12 8-4 4 4 4"/><path d="M16 12H8"/></svg>
              <div className="side-menu__link__title">
                {report.TaxService?.service_name || 'Unknown Service'}
              </div>
            </Link>
          </li>
        ))
      ) : (
        <li>No reports found for the selected type.</li>
      )}
    </ul>
  );
};

export default ScheduleReportRoutes;
