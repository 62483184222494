import React from "react";
import { Route, Routes } from "react-router-dom";
import AddService from "./addService";
import AnnualFilling from "./annual_fillings/annual_fillings";
import ReturnFilling from "./return_fillings/return_fillings";
import EditService from "./editService";

const TaxServiceRoutes = () => {
    return (
        <Routes>
            <Route path="/annual-filling" element={<AnnualFilling/>}/>
            <Route path="/return-filling" element={<ReturnFilling/>}/>
            <Route path="/add" element={<AddService/>}/>
            <Route path="/edit/:id" element={<EditService/>}/>
        </Routes>
    );
}

export default TaxServiceRoutes;