import React, { useEffect, useRef, useState } from "react";
import TableHeader from "../../../utils/table_headings";

const DepartmentTable = () => {
    const companyHeadings = ['Role', 'Pemissions', 'Status', 'Actions'];
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRefs = useRef({});

    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        Object.values(dropdownRefs.current).forEach(ref => {
            if (ref && !ref.contains(event.target)) {
                setOpenDropdownId(null);
            }
        });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const tableData = [
        { id: 1, name: 'Manager', permission: '',  status: 'Active' },
        { id: 2, name: 'Accountant', permission: '', status: 'Inactive' },
        // Add more data as needed
    ];

    return (
        <table
            data-tw-merge=""
            className="w-full text-left border-b border-slate-200/60"
        >
            <TableHeader headings={companyHeadings}/>
            <tbody>
                {tableData.map(row => (
                    <tr key={row.id} data-tw-merge="" className="[&_td]:last:border-b-0">
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <input
                                data-tw-merge=""
                                type="checkbox"
                                className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&[type='radio']]:checked:bg-primary [&[type='radio']]:checked:border-primary [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary [&[type='checkbox']]:checked:border-primary [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50"
                            />
                        </td>
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 w-80 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <div className="flex items-center">
                            
                                <div className="ml-3.5">
                                    <a className="whitespace-nowrap font-medium" href="">
                                        {row.name}
                                    </a>
                                
                                </div>
                            </div>
                        </td>
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <a className="whitespace-nowrap font-medium" href="">
                                {row.permission}
                            </a>
                        </td>
                        
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <div className={`flex items-center justify-center ${row.status === 'Active' ? 'text-success' : 'text-danger'}`}>
                                <i
                                    data-tw-merge=""
                                    data-lucide="database"
                                    className="h-3.5 w-3.5 stroke-[1.7]"
                                />
                                <div className="ml-1.5 whitespace-nowrap">{row.status}</div>
                            </div>
                        </td>
                       
                        <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 relative border-dashed py-4 dark:bg-darkmode-600">
                            <div className="flex items-center justify-center">
                                <div
                                    ref={el => dropdownRefs.current[row.id] = el}
                                    data-tw-merge=""
                                    data-tw-placement="bottom-end"
                                    className="dropdown relative h-5"
                                >
                                    <button
                                        onClick={() => toggleDropdown(row.id)}
                                        aria-expanded={openDropdownId === row.id}
                                        className="cursor-pointer h-5 w-5 text-slate-500"
                                    >
                                        <i
                                            data-tw-merge=""
                                            data-lucide="more-vertical"
                                            className="stroke-[1] w-5 h-5 fill-slate-400/70 stroke-slate-400/70"
                                        />
                                    </button>
                                    <div
                                        className={`dropdown-menu absolute z-[9999] ${openDropdownId === row.id ? 'block' : 'hidden'}`}
                                        data-transition=""
                                        data-selector=".show"
                                        data-enter="transition-all ease-linear duration-150"
                                        data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
                                        data-enter-to="!mt-1 visible opacity-100 translate-y-0"
                                        data-leave="transition-all ease-linear duration-150"
                                        data-leave-from="!mt-1 visible opacity-100 translate-y-0"
                                        data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
                                        style={{ right: 0 }}
                                    >
                                        <div
                                            data-tw-merge=""
                                            className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40"
                                        >
                                            <a className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                <i
                                                    data-tw-merge=""
                                                    data-lucide="check-square"
                                                    className="stroke-[1] mr-2 h-4 w-4"
                                                />
                                                Edit
                                            </a>
                                            <a className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item text-danger">
                                                <i
                                                    data-tw-merge=""
                                                    data-lucide="trash2"
                                                    className="stroke-[1] mr-2 h-4 w-4"
                                                />
                                                Delete
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default DepartmentTable;
