import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import '../plans.css';

const PlansSubscription = () => {

    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true); // To handle loading state
    const [error, setError] = useState(null); // To handle any errors

    // Function to fetch subscriptions from the API
    const fetchSubscriptions = async () => {
        try {
            // Replace with your API endpoint
            const response = await axios.get('https://app.taxgem.co.in/backend/api/subscription/plan');
            setSubscriptions(response.data); // Assuming the data is returned directly as an array
            setLoading(false);
        } catch (err) {
            console.error("Error fetching subscriptions:", err);
            setError('Failed to fetch subscription plans.');
            setLoading(false);
        }
    };

    // Fetch the data when the component mounts
    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const convertDaysToReadableFormat = (days) => {
        if (days < 30) {
            return `${days} day${days > 1 ? 's' : ''}`;
        } else if (days < 365) {
            const months = Math.floor(days / 30);
            const remainingDays = days % 30;
            return `${months} month${months > 1 ? 's' : ''}${remainingDays ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
        } else {
            const years = Math.floor(days / 365);
            const remainingMonths = Math.floor((days % 365) / 30);
            const remainingDays = days % 365 % 30;
            return `${years} year${years > 1 ? 's' : ''}${remainingMonths ? ` ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}` : ''}${remainingDays ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
        }
    };


    return (
        <div className='plans-page-style'>
            <div className="container fixed inset-0 grid h-screen w-screen grid-cols-12 lg:max-w-[1550px] 2xl:max-w-[1750px] before:content-[''] before:absolute before:left-0 before:inset-y-0 before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:w-screen">
                <div className="h-full w-full col-span-12 2xl:col-span-12 lg:relative pl-12 pr-12 justify-center">
                    <div className="sticky top-0  flex-col justify-center hidden h-screen lg:flex">
                        <div className="text-[2.6rem] font-medium leading-[1.4] text-white xl:text-5xl xl:leading-[1.2]">
                            <img data-placement="top" src="/logo.png" alt="TaxGem" style={{ position: "absolute", left: "5px", top: "5px", width: "100px" }} />

                        </div>
                        <div className="mt-5 text-base leading-relaxed text-white/70 xl:text-lg">
                           <div className='text-center mb-5'>Already have an account?  <Link to="/login" className="text-success">Back to Login </Link></div>
                            <div className="scroll-container justify-center">
                                <div className="scroll-content">
                                    <div class="pricing-plan">
                                        {subscriptions && subscriptions.map((plan) => (
                                            <div class="plan">
                                                <div class="first-cut">
                                                    <div>
                                                        <h2 className='mb-2' style={{color: "#fff", fontWeight:"bold", fontSize:"30px"}}>{plan.name}</h2>
                                                        <div class="price">
                                                            <p class="whole">₹{plan.price}</p>
                                                            <div class="price-details">
                                                                <p class="cent">.00</p>
                                                                <p>{convertDaysToReadableFormat(plan.start_from)}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <Link to={`/register?plan=${plan.id}`} class="button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7">Get Started</Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p class="title">For {plan.name} members:</p>
                                                        <ul>
                                                            {plan.description.split(',').map((item, index) => (
                                                                <li key={index}>{item.trim()}</li> // Ensure the text does not overflow
                                                            ))}
                                                           
                                                           
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div>
                                                    <p class="title">Extra Benefits:</p>
                                                    <p>{plan.storageSubscription?.size} {plan.storageSubscription?.type} Storage </p>
                                                    {plan.users > 0 && (<p>{plan.users} Branches</p>)}
                                                </div>
                                            </div>
                                           
                                        ))}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PlansSubscription;