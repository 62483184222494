import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { API_URL } from "../../utils/api_const";

const CompanyTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch transaction history
    const fetchTransactionHistory = async () => {
        try {
            const companyId = JSON.parse(localStorage.getItem('company')).id; // Replace with dynamic company ID as needed
            const response = await axios.get(`${API_URL}/companies/transaction-history/${companyId}`);
            setTransactions(response.data.data);
        } catch (error) {
            console.error("Error fetching transaction history:", error);
            setError("Failed to load transaction history.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactionHistory();
    }, []);

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1, // Add 1 so it starts from 1 instead of 0
            sortable: false, // Optional: Index should not be sortable
        },
        {
            name: 'Order ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Payment For',
            selector: row => row.payment_for,
            sortable: true,
        },
        {
            name: 'Total Price',
            selector: row => `₹${row.total_price}`,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.payment_status,
            sortable: true,
        },
        {
            name: 'Transaction ID',
            selector: row => row.txn_id,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => new Date(row.createdAt).toLocaleString(), // Shows Date and Time
            sortable: true,
        },
    ];

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="mt-3.5 flex flex-col gap-8">
                        <div className="box box--stacked flex flex-col">
                            <div className="overflow-auto xl:overflow-visible">
                                {loading ? (
                                    <p>Loading transactions...</p>
                                ) : error ? (
                                    <p className="text-red-500">{error}</p>
                                ) : (
                                    <DataTable
                                        title="Company Transaction History"
                                        columns={columns}
                                        data={transactions}
                                        pagination
                                        highlightOnHover
                                        striped
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyTransactions;
