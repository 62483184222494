import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { submitTaxReportOther } from "../../features/submitTaxReportSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OtherFillings = () => {
    const type = useParams();
    console.log(type);
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const [companyId, setCompanyId] = useState(0);
    const [useridsub, setUserIdSub] = useState(0);
    const [branchId, setBranchId] = useState(0);
    const [loading, setLoading] = useState(false);

    const fileInputRef = useRef(null);  

    const [taxReports, setTaxReports] = useState([{
        id: 1,
        date: '',
        name: '',
        policy_no: '',
        file: ''
    }]);

    useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        const logintype = localStorage.getItem('login_type');
        if (company) {
            const cid = company.id;
            setUserIdSub(cid);
            if (logintype == 'company') {
                setCompanyId(cid);
            } else {
                setBranchId(company.branch_id);
                setCompanyId(company.company_id);
            }
        }
    });

    const handleRemoveFile = (index) => {
        const updatedTaxReports = [...taxReports];
        updatedTaxReports[index].file = null;
        if (fileInputRef.current) {
            fileInputRef.current.value = '';  // Reset the file input field
        }  // Remove the file for the given index
        setTaxReports(updatedTaxReports);
    };

    const handleTaxReportChange = (index, e) => {
        const { name, type, files } = e.target;
        const updatedTaxReports = [...taxReports];

        if (type === 'file') {
            const file = files[0];

            // Check if a file was selected
            if (file) {
                // Check file type
                const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                if (!allowedTypes.includes(file.type)) {
                    alert('Please upload a PDF or XLSX file.');
                    return;
                }

                // Check file size (1 MB limit)
                const maxSizeInMB = 1.5;
                if (file.size > maxSizeInMB * 1024 * 1024) {
                    alert(`File size should not exceed ${maxSizeInMB} MB.`);
                    e.target.value = '';
                    return;
                }

                // If validation passes, update the file in the state
                updatedTaxReports[index][name] = file;
            }
        } else {
            updatedTaxReports[index][name] = e.target.value;
        }

        setTaxReports(updatedTaxReports);
    };


    const addNewVariant = () => {
        const company = JSON.parse(localStorage.getItem('company'));

        setTaxReports([
            ...taxReports,
            { id: taxReports.length + 1, date:'', name:'', file: '' }, // Add default values
        ]);
    };

    const removeVariant = (id) => {
        setTaxReports(taxReports.filter(detail => detail.id !== id));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = taxReports.every(report => 
            report.name && report.date && report.file
        );
    
        if (!isValid) {
            alert('Please ensure all tax reports have a registration number and a file.');
            return;
        }
        const logintype = localStorage.getItem('login_type');
        const formData = new FormData();
        
    
        formData.append('companyId', companyId);
        formData.append('branchId', branchId);
        formData.append('submitted_by', logintype);
        formData.append('user_id', useridsub);
        formData.append('type', type?.type);
    
        // Append each file
        taxReports.forEach((report, index) => {
            formData.append(`taxReports[${index}][name]`, report.name);
            formData.append(`taxReports[${index}][date]`, report.date);
            if(type.type.toUpperCase() == 'LIC'){
                formData.append(`taxReports[${index}][policy_no]`, report.policy_no);
            }
            if (report.file) {
                formData.append(`taxReports[${index}][file]`, report.file);
            }
        });
    
        // Set loading to true before the async actions
        setLoading(true);
        
        try {
            await dispatch(submitTaxReportOther(formData));
            toast.success('Submitted Successfully');
            navigator(`/${logintype}/other/filling/view`);
        } finally {
            // Set loading to false after the async actions complete
            setLoading(false);
        }
    }

    return (
        <div className="container">
            {loading && <div className="[&amp;.loading-page--before-hide]:h-screen [&amp;.loading-page--before-hide]:relative loading-page loading-page--before-hide [&amp;.loading-page--before-hide]:before:block [&amp;.loading-page--hide]:before:opacity-0 before:content-[''] before:transition-opacity before:duration-300 before:hidden before:inset-0 before:h-screen before:w-screen before:fixed before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:z-[60] [&amp;.loading-page--before-hide]:after:block [&amp;.loading-page--hide]:after:opacity-0 after:content-[''] after:transition-opacity after:duration-300 after:hidden after:h-16 after:w-16 after:animate-pulse after:fixed after:opacity-50 after:inset-0 after:m-auto after:bg-loading-puff after:bg-cover after:z-[61]"></div>}
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            {/* Add content here if needed */}
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col p-5 box box--stacked">
                                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                                        <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium text-uppercase dark:border-darkmode-400">
                                            {type?.type.toUpperCase()}
                                        </div>
                                        {taxReports.map((detail, index) => {

                                            return (
                                                <div key={index} className="mt-5 pb-5">
                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                        <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium"> Date</div>
                                                                    <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                        Required
                                                                    </div>
                                                                </div>
                                                                <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                                    Select  Date
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 w-full mt-3 xl:mt-0">
                                                            <input
                                                                type="date"
                                                                name="date"
                                                                value={detail.date}
                                                                onChange={(e) => handleTaxReportChange(index, e)}
                                                                placeholder="Filling Date"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                                                            />
                                                        </div>
                                                    </div>
                                                    {type?.type.toUpperCase() == 'LIC' && (<div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                        <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium"> Policy Number</div>
                                                                    <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                        Required
                                                                    </div>
                                                                </div>
                                                                <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                                    Enter Policy Number
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 w-full mt-3 xl:mt-0">
                                                            <input
                                                                type="text"
                                                                name="policy_no"
                                                                value={detail.policy_no}
                                                                
                                                                onChange={(e) => handleTaxReportChange(index, e)}
                                                                placeholder="Policy Number"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                                                            />
                                                        </div>
                                                    </div>)}
                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                        <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium"> Name</div>
                                                                    <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                        Required
                                                                    </div>
                                                                </div>
                                                                <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                                    Enter  Name
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 w-full mt-3 xl:mt-0">
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                value={detail.name}
                                                                onChange={(e) => handleTaxReportChange(index, e)}
                                                                placeholder="Name"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                        <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">Upload Files</div>
                                                                    <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                        Required
                                                                    </div>
                                                                </div>
                                                                <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                                    Upload Files <span className="text-danger">( Max File Size 1.5 MB )</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 flex w-full mt-3 xl:mt-0 ml-2">
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                ref={fileInputRef} 
                                                                accept=".pdf"
                                                                onChange={(e) => handleTaxReportChange(index, e)}
                                                                placeholder="Upload File"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                                                                style={{
                                                                    border: "2px solid #eee",
                                                                    borderRadius: "0px"
                                                                }}
                                                            />
                                                             {detail.file && (
                                                                <button
                                                                    type="button"
                                                                    onClick={(e) => handleRemoveFile(index, e)}
                                                                    className="btn bg-danger text-white pl-2 pr-2 text-center"
                                                                >
                                                                    &times;
                                                                </button>
                                                            )}

                                                        </div>
                                                        {index != 0 && (
                                                            <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                                                <button
                                                                    data-tw-merge=""
                                                                    className="btn-danger transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-primary text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 w-full border-dashed btn-danger"
                                                                    onClick={() => removeVariant(detail.id)}
                                                                >
                                                                    <i data-tw-merge="" data-lucide="x" className="mr-2 h-4 w-4 stroke-[1.3]" />
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">
                                    {/* <button
                                        type="button"
                                        onClick={addNewVariant}
                                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                                    >
                                        <i
                                            data-tw-merge=""
                                            data-lucide="pen-line"
                                            className="mr-2 h-4 w-4 stroke-[1.3]"
                                        />
                                        Add More
                                    </button> */}
                                    <button
                                        type="submit"
                                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                                    >
                                        <i
                                            data-tw-merge=""
                                            data-lucide="pen-line"
                                            className="mr-2 h-4 w-4 stroke-[1.3]"
                                        />
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default OtherFillings;