import React, { useEffect } from "react";
import CompanyTable from "./partials/company_table";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchCompanyCounts, getAllCompanyData, getCompanyById } from "../../features/companySlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { API_URL } from "../../utils/api_const";


const CompaniesDetail = () => {

    const { company_id } = useParams();
    const { singleCompany } = useSelector((state) => state.companies);
    const dispatch = useDispatch();

    useEffect(() => {
        
        dispatch(getCompanyById(company_id));
    }, [dispatch, company_id]);


    const handleStatusToggle = async (e) => {
        // Confirm the action with a popup
        const userConfirmed = window.confirm(`Are you sure you want to ${e.target.value === '0' ? 'deactivate' : 'activate'} this item?`);

        if (userConfirmed) {
            const newStatus = e.target.value;

            try {
                // Call the API to update the status
                const response = await axios.put(`${API_URL}/companies/update/${company_id}`, {
                    status: newStatus,
                });

                if (response.status === 200) {
                    dispatch(getCompanyById(company_id));
                    dispatch(getAllCompanyData());
                } else {
                    // Handle error if needed
                    alert('Failed to update status.');
                }
            } catch (error) {
                console.error('Error updating status:', error);
                alert('An error occurred while updating status.');
            }
        }
    };


    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            {singleCompany?.company_name}
                        </div>
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            <Link
                                to="/companies/change-password"
                                state={singleCompany?.email}
                                data-tw-merge=""
                                className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200"
                            >
                                <i
                                    data-tw-merge=""
                                    data-lucide="pen-line"
                                    className="mr-2 h-4 w-4 stroke-[1.3]"
                                />
                                Change Password
                            </Link>
                            <span className="text-white font-bold mr-2">Status</span>
                            <select
                                onChange={handleStatusToggle}
                                value={singleCompany?.status}
                                className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 rounded-[0.5rem] pl-9 sm:w-44"
                            >
                                <option value="0">Pending Approval</option>
                                <option value="1">Approved</option>
                            </select>
                        </div>
                    </div>
                    <div className="mt-3.5 flex flex-col gap-8">
                        <div className="box box--stacked flex flex-col">
                            <div className="overflow-auto xl:overflow-visible p-4">
                                <div className="p-4">
                                    <div className="mb-6">
                                        <h2 className="text-lg font-bold">Company Information</h2>
                                        <div className="flex flex-wrap sm:grid-cols-2 gap-4 mt-4">
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Username:</strong> {singleCompany?.username}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Email:</strong> {singleCompany?.email}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Date of Birth:</strong> {new Date(singleCompany?.dob).toLocaleDateString()}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Gender:</strong> {singleCompany?.gender}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Company Name:</strong> {singleCompany?.company_name}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Trade Name:</strong> {singleCompany?.trade_name}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Company Type:</strong> {singleCompany?.company_type}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>PAN Number:</strong> {singleCompany?.pan_number}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>TAN Number:</strong> {singleCompany?.tan_number}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>CIN Number:</strong> {singleCompany?.cin_number}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>MSME Registration:</strong> {singleCompany?.msme_reg}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Nature of Business:</strong> {singleCompany?.nature_of_business}</div>
                                            <div className="mb-4 mr-2 p-2 border rounded-lg"><strong>Registered Office Address:</strong> {singleCompany?.register_office_address}</div>
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <h2 className="text-lg font-bold">Branches</h2>
                                        <div className="flex flex-wrap pt-2">
                                            {singleCompany?.Branches.map((branch, index) => (
                                                <div key={index} className="mb-4 mr-2 p-4 border rounded-lg">
                                                    <div><strong>Branch Name:</strong> {branch.branch_name}</div>
                                                    <div><strong>Location:</strong> {branch.branch_location}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <h2 className="text-lg font-bold">Company Tax Information</h2>
                                        <div className="flex flex-wrap pt-2">
                                            {singleCompany?.CompanyTaxInfos.map((taxInfo, index) => (
                                                <div key={index} className="mb-4 mr-2 p-4 border rounded-lg">
                                                    <div><strong>Ward Income Tax:</strong> {taxInfo.ward_income_tax}</div>
                                                    <div><strong>Ward GST:</strong> {taxInfo.ward_gst}</div>
                                                    <div><strong>Ward TDS:</strong> {taxInfo.ward_tds}</div>
                                                    <div><strong>Area Code Income Tax:</strong> {taxInfo.area_code_income_tax}</div>
                                                    <div><strong>Area Code GST:</strong> {taxInfo.area_code_gst}</div>
                                                    <div><strong>Area Code TDS:</strong> {taxInfo.area_code_tds}</div>
                                                    <div><strong>AO Type Income Tax:</strong> {taxInfo.ao_type_income_tax}</div>
                                                    <div><strong>AO Type GST:</strong> {taxInfo.ao_type_gst}</div>
                                                    <div><strong>AO Type TDS:</strong> {taxInfo.ao_type_tds}</div>
                                                    <div><strong>Range Code Income Tax:</strong> {taxInfo.range_code_income_tax}</div>
                                                    <div><strong>Range Code GST:</strong> {taxInfo.range_code_gst}</div>
                                                    <div><strong>Range Code TDS:</strong> {taxInfo.range_code_tds}</div>
                                                    <div><strong>AO Number Income Tax:</strong> {taxInfo.ao_number_income_tax}</div>
                                                    <div><strong>AO Number GST:</strong> {taxInfo.ao_number_gst}</div>
                                                    <div><strong>AO Number TDS:</strong> {taxInfo.ao_number_tds}</div>
                                                    <div><strong>Jurisdiction Income Tax:</strong> {taxInfo.jurisdiction_income_tax}</div>
                                                    <div><strong>Jurisdiction GST:</strong> {taxInfo.jurisdiction_gst}</div>
                                                    <div><strong>Jurisdiction TDS:</strong> {taxInfo.jurisdiction_tds}</div>
                                                    <div><strong>Building Name Income Tax:</strong> {taxInfo.building_name_income_tax}</div>
                                                    <div><strong>Building Name GST:</strong> {taxInfo.building_name_gst}</div>
                                                    <div><strong>Building Name TDS:</strong> {taxInfo.building_name_tds}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <h2 className="text-lg font-bold">Bank Details</h2>
                                        <div className="flex flex-wrap pt-2">
                                            {singleCompany?.BankDetails.map((bank, index) => (
                                                <div key={index} className="mb-4 mr-2 p-4 border rounded-lg">
                                                    <div><strong>Bank Name:</strong> {bank.bank_name}</div>
                                                    <div><strong>Branch:</strong> {bank.bank_branch}</div>
                                                    <div><strong>Account Number:</strong> {bank.account_no}</div>
                                                    <div><strong>IFSC Code:</strong> {bank.ifsc}</div>
                                                    <div><strong>MICR Code:</strong> {bank.micr}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="mb-6">
                                        <h2 className="text-lg font-bold">Director Details</h2>
                                        <div className="flex flex-wrap pt-2">
                                            {singleCompany?.DirectorDetails.map((director, index) => (
                                                <div key={index} className="mb-4 mr-2 p-4 border rounded-lg">
                                                    <div><strong>Name:</strong> {director.name}</div>
                                                    <div><strong>Address:</strong> {director.address}</div>
                                                    <div><strong>City:</strong> {director.city}</div>
                                                    <div><strong>Pincode:</strong> {director.pincode}</div>
                                                    <div><strong>Police Station:</strong> {director.ps}</div>
                                                    <div><strong>Post Office:</strong> {director.po}</div>
                                                    <div><strong>Holding:</strong> {director.holding}%</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="flex-reverse flex flex-col-reverse flex-wrap items-center gap-y-2 p-5 sm:flex-row">
                                {/* <nav className="mr-auto w-full flex-1 sm:w-auto">
                                    <ul className="flex w-full mr-0 sm:mr-auto sm:w-auto">
                                        
                                        <li className="flex-1 sm:flex-initial">
                                            <a
                                                data-tw-merge=""
                                                className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3 !box dark:bg-darkmode-400"
                                            >
                                                1
                                            </a>
                                        </li>
                                        <li className="flex-1 sm:flex-initial">
                                            <a
                                                data-tw-merge=""
                                                className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                            >
                                                2
                                            </a>
                                        </li>
                                        <li className="flex-1 sm:flex-initial">
                                            <a
                                                data-tw-merge=""
                                                className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                            >
                                                3
                                            </a>
                                        </li>
                                        <li className="flex-1 sm:flex-initial">
                                            <a
                                                data-tw-merge=""
                                                className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                            >
                                                <i
                                                    data-tw-merge=""
                                                    data-lucide="chevrons-right"
                                                    className="stroke-[1] h-4 w-4"
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <select
                                    data-tw-merge=""
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] sm:w-20"
                                >
                                    <option>10</option>
                                    <option>25</option>
                                    <option>35</option>
                                    <option>50</option>
                                </select> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompaniesDetail;