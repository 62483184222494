import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableHeader from "../../../utils/table_headings";
import moment from "moment";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { getScheduleReportsByCompanyId } from "../../../features/scheduleReportSlice";
import generateFiscalYears from "../../dashboard/partials/generate_financial_year";
import { fetchBranchesByCompanyId } from "../../../features/branchSlice";
import { API_URL } from "../../../utils/api_const";
const monthMapping = {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
};

const formatPeriod = (period) => {
    const month = monthMapping[period.toLowerCase()];
    return month ? moment(month, 'MMMM').format('MMMM') : period;
};


// const CompanyTaxFillingTable = ({ scheduleReports = [], check_type }) => {
//     const companyHeadings = ['Filling Name', 'Type', 'Session', 'Branch','Status', 'Actions'];
//     const location = useLocation();
//     const [usertype, setUserType] = useState('');
//     // Function to check if the link is active
//     const isActive = (path) => location.pathname === path;


//     useEffect(() => {
//         setUserType(localStorage.getItem('login_type'));
//     });
//     // Check if scheduleReports is an array
//     if (!Array.isArray(scheduleReports)) {
//         console.error('scheduleReports is not an array:', scheduleReports);
//         return <div>Error: Invalid data format</div>;
//     }

//     // Filter reports based on check_type

//     let loop = 1;

//     return (

//         <table
//             data-tw-merge=""
//             className="w-full table-responsive text-left border-b border-slate-200/60"
//         >
//             <TableHeader headings={companyHeadings} />
//             <tbody>

//                 {scheduleReports.map((row) => {
//                     // const isSubmitted = taxServiceId.SubmittedReports?.some((e) => );
//                     // console.log(isSubmitted);
//                     return (
//                         <tr key={loop} data-tw-merge="" className="[&_td]:last:border-b-0">
//                             <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">
//                                 {loop++}
//                             </td>
//                             <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600" style={{width:"30%"}}>
//                                 <a className="whitespace-wrap font-medium" href="#0">
//                                     {row.TaxService?.service_name || 'Unknown Service'}
//                                 </a>
//                             </td>
//                             <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">
//                                 <a className="whitespace-nowrap font-medium" href="#0">
//                                     {row.type === 'return_filling' ? "Periodic Fillings" : "Annual Fillings"}
//                                 </a>
//                             </td>
//                             <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 w-40 border-dashed py-4 dark:bg-darkmode-600">
//                                 <div className="flex">
//                                     <div>
//                                         <a className="whitespace-wrap font-medium" href="#0">
//                                             {row.session}
//                                         </a>
//                                         <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
//                                             {row.type === 'return_filling' && row.period ? formatPeriod(row.period) : ''}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </td>

//                             <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">
//                                 <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
//                                     {row.Branch?.branch_name || 'Self'}
//                                 </div>
//                             </td>
//                             <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">
//                                 <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
//                                     <span className={`${check_type == 'upcoming-filling'?'bg-primary':'bg-danger'} p-2 border-b rounded-full border text-white`}>
//                                         {check_type == 'upcoming-filling'?row.nextScheduleDate:row.pendingSchedulesDate}
//                                     </span>
//                                 </div>
//                             </td>
//                             <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 relative border-dashed py-4 dark:bg-darkmode-600">
//                                 <div className="flex">
//                                     <div data-tw-merge="" data-tw-placement="bottom-end" className="dropdown relative h-5">
//                                         <Link to={`/${usertype}/tax/${check_type}/${row.id}/${row.TaxService?.id}/${check_type == 'upcoming-filling'?row.nextScheduleDate:row.pendingSchedulesDate}/${row.branch_id == null?'company':'branch'}`} className="btn btn-success cursor-pointer h-5 w-5 text-slate-500">
//                                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-book-text">
//                                                 <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" />
//                                                 <path d="M8 11h8" />
//                                                 <path d="M8 7h6" />
//                                             </svg>
//                                         </Link>
//                                     </div>
//                                 </div>
//                             </td>
//                         </tr>
//                     );
//                 })}

//             </tbody>
//         </table>
//     );
// }




const CompanyTaxFillingTable = ({ scheduleReports = [], check_type, selectedBranch, selectedFillingType, searchText}) => {
    const fiscalYears = generateFiscalYears();
    const dispatch = useDispatch();
    const [schedules, setSchedules] = useState([]);
    const today = new Date();
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(
        localStorage.getItem('selectedFiscalYear') || fiscalYears[fiscalYears.length - 1]
    );
    const [usertype, setUserType] = useState('');

    const [selectTypeData, setSelectedTypeData] = useState('');
    useEffect(() => {
        if (check_type === 'pending-filling') {
            setSelectedTypeData('pending');
        } else if (check_type === 'upcoming-filling') {
            setSelectedTypeData('upcoming');
        }
    }, [check_type]);
    const [showAll, setShowAll] = useState(false);
    const { branches, status, error } = useSelector((state) => state.branches);
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active accordion

    useEffect(() => {
        setUserType(localStorage.getItem('login_type'));
    });

    useEffect(() => {
        localStorage.setItem('selectedFiscalYear', selectedFiscalYear);
    }, [selectedFiscalYear]);

    const handleFiscalYearChange = (e) => {
        setSelectedFiscalYear(e.target.value);

    };

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Toggle between showing and hiding the accordion
    };

    function getFrequencyText(row) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (row.frequency === 'monthly') {
            return `${row.day}th of Every Month`;
        } else if (row.frequency === 'quarterly') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            //     if (startMonthIndex !== -1) {
            //         const quarterMonths = [startMonthIndex, (startMonthIndex + 3) % 12, (startMonthIndex + 6) % 12, (startMonthIndex + 9) % 12];
            //         const quarterMonthNames = quarterMonths.map(index => months[index]);
            //         return `${row.day} of ${quarterMonthNames.join(', ')}`;
            //     } else {
            //         return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            //     }

            if (startMonthIndex !== -1) {
                // Define the quarter months based on the start month index
                const quarterMonths = [
                    startMonthIndex,
                    (startMonthIndex + 3) % 12,
                    (startMonthIndex + 6) % 12,
                    (startMonthIndex + 9) % 12
                ];
                let dayData = row.day;
            
                const quarterMonthNames = quarterMonths.map((monthIndex) => {
                    let date = moment().month(monthIndex).date(row.day); // Use the day from the row
            
                    // Adjust the date based on the specific conditions
                    if (date.month() === 3) { // If it's April (index 3)
                        if(row.day == '30'){
                            dayData = '31';
                        }
                        date = date.add(1, 'month'); // Add one month
                        console.log('Adjusted date after adding one month:', date.format('MMM DD, YYYY'));
                    }
                    if (row.day === '30') { // If the day is the 30th
                        date = date.add(1, 'day'); // Add one more day
                    }
            
                    return date.format('MMM DD');
                });
                
                
            
                return `${dayData} of ${quarterMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency === 'semi-annually') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const halfYearMonths = [startMonthIndex, (startMonthIndex + 6) % 12];
                const halfYearMonthNames = halfYearMonths.map(index => months[index]);
                return `${row.day} of ${halfYearMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency == 'annually') {
            const date = moment(row.day);
            const day = date.format('DD'); // Day of the month
            const month = date.format('MMMM'); // Full month name
            return `${day} of Every ${month}`;
        } else {
            return '';
        }
    }


    useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        if (company) {
            const companyId = company.id;
            dispatch(fetchBranchesByCompanyId(companyId));
        }
    }, [dispatch]);


    useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        const usertype = localStorage.getItem('login_type');

        if (company) {
            // const companyId = company.id;
            // /search-report/${selectedFiscalYear}?company_id=${companyId}
            const fetchSchedules = async () => {
                const companyId = company.id;
                const branchId = company.branch_id;
                let url = '';
                if (usertype == 'company') {
                    url = `${API_URL}/companies/tax-service/${companyId}/${selectedFiscalYear}`;
                } else {
                    url = `${API_URL}/branches/tax-service/${branchId}/${selectedFiscalYear}`
                }
                try {
                    const response = await fetch(url);
                    const data = await response.json();
                    setSchedules(data);
                } catch (error) {
                    console.error("Error fetching schedules:", error);
                }
            };
            fetchSchedules();
        }
    }, [selectedFiscalYear]);

    const handleShowDataType = (e) => {
        setSelectedTypeData(e.target.value);
        if (e.target.value == 'all') {
            setShowAll(false);
        } else {
            setShowAll(true);
        }

    }

    const generateTableRows = (service) => {
        const today = moment();
        const startMonth = 3; // April
        const endMonth = 14; // March of the following year

        const getDateStatus = (date, reports) => {
            if (reports.length > 0) {
                const report = reports.find(report => moment(report.due_date).isSame(date, 'day'));
                return report ? 'Submitted' : date.isBefore(today)?'Pending':'Upcoming';
            }
            // console.log('date is',date);
            // console.log(date.isBefore(moment().startOf('day'), 'day') ? 'Pending' : 'Upcoming');
            return date.isBefore(moment().startOf('day'), 'day') ? 'Pending' : 'Upcoming';
        };

        const generateRowsForFrequency = (frequency, day, start_from, service_name, itemdata) => {
            console.log(day);
            const rows = [];
            const monthsInYear = Array.from({ length: 12 }, (_, i) => (i + 3) % 12); // Generate month indices from April to March
            const row = Array(12).fill(null);
            const [currentYear, endYear] = selectedFiscalYear.split('-').map(Number);
            // console.log('Branch Schedule',itemdata.BranchSchedules);
            // console.log('Company Schedule',itemdata.ScheduleReports)
            const scheduleId = itemdata.BranchSchedules?.[0]?.id || itemdata.ScheduleReports?.[0]?.id;


            if (frequency === 'monthly') {
                monthsInYear.forEach((month, index) => {
                    const dates = moment().year(index < 9 ? currentYear : currentYear + 1).month(month).date(day);
                    row[index] = {
                        date: dates.format('MMM DD'),
                        scheduleId: scheduleId,
                        isBranch: itemdata.BranchSchedules?'branch':'company',
                        itemid: itemdata.id,
                        scheduleDate: dates.format('YYYY-MM-DD'),
                        status: getDateStatus(dates, service.SubmittedReports),
                        color: getDateStatus(dates, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(dates, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                    };
                });
            } else if (frequency === 'quarterly') {
                const startMonthQuarterly = moment().month(start_from);
                if(startMonthQuarterly.month() == 2){
                    const monthsInYearMarch = Array.from({ length: 12 }, (_, i) => (i + 2) % 12);;

                    monthsInYearMarch.forEach((month, index) => {
                        if (index % 3 === 0) { 
                            
                            let date = moment().year(index < 9 ? currentYear : currentYear + 1).month(month).date(day);
                            console.log('checking index data');
                            console.log(index);
                            console.log(date.month());
                           
                            if(index == 0){
                                row[11] = {
                                    date: date.format('MMM DD'),
                                    scheduleId: scheduleId,
                                    isBranch: itemdata.BranchSchedules?'branch':'company',
                                    itemid: itemdata.id,
                                    scheduleDate: date.format('YYYY-MM-DD'),
                                    status: getDateStatus(date, service.SubmittedReports),
                                    color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                                }
                            }else{
                                row[index-1] = {
                                    date: date.format('MMM DD'),
                                    scheduleId: scheduleId,
                                    isBranch: itemdata.BranchSchedules?'branch':'company',
                                    itemid: itemdata.id,
                                    scheduleDate: date.format('YYYY-MM-DD'),
                                    status: getDateStatus(date, service.SubmittedReports),
                                    color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                                };
                            }
                            
                        }
                    });
                }else{
                    monthsInYear.forEach((month, index) => {
                        if (index % 3 === 0) { // Every quarter
                            let myindex = index;
                            let date = moment().year(index < 9 ? currentYear : currentYear + 1).month(month).date(day);
                            console.log('this is date of mont'+ date.format('MM'), date.month());
                            if (startMonthQuarterly.month() == 0 && date.month() === 3) {
                                
                                myindex = index+1;
                                date = date.add(1, 'month'); // Add one month to the date
                                console.log('Adjusted date after adding one month:', date.format('MMM DD, YYYY'));
                            }
                            if(day == '30'){
                                date = date.add(1,'day');
                            }
                            
                            row[myindex] = {
                                date: date.format('MMM DD'),
                                scheduleId: scheduleId,
                                isBranch: itemdata.BranchSchedules?'branch':'company',
                                itemid: itemdata.id,
                                scheduleDate: date.format('YYYY-MM-DD'),
                                status: getDateStatus(date, service.SubmittedReports),
                                color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                            };
                        }
                    });
                }
                
            } else if (frequency === 'semi-annually') {
                const semiAnnualMonths = {
                    january: [0, 6],
                    april: [3, 9],
                    october: [9, 3],
                    june: [5, 11]
                };
                const months = semiAnnualMonths[start_from] || [];
                monthsInYear.forEach((month, index) => {
                    if (months.includes(month)) {
                        const date = moment().year(index < 9 ? currentYear : currentYear + 1).month(month).date(day);
                        row[index] = {
                            date: date.format('MMM DD'),
                            scheduleId: scheduleId,
                            isBranch: itemdata.BranchSchedules?'branch':'company',
                            itemid: itemdata.id,
                            scheduleDate: date.format('YYYY-MM-DD'),
                            status: getDateStatus(date, service.SubmittedReports),
                            color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                        };
                    }
                });
            } else if (frequency === 'annually') {
                // Determine the starting month for annual frequency
                const startMonthAnnually = moment(day);
                // console.log('i smonth data', startMonthAnnually);
                // const currentYear = moment().year(currentYear); // Get the current year

                monthsInYear.forEach((month, index) => {
                    // Only set the row value if the month matches the start month
                    if (month === startMonthAnnually.month()) {
                        console.log('servicename', service_name)
                        console.log('current year', currentYear);
                        // console.log('month', month);
                        console.log('date', day);
                        // Use the current year for dates in the current year range
                        const date = moment(day).year(currentYear);

                    

                        console.log('fine data date', date);

                        row[index] = {
                            date: date.format('MMM DD'),
                            itemid: itemdata.id,
                            scheduleId: scheduleId,
                            isBranch: itemdata.BranchSchedules?'branch':'company',
                            scheduleDate: date.format('YYYY-MM-DD'),
                            status: getDateStatus(date, service.SubmittedReports),
                            color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                        };
                    } else {
                        // Handle cases where the month does not match the start month
                        row[index] = {
                            date: '',
                            scheduleId: '',
                            itemid: '',
                            isBranch: '',
                            scheduleDate: '',// Leave blank if the month does not match
                            status: 'Not Applicable',
                            color: 'grey'
                        };
                    }
                });
            }
            rows.push(row);
            return rows;
        };

        if (service.frequency) {
            return generateRowsForFrequency(service.frequency, service.day, service.start_from, service.service_name, service);
        }

        return [];
    };

    const filteredData = schedules.length > 0 && schedules.filter((row) => {
        return (
            row.service_name.toLowerCase().includes(searchText.toLowerCase())
        );
    });

    return (
        <div className="box box--stacked flex flex-col p-5">
            <div className="accordion">
                {filteredData && filteredData.length > 0 && filteredData.map((item, index) => (
                    <div
                        data-tw-merge=""
                        key={index}
                        style={{
                            display: (
                                selectedBranch !== '' && Number(item.BranchSchedules?.[0]?.branch_id) !== Number(selectedBranch)
                                || selectedFillingType !== '' && item.frequency !== selectedFillingType
                            ) ? 'none' : 'block'
                        }}
                        // style={{ display: selectedBranch !== '' && Number(item.BranchSchedules?.[0]?.branch_id) !== Number(selectedBranch) ? 'none' : 'block' }}
                        className="accordion-item py-4 first:-mt-4 last:-mb-4 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-slate-200/60 [&:not(:last-child)]:dark:border-darkmode-400 p-4 first:mt-0 last:mb-0 border border-slate-200/60 mt-3 dark:border-darkmode-400"
                    >
                        <div className="accordion-header" id="faq-accordion-5" >
                            <button
                                data-tw-merge=""
                                data-tw-toggle="collapse"
                                data-tw-target={`#faq-accordion-${index}-collapse`}
                                type="button"
                                aria-expanded="true"
                                aria-controls={`faq-accordion-${index}-collapse`}
                                onClick={() => toggleAccordion(index)}
                                className="accordion-button outline-none py-4 -my-4 font-medium w-full text-left dark:text-slate-400 [&:not(.collapsed)]:text-primary [&:not(.collapsed)]:dark:text-slate-300"
                            >
                                <table className="table w-full">
                                    <thead>
                                        <tr>
                                            {item.BranchSchedules?.[0] && (<th style={{ width: "20%" }}>{item.BranchSchedules?.[0]?.Branch?.branch_name}</th>)}
                                            <th style={{ width: "30%" }}>{item.service_name}</th>
                                            <th style={{ width: "20%", textTransform: "capitalize" }}>
                                                {item.frequency}<br />
                                                <small>{item.service_type == 'return_filling' ? 'Periodic Filling' : 'Annual Filling'}</small>
                                            </th>
                                            <th style={{ width: "30%" }}>{getFrequencyText(item)}</th>
                                            {/* <th style={{ width: "10%" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-eye"><path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0" /><circle cx="12" cy="12" r="3" /></svg></th> */}
                                        </tr>
                                    </thead>
                                </table>
                            </button>
                        </div>




                        <div
                            id={`faq-accordion-${index}-collapse`}
                            aria-labelledby={`faq-accordion-${index}`}
                            className={`accordion-collapse collapse mt-3 text-slate-700 leading-relaxed dark:text-slate-400 [&.collapse:not(.show)]:hidden [&.collapse.show]:visible show`}
                            style={{ overflowX: 'scroll' }}
                        >
                            <div
                                data-tw-merge=""
                                className="accordion-body leading-relaxed text-slate-600 dark:text-slate-500 leading-relaxed text-slate-600 dark:text-slate-500"
                            >
                                <table className="w-full text-left border-b border-slate-200/60 mt-5">
                                    <thead>
                                        <tr>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">April</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">May</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">June</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">July</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">August</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">September</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">October</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">November</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">December</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">January</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">February</th>
                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">March</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {generateTableRows(item).map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                {/* {row.map((cell, cellIndex) => (
                                                                    <td key={cellIndex} style={{textAlign:'center'}} className="border-b dark:border-darkmode-300 border py-4 dark:bg-darkmode-600">
                                                                        {selectTypeData == 'pending' && cell?.color == 'red'?
                                                                            
                                                                                <span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>
                                                                    
                                                                            :''}
                                                                        {selectTypeData == 'completed' && cell?.color == 'green'?<span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>:''}
                                                                        {selectTypeData == 'upcoming' && cell?.color == 'blue'?<span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>:''}
                                                                        {selectTypeData == 'all'?
                                                                            {cell?.color == 'red'?
                                                                                <Link to={`/${usertype}/tax/pending-filling/${getScheduleId(item)}/${cell.TaxService?.id}/${cell.scheduleDate}`}>
                                                                                    <span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>
                                                                                </Link>:
                                                                                <span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>
                                                                            }
                                                                            
                                                                        :''}
                                                                    </td>
                                                                ))} */}
                                                {row.map((cell, cellIndex) => (
                                                    <td
                                                        key={cellIndex}
                                                        style={{ textAlign: 'center' }}
                                                        className="border-b dark:border-darkmode-300 border py-4 dark:bg-darkmode-600"
                                                    >
                                                        {selectTypeData === 'pending' && cell?.color === 'red' && (
                                                            <Link
                                                                to={`/${usertype}/tax/pending-filling/${cell.scheduleId}/${cell.itemid}/${cell.scheduleDate}/${cell.isBranch}`}
                                                            >
                                                                <span
                                                                    className="badge"
                                                                    style={{
                                                                        backgroundColor: cell?.color || 'transparent',
                                                                        textAlign: 'center',
                                                                        color: '#fff',
                                                                        padding: '5px 5px',
                                                                        borderRadius: '20px',
                                                                    }}
                                                                >
                                                                    {cell?.date || ''}
                                                                </span>
                                                            </Link>
                                                        )}

                                                        {selectTypeData === 'completed' && cell?.color === 'green' && (
                                                            <span
                                                                className="badge"
                                                                style={{
                                                                    backgroundColor: cell?.color || 'transparent',
                                                                    textAlign: 'center',
                                                                    color: '#fff',
                                                                    padding: '5px 5px',
                                                                    borderRadius: '20px',
                                                                }}
                                                            >
                                                                {cell?.date || ''}
                                                            </span>
                                                        )}

                                                        {selectTypeData === 'upcoming' && cell?.color === 'blue' && (
                                                            <Link
                                                                to={`/${usertype}/tax/upcoming-filling/${cell.scheduleId}/${cell.itemid}/${cell.scheduleDate}/${cell.isBranch}`}
                                                            >
                                                                <span
                                                                    className="badge"
                                                                    style={{
                                                                        backgroundColor: cell?.color || 'transparent',
                                                                        textAlign: 'center',
                                                                        color: '#fff',
                                                                        padding: '5px 5px',
                                                                        borderRadius: '20px',
                                                                    }}
                                                                >
                                                                    {cell?.date || ''}
                                                                </span>
                                                            </Link>
                                                        )}

                                                        {selectTypeData === 'all' && (
                                                            <div>
                                                                {cell?.color === 'red' ? (
                                                                    <Link
                                                                        to={`/${usertype}/tax/pending-filling/${cell.scheduleId}/${cell.itemid}/${cell.scheduleDate}/${cell.isBranch}`}
                                                                    >
                                                                        <span
                                                                            className="badge"
                                                                            style={{
                                                                                backgroundColor: cell?.color || 'transparent',
                                                                                textAlign: 'center',
                                                                                color: '#fff',
                                                                                padding: '5px 5px',
                                                                                borderRadius: '20px',
                                                                            }}
                                                                        >
                                                                            {cell?.date || ''}
                                                                        </span>
                                                                    </Link>
                                                                ) : cell?.color === 'blue' ? (
                                                                    <Link
                                                                        to={`/${usertype}/tax/upcoming-filling/${cell.scheduleId}/${cell.itemid}/${cell.scheduleDate}/${cell.isBranch}`}
                                                                    >
                                                                        <span
                                                                            className="badge"
                                                                            style={{
                                                                                backgroundColor: cell?.color || 'transparent',
                                                                                textAlign: 'center',
                                                                                color: '#fff',
                                                                                padding: '5px 5px',
                                                                                borderRadius: '20px',
                                                                            }}
                                                                        >
                                                                            {cell?.date || ''}
                                                                        </span>
                                                                    </Link>
                                                                ) : (
                                                                    <span
                                                                        className="badge"
                                                                        style={{
                                                                            backgroundColor: cell?.color || 'transparent',
                                                                            textAlign: 'center',
                                                                            color: '#fff',
                                                                            padding: '5px 5px',
                                                                            borderRadius: '20px',
                                                                        }}
                                                                    >
                                                                        {cell?.date || ''}
                                                                    </span>
                                                                )}
                                                            </div>

                                                        )}
                                                    </td>
                                                ))}

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    )
}

export default CompanyTaxFillingTable;