import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { updateBranch } from "../../features/branchSlice";
import { getAllCompanyData } from "../../features/companySlice";
import { API_URL } from "../../utils/api_const";
import axios from 'axios';

const EditBranch = () => {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const status = useSelector((state) => state.branches.status);
    const { companies } = useSelector((state) => state.companies);
    const [passwordError, setPasswordError] = useState('');
    const [sameCompany, setSameCompanyData] = useState(false);
    const { id } = useParams();
    const location = useLocation();
    const { branchData } = location.state || {}; //

    const [formData, setFormData] = useState({
        company_id: '',
        branch_name: '',
        branch_location: '',
        trade_name: '',
        additional_trade_name: '',
        pan_number: '',
        pan_image: '',
        register_office_address: '',
        msme_reg: '',
        nature_of_business: ''
    });


    const [formDataError, setFormDataError] = useState({
        branch_name: '',
        branch_location: '',
        trade_name: '',
        additional_trade_name: '',
        pan_number: '',
        pan_image: '',
        register_office_address: '',
        msme_reg: '',
        nature_of_business: ''
    });

    useEffect(() => {
        if (branchData) {
            setFormData({
                company_id: branchData.company_id,
                branch_name: branchData.branch_name,
                branch_location: branchData.branch_location,
                trade_name: branchData.trade_name,
                additional_trade_name: branchData.additional_trade_name,
                pan_number: branchData.pan_number,
                pan_image: branchData.pan_image,
                register_office_address: branchData.register_office_address,
                msme_reg: branchData.msme_reg,
                nature_of_business: branchData.nature_of_business
            });
        }
    }, [branchData]);


    useEffect(() => {
        dispatch(getAllCompanyData());
    }, [dispatch]);

    const handleChange = async (e) => {
        if (e.target.type === 'file') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.files[0]
            });
        } else {

            if (e.target.name === 'pan_number') {
                setFormDataError({
                    ...formDataError,
                    [e.target.name]: "",
                })
                const panValue = e.target.value.toUpperCase(); // Convert input to uppercase

                // Regex to validate PAN card format: 5 uppercase letters, 4 digits, 1 uppercase letter
                const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

                if (panValue.length <= 10) {
                    // Update formData with the uppercase PAN value
                    setFormData({
                        ...formData,
                        [e.target.name]: panValue, // Set the uppercase value in the form data
                    });

                    if (!panRegex.test(panValue) && panValue.length === 10) {
                        setFormDataError({
                            ...formDataError,
                            [e.target.name]: 'Invalid Pan Card Number'
                        })
                    }

                    try {
                        const response = await axios.post(`${API_URL}/companies/check-pan`, { pan_number: e.target.value });
                        if (response.data.exists) {
                            setFormDataError(prevErrors => ({
                                ...prevErrors,
                                pan_number: 'This PAN number is already registered with another company.'
                            }));
                        }
                    } catch (error) {
                        console.error('Error checking PAN number:', error);
                    }
                }
            } else {
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                });
            }
            // setFormData({
            //     ...formData,
            //     [e.target.name]: e.target.value
            // });
        }
        if (e.target.name === 'password' || e.target.name === 'confirm_password') {
            setPasswordError('');
        }
    };

    const handleCheckbox = (e) => {
        console.log(e.target.checked);
        setSameCompanyData(e.target.checked);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.company_id === '') {
            setFormDataError((prevErrors) => ({
                ...prevErrors, // retain previous error states
                company_id: 'Please Select company' // set the error message for company_id
            }));
            return;
        }


        if (formData.branch_name === '') {
            setFormDataError((prevErrors) => ({
                ...prevErrors, // retain previous error states
                branch_name: 'Branch Name is required' // set the error message for company_id
            }));
            return;
        }

        if (formData.branch_location === '') {
            setFormDataError((prevErrors) => ({
                ...prevErrors, // retain previous error states
                branch_location: 'Branch location is required' // set the error message for company_id
            }));
            return;
        }

        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

        if (sameCompany === false) {
            if (formData.trade_name === '') {
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    trade_name: 'Trade Name is required'
                }));
                return;
            }
            if (formData.pan_number === '') {
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    pan_number: 'Pan Card Number is required'
                }));
                return;
            } else if (!panRegex.test(formData.pan_number)) {
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    pan_number: 'Invalid PAN Card Number'
                }));
                return;
            }
            if (formData.register_office_address === '') {
                setFormDataError((prevErrors) => ({
                    ...prevErrors,
                    register_office_address: 'Register office address is rrequired'
                }));
                return;
            }
        }


        if (formData.password !== formData.confirm_password) {
            setPasswordError('Password does not Match');
            return;
        }

        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }

        try {

            // Dispatch the action and wait for it to complete
            const resultAction = await dispatch(updateBranch({ id: branchData.id, branch: formDataObj }));

            // Check if the action was successful and get the company ID
            if (updateBranch.fulfilled.match(resultAction)) {
                navigator(`/branches`)
            } else {
                // Handle the error case
                console.error('Failed to create company:', resultAction.payload);
            }

            // Dispatch the action and wait for it to complete
            // const resultAction = await dispatch(addBranch(formDataObj));

            // // Check if the action was successful and get the company ID
            // if (addBranch.fulfilled.match(resultAction)) {
            //     navigator(`/branches`);
            // } else {
            //     // Handle the error case
            //     console.error('Failed to create company:', resultAction.payload);
            // }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Add Branch
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col p-5 box box--stacked">
                                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                                        <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                                            <i
                                                data-tw-merge=""
                                                data-lucide="chevron-down"
                                                className="w-5 h-5 mr-2 stroke-[1.3]"
                                            />
                                            Branch Information
                                        </div>
                                        <div className="mt-5">
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Company</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Company where your want to add this branch.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <select
                                                        data-tw-merge=""
                                                        id="category"
                                                        onChange={handleChange}
                                                        value={formData.company_id}
                                                        name="company_id"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                                    >
                                                        <option value=""> -- Select Status --</option>
                                                        {companies && companies.map((company) => (
                                                            <option key={company.id} value={company.id}>
                                                                {company.company_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {formDataError.company_id && (<span className="text-danger">{formDataError.company_id}</span>)}
                                                </div>
                                            </div>

                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Branch Name</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Enter the unique name Branch.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="branch_name"
                                                        onChange={handleChange}
                                                        value={formData.branch_name}
                                                        placeholder="Branch name"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formDataError.branch_name && (<span className="text-danger">{formDataError.branch_name}</span>)}
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Branch Location</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Enter the Location of the Branch
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="branch_location"
                                                        value={formData.branch_location}
                                                        onChange={handleChange}
                                                        placeholder="Branch Location"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formDataError.branch_location && (<span className="text-danger">{formDataError.branch_location}</span>)}

                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Other Details </div>

                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">

                                                            ( Same as Company )
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="checkbox"
                                                        checked={sameCompany}
                                                        name="same_company"
                                                        onChange={handleCheckbox}
                                                        placeholder="Branch Location"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />

                                                </div>
                                            </div>
                                            {sameCompany == false && (
                                                <>

                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                        <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">Trade Name <span className="text-danger">*</span></div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="flex-1 w-full mt-3 xl:mt-0">
                                                            <input
                                                                data-tw-merge=""
                                                                type="text"
                                                                name="trade_name"

                                                                value={formData.trade_name}
                                                                onChange={handleChange}
                                                                placeholder="Trade name"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                            />
                                                            {formDataError.trade_name && (<span className="text-danger">{formDataError.trade_name}</span>)}


                                                        </div>
                                                    </div>
                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0  xl:items-center">
                                                        <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">Additional Trade Name</div>
                                                                    {/* <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                    *
                                                                </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 w-full mt-3 xl:mt-0">
                                                            <input
                                                                data-tw-merge=""
                                                                type="text"
                                                                value={formData.additional_trade_name}
                                                                onChange={handleChange}
                                                                name="additional_trade_name"
                                                                placeholder="Additional Trade name"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0  xl:items-center">
                                                        <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">PAN Number & Pan Image<span className="text-danger">*</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="sm:flex xl:flex-row">
                                                            <div className="flex-1 w-full mt-3 xl:mt-0">
                                                                <input
                                                                    data-tw-merge=""
                                                                    type="text"
                                                                    name="pan_number"
                                                                    value={formData.pan_number}
                                                                    onChange={handleChange}
                                                                    placeholder="Pan Card Number"
                                                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                                />
                                                                {formDataError.pan_number && (<span className="text-danger">{formDataError.pan_number}</span>)}
                                                            </div>

                                                            <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                                                <input
                                                                    data-tw-merge=""
                                                                    type="file"
                                                                    name="pan_image"
                                                                    onChange={handleChange}
                                                                    placeholder="Pan Card Image"
                                                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                        <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-100">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">Registered Office Address <span className="text-danger">*</span></div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="flex-1 w-full mt-3 xl:mt-0">
                                                            <input
                                                                data-tw-merge=""
                                                                type="text"
                                                                onChange={handleChange}
                                                                value={formData.register_office_address}
                                                                name="register_office_address"
                                                                placeholder="Registered Office Address"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                            />
                                                            {formDataError.register_office_address && (<span className="text-danger">{formDataError.register_office_address}</span>)}

                                                        </div>
                                                    </div>

                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                        <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">GST Number</div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 w-full mt-3 xl:mt-0">

                                                            <input
                                                                data-tw-merge
                                                                name="msme_reg"
                                                                placeholder="GST Number"
                                                                value={formData.msme_reg}
                                                                onChange={handleChange}
                                                                type="text" data-single-mode="true" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&amp;[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker mx-auto block w-56 mx-auto block w-56 datepicker mx-auto block w-56 mx-auto block w-56"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                        <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                            <div className="text-left">
                                                                <div className="flex items-center">
                                                                    <div className="font-medium">Nature of Business</div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1 w-full mt-3 xl:mt-0">
                                                            <input
                                                                data-tw-merge=""
                                                                type="text"
                                                                name="nature_of_business"
                                                                value={formData.nature_of_business}
                                                                onChange={handleChange}
                                                                placeholder="Nature of Business"
                                                                className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">

                                    <button
                                        type="submit"
                                        data-tw-merge=""
                                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                                    >
                                        <i
                                            data-tw-merge=""
                                            data-lucide="pen-line"
                                            className="mr-2 h-4 w-4 stroke-[1.3]"
                                        />
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditBranch;