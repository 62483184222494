import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableHeader from "../../utils/table_headings";
import { deletePlan, fetchPlans } from "../../features/subscriptionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../utils/api_const";

const Subscription = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const heading = ['Image','Name', 'Price', 'Branches', 'Description', 'Status', 'Actions'];

    const { plans, loading, error } = useSelector((state) => state.subscriptions);

    useEffect(() => {
        dispatch(fetchPlans());
    }, [dispatch]);


    const handleDelete = async (id) => {
        dispatch(deletePlan(id));
    }

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Subscription Plans
                        </div>
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            <Link
                                to="/subscriptions/plan/add"
                                data-tw-merge=""
                                className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200"
                            >
                                <i
                                    data-tw-merge=""
                                    data-lucide="pen-line"
                                    className="mr-2 h-4 w-4 stroke-[1.3]"
                                />
                                Add New
                            </Link>
                        </div>
                    </div>
                    <div className="mt-3.5 flex flex-col gap-8">

                        <div className="box box--stacked flex flex-col">

                            <div className="overflow-auto xl:overflow-visible">
                                <table
                                    data-tw-merge=""
                                    className="w-full text-left border-b border-slate-200/60"
                                >
                                    <TableHeader headings={heading} />
                                    <tbody>
                                        {plans && plans.map((plan, index) => (
                                            <tr key={plan.id} className="border-b border-slate-200/60">
                                                <td className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">{index + 1}</td>
                                                <td  className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">
                                                        <img src={`${API_URL}/${plan.image}`} alt={plan.name} className="w-12 h-12 object-cover" />
                                                    </td>
                                                <td className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">{plan.name}</td>
                                                <td className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">{plan.price}</td>
                                                <td className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">{plan.users} Branches</td>
                                                <td className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">{plan.description}</td>
                                                <td className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">{plan.status ? 'Active' : 'Inactive'}</td>
                                                <td className="px-5 flex flex-col border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">
                                                    <button
                                                        onClick={() => navigate(`/subscriptions/plan/edit/${plan.id}`)}
                                                        className="flex-1 text-primary"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="lucide lucide-file-pen-line"
                                                        >
                                                            <path d="m18 5-2.414-2.414A2 2 0 0 0 14.172 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2" />
                                                            <path d="M21.378 12.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                                                            <path d="M8 18h1" />
                                                        </svg>

                                                    </button>
                                                    <button
                                                        onClick={() => handleDelete(plan.id)}
                                                        className="flex-1 text-danger"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="lucide lucide-delete"
                                                        >
                                                            <path d="M10 5a2 2 0 0 0-1.344.519l-6.328 5.74a1 1 0 0 0 0 1.481l6.328 5.741A2 2 0 0 0 10 19h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2z" />
                                                            <path d="m12 9 6 6" />
                                                            <path d="m18 9-6 6" />
                                                        </svg>

                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex-reverse flex flex-col-reverse flex-wrap items-center gap-y-2 p-5 sm:flex-row">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Subscription;