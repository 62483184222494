import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCompanyById, updateCompany } from "../../features/companySlice";
import { API_URL } from "../../utils/api_const";
import moment from 'moment';
import Swal from "sweetalert2";
import { fetchTaxServices } from "../../features/taxServiceSlice";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const CompanyProfile = () => {

    const company_id = useParams();
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const status = useSelector((state) => state.companies.status);
    const error = useSelector((state) => state.companies.error);
    const [passwordError, setPasswordError] = useState('');
    const { singleCompany } = useSelector((state) => state.companies);
    const taxServices = useSelector((state) => state.taxService.taxServices);


    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const openLightbox = (imageUrl) => {
        setCurrentImage(imageUrl);
        setIsOpen(true);
        setIsLoading(true);
    };

    const closeLightbox = () => {
        setIsOpen(false);
        setCurrentImage('');
        setIsLoading(true);
    };

    const handleImageLoad = () => {
        setIsLoading(false);
    };

    useEffect(() => {
        dispatch(fetchTaxServices());
    }, [dispatch]);


    useEffect(() => {
        console.log('company id ', company_id);
        if (company_id) {
            dispatch(getCompanyById(company_id.company_id));
        }
    }, [dispatch, company_id]);

    const [formData, setFormData] = useState({
        profile_image: '',
        username: '',
        email: '',
        mobile_no: '',
        company_name: '',
        trade_name: '',
        additional_trade_name: '',
        company_type: '',
        pan_number: '',
        pan_image: '',
        tan_number: '',
        tan_image: '',
        doi: '',
        doi_image: '',
        register_office_address: '',
        cin_number: '',
        msme_reg: '',
        nature_of_business: ''
    });

    useEffect(() => {
        if (singleCompany) {
            const formattedDoi = singleCompany.doi
                ? new Date(singleCompany.doi).toISOString().split('T')[0]
                : '';  // Format the DOI only if it exists

            setFormData({
                profile_image: singleCompany.profile_image || '',
                username: singleCompany.username || '',
                email: singleCompany.email || '',
                mobile_no: singleCompany.mobile_no || '',

                company_name: singleCompany.company_name || '',
                trade_name: singleCompany.trade_name || '',
                additional_trade_name: singleCompany.additional_trade_name || '',
                company_type: singleCompany.company_type || '',
                pan_number: singleCompany.pan_number || '',
                pan_image: singleCompany.pan_image || '',
                tan_number: singleCompany.tan_number || '',
                tan_image: singleCompany.tan_image || '',
                doi: formattedDoi, // Correctly formatted DOI
                doi_image: singleCompany.doi_image || '', // Keep the image URL or value as it is
                register_office_address: singleCompany.register_office_address || '',
                cin_number: singleCompany.cin_number || '',
                msme_reg: singleCompany.msme_reg || '',
                nature_of_business: singleCompany.nature_of_business || ''
            });
        }
    }, [singleCompany]);


    const handleChange = (e) => {
        const { name, type, value, files } = e.target;
    
        if (type === 'file') {
            const file = files[0];
            
            // Check if file size is greater than 1 MB (1 MB = 1,048,576 bytes)
            if (file && file.size > 1048576) {
                alert("File size should not exceed 1 MB");
                return;
            }
    
            setFormData((prevData) => ({
                ...prevData,
                [name]: file
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };
    



    const handleSubmit = async (e) => {
        e.preventDefault();


        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }

        try {
            // Dispatch the action and wait for it to complete
            const resultAction = await dispatch(updateCompany({ id: company_id.company_id, companyData: formDataObj }));

            // Check if the action was successful and get the company ID
            if (updateCompany.fulfilled.match(resultAction)) {
                Swal.fire({
                    icon: 'success',
                    title: 'Updated!',
                    text: 'Profile Successfully Updated',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });
                await dispatch(getCompanyById(company_id.company_id));
                const companyProfile = JSON.parse(localStorage.getItem('company')) || {};
                companyProfile.profile_image = singleCompany?.profile_image; // Ensure profile_image is part of the formData
                localStorage.setItem('company', JSON.stringify(companyProfile));
                navigator(`/company/dashboard`);
            } else {
                // Handle the error case
                console.error('Failed to create company:', resultAction.payload);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    console.log('singggg ', singleCompany);


    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col p-5 box box--stacked">
                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                        <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                            <i
                                data-tw-merge=""
                                data-lucide="chevron-down"
                                className="w-5 h-5 mr-2 stroke-[1.3]"
                            />
                            Company Profile
                        </div>
                        <div className="mt-5">
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Profile Image</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            <span className="text-danger">(File Size Max: 1 MB)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0 mr-3">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                        name="profile_image"
                                        accept="image/*"
                                        onChange={handleChange}
                                        placeholder="Profile Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                                <div className="flex-1 w-50 mt-3 xl:mt-0">
                                    <img src={`${API_URL}/${formData.profile_image}`} style={{ width: "50px", height: "50px", borderRadius: "50%" }} onClick={() => openLightbox(`${API_URL}/${formData.profile_image}`)} />

                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">User Name</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the User Name.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        placeholder="User name"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Email</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Unique Email Address.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="email"
                                        value={formData.email}
                                        name="email"
                                        onChange={handleChange}
                                        placeholder="Email Address"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>

                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Mobile Number</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the unique Mobile Number.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        required={true}
                                        accept="[0-9]+"
                                        value={formData.mobile_no}
                                        name="mobile_no"
                                        onChange={handleChange}
                                        placeholder="1234567890"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                        maxLength={10}
                                        minLength={10}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Name of Assessee</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the unique name of your Assessee.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="company_name"
                                        value={formData.company_name}
                                        onChange={handleChange}
                                        placeholder="Company name"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Trade Name</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Trade Name
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="trade_name"
                                        value={formData.trade_name}
                                        onChange={handleChange}
                                        placeholder="Trade name"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />

                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Additional Trade Name</div>
                                            {/* <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                    Required
                                                                </div> */}
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Additional Trade Name
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        value={formData.additional_trade_name}
                                        onChange={handleChange}
                                        name="additional_trade_name"
                                        placeholder="Additional Trade name"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Company Type</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Select Company Type
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <select
                                        data-tw-merge=""
                                        id="category"
                                        value={formData.company_type}
                                        onChange={handleChange}
                                        name="company_type"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                    >
                                        <option value=""> -- Select Status --</option>
                                        <option value="Individual">Individual</option>
                                        <option value="Hindu Undivided Family">Hindu Undivided Family</option>
                                        <option value="Partnership Firm">Partnership Firm</option>
                                        <option value="Company">Company</option>
                                        <option value="Association of Persons (AOP) or Body of Individuals (BOI)">Association of Persons (AOP) or Body of Individuals (BOI)</option>
                                        <option value="Local Authority">Local Authority</option>
                                        <option value="Artificial Judicial Body (not covered under any of the above-mentioned categories)">Artificial Judicial Body (not covered under any of the above-mentioned categories)</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">PAN Card</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter Pan Card Number & Upload Pan Card Image <span className="text-danger">(File Size Max: 1 MB)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        value={formData.pan_number}
                                        name="pan_number"
                                       
                                        onChange={handleChange}
                                        placeholder="Pan Card Number"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                        name="pan_image"
                                         accept="image/*"
                                        onChange={handleChange}
                                        placeholder="Pan Card Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                                <div className="flex-1 w-50 ml-4 mt-3 xl:mt-0">
                                    {formData.pan_image && (<img src={`${API_URL}/${formData.pan_image}`} style={{ width: "70px", height: "50px", borderRadius: "10px" }} onClick={() => openLightbox(`${API_URL}/${formData.pan_image}`)}/>)}

                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">TAN Card</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter Tan Card Number & Upload Tan Card Image <span className="text-danger">(File Size Max: 1 MB)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="tan_number"
                                        value={formData.tan_number}
                                        onChange={handleChange}
                                        placeholder="Tan Card Number"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                        name="tan_image"
                                        accept="image/*"
                                        
                                        onChange={handleChange}
                                        placeholder="Tan Card Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                                <div className="flex-1 w-50 ml-4 mt-3 xl:mt-0">
                                    {formData.tan_image && (<img src={`${API_URL}/${formData.tan_image}`} style={{ width: "70px", height: "50px", borderRadius: "10px" }} onClick={() => openLightbox(`${API_URL}/${formData.tan_image}`)} />)}

                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">DOB / DOI</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Select Date of Birth & Upload Image <span className="text-danger">(File Size Max: 1 MB)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">

                                    <input
                                        data-tw-merge
                                        name="doi"
                                        value={formData.doi}
                                        onChange={handleChange}
                                        type="date" data-single-mode="true" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&amp;[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker mx-auto block w-56 mx-auto block w-56 datepicker mx-auto block w-56 mx-auto block w-56"
                                    />

                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                         accept="image/*"
                                        onChange={handleChange}
                                        name="doi_image"
                                        placeholder="Tan Card Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                                <div className="flex-1 w-50 ml-4 mt-3 xl:mt-0">
                                    {formData.doi_image && (<img src={`${API_URL}/${formData.doi_image}`} style={{ width: "70px", height: "50px", borderRadius: "10px" }} onClick={() => openLightbox(`${API_URL}/${formData.doi_image}`)}/>)}

                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Registered Office Address</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Registered office Address
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        value={formData.register_office_address}
                                        onChange={handleChange}
                                        name="register_office_address"
                                        placeholder="Registered Office Address"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">CIN</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the CIN Number
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="cin_number"
                                        value={formData.cin_number}
                                        onChange={handleChange}
                                        placeholder="CIN Number"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">GST Number</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter GST Number & Upload Image
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">

                                    <input
                                        data-tw-merge
                                        name="msme_reg"
                                        value={formData.msme_reg}
                                        onChange={handleChange}
                                        type="text" data-single-mode="true" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&amp;[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker mx-auto block w-56 mx-auto block w-56 datepicker mx-auto block w-56 mx-auto block w-56"
                                    />

                                </div>
                                {/* <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                        name="msme_image"
                                        placeholder="MSME Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div> */}
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Nature of Business</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Nature of Business
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        value={formData.nature_of_business}
                                        name="nature_of_business"
                                        onChange={handleChange}
                                        placeholder="Nature of Business"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">
                    <button
                        type="submit"
                        data-tw-merge=""
                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                    >
                        <i
                            data-tw-merge=""
                            data-lucide="pen-line"
                            className="mr-2 h-4 w-4 stroke-[1.3]"
                        />
                        Update
                    </button>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={currentImage}
                        onCloseRequest={closeLightbox}
                        imageCaption={isLoading ? 'Loading...' : ''} // Display a loading message
                        onImageLoad={handleImageLoad} // Handle image load event
                        reactModalProps={{
                            // You can add additional react-modal props here if needed
                        }}
                    />
                )}
            </form>
            {status === 'loading' && <div className="[&amp;.loading-page--before-hide]:h-screen [&amp;.loading-page--before-hide]:relative loading-page loading-page--before-hide [&amp;.loading-page--before-hide]:before:block [&amp;.loading-page--hide]:before:opacity-0 before:content-[''] before:transition-opacity before:duration-300 before:hidden before:inset-0 before:h-screen before:w-screen before:fixed before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:z-[60] [&amp;.loading-page--before-hide]:after:block [&amp;.loading-page--hide]:after:opacity-0 after:content-[''] after:transition-opacity after:duration-300 after:hidden after:h-16 after:w-16 after:animate-pulse after:fixed after:opacity-50 after:inset-0 after:m-auto after:bg-loading-puff after:bg-cover after:z-[61]"></div>}
        </>

    );
}
export default CompanyProfile;