import React, { useState } from "react";
import CompanyAddStep1 from "./add_form/company_step_1";
import CompanyAddStep2 from "./add_form/company_step_2";
import { Navigate, Route, Routes } from "react-router-dom";
import CompanyAddStep3 from "./add_form/company_step_3";

const AddCompanies = () => {


    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Add Company
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <Routes>
                                <Route path="/" element={<Navigate to="/companies/add/step1" />}/>
                                <Route path="/step1" element={<CompanyAddStep1/>}/>
                                <Route path="/step2/:id" element={<CompanyAddStep2/>}/>
                                <Route path="/step3/:id" element={<CompanyAddStep3/>}/>
                            </Routes>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}

export default AddCompanies;