import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';

const initialState = {
  taxServices: [],
  status: 'idle',
  error: null,
};

export const fetchTaxServices = createAsyncThunk('taxService/fetchTaxServices', async (params) => {
    const { service_type } = params || {};
    const response = await axios.get(`${API_URL}/taxservices`,  {
        params: { service_type }
    });
    return response.data;
});


export const fetchTaxServicesCompany = createAsyncThunk('taxService/fetchTaxServicesCompany', async (params) => {
  const { id, service_type } = params || {};
  const response = await axios.get(`${API_URL}/taxservices/company/service`,  {
      params: { id, service_type }
  });
  return response.data;
});

export const addTaxService = createAsyncThunk('taxService/addTaxService', async (newService) => {
  const response = await axios.post(`${API_URL}/taxservices/add`, newService);
  return response.data;
});

export const updateTaxService = createAsyncThunk('taxService/updateTaxService', async (updatedService) => {
  const response = await axios.put(`${API_URL}/taxservices/${updatedService.id}`, updatedService);
  return response.data;
});

export const deleteTaxService = createAsyncThunk('taxService/deleteTaxService', async (id) => {
  await axios.delete(`${API_URL}/taxservices/${id}`);
  return id;
});

const taxServiceSlice = createSlice({
  name: 'taxService',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxServices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTaxServices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.taxServices = action.payload;
      })
      .addCase(fetchTaxServices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchTaxServicesCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTaxServicesCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.taxServices = action.payload;
      })
      .addCase(fetchTaxServicesCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addTaxService.fulfilled, (state, action) => {
        state.taxServices.push(action.payload);
      })
      .addCase(updateTaxService.fulfilled, (state, action) => {
        const index = state.taxServices.findIndex(service => service.id === action.payload.id);
        state.taxServices[index] = action.payload;
      })
      .addCase(deleteTaxService.fulfilled, (state, action) => {
        state.taxServices = state.taxServices.filter(service => service.id !== action.payload);
      });
  },
});

export default taxServiceSlice.reducer;
