import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import generateFiscalYears from "../dashboard/partials/generate_financial_year";
import { fetchTaxReports } from "../../features/submitTaxReportSlice";
import AdminReportTable from "./partials/admin_report_table";
import { getAllCompanyData } from "../../features/companySlice";

const AdminReportPage = () => {
    const { filling } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    const isActive = (path) => location.pathname === path;
    const [loader, setLoader] = useState(true);
    const fiscalYears = generateFiscalYears();
    const [searchText, setSearchText] = useState('');
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(
        localStorage.getItem('selectedFiscalYear') || fiscalYears[fiscalYears.length - 1]
    );
    const [selectedCompany, setSelectedCompany] = useState("");
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    // Select the necessary data from the Redux store
    const { companies, status, error } = useSelector((state) => state.companies);
    const { fetchedTaxReports, fetchStatus, fetchError } = useSelector((state) => state.submitTaxReport);

    useEffect(() => {
        dispatch(getAllCompanyData());
    }, [dispatch]);

    useEffect(() => {
        localStorage.setItem('selectedFiscalYear', selectedFiscalYear);
        const payload = {
            session: selectedFiscalYear
        };
        dispatch(fetchTaxReports(payload)).finally(() => {
            setLoader(false);
        });
    }, [selectedFiscalYear, filling, dispatch]);

    const handleBranchChange = async (e) => {
        const branchId = e.target.value;
        setSelectedBranch(branchId);
        const payload = {
            session: selectedFiscalYear,
            company_id: selectedCompany,
            branch_id: branchId
        };
        dispatch(fetchTaxReports(payload)).finally(() => {
            setLoader(false);
        });
    }

    const handleCompanyChange = async (e) => {
        const companyId = e.target.value;
        setSelectedCompany(companyId);
        setSelectedBranch('');
        const selectedCompanyData = companies.find(company => company.id === Number(companyId));
        setBranches(selectedCompanyData?.Branches || []);

        const payload = {
            session: selectedFiscalYear,
            company_id: companyId
        };
        dispatch(fetchTaxReports(payload)).finally(() => {
            setLoader(false);
        });
    }

    const handleFiscalYearChange = (e) => {
        setSelectedFiscalYear(e.target.value);
    };

    useEffect(() => {
        console.log(fetchedTaxReports);
    });


    const filteredData = fetchedTaxReports.filter((row) => {
        return (
            row.taxService?.service_name.toLowerCase().includes(searchText.toLowerCase()) ||
            row.branch?.branch_name.toLowerCase().includes(searchText.toLowerCase()) ||
            row.company?.company_name.toLowerCase().includes(searchText.toLowerCase())
            
        );
    });


    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            {filling && filling === 'return-filling' ? "Periodic Fillings Reports" : "Annual Fillings Reports"}
                        </div>
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            <div className="relative">

                                <input type="text" value={searchText}
                                    onChange={handleSearch} placeholder="Search by Filling Name" class="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-100 mr-2 text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&amp;[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10" />
                                <span className="text-white font-bold mr-2">Company </span>
                                {/* <i data-tw-merge data-lucide="calendar-check2" className="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4 stroke-[1.3] group-[.mode--light]:!text-slate-200" /> */}
                                <select data-tw-merge
                                    onChange={handleCompanyChange}
                                    value={selectedCompany}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:bg-chevron-white group-[.mode--light]:!text-slate-200 sm:w-44">
                                    <option value="">All</option>
                                    {companies.map((com, index) => (
                                        <option key={index} value={com.id}>
                                            {com.company_name}
                                        </option>
                                    ))}


                                </select>
                            </div>
                            {branches.length > 0 && (<div className="relative">
                                <span className="text-white font-bold mr-2">Branch </span>
                                {/* <i data-tw-merge data-lucide="calendar-check2" className="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4 stroke-[1.3] group-[.mode--light]:!text-slate-200" /> */}
                                <select data-tw-merge
                                    onChange={handleBranchChange}
                                    value={selectedBranch}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:bg-chevron-white group-[.mode--light]:!text-slate-200 sm:w-44">
                                    <option value="">All</option>
                                    {branches.map((branch, index) => (
                                        <option key={index} value={branch.id}>
                                            {branch.branch_name}
                                        </option>
                                    ))}


                                </select>
                            </div>)}
                            <div className="relative">
                                <span className="text-white font-bold mr-2">Financial Year </span>
                                {/* <i data-tw-merge data-lucide="calendar-check2" className="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4 stroke-[1.3] group-[.mode--light]:!text-slate-200" /> */}
                                <select data-tw-merge
                                    onChange={handleFiscalYearChange}
                                    value={selectedFiscalYear}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:bg-chevron-white group-[.mode--light]:!text-slate-200 sm:w-44">
                                    <option value="custom-date">Custom Date</option>
                                    {fiscalYears.map((year, index) => (
                                        <option key={index} value={year}>
                                            {year.replace('-', ' - ')}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            {/* <div className="relative">
                                <i data-tw-merge data-lucide="calendar" className="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4 stroke-[1.3] group-[.mode--light]:!text-slate-200" />
                                <input data-tw-merge type="text" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 sm:w-64" />
                            </div> */}
                        </div>
                    </div>
                    <div className="mt-3.5 flex flex-col gap-8">
                        <div className="box box--stacked flex flex-col">
                            <div className="overflow-auto xl:overflow-visible">
                                {loader ? (
                                    <div>Loading...</div>
                                ) : (
                                    <AdminReportTable taxReports={filteredData} check_type={filling} />
                                )}
                                {fetchError && <div>Error: {fetchError.message}</div>}
                            </div>
                            {/* <div className="flex-reverse flex flex-col-reverse flex-wrap items-center gap-y-2 p-5 sm:flex-row">
                                
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminReportPage;
