import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableHeader from "../../../utils/table_headings";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { getScheduleReportsByCompanyId } from "../../../features/scheduleReportSlice";
const monthMapping = {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
};

const formatPeriod = (period) => {
    const month = monthMapping[period.toLowerCase()];
    return month ? moment(month, 'MMMM').format('MMMM') : period;
};


const CompanyReportTable = ({ taxReports = [], check_type }) => {
    
    const companyHeadings = ['Filling Name', 'Type', 'Session', 'Due Date', 'Submitted By','Submitted On', 'Actions'];
    const location = useLocation();
    const navigate = useNavigate();
    const [usertype, setUserType] = useState('');
    const [monthFilter, setMonthFilter] = useState("");

    useEffect(() => {
        setUserType(localStorage.getItem('login_type'));
        console.log('user type', usertype);
    });
    // Function to check if the link is active
    const isActive = (path) => location.pathname === path;

    const handleNavigate = (rowData) => () => {
        navigate(`/${usertype}/report-detail`, { state: { rowData } });
    };

      
    // Check if scheduleReports is an array
    if (!Array.isArray(taxReports)) {
        console.error('scheduleReports is not an array:', taxReports);
        return <div>Error: Invalid data format</div>;
    }
    const filteredReports = taxReports.filter((report) => {
        const isCheckTypeMatch = report.taxService?.service_type === check_type.replace(/-/g, '_');
        const isMonthMatch = !monthFilter || moment(report.due_date).format("MMMM") === monthFilter;
        return isCheckTypeMatch && isMonthMatch;
    });

    const columns = [
        { name: "No.", selector: (row, index) => index + 1, sortable: true, width: "5%" },
        { 
            name: "Filling Name", 
            cell: (row) => (
                <div>
                    <a href="#0">{row.taxService?.service_name || 'Unknown Service'}</a>
                    <div className="text-xs text-slate-500">{row.branch?.branch_name}</div>
                </div>
            ),
            sortable: true,
            width: "20%"
        },
        { 
            name: "Type", 
            selector: (row) => row.taxService?.service_type === 'return_filling' ? "Periodic Fillings" : "Annual Fillings", 
            sortable: true 
        },
        { 
            name: "Session", 
            selector: (row) => row.scheduleReport?.session ?? row.branchSchedule?.session, 
            sortable: true 
        },
        { 
            name: "Due Date", 
            selector: (row) => moment(row.due_date).format("DD MMMM, YYYY"), 
            sortable: true 
        },
        { 
            name: "Submitted By", 
            cell: (row) => (
                <div>
                    {row.submitted_by === 'company' && row.user_id === row.company.id
                        ? 'Self Submitted'
                        : row.submitted_by == 'undefined'?"Admin":row.branch?.branch_name}
                    <br />
                    <b>{row.submitted_by === 'company' && row.user_id === row.company.id ? '' : row.branchUser?.username}</b>
                </div>
            ), 
            sortable: true 
        },
        { 
            name: "Submitted On", 
            selector: (row) => moment(row.createdAt).local().format('DD MMMM, YYYY H:mm:ss'), 
            sortable: true 
        },
        { 
            name: "Actions", 
            cell: (row) => (
                <button onClick={handleNavigate(row)} className="btn btn-success h-5 w-5 text-slate-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-book-text">
                        <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" />
                        <path d="M8 11h8" />
                        <path d="M8 7h6" />
                    </svg>
                </button>
            )
        }
    ];

    let loop = 1;
    return (
        <div>
            <div className="flex flex-col gap-4 p-5 sm:flex-row sm:items-center sm:gap-x-4">
                <div className="flex flex-col">
                    <label htmlFor="monthFilter" className="mb-2">Filter by Month:</label>
                    <select
                        id="monthFilter"
                        value={monthFilter}
                        onChange={(e) => setMonthFilter(e.target.value)}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value="">All Months</option>
                        {Object.values(monthMapping).map((month) => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredReports}
                pagination
                highlightOnHover
                pointerOnHover
                striped
                responsive
                defaultSortFieldId={1}
                theme="default"
            />
        </div>
    );
}

export default CompanyReportTable;