import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteScheduleReport, getAllScheduleReports } from "../../../features/scheduleReportSlice";
import TableHeader from "../../../utils/table_headings";
import moment from "moment";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { Delete } from 'lucide-react';

const monthMapping = {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
};

const formatPeriod = (period) => {
    const month = monthMapping[period.toLowerCase()];
    return month ? moment(month, 'MMMM').format('MMMM') : period;
};

const ScheduleReportTable = () => {


    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const companyHeadings = ['Reports', 'Frequency', 'Type', 'Company Name', 'Actions'];
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRefs = useRef({});
    const { scheduleReports = [], status, error } = useSelector((state) => state.scheduleReports);
    const [service_name, setServiceName] = useState('');
    const [company, setCompany] = useState('');
    const [frequency, setFrequency] = useState('');
    const [type, setType] = useState('');


    useEffect(() => {
        dispatch(getAllScheduleReports());
    }, [dispatch]);

    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        let clickedOutside = true;

        Object.values(dropdownRefs.current).forEach(ref => {
            if (ref && ref.contains(event.target)) {
                clickedOutside = false;
            }
        });

        if (clickedOutside) {
            setOpenDropdownId(null);

            if (event.target.tagName === 'A' && event.target.href) {
                window.location.href = event.target.href;
            }
        }
    };

    const handleDelete = async (id, company_name) => {
        const ok = confirm(`Are you sure you want to delete this Filling Schedule of ${company_name}?`);
        if (ok) {
            try {
                // Dispatch the delete action
                await dispatch(deleteScheduleReport({ id, type: 'company' })).unwrap();

                // Optionally reset the search text to empty
                setSearchText('');

                // Fetch all schedule reports again
                await dispatch(getAllScheduleReports()).unwrap();
            } catch (error) {
                console.error('Failed to delete schedule report:', error);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if (scheduleReports) {
            console.log(scheduleReports);
        }
    }, [scheduleReports]);

    if (status === 'loading') return <div>Loading...</div>;
    if (status === 'failed') return <div>Error: {error}</div>;

    function getFrequencyText(row) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (row.frequency === 'monthly') {
            return `${row.day}th of Every Month`;
        } else if (row.frequency === 'quarterly') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const quarterMonths = [startMonthIndex, (startMonthIndex + 3) % 12, (startMonthIndex + 6) % 12, (startMonthIndex + 9) % 12];
                const quarterMonthNames = quarterMonths.map(index => months[index]);
                return `${row.day} of ${quarterMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency === 'semi-annually') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const halfYearMonths = [startMonthIndex, (startMonthIndex + 6) % 12];
                const halfYearMonthNames = halfYearMonths.map(index => months[index]);
                return `${row.day} of ${halfYearMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency == 'annually') {
            const date = moment(row.date);
            const day = date.format('D'); // Day of the month
            const month = date.format('MMMM'); // Full month name
            return `${day} of Every ${month}`;
        } else {
            return '';
        }
    }


    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    // Filtered data based on the search text
    // const filteredData = scheduleReports.filter((row) => {
    //     return (
    //         row.TaxService?.service_name.toLowerCase().includes(searchText.toLowerCase()) ||
    //         row.Company?.company_name.toLowerCase().includes(searchText.toLowerCase())
    //     );
    // });

    const filteredData = scheduleReports.filter((row) => {
        const matchServiceName = service_name ? row.TaxService?.service_name.toLowerCase().includes(service_name.toLowerCase()) : true;
        const matchesCompany = company ? row.Company?.company_name.toLowerCase().includes(company.toLowerCase()) : true;
        const matchesFrequency = frequency ? row.TaxService?.frequency === frequency : true;
        const matchesType = type ? row.type === type : true;

        return matchServiceName && matchesCompany && matchesFrequency && matchesType;
    });

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: false,
            width: '50px',
        },
        {
            name: 'Service Name',
            selector: (row) => row.TaxService?.service_name,
            cell: (row) => (
                <div>
                    <a className="whitespace-wrap font-medium w-60" href="#0">
                        {row.TaxService?.service_name}
                    </a>
                    <div className="mt-0.5 whitespace-wrap text-xs text-slate-500">
                        {row.session}
                    </div>
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Frequency',
            selector: (row) => row.TaxService?.frequency,
            cell: (row) => (
                <div>
                    <a className="whitespace-nowrap font-medium" href="#0">
                        {row.TaxService?.frequency}
                    </a>
                    <div className="mt-0.5 whitespace-wrap text-xs text-slate-500">
                        {row.TaxService && getFrequencyText(row.TaxService)}
                    </div>
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Type',
            selector: (row) => row.type,
            cell: (row) => (
                <a className="whitespace-nowrap font-medium" href="#0">
                    {row.type === 'return_filling' ? 'Periodic Fillings' : 'Annual Fillings'}
                </a>
            ),
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: (row) => row.Company?.company_name,
            cell: (row) => (
                <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                    {row.Company?.company_name || ''}
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="flex">
                    <Delete className="cursor-pointer" onClick={() => handleDelete(row.id, row.Company?.company_name)} />
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];


    return (
        <div>

            <div className="flex flex-col gap-4 p-5 sm:flex-row sm:items-center sm:gap-x-4">

                {/* Company Filter */}
                <div className="flex flex-col">
                    <label className="mb-1">Service Name:</label>
                    <input
                        type="text"
                        value={service_name}
                        onChange={(e) => setServiceName(e.target.value)}
                        placeholder="Enter Service name"
                        className="border rounded px-2 py-1 border-slate-200"
                    />
                </div>
                <div className="flex flex-col">
                    <label className="mb-1">Company:</label>
                    <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Enter company name"
                        className="border rounded px-2 py-1 border-slate-200"
                    />
                </div>

                {/* Frequency Filter */}
                <div className="flex flex-col">
                    <label className="mb-1">Frequency:</label>

                    <select
                        data-tw-merge=""
                        name="frequency"
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value=""> -- Select --</option>
                        <option value="weekly">Weekly (Occurring once a week)</option>
                        <option value="monthly">Monthly (Occurring once a month)</option>
                        <option value="quarterly">Quarterly (Occurring once every three months)</option>
                        <option value="semi-annually">Semi-Annually (Occurring once every six months)</option>
                        <option value="annually">Annually (Occurring once a year)</option>
                        <option value="triennially">Triennially (Occurring once every three years)</option>
                    </select>

                </div>

                {/* Type Filter */}
                <div className="flex flex-col">
                    <label className="mb-1">Type:</label>
                    <select
                        data-tw-merge=""
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value=""> -- Select Status --</option>
                        <option value="return_filling">Periodic Fillings</option>
                        <option value="annual_filling">Annual Fillings</option>

                    </select>
                    
                </div>

                {/* Reset Button */}
                <div className="flex flex-col mt-2">
                    <button
                        onClick={() => {
                            setServiceName('');
                            setCompany('');
                            setFrequency('');
                            setType('');
                        }}
                        type="button"
                        className="bg-success mt-4 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-200 ease-in-out"
                    >
                        Reset
                    </button>
                </div>
            </div>

            {/* <input
                    type="text"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearch}
                    className="border rounded mb-4 px-2 py-1 form-control"
                /> */}
            <DataTable
                columns={columns}
                data={filteredData}
                noHeader
                pagination
                highlightOnHover
                responsive
                pointerOnHover
                noDataComponent={<div className="text-center">No reports available</div>}
            />
        </div>
    );
}

export default ScheduleReportTable;
