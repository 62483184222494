// src/features/scheduleReportSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';

const initialState = {
  scheduleReports: [],
  branchScheduleReport: [],
  pendingScheduleReports: [],
  upcomingScheduleReports: [],
  status: 'idle',
  error: null,
  currentScheduleReport: null,
};

// Thunks for async actions

// Add a new Schedule Report
export const addScheduleReport = createAsyncThunk(
  'scheduleReports/addScheduleReport',
  async (scheduleReportData) => {
    const response = await axios.post(`${API_URL}/schedules/add`, scheduleReportData);
    return response.data;
  }
);


// Add a new Schedule Report
export const addBranchScheduleReport = createAsyncThunk(
  'scheduleReports/addBranchScheduleReport',
  async (scheduleReportData) => {
    const response = await axios.post(`${API_URL}/branch/schedule/add`, scheduleReportData);
    return response.data;
  }
);
// Define the thunk with destructured parameters
export const getScheduleReportsByCompanyId = createAsyncThunk(
  'scheduleReports/getScheduleReportsByCompanyId',
  async ({ id, type, year }) => {
    console.log('Fetching data for ID:', id, 'and type:', type);
    const response = await axios.get(`${API_URL}/schedules/company/${id}/${type}/${year}`);
    return response.data;
  }
);


export const getScheduleReportsByBranch = createAsyncThunk(
  'scheduleReports/getScheduleReportsByBranch',
  async ({ branch_id, year }) => {
    const response = await axios.get(`${API_URL}/schedules/branch/${branch_id}/${year}`);
    return response.data;
  }
);


// Get Pending Schedule Reports
export const getPendingScheduleReports = createAsyncThunk(
  'scheduleReports/getPendingScheduleReports',
  async ({id, year}) => {
    const response = await axios.get(`${API_URL}/schedules/pending/${id}/${year}`);
    return response.data;
  }
);




// Get Pending Schedule Reports
export const getPendingBranchScheduleReports = createAsyncThunk(
  'scheduleReports/getPendingBranchScheduleReports',
  async ({id, year}) => {
    const response = await axios.get(`${API_URL}/schedules/branch/pending/${id}/${year}`);
    return response.data;
  }
);

// Get Upcoming Schedule Reports
export const getUpcomingScheduleReports = createAsyncThunk(
  'scheduleReports/getUpcomingScheduleReports',
  async ({id}) => {
    const response = await axios.get(`${API_URL}/schedules/upcomming/${id}`);
    return response.data;
  }
);


// Get Upcoming Schedule Reports
export const getUpcomingBranchScheduleReports = createAsyncThunk(
  'scheduleReports/getUpcomingBranchScheduleReports',
  async ({id}) => {
    const response = await axios.get(`${API_URL}/schedules/branch/upcomming/${id}`);
    return response.data;
  }
);


export const clearScheduleReports = () => ({
  type: 'scheduleReports/clear'
});

// Update an existing Schedule Report
export const updateScheduleReport = createAsyncThunk(
  'scheduleReports/updateScheduleReport',
  async ({ id, scheduleReportData }) => {
    const response = await axios.put(`${API_URL}/schedules/update/${id}`, scheduleReportData);
    return response.data;
  }
);


// Update an existing Schedule Report
export const updateBranchScheduleReport = createAsyncThunk(
  'scheduleReports/updateBranchScheduleReport',
  async ({ id, scheduleReportData }) => {
    const response = await axios.put(`${API_URL}/branch/schedule/update/${id}`, scheduleReportData);
    return response.data;
  }
);

// Delete a Schedule Report
export const deleteScheduleReport = createAsyncThunk(
  'scheduleReports/deleteScheduleReport',
  async ({ id, type }, { rejectWithValue }) => {
    try {
      // Make the API request to delete the schedule report
      const response = await axios.delete(`${API_URL}/schedules/delete/${id}/${type}`);
      
      // If the response is successful, return the id to update the state
      return id;
    } catch (error) {
      // Return a custom error message if the request fails
      return rejectWithValue(error.response?.data || 'Failed to delete the schedule report');
    }
  }
);

// Get all Schedule Reports
export const getAllScheduleReports = createAsyncThunk(
  'scheduleReports/getAllScheduleReports',
  async () => {
    const response = await axios.get(`${API_URL}/schedules`);
    return response.data;
  }
);

// Get a specific Schedule Report by ID
export const getScheduleReportById = createAsyncThunk(
  'scheduleReports/getScheduleReportById',
  async ({id, type}) => {
    const response = await axios.get(`${API_URL}/schedules/view/${id}/${type}`);
    return response.data;
  }
);

const scheduleReportSlice = createSlice({
  name: 'scheduleReports',
  initialState,
  reducers: {
    clearScheduleReports: (state) => {
      state.scheduleReports = [];
      state.pendingScheduleReports = [];
      state.upcomingScheduleReports = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Add Schedule Report
      .addCase(addScheduleReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addScheduleReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.scheduleReports.push(action.payload);
      })
      .addCase(addScheduleReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(addBranchScheduleReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addBranchScheduleReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.branchScheduleReport.push(action.payload);
      })
      .addCase(addBranchScheduleReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Update Schedule Report
      .addCase(updateScheduleReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateScheduleReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.branchScheduleReport.findIndex((report) => report.id === action.payload.id);
        if (index !== -1) {
          state.branchScheduleReport[index] = action.payload;
        }
      })
      .addCase(updateScheduleReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateBranchScheduleReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBranchScheduleReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.branchScheduleReport.findIndex((report) => report.id === action.payload.id);
        if (index !== -1) {
          state.branchScheduleReport[index] = action.payload;
        }
      })
      .addCase(updateBranchScheduleReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Delete Schedule Report
      .addCase(deleteScheduleReport.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteScheduleReport.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // state.scheduleReports = state.scheduleReports.filter((report) => report.id !== action.payload);s
      })
      .addCase(deleteScheduleReport.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Get All Schedule Reports
      .addCase(getAllScheduleReports.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllScheduleReports.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.scheduleReports = action.payload;
      })
      .addCase(getAllScheduleReports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Get Schedule Report By ID
      .addCase(getScheduleReportById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getScheduleReportById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.currentScheduleReport = action.payload;
      })
      .addCase(getScheduleReportById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getScheduleReportsByCompanyId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getScheduleReportsByCompanyId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.scheduleReports = action.payload;
      })
      .addCase(getScheduleReportsByCompanyId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(getScheduleReportsByBranch.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getScheduleReportsByBranch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.branchScheduleReport = action.payload;
      })
      .addCase(getScheduleReportsByBranch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(getPendingScheduleReports.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPendingScheduleReports.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pendingScheduleReports = action.payload;
      })
      .addCase(getPendingScheduleReports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Get Upcoming Schedule Reports
      .addCase(getUpcomingScheduleReports.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUpcomingScheduleReports.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.upcomingScheduleReports = action.payload;
      })
      .addCase(getUpcomingScheduleReports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })


      .addCase(getPendingBranchScheduleReports.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPendingBranchScheduleReports.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pendingScheduleReports = action.payload;
      })
      .addCase(getPendingBranchScheduleReports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Get Upcoming Schedule Reports
      .addCase(getUpcomingBranchScheduleReports.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUpcomingBranchScheduleReports.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.upcomingScheduleReports = action.payload;
      })
      .addCase(getUpcomingBranchScheduleReports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default scheduleReportSlice.reducer;
