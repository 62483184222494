import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';

// Initial state
const initialState = {
  companyId: '',
  bankDetailsData: [],
  directorDetailsdata: [],
  status: 'idle',
  error: null
};

// Thunk to handle form submission
export const saveDetails = createAsyncThunk(
  'details/saveDetails',
  async (details, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/bank-n-directors-details/save`, details, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



const bankNDirectorDetailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
    addBankDetail: (state) => {
      state.bankDetails.push({
        name: '',
        branch: '',
        account_no: '',
        ifsc: '',
        micr: ''
      });
    },
    removeBankDetail: (state, action) => {
      state.bankDetails.splice(action.payload, 1);
    },
    setBankDetail: (state, action) => {
      const { index, detail } = action.payload;
      state.bankDetails[index] = detail;
    },
    addDirectorDetail: (state) => {
      state.directorDetails.push({
        name: '',
        holding: '',
        address: '',
        city: '',
        pincode: '',
        ps: '',
        po: ''
      });
    },
    removeDirectorDetail: (state, action) => {
      state.directorDetails.splice(action.payload, 1);
    },
    setDirectorDetail: (state, action) => {
      const { index, detail } = action.payload;
      state.directorDetails[index] = detail;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveDetails.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(saveDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export const {
  setCompanyId,
  addBankDetail,
  removeBankDetail,
  setBankDetail,
  addDirectorDetail,
  removeDirectorDetail,
  setDirectorDetail
} = bankNDirectorDetailsSlice.actions;

export default bankNDirectorDetailsSlice.reducer;
