import React, { useMemo, useState } from "react";
import TableHeader from "../../../utils/table_headings";
import moment from "moment";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { checkAuthType } from "../../../actions/authAction";
import DataTable from "react-data-table-component";


const OtherFillingTable = ({taxReports = []}) => {
    const navigate = useNavigate();
    const headings = ['Filling Name', 'Type', 'Date', 'Submitted By','Submitted On', 'Actions'];
    let loop = 1;
    const [monthFilter, setMonthFilter] = useState("");
    const handleNavigate = (rowData) => () => {
        navigate(`/${checkAuthType()}/other-report-detail`, { state: { rowData } });
    };
    const monthMapping = {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December'
    };
    
    const filteredReports = useMemo(() => {
        if (!monthFilter) return taxReports;
        return taxReports.filter(report => 
            moment(report.date).format('MMMM') === monthFilter
        );
    }, [taxReports, monthFilter]);

    const columns = [
        { name: "Filling Name", selector: row => row.name || 'Unknown', sortable: true, width: "20%" },
        { name: "Type", selector: row => row.type, sortable: true },
        { name: "Date", selector: row => moment(row.date).format('DD MMMM, YYYY'), sortable: true },
        { name: "Submitted By", 
          cell: row => row.submitted_by === 'company' && row.user_id === row.company.id ? 'Self Submitted' : row.branch?.branch_name,
          sortable: true 
        },
        { name: "Submitted On", selector: row => moment(row.createdAt).format('DD MMMM, YYYY H:mm:ss'), sortable: true },
        { name: "Actions", 
          cell: row => (
            <button
              onClick={handleNavigate(row)}
              className="btn btn-success cursor-pointer h-5 w-5 text-slate-500"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-book-text">
                <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 1 1 0-5H20" />
                <path d="M8 11h8" />
                <path d="M8 7h6" />
              </svg>
            </button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true
        }
    ];

    return (
        <div>
            <div className="flex flex-col gap-4 p-5 sm:flex-row sm:items-center sm:gap-x-4">
                <div className="flex flex-col">
                    <label htmlFor="monthFilter" className="mb-2">Filter by Month:</label>
                    <select
                        id="monthFilter"
                        value={monthFilter}
                        onChange={(e) => setMonthFilter(e.target.value)}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value="">All Months</option>
                        {Object.values(monthMapping).map((month) => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredReports}
                pagination
                highlightOnHover
                customStyles={{
                    rows: {
                        style: {
                            minHeight: '60px',
                        },
                    },
                    headCells: {
                        style: {
                            fontWeight: 'bold',
                        },
                    },
                }}
            />
        </div>
    );
}

export default OtherFillingTable;