import moment from "moment";


export const getEndSubscription = (end_at) => {
    const subscriptionEndDate = moment(end_at);
    const currentDate = moment();
    const daysUntilExpiry = subscriptionEndDate.diff(currentDate, 'days'); // Get the difference in days

    if (daysUntilExpiry >= 0 && daysUntilExpiry <= 7) {
        let message;
        if (daysUntilExpiry === 0) {
            message = "Your subscription expires today!";
        } else if (daysUntilExpiry === 1) {
            message = "Your subscription will expire in 1 day!";
        } else {
            message = `Your subscription will expire in ${daysUntilExpiry} days!`;
        }
        return message;

    }
    return false;
}