import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../utils/api_const";

const SupportPage = () => {
  const [supports, setSupports] = useState([]);
  const [selectedSupport, setSelectedSupport] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const intervalRef = useRef(null);
  const [message, setMessage] = useState("");
    const [sloading, setSLoading] = useState(false);
    const [setcloseLoading, setLoadingClose] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
        if (!message.trim()) return; // Prevent empty messages
        
        setSLoading(true);

        try {
            await axios.post(`${API_URL}/support/response`, {
                support_number: selectedSupport['support_number'],
                message: message,
                message_by: "admin", // Change this based on user type
            });

            setMessage(""); // Clear input after sending
            fetchMessages(selectedSupport['support_number']); // Refresh chat messages
        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setSLoading(false);
        }
    };


  useEffect(() => {
    fetchSupports();
  }, []);

  const fetchSupports = async () => {
    try {
      const response = await axios.get(`${API_URL}/support`);
      setSupports(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching supports:", error);
      setLoading(false);
    }
  };

  const fetchMessages = async (supportNumber) => {
    try {
      const response = await axios.get(`${API_URL}/support/data/${supportNumber}`);
      setMessages(response.data.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleSelectSupport = (support) => {
    setSelectedSupport(support);
  
    // Clear the previous interval if it exists
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  
    // Start a new interval and store its ID
    intervalRef.current = setInterval(() => {
      fetchMessages(support.support_number);
    }, 4000);
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleClose = async () => {
    if(window.confirm('Are you sure to close this support')){
      setLoadingClose(true);
      try {
        const response = await fetch(`${API_URL}/support/update-status/${selectedSupport['support_number']}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status: 1 }),
        });

        const result = await response.json();

        if (response.ok) {
          alert("Status updated successfully!");
          window.location.reload();
        } else {
          alert(result.error || "Failed to update status");
        }
      } catch (error) {
        console.error("Error updating status:", error);
        alert("An error occurred. Please try again.");
      } finally {
        setLoadingClose(false);
      }
    }
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex grid-cols-12 gap-x-6 gap-y-10">
        {/* Left Side - Support Tickets */}
        <div className="flex-2 bg-white p-4 shadow-md rounded-lg">
          <h3 className="text-lg font-semibold mb-3">Support Tickets</h3>
          {loading ? (
            <p className="text-gray-500">Loading...</p>
          ) : (
            <div className="flex flex-col gap-y-2">
              {supports.map((support) => (
                <div
                  key={support.support_number}
                  className={`cursor-pointer p-3 rounded-lg text-sm font-medium border ${
                    selectedSupport?.support_number === support.support_number
                      ? "bg-primary text-white"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                  onClick={() => handleSelectSupport(support)}
                >
                  
                  <strong>#{support.support_number}</strong><br/>
                  <small>{support.Company?.company_name}</small>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Right Side - Chat Messages */}
        <div className="flex-1 bg-white p-4 shadow-md rounded-lg">
          {selectedSupport ? (
            <>
              <h3 className="text-lg flex font-semibold mb-3">
                <span className="flex-1">
                Support <strong>#{selectedSupport.support_number}</strong><br />
                <small>Company Name: {selectedSupport.Company?.company_name}</small>  
                </span>
                <span className="flex-2">
                  {selectedSupport['is_closed'] == 0 && (<button className="bg-success hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-200 ease-in-out" onClick={handleClose}
        disabled={setcloseLoading}>{setcloseLoading ? "Updating..." : "Close"} </button>)}
                </span>
              </h3>

              <div className="h-80 w-full overflow-y-auto border rounded-lg p-3" style={{
                height: 500,
                overflowY: "auto"
              }}>
                {messages && messages.map((msg, index) => (
                    <div
                      key={index}
                      style={{
                        background: msg.message_by === "admin"?"#eee":"#8af298",
                        width: "65%",
                        float: msg.message_by === "admin"?"right":"left"
                      }}
                      className={`p-2 my-2 max-w-xs rounded-lg text-sm ${
                        msg.message_by === "admin"
                          ? "bg-blue text-black self-start"
                          : "bg-gray text-gray-800 self-end"
                      }`}
                    >
                      <p>{msg.message}</p>
                    </div>
                  ))}
                    
               </div>

               {selectedSupport['is_closed'] == 0 && (<form onSubmit={handleSubmit} className="flex group input-group mt-5">
                    <input
                        type="text"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Type your message..."
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary"
                        disabled={loading} // Disable input while sending
                    />
                    <div id="input-group-price" className="bg-slate-100 border shadow-sm border-slate-200 text-slate-600">
                        <button
                            type="submit"
                            className="bg-success hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-200 ease-in-out"
                            disabled={loading} // Disable button while sending
                        >
                            {loading ? "Sending..." : "Submit"}
                        </button>
                    </div>
                </form>)}
            </>
          ) : (
            <p className="text-gray-500">Select a support ticket</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
