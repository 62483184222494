import React, { useEffect, useRef, useState } from "react";
import TableHeader from "../../../utils/table_headings";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanyData, deleteCompany } from "../../../features/companySlice";
import moment from 'moment';
import { API_URL } from "../../../utils/api_const";
import axios from "axios";
import { Link } from "react-router-dom";
import { Eye } from "lucide-react";
import { Delete } from "lucide-react";
import { Pencil } from "lucide-react";



const CompanyTable = () => {
    const dispatch = useDispatch();

    const companyHeadings = ['Name', 'Company Name', 'Company Type', 'Branches', 'Directors', 'Status', 'Registration Date', 'Actions'];
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRefs = useRef({});
    const [loading, setLoading] = useState(false);
    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        Object.values(dropdownRefs.current).forEach(ref => {
            if (ref && !ref.contains(event.target)) {
                setOpenDropdownId(null);
            }
        });
    };

    const { companies } = useSelector((state) => state.companies);

    const handleStatusToggle = (company_id, status) => async () => {
        console.log(status);
        // Confirm the action with a popup
        const userConfirmed = window.confirm(`Are you sure you want to ${status === 1 ? 'deactivate' : 'activate'} this item?`);

        if (userConfirmed) {
            const newStatus = status === 1 ? '0' : '1';
            console.log('sdkfasfasklasfasf', newStatus);
            setLoading(true); // Show loading state while making the API call

            try {
                // Call the API to update the status
                const response = await axios.put(`${API_URL}/companies/update/${company_id}`, {
                    status: newStatus,
                });

                if (response.status === 200) {
                    dispatch(getAllCompanyData());
                } else {
                    // Handle error if needed
                    alert('Failed to update status.');
                }
            } catch (error) {
                console.error('Error updating status:', error);
                alert('An error occurred while updating status.');
            } finally {
                setLoading(false); // Hide loading state
            }
        }
    };

    useEffect(() => {
        dispatch(getAllCompanyData());
    }, [dispatch]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // const tableData = [
    //     { id: 1, name: 'Leonardo DiCaprio', department: 'Support Specialist', branch: 'Support Team', status: 'Active', joinedDate: 'May 5, 2018', email: 'leonardo.dicaprio@left4code.com' },
    //     { id: 2, name: 'John Doe', department: 'HR Specialist', branch: 'HR Team', status: 'Inactive', joinedDate: 'June 12, 2019', email: 'john.doe@example.com' },
    //     // Add more data as needed
    // ];

    const handleDelete = (id) => {
        // e.preventDefault();
        const ok = confirm('Are you sure to delete this company');
        if (ok) {
            dispatch(deleteCompany(id));
            dispatch(getAllCompanyData());
        }
    }

    const [companyType, setCompanyType] = useState('');
    const [status, setStatus] = useState('');
    const [registrationDateFrom, setRegistrationDateFrom] = useState('');
    const [registrationDateTo, setRegistrationDateTo] = useState('');

    // Filtered companies
    const filteredCompanies = companies.filter((company) => {
        const matchesCompanyType = companyType ? company.company_type === companyType : true;
        const matchesStatus = status !== ""
            ? company.status.toString() === status
            : true;

        const matchesRegistrationDateFrom = registrationDateFrom
            ? moment(company.createdAt).isSameOrAfter(moment(registrationDateFrom))
            : true;
        const matchesRegistrationDateTo = registrationDateTo
            ? moment(company.createdAt).isSameOrBefore(moment(registrationDateTo))
            : true;

        return (
            matchesCompanyType &&
            matchesStatus &&
            matchesRegistrationDateFrom &&
            matchesRegistrationDateTo
        );
    });

    return (
        <>
            <div className="flex flex-col gap-4 p-5 sm:flex-row items-center sm:gap-x-4">

                <div className="flex flex-col m-2">
                    <label className="mb-1">Company Type:</label>
                    <select
                        data-tw-merge=""
                        id="category"
                        value={companyType}
                        onChange={(e) => setCompanyType(e.target.value)}
                        name="company_type"
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value=""> -- Select Type --</option>
                        <option value="Individual">Individual</option>
                        <option value="* Public Limited Company">Public Limited Company</option>
                        <option value="Private Limited Company">Private Limited Company</option>
                        <option value="Partnerships">Partnerships</option>
                        <option value="LLP Registration">LLP Registration</option>
                        <option value="One Person Company">One Person Company</option>
                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                        <option value="Section 8 Company">Section 8 Company</option>
                        <option value="Association of Persons (AOP) or Body of Individuals (BOI)">Association of Persons (AOP) or Body of Individuals (BOI)</option>
                        <option value="Local Authority">Local Authority</option>
                        <option value="Artificial Judicial Body (not covered under any of the above-mentioned categories)">Artificial Judicial Body (not covered under any of the above-mentioned categories)</option>
                    </select>

                </div>

                <div className="flex flex-col">
                    <label className="mb-1">Status:</label>

                    <select
                        data-tw-merge=""
                        id="category"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        name="status"
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value=""> -- Select Status --</option>
                        <option value="0"> Inactive </option>
                        <option value="1"> Active </option>
                    </select>
                </div>

                <div className="flex flex-col">
                    <label className="mb-1">Registration Date From:</label>
                    <input
                        type="date"
                        value={registrationDateFrom}
                        onChange={(e) => setRegistrationDateFrom(e.target.value)}
                        className="border rounded px-2 py-1 border-slate-200"
                    />
                </div>

                <div className="flex flex-col">
                    <label className="mb-1">Registration Date To:</label>
                    <input
                        type="date"
                        min={registrationDateFrom}
                        value={registrationDateTo}
                        onChange={(e) => setRegistrationDateTo(e.target.value)}
                        className="border rounded px-2 py-1 border-slate-200"
                    />
                </div>
                <div className="flex flex-col mt-2">
                    <button
                        onClick={() => {
                            setCompanyType('');
                            setStatus('');
                            setRegistrationDateFrom('');
                            setRegistrationDateTo('');
                        }}
                        type="button"
                        className="bg-success mt-4 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-200 ease-in-out"
                    >
                        Reset
                    </button>
                </div>


            </div>

            <table
                data-tw-merge=""
                className="w-full text-left border-b border-slate-200/60"
            >
                <TableHeader headings={companyHeadings} />
                <tbody>
                    {filteredCompanies && filteredCompanies.map((row, index) => (
                        <tr key={row.id} data-tw-merge="" className="[&_td]:last:border-b-0">
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                {/* <input
                                    data-tw-merge=""
                                    type="checkbox"
                                    className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&[type='radio']]:checked:bg-primary [&[type='radio']]:checked:border-primary [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary [&[type='checkbox']]:checked:border-primary [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50"
                                /> */}
                                {index + 1}
                            </td>
                            <td
                                style={{ width: "200px" }}
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 w-80 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div className="flex">

                                    <div>
                                        <a className="font-medium" href="">
                                            {row.username}
                                        </a>
                                        <div className="mt-0.5 text-xs text-slate-500">
                                            {row.email}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <a className="font-medium" href="">
                                    {row.company_name}
                                </a>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div className="mt-0.5 text-xs text-slate-500">
                                    {row.company_type}
                                </div>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div className="mt-0.5 text-xs text-slate-500">

                                    {row.Branches?.length ?? 0} Branches
                                </div>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div className="mt-0.5 text-xs text-slate-500">
                                    {row.DirectorDetails ? row.DirectorDetails.length : 0} Directors
                                </div>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div onClick={handleStatusToggle(row.id, row.status)} className={`flex ${row.status == '1' ? 'text-success' : 'text-danger'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-database"><ellipse cx="12" cy="5" rx="9" ry="3" /><path d="M3 5V19A9 3 0 0 0 21 19V5" /><path d="M3 12A9 3 0 0 0 21 12" /></svg>
                                    <div className="ml-1.5 whitespace-nowrap">{row.status == '1' ? "Active" : "Inactive"}</div>
                                </div>
                            </td>
                            <td
                                data-tw-merge=""
                                className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                            >
                                <div className="whitespace-nowrap">{moment(row.createdAt).format('MMMM D, YYYY')}</div>
                            </td>
                            <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 relative border-dashed py-4 dark:bg-darkmode-600">
                                {/* <div className="flex">
                                    <div
                                        ref={el => dropdownRefs.current[row.id] = el}
                                        data-tw-merge=""
                                        data-tw-placement="bottom-end"
                                        className="dropdown relative h-5"
                                    >
                                        <button
                                            onClick={() => toggleDropdown(row.id)}
                                            aria-expanded={openDropdownId === row.id}
                                            className="cursor-pointer h-5 w-5 text-slate-500"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-ellipsis-vertical"><circle cx="12" cy="12" r="1"/><circle cx="12" cy="5" r="1"/><circle cx="12" cy="19" r="1"/></svg>
                                        </button>
                                        <div
                                            className={`dropdown-menu absolute z-[9999] ${openDropdownId === row.id ? 'block' : 'hidden'}`}
                                            data-transition=""
                                            data-selector=".show"
                                            data-enter="transition-all ease-linear duration-150"
                                            data-enter-from="absolute !mt-5 invisible opacity-0 translate-y-1"
                                            data-enter-to="!mt-1 visible opacity-100 translate-y-0"
                                            data-leave="transition-all ease-linear duration-150"
                                            data-leave-from="!mt-1 visible opacity-100 translate-y-0"
                                            data-leave-to="absolute !mt-5 invisible opacity-0 translate-y-1"
                                            style={{ right: 0 }}
                                        >
                                            <div
                                                data-tw-merge=""
                                                className="dropdown-content rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600 w-40"
                                            >
                                                <a className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                                    <i
                                                        data-tw-merge=""
                                                        data-lucide="check-square"
                                                        className="stroke-[1] mr-2 h-4 w-4"
                                                    />
                                                    View
                                                </a>
                                                <a className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item text-danger">
                                                    <i
                                                        data-tw-merge=""
                                                        data-lucide="trash2"
                                                        className="stroke-[1] mr-2 h-4 w-4"
                                                    />
                                                    Delete
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="flex">
                                    <Link className="mr-2 text-warning" to={`/companies/edit/step1/${row.id}`} state={{ companyName: row.company_name }}>
                                        <Pencil />
                                    </Link>
                                    <Link className="mr-2 text-primary" to={`/companies/detail/${row.id}`}>
                                        <Eye />
                                    </Link>
                                    <Link className="text-danger" onClick={() => handleDelete(row.id)}>
                                        <Delete />
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default CompanyTable;
