import React from "react";
import { Route, Routes } from "react-router-dom";
import Companies from "./companies";
import AddCompanies from "./add_company";
import EditCompanies from "./edit_company";
import CompaniesDetail from "./company_detail";
import CompanyChangePassword from './company_change_password';

const CompanyRoutes = () => {
    return(
        <Routes>
            <Route path="/" element={<Companies/>} />
            <Route path="change-password" element={<CompanyChangePassword />} />
            <Route path="add/*" exact element={<AddCompanies />} />
            <Route path="edit/*" exact element={<EditCompanies />} />

            <Route path="detail/:company_id" exact element={<CompaniesDetail />} />
        </Routes> 
    );
}
export default CompanyRoutes;