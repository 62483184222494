import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import generateFiscalYears from "../dashboard/partials/generate_financial_year";
import { getAllCompanyData } from "../../features/companySlice";
import { API_URL } from "../../utils/api_const";
import moment from "moment";

const AdminFillings = () => {
    const dispatch = useDispatch();
    const fiscalYears = generateFiscalYears();
    const [showAll, setShowAll] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedFillingType, setSelectedFillingType] = useState('');
    const { companies, status, error } = useSelector((state) => state.companies);
    const [schedules, setSchedules] = useState([]);
    const [branches, setBranches] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active accordion
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(
        localStorage.getItem('selectedFiscalYear') || fiscalYears[fiscalYears.length - 1]
    );
    const [selectTypeData, setSelectedTypeData] = useState('all');

    useEffect(() => {
        dispatch(getAllCompanyData());
    }, [dispatch]);

    const handleFiscalYearChange = async (e) => {
        console.log(e.target.value);
        setSelectedFiscalYear(e.target.value);
        let url;
        if(selectedBranch){
            url = `${API_URL}/branches/tax-service/${selectedBranch}/${e.target.value}`;
        }else{
            url = `${API_URL}/companies/tax-service/${selectedCompany}/${e.target.value}`;
        }
        const response = await fetch(url);
        const data = await response.json();
        setSchedules(data);
    };

    const handleCompanyChange = async (e) => {
        const companyId = e.target.value;
        setSelectedCompany(companyId);
        setSelectedBranch('');
        const selectedCompanyData = companies.find(company => company.id === Number(companyId));
        setBranches(selectedCompanyData?.Branches || []);
        if(companyId){
            const url = `${API_URL}/companies/tax-service/${companyId}/${selectedFiscalYear}`;
            const response = await fetch(url);
            const data = await response.json();
            setSchedules(data);
        }else {
            setSchedules([]);
        }
    }
   
    const handleBranchChange = async (e) => {
        const branchId = e.target.value;
        setSelectedBranch(branchId);
      
      
        if(branchId){
            const url = `${API_URL}/branches/tax-service/${branchId}/${selectedFiscalYear}`;
            const response = await fetch(url);
            const data = await response.json();
            setSchedules(data);
        }else if(selectedCompany){
            const url = `${API_URL}/companies/tax-service/${selectedCompany}/${selectedFiscalYear}`;
            const response = await fetch(url);
            const data = await response.json();
            setSchedules(data);
        }else{
            setSchedules([]);
        }
       
    }

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Toggle between showing and hiding the accordion
    };


    const handleShowDataType = (e) => {
        setSelectedTypeData(e.target.value);
        if (e.target.value == 'all') {
            setShowAll(false);
        } else {
            setShowAll(true);
        }

    }


    const generateTableRows = (service) => {
        const today = moment();
        const startMonth = 3; // April
        const endMonth = 14; // March of the following year

        const getDateStatus = (date, reports) => {
            if (reports.length > 0) {
                const report = reports.find(report => moment(report.due_date).isSame(date, 'day'));
                return report ? 'Submitted' : date.isBefore(today) ? 'Pending' : 'Upcoming';
            }
            // console.log('date is',date);
            // console.log(date.isBefore(moment().startOf('day'), 'day') ? 'Pending' : 'Upcoming');
            return date.isBefore(moment().startOf('day'), 'day') ? 'Pending' : 'Upcoming';
        };

        const generateRowsForFrequency = (frequency, day, start_from, service_name, itemdata) => {
            console.log(day);
            const rows = [];
            const monthsInYear = Array.from({ length: 12 }, (_, i) => (i + 3) % 12); // Generate month indices from April to March
            const row = Array(12).fill(null);
            const [currentYear, endYear] = selectedFiscalYear.split('-').map(Number);
            // console.log('Branch Schedule',itemdata.BranchSchedules);
            // console.log('Company Schedule',itemdata.ScheduleReports)
            const scheduleId = itemdata.BranchSchedules?.[0]?.id || itemdata.ScheduleReports?.[0]?.id;


            if (frequency === 'monthly') {
                monthsInYear.forEach((month, index) => {
                    const dates = moment().year(index < 9 ? currentYear : currentYear + 1).month(month).date(day);
                    row[index] = {
                        date: dates.format('MMM DD'),
                        scheduleId: scheduleId,
                        isBranch: itemdata.BranchSchedules ? 'branch' : 'company',
                        itemid: itemdata.id,
                        scheduleDate: dates.format('YYYY-MM-DD'),
                        status: getDateStatus(dates, service.SubmittedReports),
                        color: getDateStatus(dates, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(dates, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                    };
                });
            } else if (frequency === 'quarterly') {
                const startMonthQuarterly = moment().month(start_from);
                if (startMonthQuarterly.month() == 2) {
                    const monthsInYearMarch = Array.from({ length: 12 }, (_, i) => (i + 2) % 12);;

                    monthsInYearMarch.forEach((month, index) => {
                        if (index % 3 === 0) {

                            let date = moment().year(index < 9 ? currentYear : currentYear + 1).month(month).date(day);
                            console.log('checking index data');
                            console.log(index);
                            console.log(date.month());

                            if (index == 0) {
                                row[11] = {
                                    date: date.format('MMM DD'),
                                    scheduleId: scheduleId,
                                    isBranch: itemdata.BranchSchedules ? 'branch' : 'company',
                                    itemid: itemdata.id,
                                    scheduleDate: date.format('YYYY-MM-DD'),
                                    status: getDateStatus(date, service.SubmittedReports),
                                    color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                                }
                            } else {
                                row[index - 1] = {
                                    date: date.format('MMM DD'),
                                    scheduleId: scheduleId,
                                    isBranch: itemdata.BranchSchedules ? 'branch' : 'company',
                                    itemid: itemdata.id,
                                    scheduleDate: date.format('YYYY-MM-DD'),
                                    status: getDateStatus(date, service.SubmittedReports),
                                    color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                                };
                            }

                        }
                    });
                } else {
                    monthsInYear.forEach((month, index) => {
                        if (index % 3 === 0) { // Every quarter
                            let myindex = index;
                            let date = moment().year(index < 9 ? currentYear : currentYear + 1).month(month).date(day);
                            console.log('this is date of mont' + date.format('MM'), date.month());
                            if (startMonthQuarterly.month() == 0 && date.month() === 3) {

                                myindex = index + 1;
                                date = date.add(1, 'month'); // Add one month to the date
                                console.log('Adjusted date after adding one month:', date.format('MMM DD, YYYY'));
                            }
                            if (day == '30') {
                                date = date.add(1, 'day');
                            }

                            row[myindex] = {
                                date: date.format('MMM DD'),
                                scheduleId: scheduleId,
                                isBranch: itemdata.BranchSchedules ? 'branch' : 'company',
                                itemid: itemdata.id,
                                scheduleDate: date.format('YYYY-MM-DD'),
                                status: getDateStatus(date, service.SubmittedReports),
                                color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                            };
                        }
                    });
                }

            } else if (frequency === 'semi-annually') {
                const semiAnnualMonths = {
                    january: [0, 6],
                    april: [3, 9],
                    october: [9, 3],
                    june: [5, 11]
                };
                const months = semiAnnualMonths[start_from] || [];
                monthsInYear.forEach((month, index) => {
                    if (months.includes(month)) {
                        const date = moment().year(index < 9 ? currentYear : currentYear + 1).month(month).date(day);
                        row[index] = {
                            date: date.format('MMM DD'),
                            scheduleId: scheduleId,
                            isBranch: itemdata.BranchSchedules ? 'branch' : 'company',
                            itemid: itemdata.id,
                            scheduleDate: date.format('YYYY-MM-DD'),
                            status: getDateStatus(date, service.SubmittedReports),
                            color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                        };
                    }
                });
            } else if (frequency === 'annually') {
                // Determine the starting month for annual frequency
                const startMonthAnnually = moment(day);
                // console.log('i smonth data', startMonthAnnually);
                // const currentYear = moment().year(currentYear); // Get the current year

                monthsInYear.forEach((month, index) => {
                    // Only set the row value if the month matches the start month
                    if (month === startMonthAnnually.month()) {
                        console.log('servicename', service_name)
                        console.log('current year', currentYear);
                        // console.log('month', month);
                        console.log('date', day);
                        // Use the current year for dates in the current year range
                        const date = moment(day).year(currentYear);



                        console.log('fine data date', date);

                        row[index] = {
                            date: date.format('MMM DD'),
                            itemid: itemdata.id,
                            scheduleId: scheduleId,
                            isBranch: itemdata.BranchSchedules ? 'branch' : 'company',
                            scheduleDate: date.format('YYYY-MM-DD'),
                            status: getDateStatus(date, service.SubmittedReports),
                            color: getDateStatus(date, service.SubmittedReports) === 'Submitted' ? 'green' : getDateStatus(date, service.SubmittedReports) === 'Pending' ? 'red' : 'blue'
                        };
                    } else {
                        // Handle cases where the month does not match the start month
                        row[index] = {
                            date: '',
                            scheduleId: '',
                            itemid: '',
                            isBranch: '',
                            scheduleDate: '',// Leave blank if the month does not match
                            status: 'Not Applicable',
                            color: 'grey'
                        };
                    }
                });
            }
            rows.push(row);
            return rows;
        };

        if (service.frequency) {
            return generateRowsForFrequency(service.frequency, service.day, service.start_from, service.service_name, service);
        }

        return [];
    };

    function getFrequencyText(row) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (row.frequency === 'monthly') {
            return `${row.day}th of Every Month`;
        } else if (row.frequency === 'quarterly') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            //     if (startMonthIndex !== -1) {
            //         const quarterMonths = [startMonthIndex, (startMonthIndex + 3) % 12, (startMonthIndex + 6) % 12, (startMonthIndex + 9) % 12];
            //         const quarterMonthNames = quarterMonths.map(index => months[index]);
            //         return `${row.day} of ${quarterMonthNames.join(', ')}`;
            //     } else {
            //         return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            //     }

            if (startMonthIndex !== -1) {
                // Define the quarter months based on the start month index
                const quarterMonths = [
                    startMonthIndex,
                    (startMonthIndex + 3) % 12,
                    (startMonthIndex + 6) % 12,
                    (startMonthIndex + 9) % 12
                ];
                let dayData = row.day;

                const quarterMonthNames = quarterMonths.map((monthIndex) => {
                    let date = moment().month(monthIndex).date(row.day); // Use the day from the row

                    // Adjust the date based on the specific conditions
                    if (date.month() === 3) { // If it's April (index 3)
                        if (row.day == '30') {
                            dayData = '31';
                        }
                        date = date.add(1, 'month'); // Add one month
                        console.log('Adjusted date after adding one month:', date.format('MMM DD, YYYY'));
                    }
                    if (row.day === '30') { // If the day is the 30th
                        date = date.add(1, 'day'); // Add one more day
                    }

                    return date.format('MMM DD');
                });



                return `${dayData} of ${quarterMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency === 'semi-annually') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const halfYearMonths = [startMonthIndex, (startMonthIndex + 6) % 12];
                const halfYearMonthNames = halfYearMonths.map(index => months[index]);
                return `${row.day} of ${halfYearMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency == 'annually') {
            const date = moment(row.day);
            const day = date.subtract(1, 'day').format('DD'); // Day of the month
            const month = date.format('MMMM'); // Full month name
            return `${day} of Every ${month}`;
        } else {
            return '';
        }
    }


    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Submissions
                        </div>
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            
                            <div className="relative">
                                <span className="text-white font-bold mr-2">Companies </span>
                                <select data-tw-merge
                                    onChange={handleCompanyChange}
                                    value={selectedCompany}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:bg-chevron-white group-[.mode--light]:!text-slate-200 sm:w-44">
                                    <option value="" disabled selected>Select Company</option>
                                    {companies.map((com, index) => (
                                        <option key={index} value={com.id}>
                                            {com.company_name}
                                        </option>
                                    ))}


                                </select>
                            </div>
                           {branches && branches.length > 0 && (<div className="relative">
                                <span className="text-white font-bold mr-2">Branch </span>
                                <i data-tw-merge data-lucide="calendar-check2" className="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4 stroke-[1.3] group-[.mode--light]:!text-slate-200" />
                                <select data-tw-merge
                                    onChange={handleBranchChange}
                                    value={selectedBranch}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:bg-chevron-white group-[.mode--light]:!text-slate-200 sm:w-44">
                                    <option value="">All</option>

                                    {branches.map((branch, index) => (
                                        <option key={index} value={branch.id}>
                                            {branch.branch_name}
                                        </option>
                                    ))}

                                </select>
                            </div>)}
                            <div className="relative">
                                <span className="text-white font-bold mr-2">Financial Year </span>
                                <select data-tw-merge
                                    onChange={handleFiscalYearChange}
                                    value={selectedFiscalYear}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] pl-9 group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:bg-chevron-white group-[.mode--light]:!text-slate-200 sm:w-44">
                                    <option value="custom-date">Custom Date</option>
                                    {fiscalYears.map((year, index) => (
                                        <option key={index} value={year}>
                                            {year.replace('-', ' - ')}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            <div className="relative">
                                <span className="text-white font-bold mr-2">Period Type </span>
                                <select
                                    onChange={(e) => setSelectedFillingType(e.target.value)}
                                    value={selectedFillingType}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 rounded-[0.5rem] pl-9 sm:w-44"
                                >

                                    <option value="">All</option>
                                    <option value='monthly'>Monthly</option>
                                    <option value='quarterly'>Quaterly</option>
                                    <option value='semi-annually'>Semi Annually</option>
                                    <option value='annually'>Annually</option>
                                </select>
                            </div>
                           
                        </div>
                    </div>
                    <div className="mt-3.5 flex flex-col gap-8">

                    <div className="box box--stacked flex flex-col p-5">
                            <div className="accordion">
                                {schedules && schedules.length > 0 && schedules.map((item, index) => (
                                    <div
                                        data-tw-merge=""
                                        key={index}
                                        style={{
                                            display: (
                                                selectedBranch !== '' && Number(item.BranchSchedules?.[0]?.branch_id) !== Number(selectedBranch)
                                                || selectedFillingType !== '' && item.frequency !== selectedFillingType
                                            ) ? 'none' : 'block'
                                        }}
                                        // style={{ display: selectedBranch !== '' && Number(item.BranchSchedules?.[0]?.branch_id) !== Number(selectedBranch) ? 'none' : 'block' }}
                                        className="accordion-item py-4 first:-mt-4 last:-mb-4 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-slate-200/60 [&:not(:last-child)]:dark:border-darkmode-400 p-4 first:mt-0 last:mb-0 border border-slate-200/60 mt-3 dark:border-darkmode-400"
                                    >
                                        <div className="accordion-header" id="faq-accordion-5" >
                                            <button
                                                data-tw-merge=""
                                                data-tw-toggle="collapse"
                                                data-tw-target={`#faq-accordion-${index}-collapse`}
                                                type="button"
                                                aria-expanded={activeIndex === index || showAll}
                                                aria-controls={`faq-accordion-${index}-collapse`}
                                                onClick={() => toggleAccordion(index)}
                                                className="accordion-button outline-none py-4 -my-4 font-medium w-full text-left dark:text-slate-400 [&:not(.collapsed)]:text-primary [&:not(.collapsed)]:dark:text-slate-300"
                                            >
                                                <table className="table w-full">
                                                    <thead>
                                                        <tr>
                                                            {item.BranchSchedules?.[0] && (<th style={{ width: "20%" }}>{item.BranchSchedules?.[0]?.Branch?.branch_name}</th>)}
                                                            <th style={{ width: "20%" }}>{item.service_name}</th>
                                                            <th style={{ width: "20%", textTransform: "capitalize" }}>
                                                                {item.frequency}<br />
                                                                <small>{item.service_type == 'return_filling' ? 'Periodic Filling' : 'Annual Filling'}</small>
                                                            </th>
                                                            <th style={{ width: "30%" }}>{getFrequencyText(item)}</th>
                                                            <th style={{ width: "10%" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-eye"><path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0" /><circle cx="12" cy="12" r="3" /></svg></th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </button>
                                        </div>




                                        <div
                                            id={`faq-accordion-${index}-collapse`}
                                            aria-labelledby={`faq-accordion-${index}`}
                                            className={`accordion-collapse collapse mt-3 text-slate-700 leading-relaxed dark:text-slate-400 [&.collapse:not(.show)]:hidden [&.collapse.show]:visible ${activeIndex === index || showAll == true ? 'show' : 'hidden'}`}
                                            style={{ overflowX: 'scroll' }}
                                        >
                                            <div
                                                data-tw-merge=""
                                                className="accordion-body leading-relaxed text-slate-600 dark:text-slate-500 leading-relaxed text-slate-600 dark:text-slate-500"
                                            >
                                                <table className="w-full text-left border-b border-slate-200/60 mt-5">
                                                    <thead>
                                                        <tr>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">April</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">May</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">June</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">July</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">August</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">September</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">October</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">November</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">December</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">January</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">February</th>
                                                            <th className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500">March</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {generateTableRows(item).map((row, rowIndex) => (
                                                            <tr key={rowIndex}>
                                                                {/* {row.map((cell, cellIndex) => (
                                                                    <td key={cellIndex} style={{textAlign:'center'}} className="border-b dark:border-darkmode-300 border py-4 dark:bg-darkmode-600">
                                                                        {selectTypeData == 'pending' && cell?.color == 'red'?
                                                                            
                                                                                <span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>
                                                                    
                                                                            :''}
                                                                        {selectTypeData == 'completed' && cell?.color == 'green'?<span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>:''}
                                                                        {selectTypeData == 'upcoming' && cell?.color == 'blue'?<span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>:''}
                                                                        {selectTypeData == 'all'?
                                                                            {cell?.color == 'red'?
                                                                                <Link to={`/admin/tax/pending-filling/${getScheduleId(item)}/${cell.TaxService?.id}/${cell.scheduleDate}`}>
                                                                                    <span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>
                                                                                </Link>:
                                                                                <span className="badge" style={{ backgroundColor: cell?.color || 'transparent', textAlign: 'center', color: '#fff', padding:'5px 5px', borderRadius:'20px' }}>{cell?.date || ''}</span>
                                                                            }
                                                                            
                                                                        :''}
                                                                    </td>
                                                                ))} */}
                                                                {row.map((cell, cellIndex) => (
                                                                    <td
                                                                        key={cellIndex}
                                                                        style={{ textAlign: 'center' }}
                                                                        className="border-b dark:border-darkmode-300 border py-4 dark:bg-darkmode-600"
                                                                    >
                                                                        {selectTypeData === 'pending' && cell?.color === 'red' && (
                                                                            <Link
                                                                                to={`/admin/tax/pending-filling/${cell.scheduleId}/${cell.itemid}/${cell.scheduleDate}/${cell.isBranch}`}
                                                                                state={{ company_id: selectedCompany, branch_id: selectedBranch || item.BranchSchedules?.[0]?.branch_id }}
                                                                            
                                                                            >
                                                                                <span
                                                                                    className="badge"
                                                                                    style={{
                                                                                        backgroundColor: cell?.color || 'transparent',
                                                                                        textAlign: 'center',
                                                                                        color: '#fff',
                                                                                        padding: '5px 5px',
                                                                                        borderRadius: '20px',
                                                                                    }}
                                                                                >
                                                                                    {cell?.date || ''}
                                                                                </span>
                                                                            </Link>
                                                                        )}

                                                                        {selectTypeData === 'completed' && cell?.color === 'green' && (
                                                                            <span
                                                                                className="badge"
                                                                                style={{
                                                                                    backgroundColor: cell?.color || 'transparent',
                                                                                    textAlign: 'center',
                                                                                    color: '#fff',
                                                                                    padding: '5px 5px',
                                                                                    borderRadius: '20px',
                                                                                }}
                                                                            >
                                                                                {cell?.date || ''}
                                                                            </span>
                                                                        )}

                                                                        {selectTypeData === 'upcoming' && cell?.color === 'blue' && (
                                                                            <Link
                                                                                to={`/admin/tax/upcoming-filling/${cell.scheduleId}/${cell.itemid}/${cell.scheduleDate}/${cell.isBranch}`}
                                                                                state={{ company_id: selectedCompany, branch_id: selectedBranch || item.BranchSchedules?.[0]?.branch_id}}
                                                                            >
                                                                                <span
                                                                                    className="badge"
                                                                                    style={{
                                                                                        backgroundColor: cell?.color || 'transparent',
                                                                                        textAlign: 'center',
                                                                                        color: '#fff',
                                                                                        padding: '5px 5px',
                                                                                        borderRadius: '20px',
                                                                                    }}
                                                                                >
                                                                                    {cell?.date || ''}
                                                                                </span>
                                                                            </Link>
                                                                        )}

                                                                        {selectTypeData === 'all' && (
                                                                            <div>
                                                                                {cell?.color === 'red' ? (
                                                                                    <Link
                                                                                        to={`/admin/tax/pending-filling/${cell.scheduleId}/${cell.itemid}/${cell.scheduleDate}/${cell.isBranch}`}
                                                                                        state={{ company_id: selectedCompany, branch_id: selectedBranch || item.BranchSchedules?.[0]?.branch_id }}
                                                                                    >
                                                                                        <span
                                                                                            className="badge"
                                                                                            style={{
                                                                                                backgroundColor: cell?.color || 'transparent',
                                                                                                textAlign: 'center',
                                                                                                color: '#fff',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '20px',
                                                                                            }}
                                                                                        >
                                                                                            {cell?.date || ''}
                                                                                        </span>
                                                                                    </Link>
                                                                                ) : cell?.color === 'blue' ? (
                                                                                    <Link
                                                                                        to={`/admin/tax/upcoming-filling/${cell.scheduleId}/${cell.itemid}/${cell.scheduleDate}/${cell.isBranch}`}
                                                                                        state={{ company_id: selectedCompany, branch_id: selectedBranch || item.BranchSchedules?.[0]?.branch_id }}
                                                                                    >
                                                                                        <span
                                                                                            className="badge"
                                                                                            style={{
                                                                                                backgroundColor: cell?.color || 'transparent',
                                                                                                textAlign: 'center',
                                                                                                color: '#fff',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '20px',
                                                                                            }}
                                                                                        >
                                                                                            {cell?.date || ''}
                                                                                        </span>
                                                                                    </Link>
                                                                                ) : (
                                                                                    <span
                                                                                        className="badge"
                                                                                        style={{
                                                                                            backgroundColor: cell?.color || 'transparent',
                                                                                            textAlign: 'center',
                                                                                            color: '#fff',
                                                                                            padding: '5px 5px',
                                                                                            borderRadius: '20px',
                                                                                        }}
                                                                                    >
                                                                                        {cell?.date || ''}
                                                                                    </span>
                                                                                )}
                                                                            </div>

                                                                        )}
                                                                    </td>
                                                                ))}

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminFillings;