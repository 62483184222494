import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../utils/api_const';

const initialState = {
    company: null,
    token: null,
    loading: false,
    error: null,
    message: null,
};

export const companyLogin = createAsyncThunk('companyAuth/login', async (formData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${API_URL}/auth/company-login`, formData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const companyAuthSlice = createSlice({
  name: 'companyAuth',
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.message = null;
      state.error = null;
    },
    setCompanyFromLocalStorage: (state, action) => {
        state.company = action.payload;
    },
    signout: (state) => {
      state.company = null;
      state.token = null;
      localStorage.removeItem('login_type');
      localStorage.removeItem('company');
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(companyLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      })
      .addCase(companyLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.company = action.payload.entity;
        state.token = action.payload.token;
        state.message = action.payload.message;
        localStorage.setItem('login_type',action.payload.type);
        localStorage.setItem('company', JSON.stringify(action.payload.entity));
        localStorage.setItem('token', action.payload.token);
      })
      .addCase(companyLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export const { clearMessage, setCompanyFromLocalStorage, signout } = companyAuthSlice.actions;
export default companyAuthSlice.reducer;
