import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteScheduleReport, getAllScheduleReports, getScheduleReportsByBranch } from "../../../features/scheduleReportSlice";
import TableHeader from "../../../utils/table_headings";
import moment from "moment";
import { Link } from "react-router-dom";
import { fetchBranchesByCompanyId } from "../../../features/branchSlice";
import { Delete } from "lucide-react";
import Swal from "sweetalert2";

const monthMapping = {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
};

const formatPeriod = (period) => {
    const month = monthMapping[period.toLowerCase()];
    return month ? moment(month, 'MMMM').format('MMMM') : period;
};

const BranchScheduleReportTable = ({ branchesData }) => {
    const dispatch = useDispatch();
    console.log('branches data details', branchesData);
    const companyHeadings = ['Reports', 'Frequency', 'Type', 'Branch', 'Actions'];
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRefs = useRef({});
    const { branches, error } = useSelector((state) => state.branches);
    const [scheduleReports, setScheduleReports] = useState([]);
    const [loader, setLoader] = useState(true);

    const [service_name, setServiceName] = useState('');
    const [frequency, setFrequency] = useState('');
    const [type, setType] = useState('');

    useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        if (company) {
            const companyId = company.id;
            dispatch(fetchBranchesByCompanyId(companyId));
        }
    }, [dispatch]);
    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        let clickedOutside = true;

        Object.values(dropdownRefs.current).forEach(ref => {
            if (ref && ref.contains(event.target)) {
                clickedOutside = false;
            }
        });

        if (clickedOutside) {
            setOpenDropdownId(null);

            if (event.target.tagName === 'A' && event.target.href) {
                window.location.href = event.target.href;
            }
        }
    };


    const handleDelete = ({ id, type }) => () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteScheduleReport({ id, type }));
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Successfully Deleted',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                setScheduleReports(prevReports =>
                    prevReports.filter(report => report.id !== id)
                );
            }
        });
    }
    // const handleDelete = (id) => {
    //     dispatch(deleteScheduleReport(id));
    // };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function getFrequencyText(row) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (row.frequency === 'monthly') {
            return `${row.day}th of Every Month`;
        } else if (row.frequency === 'quarterly') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const quarterMonths = [startMonthIndex, (startMonthIndex + 3) % 12, (startMonthIndex + 6) % 12, (startMonthIndex + 9) % 12];
                const quarterMonthNames = quarterMonths.map(index => months[index]);
                return `${row.day} of ${quarterMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency === 'semi-annually') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const halfYearMonths = [startMonthIndex, (startMonthIndex + 6) % 12];
                const halfYearMonthNames = halfYearMonths.map(index => months[index]);
                return `${row.day} of ${halfYearMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency == 'annually') {
            const date = moment(row.date);
            const day = date.format('D'); // Day of the month
            const month = date.format('MMMM'); // Full month name
            return `${day} of Every ${month}`;
        } else {
            return '';
        }
    }




    useEffect(() => {
        setLoader(true);
        setScheduleReports([]);

        if (branchesData) {
            if (branches) {
                branches.forEach(branch => {
                    console.log(`branchid and branchdata ${branch.id} ${branchesData}`);
                    if (branch.BranchSchedules && Number(branch.id) === Number(branchesData)) {
                        const updatedSchedules = branch.BranchSchedules.map(schedule => ({
                            ...schedule,
                            branch_name: branch.branch_name, // Add branch.name to each schedule
                        }));
                        setScheduleReports(prevReports => [...prevReports, ...updatedSchedules]);
                    }
                });
            }
        } else {
            if (branches) {
                branches.forEach(branch => {
                    if (branch.BranchSchedules) {
                        const updatedSchedules = branch.BranchSchedules.map(schedule => ({
                            ...schedule,
                            branch_name: branch.branch_name, // Add branch.name to each schedule
                        }));
                        setScheduleReports(prevReports => [...prevReports, ...updatedSchedules]);
                    }
                });
            }
        }

        setLoader(false);
    }, [branches, branchesData]);


    const filteredData = scheduleReports.filter((row) => {
        const matchServiceName = service_name ? row.TaxService?.service_name.toLowerCase().includes(service_name.toLowerCase()) : true;
        const matchesFrequency = frequency ? row.TaxService?.frequency === frequency : true;
        const matchesType = type ? row.type === type : true;

        return matchServiceName && matchesFrequency && matchesType;
    });

    return (

        <>
            <div className="flex flex-col gap-4 p-5 sm:flex-row sm:items-center sm:gap-x-4">

                {/* Company Filter */}
                <div className="flex flex-col">
                    <label className="mb-1">Service Name:</label>
                    <input
                        type="text"
                        value={service_name}
                        onChange={(e) => setServiceName(e.target.value)}
                        placeholder="Enter Service name"
                        className="border rounded px-2 py-1 border-slate-200"
                    />
                </div>
        

                {/* Frequency Filter */}
                <div className="flex flex-col">
                    <label className="mb-1">Frequency:</label>

                    <select
                        data-tw-merge=""
                        name="frequency"
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value=""> -- Select --</option>
                        <option value="weekly">Weekly (Occurring once a week)</option>
                        <option value="monthly">Monthly (Occurring once a month)</option>
                        <option value="quarterly">Quarterly (Occurring once every three months)</option>
                        <option value="semi-annually">Semi-Annually (Occurring once every six months)</option>
                        <option value="annually">Annually (Occurring once a year)</option>
                        <option value="triennially">Triennially (Occurring once every three years)</option>
                    </select>

                </div>

                {/* Type Filter */}
                <div className="flex flex-col">
                    <label className="mb-1">Type:</label>
                    <select
                        data-tw-merge=""
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value=""> -- Select Status --</option>
                        <option value="return_filling">Periodic Fillings</option>
                        <option value="annual_filling">Annual Fillings</option>

                    </select>

                </div>

                {/* Reset Button */}
                <div className="flex flex-col mt-2">
                    <button
                        onClick={() => {
                            setServiceName('');
                            setFrequency('');
                            setType('');
                        }}
                        type="button"
                        className="bg-success mt-4 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-200 ease-in-out"
                    >
                        Reset
                    </button>
                </div>
            </div>
            <table
                data-tw-merge=""
                className="w-full text-left border-b border-slate-200/60"
            >
                <TableHeader headings={companyHeadings} />
                <tbody>
                    {filteredData && filteredData.length > 0 ? (
                        filteredData.map((row, index) => (
                            <tr key={row.id} data-tw-merge="" className="[&_td]:last:border-b-0">
                                <td
                                    data-tw-merge=""
                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"

                                >
                                    {index + 1}
                                </td>
                                <td
                                    data-tw-merge=""
                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                                    style={{ width: '20% !important' }}
                                >
                                    <div className="flex">
                                        <div>
                                            <a className="whitespace-wrap font-medium w-60" href="#0">
                                                {row.TaxService?.service_name}
                                            </a>
                                            <div className="mt-0.5 whitespace-wrap text-xs text-slate-500">
                                                {row.session}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td
                                    data-tw-merge=""
                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                                >
                                    <a className="whitespace-nowrap font-medium" href="#0">
                                        {row.TaxService?.frequency}
                                    </a>
                                    <div className="mt-0.5 whitespace-wrap text-xs text-slate-500">
                                        {row.TaxService && getFrequencyText(row.TaxService)}
                                    </div>
                                </td>
                                <td
                                    data-tw-merge=""
                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                                >
                                    <a className="whitespace-nowrap font-medium" href="#0">
                                        {row.type === 'return_filling' ? "Periodic Fillings" : "Annual Fillings"}
                                    </a>
                                </td>

                                <td
                                    data-tw-merge=""
                                    className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                                >
                                    <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                        {row.branch_name || ''}
                                    </div>
                                </td>
                                <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 relative border-dashed py-4 dark:bg-darkmode-600">
                                    <div className="flex">
                                        <Link onClick={handleDelete({ id: row.id, type: 'branch' })}>
                                            <Delete />
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="px-5 py-4 text-center">No reports available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default BranchScheduleReportTable;
