import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';

// Define the initial state
const initialState = {
  branchUsers: [],
  status: 'idle', // idle | loading | succeeded | failed
  error: null,
};

// Async thunk to fetch all branch users
export const fetchBranchUsers = createAsyncThunk(
  'branchUsers/fetchBranchUsers',
  async (branch_id) => {
    const response = await axios.get(`${API_URL}/branch/users/${branch_id}`);
    return response.data;
  }
);

// Async thunk to add a new branch user
export const addBranchUser = createAsyncThunk(
  'branchUsers/addBranchUser',
  async ({ branch_id, formData }) => {
    const response = await axios.post(`${API_URL}/branch/users/add/${branch_id}`, formData);
    return response.data;
  }
);

// Async thunk to update an existing branch user
export const updateBranchUser = createAsyncThunk(
  'branchUsers/updateBranchUser',
  async ({ id, updatedUser }) => {
    const response = await axios.put(`${API_URL}/branch/users/update/${id}`, updatedUser);
    return response.data;
  }
);

// Async thunk to delete a branch user
export const deleteBranchUser = createAsyncThunk(
  'branchUsers/deleteBranchUser',
  async (id) => {
    await axios.delete(`${API_URL}/branch/users/delete/${id}`);
    return id;
  }
);

export const fetchSingleBranchUser = createAsyncThunk(
  'branchUsers/fetchSingleBranchUser',
  async (id) => {
    const response = await axios.get(`${API_URL}/branch/users/single/${id}`);
    return response.data;
  }
);


export const branchUserSlice = createSlice({
  name: 'branchUsers',
  initialState,
  reducers: {
    // You can add additional non-async reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      // Fetch branch users
      .addCase(fetchBranchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBranchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.branchUsers = action.payload;
      })
      .addCase(fetchBranchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Add a new branch user
      .addCase(addBranchUser.fulfilled, (state, action) => {
        state.branchUsers.push(action.payload);
      })

      // Update an existing branch user
      .addCase(updateBranchUser.fulfilled, (state, action) => {
        const index = state.branchUsers.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.branchUsers[index] = action.payload;
        }
      })

      // Delete a branch user
      .addCase(deleteBranchUser.fulfilled, (state, action) => {
        state.branchUsers = state.branchUsers.filter(user => user.id !== action.payload);
      })
      .addCase(fetchSingleBranchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleBranchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Optional: Handle if you want to replace or append the fetched user
        // Example: Replacing the existing user in the state
        const index = state.branchUsers.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.branchUsers[index] = action.payload;
        } else {
          state.branchUsers.push(action.payload);
        }
      })
      .addCase(fetchSingleBranchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Export the async thunks to use them in components
// export { fetchBranchUsers, addBranchUser, updateBranchUser, deleteBranchUser };

// Export the reducer to be included in the store
export default branchUserSlice.reducer;
