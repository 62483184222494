import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { signout } from "../../../../features/auth/companyAuthSlice";
import { checkAuthType } from "../../../../actions/authAction";
import { Link } from "react-router-dom";
import { API_URL } from "../../../../utils/api_const";


const Header = () => {

    const dispatch = useDispatch();
    const [storageData, setStorageData] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    const handleSignoutClick = () => {
        dispatch(signout());
    };


    // Function to fetch storage data
    const fetchStorageData = async () => {
        let login_type = localStorage.getItem('login_type');
        const company = JSON.parse(localStorage.getItem('company'));
        if(company){
            try {
                let companyId = 0;
                if(login_type == 'company'){
                    companyId = company.id;
                }else{
                    companyId = company.company_id;
                }
                const response = await fetch(`${API_URL}/companies/storage-data/${companyId}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch storage data");
                }
                const data = await response.json();
                setStorageData(data);  
            } catch (err) {
                console.log(err);
            }
        }
    };

    // UseEffect to call the fetchStorageData function when component mounts
    useEffect(() => {
        fetchStorageData();
        const intervalId = setInterval(() => {
            fetchStorageData();
        }, 10000); // 5 minutes
    
        // Cleanup the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    const   calculateStorage = () => {
        const parseStorageSize = (storageString) => {
            if (!storageString || typeof storageString !== 'string') {
                return 0; // Return 0 if it's undefined or not a valid string
            }
            const [value, unit] = storageString.split(' '); // Split the value and unit
            const size = parseFloat(value); // Convert the numeric part to a float
    
            // Convert to MB based on the unit
            switch (unit.toUpperCase()) {
                case 'TB':
                    return size * 1024 * 1024; // TB to MB
                case 'GB':
                    return size * 1024; // GB to MB
                case 'MB':
                default:
                    return size; // Already in MB
            }
        };
        const usedStorageMB = parseStorageSize(storageData?.usedStorage); // Used Storage in MB
        const totalStorageMB = parseStorageSize(storageData?.availableStorage); // Total Storage in MB
        const usedPercentage = (usedStorageMB / totalStorageMB) * 100; // Percentage
        return usedPercentage;
    }

    console.log('storage user dand ata ', storageData);

    return (
        <div className="fixed inset-x-0 top-0 mt-3.5 h-[65px] transition-[margin] duration-100 xl:ml-[275px] group-[.side-menu--collapsed]:xl:ml-[90px]">
            <div className="top-bar absolute left-0 xl:left-3.5 right-0 h-full mx-5 group before:content-[''] before:absolute before:top-0 before:inset-x-0 before:-mt-[15px] before:h-[20px] before:backdrop-blur">
                <div className="box group-[.top-bar--active]:box container flex h-full w-full items-center border-transparent bg-transparent shadow-none transition-[padding,background-color,border-color] duration-300 ease-in-out group-[.top-bar--active]:border-transparent group-[.top-bar--active]:bg-transparent group-[.top-bar--active]:bg-gradient-to-r group-[.top-bar--active]:from-theme-1 group-[.top-bar--active]:to-theme-2 group-[.top-bar--active]:px-5">
                    <div className="flex items-center gap-1 xl:hidden">
                        <a
                            className="open-mobile-menu rounded-full p-2 text-white hover:bg-white/5"
                            href="#0"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-menu"><line x1="4" x2="20" y1="12" y2="12" /><line x1="4" x2="20" y1="6" y2="6" /><line x1="4" x2="20" y1="18" y2="18" /></svg>
                        </a>
                        {/* <a
                            className="rounded-full p-2 text-white hover:bg-white/5"
                            data-tw-toggle="modal"
                            data-tw-target="#quick-search"
                            href="#0"
                        >
                            <i
                                data-tw-merge=""
                                data-lucide="search"
                                className="stroke-[1] h-[18px] w-[18px]"
                            />
                        </a> */}
                    </div>
                    {/* BEGIN: Breadcrumb */}
                    <nav aria-label="breadcrumb" className="flex hidden flex-1 xl:block">
                        <ol className="flex items-center text-theme-1 dark:text-slate-300 text-white/90">
                            <li className="">
                                <a href="">TAXGEM</a>
                            </li>

                        </ol>
                    </nav>

                    <div className="flex flex-1 items-center">
                        <div className="ml-auto flex items-center gap-1">
                            <div className="flex hidden flex-1 xl:block sm:block xs:block">
                                <span className="text-white">Used: {storageData?.usedStorage}</span>

                                {/* Progress bar representing storage used */}
                                <div className="relative w-48 h-4 bg-white rounded-lg overflow-hidden">
                                    <div className="absolute top-0 left-0 h-full bg-success" data-check={calculateStorage()} style={{ width: `${calculateStorage()}%` }}></div>
                                </div>

                                {/* Storage Remaining */}
                                <span className="text-white">Total Storage: {storageData?.availableStorage}</span>

                            </div>
                            {checkAuthType() == 'company' && (

                                <Link to={`/company/storages`} className="ml-3 cursor-pointer overflow-hidden rounded-full border-[3px] border-white text-white p-2">
                                    Upgrade Storage
                                </Link>
                            )}
                        </div>
                        <div
                            className="dropdown relative ml-5"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <button
                                aria-expanded={isDropdownOpen}
                                className="cursor-pointer image-fit h-[36px] w-[36px] overflow-hidden rounded-full border-[3px] border-white/[0.15]"
                            >
                                {JSON.parse(localStorage.getItem('company')).profile_image != null ? (
                                    <img
                                        src={`${API_URL}/${JSON.parse(localStorage.getItem('company')).profile_image}`}
                                        alt="Profile Image"
                                    />
                                ) : (
                                    <img
                                        src={`/logo_ne.png`}
                                        alt="Profile Image"
                                    />
                                )}
                            </button>

                            {isDropdownOpen && (
                                <div className="dropdown-menu absolute z-[9999] mt-0 right-0 w-56 rounded-md border-transparent bg-white p-2 shadow-[0px_3px_10px_#00000017] dark:border-transparent dark:bg-darkmode-600">
                                    <Link
                                        to={`${checkAuthType()}/profile/${JSON.parse(localStorage.getItem('company')).id}`}
                                        className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                                    >
                                        <i className="stroke-[1] mr-2 h-4 w-4" />
                                        Profile
                                    </Link>
                                    <div className="h-px my-2 -mx-2 bg-slate-200/60 dark:bg-darkmode-400"></div>
                                    {checkAuthType() == 'company' && (<Link
                                        to={`company/fillings/${JSON.parse(localStorage.getItem('company')).id}`}
                                        className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                                    >
                                        <i className="stroke-[1] mr-2 h-4 w-4" />
                                        Fillings
                                    </Link>)}
                                    <div className="h-px my-2 -mx-2 bg-slate-200/60 dark:bg-darkmode-400"></div>
                                    {checkAuthType() == 'company' && (
                                        <Link
                                            to={`company/subscription?isrenew=all`}
                                            className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                                        >
                                            <i className="stroke-[1] mr-2 h-4 w-4" />
                                            Subscription
                                        </Link>
                                    )}
                                    <div className="h-px my-2 -mx-2 bg-slate-200/60 dark:bg-darkmode-400"></div>
                                    {checkAuthType() == 'company' && (<Link
                                        to={`company/edit/step2/${JSON.parse(localStorage.getItem('company')).id}`}
                                        className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                                    >
                                        <i className="stroke-[1] mr-2 h-4 w-4" />
                                        Update Assessing Officer Details
                                    </Link>)}
                                    <div className="h-px my-2 -mx-2 bg-slate-200/60 dark:bg-darkmode-400"></div>
                                    {checkAuthType() == 'company' && (<Link
                                        to={`company/edit/step3/${JSON.parse(localStorage.getItem('company')).id}`}
                                        className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                                    >
                                        <i className="stroke-[1] mr-2 h-4 w-4" />
                                        Update Bank & Directors
                                    </Link>)}
                                    {checkAuthType() == 'company' && (<div className="h-px my-2 -mx-2 bg-slate-200/60 dark:bg-darkmode-400"></div>)}
                                    <a
                                        onClick={handleSignoutClick}
                                        className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item"
                                    >
                                        <i className="stroke-[1] mr-2 h-4 w-4" />
                                        Logout
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Header;