

import React, { useEffect, useState } from "react";
import ScheduleReportTable from "./partials/schedule_table";
import { Link } from "react-router-dom";
import BranchScheduleReportTable from "./partials/branch_schedule_table";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranchesByCompanyId } from "../../features/branchSlice";
import { checkAuthType } from "../../actions/authAction";

const BranchScheduleReport = () => {
    const dispatch = useDispatch();
    const { branches, status, error } = useSelector((state) => state. branches);
    const [selectedBranch, setSelectedBranchData] = useState('');

    useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        if(company){
            const companyId = company.id;
            dispatch(fetchBranchesByCompanyId(companyId));
        }
    }, [dispatch]);

    const handleChange = (e) => {
        setSelectedBranchData(e.target.value);
    }


    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Schedule Branch Reports
                        </div>
                        
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            {checkAuthType() == 'company'?<div className="relative">
                                <span className="text-white font-bold mr-2">Branches </span>
                                <select
                                    onChange={handleChange}
                                    value={selectedBranch}
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 rounded-[0.5rem] pl-9 sm:w-44"
                                >
                                    <option value="">Select Branch</option>
                                    {branches.map((branch, index) => (
                                        <option key={index} value={branch.id}>
                                            {branch.branch_name}
                                        </option>
                                    ))}
                                </select>
                            </div>:''}
                            <Link
                                to="/company/branch/schedule/add"
                                data-tw-merge=""
                                className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200"
                            >
                                <i
                                    data-tw-merge=""
                                    data-lucide="pen-line"
                                    className="mr-2 h-4 w-4 stroke-[1.3]"
                                />
                                Add New
                            </Link>
                        </div>
                    </div>
                    <div className="mt-3.5 flex flex-col gap-8">
                        
                        <div className="box box--stacked flex flex-col">
                            <div className="flex flex-col gap-y-2 p-5 sm:flex-row sm:items-center">
                            
                            </div>
                            <div className="overflow-auto xl:overflow-visible">
                               <BranchScheduleReportTable  branchesData={selectedBranch} />
                            </div>
                            <div className="flex-reverse flex flex-col-reverse flex-wrap items-center gap-y-2 p-5 sm:flex-row">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BranchScheduleReport;