// src/features/branches/branchSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';

export const fetchBranches = createAsyncThunk('branches/fetchBranches', async () => {
  const response = await axios.get(`${API_URL}/branches`);
  return response.data;
});

export const fetchBranchesByCompanyId = createAsyncThunk('branches/fetchBranchesByCompanyId', async (companyId) => {
  const response = await axios.get(`${API_URL}/branches`, { params: { companyId } });
  return response.data;
});

export const addBranch = createAsyncThunk('branches/addBranch', async (branch) => {
  const response = await axios.post(`${API_URL}/branches/add`, branch);
  return response.data;
});

export const updateBranch = createAsyncThunk('branches/updateBranch', async ({id,branch}) => {
  console.log(branch)
  const response = await axios.put(`${API_URL}/branches/update/${id}`, branch);
  return response.data;
});

export const deleteBranch = createAsyncThunk('branches/deleteBranch', async (id) => {
  await axios.delete(`${API_URL}/branches/delete/${id}`);
  return id;
});

const branchSlice = createSlice({
  name: 'branches',
  initialState: {
    branches: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBranches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBranches.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.branches = action.payload;
      })
      .addCase(fetchBranches.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchBranchesByCompanyId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBranchesByCompanyId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.branches = action.payload;
      })
      .addCase(fetchBranchesByCompanyId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addBranch.fulfilled, (state, action) => {
        state.branches.push(action.payload);
      })
      .addCase(updateBranch.fulfilled, (state, action) => {
        const index = state.branches.findIndex(branch => branch.id === action.payload.id);
        state.branches[index] = action.payload;
      })
      .addCase(deleteBranch.fulfilled, (state, action) => {
        state.branches = state.branches.filter(branch => branch.id !== action.payload);
      });
  }
});

export default branchSlice.reducer;
