import React, { useEffect } from "react";
import CompanyTable from "./partials/company_table";
import { Link, useNavigate } from "react-router-dom";
import { fetchCompanyCounts, getAllCompanyData } from "../../features/companySlice";
import { useDispatch, useSelector } from "react-redux";


const Companies = () => {
    const { totalCompanies, activeCompanies, inactiveCompanies, status, error } = useSelector((state) => state.companies);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCompanyCounts());
    }, [dispatch]);

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col gap-y-3 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Companies
                        </div>
                        <div className="flex flex-col gap-x-3 gap-y-2 sm:flex-row md:ml-auto">
                            <Link
                                to="/companies/add"
                                data-tw-merge=""
                                className="transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary group-[.mode--light]:!border-transparent group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200"
                            >
                                <i
                                    data-tw-merge=""
                                    data-lucide="pen-line"
                                    className="mr-2 h-4 w-4 stroke-[1.3]"
                                />
                                Add New Company
                            </Link>
                        </div>
                    </div>
                    <div className="mt-3.5 flex flex-col gap-8">
                        <div className="box box--stacked flex flex-col p-5">
                            <div className="grid grid-cols-4 gap-5">
                                <div className="box col-span-4 rounded-[0.6rem] border border-dashed border-slate-300/80 p-5 shadow-sm md:col-span-1 xl:col-span-1">
                                    <div className="text-base text-slate-500">Registered Companies</div>
                                    <div className="mt-1.5 text-2xl font-medium">{totalCompanies}</div>

                                </div>
                                <div className="box col-span-4 rounded-[0.6rem] border border-dashed border-slate-300/80 p-5 shadow-sm md:col-span-1 xl:col-span-1">
                                    <div className="text-base text-slate-500">Active Companies</div>
                                    <div className="mt-1.5 text-2xl font-medium">{activeCompanies}</div>

                                </div>
                                <div className="box col-span-4 rounded-[0.6rem] border border-dashed border-slate-300/80 p-5 shadow-sm md:col-span-1 xl:col-span-1">
                                    <div className="text-base text-slate-500">New Companies</div>
                                    <div className="font-mediumm mt-1.5 text-2xl">{inactiveCompanies}</div>

                                </div>
                            
                            </div>
                        </div>
                        <div className="box box--stacked flex flex-col">
                            {/* <div className="flex flex-col gap-y-2 p-5 sm:flex-row sm:items-center">
                                <div>
                                    <div className="relative">
                                        <i
                                            data-tw-merge=""
                                            data-lucide="search"
                                            className="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4 stroke-[1.3] text-slate-500"
                                        />
                                        <input
                                            data-tw-merge=""
                                            type="text"
                                            placeholder="Search users..."
                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 rounded-[0.5rem] pl-9 sm:w-64"
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="overflow-auto">
                                <CompanyTable />
                            </div>
                            <div className="flex-reverse flex flex-col-reverse flex-wrap items-center gap-y-2 p-5 sm:flex-row">
                                {/* <nav className="mr-auto w-full flex-1 sm:w-auto">
                                    <ul className="flex w-full mr-0 sm:mr-auto sm:w-auto">
                                        
                                        <li className="flex-1 sm:flex-initial">
                                            <a
                                                data-tw-merge=""
                                                className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3 !box dark:bg-darkmode-400"
                                            >
                                                1
                                            </a>
                                        </li>
                                        <li className="flex-1 sm:flex-initial">
                                            <a
                                                data-tw-merge=""
                                                className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                            >
                                                2
                                            </a>
                                        </li>
                                        <li className="flex-1 sm:flex-initial">
                                            <a
                                                data-tw-merge=""
                                                className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                            >
                                                3
                                            </a>
                                        </li>
                                        <li className="flex-1 sm:flex-initial">
                                            <a
                                                data-tw-merge=""
                                                className="transition duration-200 border items-center justify-center py-2 rounded-md cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed min-w-0 sm:min-w-[40px] shadow-none font-normal flex border-transparent text-slate-800 sm:mr-2 dark:text-slate-300 px-1 sm:px-3"
                                            >
                                                <i
                                                    data-tw-merge=""
                                                    data-lucide="chevrons-right"
                                                    className="stroke-[1] h-4 w-4"
                                                />
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <select
                                    data-tw-merge=""
                                    className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1 rounded-[0.5rem] sm:w-20"
                                >
                                    <option>10</option>
                                    <option>25</option>
                                    <option>35</option>
                                    <option>50</option>
                                </select> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Companies;