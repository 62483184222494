import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addTaxService } from "../../features/taxServiceSlice";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';


const AddService = () => {
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const [service_type, setServiceType] = useState('');
    const [service_name, setServiceName] = useState('');
    const [frequency, setFrequency] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [start_from, setStartFrom] = useState('');
    const [isSection, setIsSection] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);


    const handleFrequencyChange = (e) => {
        const value = e.target.value;
        setFrequency(value);
        setSelectedDay('');
        setSelectedDate(null);
        setStartFrom('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const dayFinal = frequency === 'weekly' ? selectedDay : selectedDate;
        console.log({ service_type, service_name, frequency, day: dayFinal, isSection });

        try {
            const resultAction = await dispatch(addTaxService({ service_type, service_name, frequency, day: dayFinal,start_from, isSection})).unwrap();
            if (resultAction) {
                if (service_type == 'return_filling') {
                    navigator('/tax-service/return-filling');
                } else {
                    navigator('/tax-service/annual-filling');
                }

            }
        } catch (error) {
            console.error('Failed to create tax service:', error);
        }
    };

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Add Tax Service
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col p-5 box box--stacked">
                                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">

                                        <div className="mt-5">
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Select Service Type</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Tax Service Type.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <select
                                                        data-tw-merge=""
                                                        id="category"
                                                        name="service_type"
                                                        onChange={(e) => setServiceType(e.target.value)}
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                                    >
                                                        <option value=""> -- Select --</option>
                                                        <option value="return_filling">Periodic Filling</option>
                                                        <option value="annual_filling">Annual Filling</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Service Name</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Enter the unique service name.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="service_name"
                                                        onChange={(e) => setServiceName(e.target.value)}
                                                        placeholder="Service Name"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Frequency</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Service Frequency type
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">

                                                    <select
                                                        data-tw-merge=""
                                                        name="frequency"
                                                        onChange={handleFrequencyChange}
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                                    >
                                                        <option value=""> -- Select --</option>
                                                        <option value="weekly">Weekly (Occurring once a week)</option>
                                                        <option value="monthly">Monthly (Occurring once a month)</option>
                                                        <option value="quarterly">Quarterly (Occurring once every three months)</option>
                                                        <option value="semi-annually">Semi-Annually (Occurring once every six months)</option>
                                                        <option value="annually">Annually (Occurring once a year)</option>
                                                        <option value="triennially">Triennially (Occurring once every three years)</option>
                                                    </select>
                                                </div>
                                                
                                            </div>
                                            {frequency === 'weekly' && (
                                                <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                    <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                        <div className="text-left">
                                                            <div className="flex items-center">
                                                                <div className="font-medium">Select Day</div>
                                                                <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                    Required
                                                                </div>
                                                            </div>
                                                            <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                                Select Service Frequency Day
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex-1 w-full mt-3 xl:mt-0">

                                                        <select
                                                            name="day"
                                                            onChange={(e) => setSelectedDay(e.target.value)}
                                                            value={selectedDay}
                                                            className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 mt-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50"
                                                        >
                                                            <option value=""> -- Select Day --</option>
                                                            <option value="monday">Monday</option>
                                                            <option value="tuesday">Tuesday</option>
                                                            <option value="wednesday">Wednesday</option>
                                                            <option value="thursday">Thursday</option>
                                                            <option value="friday">Friday</option>
                                                            <option value="saturday">Saturday</option>
                                                            <option value="sunday">Sunday</option>
                                                        </select>

                                                    </div>


                                                </div>
                                            )}
                                            {frequency !== 'weekly' && frequency !== '' && (
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Select Date</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Service Frequency Date
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div className="flex-1 w-full mt-3 xl:mt-0">

                                                {frequency && (frequency === 'monthly') && (
                                                    <select
                                                    name="date"
                                                    onChange={(e) => setSelectedDate(e.target.value)}
                                                    value={selectedDate}
                                                    className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 mt-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50"
                                                    >
                                                    <option value=""> -- Select Date --</option>
                                                    {Array.from({ length: 31 }, (_, i) => (
                                                        <option key={i} value={i + 1}>
                                                        {i + 1}
                                                        </option>
                                                    ))}
                                                    </select>
                                                )}
                                                {frequency && (frequency === 'quarterly' || frequency === 'semi-annually') && (
                                                    <>
                                                        <select
                                                        name="date"
                                                        onChange={(e) => setSelectedDate(e.target.value)}
                                                        value={selectedDate}
                                                        className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 mt-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50"
                                                        >
                                                        <option value=""> -- Select Date --</option>
                                                        {Array.from({ length: 31 }, (_, i) => (
                                                            <option key={i} value={i + 1}>
                                                            {i + 1}
                                                            </option>
                                                        ))}
                                                        </select>
                                                        <select
                                                        name="start_from"
                                                        onChange={(e) => setStartFrom(e.target.value)}
                                                        value={start_from}
                                                        selected={start_from}
                                                        className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 mt-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50"
                                                        >
                                                            <option value=""> --- Start From --- </option>
                                                            <option value="january">January</option>
                                                            {frequency == 'quarterly' && <option value="march">March</option>}
                                                            {frequency == 'semi-annually' && <option value="june">June</option>}
                                                            {frequency == 'semi-annually' && <option value="april">April</option>}
                                                            {frequency == 'semi-annually' && <option value="october">October</option>}
                                                        </select>
                                                    </>
                                                )}
                                                {frequency && (frequency === 'annually' || frequency === 'triennially') && (
                                                    <DatePicker
                                                    selected={selectedDate}
                                                    onChange={(date) => setSelectedDate(date)}
                                                    className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 mt-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50"
                                                    dateFormat="dd/MM"
                                                    placeholderText="Select a date"
                                                    />
                                                )}
                                                </div>
                                               
                                                
                                            </div>
                                            )}

<div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Is Section</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Select Is Sections
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div className="flex-1 w-full mt-3 xl:mt-0">

                                                
                                                        <select
                                                            name="isSection"
                                                            selected={isSection}
                                                            onChange={(e) => setIsSection(e.target.value)}
                                                            value={selectedDay}
                                                            className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 mt-2 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50"
                                                        >
                                                            <option value="0">No</option>
                                                            <option value="1">Yes</option>
                                                        </select>
                                                </div>
                                               
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">

                                    <button
                                        type="submit"
                                        data-tw-merge=""
                                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                                    >
                                        <i
                                            data-tw-merge=""
                                            data-lucide="pen-line"
                                            className="mr-2 h-4 w-4 stroke-[1.3]"
                                        />
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddService;