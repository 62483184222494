// actions/authAction.js
export const checkAuthStatus = () => (dispatch) => {
    // const loginType = localStorage.getItem('login_type');
    const user = localStorage.getItem('user');
    if (user) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: JSON.parse(user) });
    } else {
      dispatch({ type: 'LOGOUT' });
    }
    
};

export const checkAuthType = () => {
  const logintype = localStorage.getItem('login_type');
  return logintype;
}


export const RAZORPAY_KEY_ID = 'rzp_live_zj3Sn8rfgLZkkj';
// export const RAZORPAY_KEY_ID = 'rzp_test_gpQYAcJdnQWHaZ';
// export const RAZORPAY_KEY_ID = 'rzp_test_9jjChZEd1Arzwz';
