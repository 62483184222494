// src/features/subscriptionSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../utils/api_const';

const initialState = {
    plans: [],
    storageSubscriptions: [],
    singlePlan: null,
    loading: false,
    error: null,
};

// Async Thunks for CRUD operations
export const fetchPlans = createAsyncThunk('subscriptions/fetchPlans', async () => {
    const response = await axios.get(`${API_URL}/subscription/plan`);
    return response.data;
});

export const fetchSinglePlan = createAsyncThunk('subscriptions/fetchSinglePlan', async (id) => {
    const response = await axios.get(`${API_URL}/subscription/plan/single/${id}`);
    return response.data;
});

export const addPlan = createAsyncThunk('subscriptions/addPlan', async (plan) => {
    const response = await axios.post(`${API_URL}/subscription/plan/add`, plan);
    return response.data;
});

export const updatePlan = createAsyncThunk('subscriptions/updatePlan', async ({ id, plan }) => {
    const response = await axios.put(`${API_URL}/subscription/plan/update/${id}`, plan);
    return response.data;
});

export const deletePlan = createAsyncThunk('subscriptions/deletePlan', async (id) => {
    await axios.delete(`${API_URL}/subscription/plan/delete/${id}`);
    return id;
});

// Storage Subscription Thunks
export const fetchStorageSubscriptions = createAsyncThunk('subscriptions/fetchStorageSubscriptions', async () => {
    const response = await axios.get(`${API_URL}/subscription/storage`);
    return response.data;
});

export const addStorageSubscription = createAsyncThunk('subscriptions/addStorageSubscription', async (storage) => {
    const response = await axios.post(`${API_URL}/subscription/storage/add`, storage);
    return response.data;
});

export const updateStorageSubscription = createAsyncThunk('subscriptions/updateStorageSubscription', async ({ id, storage }) => {
    const response = await axios.put(`${API_URL}/subscription/storage/update/${id}`, storage);
    return response.data;
});

export const deleteStorageSubscription = createAsyncThunk('subscriptions/deleteStorageSubscription', async (id) => {
    await axios.delete(`${API_URL}/subscription/storage/delete/${id}`);
    return id;
});

// Subscription Slice
const subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPlans.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPlans.fulfilled, (state, action) => {
                state.loading = false;
                state.plans = action.payload;
            })
            .addCase(fetchPlans.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchSinglePlan.fulfilled, (state, action) => {
                state.singlePlan = action.payload;
            })
            .addCase(addPlan.fulfilled, (state, action) => {
                state.plans.push(action.payload);
            })
            .addCase(updatePlan.fulfilled, (state, action) => {
                const index = state.plans.findIndex((plan) => plan.id === action.payload.id);
                if (index !== -1) {
                    state.plans[index] = action.payload;
                }
            })
            .addCase(deletePlan.fulfilled, (state, action) => {
                state.plans = state.plans.filter((plan) => plan.id !== action.payload);
            })
            .addCase(fetchStorageSubscriptions.fulfilled, (state, action) => {
                state.storageSubscriptions = action.payload.data;
            })
            .addCase(addStorageSubscription.fulfilled, (state, action) => {
                state.storageSubscriptions.push(action.payload);
            })
            .addCase(updateStorageSubscription.fulfilled, (state, action) => {
                const index = state.storageSubscriptions.findIndex((storage) => storage.id === action.payload.id);
                if (index !== -1) {
                    state.storageSubscriptions[index] = action.payload;
                }
            })
            .addCase(deleteStorageSubscription.fulfilled, (state, action) => {
                state.storageSubscriptions = state.storageSubscriptions.filter((storage) => storage.id !== action.payload);
            });
    },
});

// Export actions if needed
export const {} = subscriptionSlice.actions;

// Export reducer
export default subscriptionSlice.reducer;
