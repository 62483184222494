const generateFiscalYears = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const startYear = 2019;
    const years = [];
  
    for (let year = startYear; year <= currentYear; year++) {
      years.push(`${year}-${year + 1}`);
    }
  
    // if (currentMonth > 2) { // If current month is after March (index 2)
    //   years.push(`${currentYear}-${currentYear + 1}`);
    // }
  
    return years;
};


export default generateFiscalYears;