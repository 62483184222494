import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { clearMessage, login } from "../../features/auth/authSlice";
import { clearMessage, companyLogin } from "../../features/auth/companyAuthSlice";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CompanyLogin = () => {

    const [formData, setFormData] = useState({ email: '', password: '' });
    const dispatch = useDispatch();
    const { company, loading, error, message } = useSelector((state) => state.companyAuth);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const result = dispatch(companyLogin(formData));
        try {
            const result = await dispatch(companyLogin(formData)).unwrap();
            toast.success('Login Successfully');
            // navigator(`/dashboard`);
    
        } catch (error) {
            // Handle errors here
            if (error.status === 403) {
                toast.error('Your account is under review. Please wait for approval.');
            } else {
                toast.error('Login failed. Please check your credentials.');
            }
        }
       
    };

    
    useEffect(() => {
        if (company) {
            navigate('/company/dashboard');
        }
    }, [company, navigate]);

    useEffect(() => {
        if (error || message) {
        setTimeout(() => {
            dispatch(clearMessage());
        }, 3000); // Clear message after 3 seconds
        }
    }, [error, message, dispatch]);

    return (
        <div>
            <ToastContainer/>
            <div className="container grid grid-cols-12 px-5 py-10 sm:px-10 sm:py-14 md:px-36 lg:h-screen lg:max-w-[1550px] lg:py-0 lg:pl-14 lg:pr-12 xl:px-24 2xl:max-w-[1750px]">
                <div className="relative z-50 h-full col-span-12 p-7 sm:p-14 bg-white rounded-2xl lg:bg-transparent lg:pr-10 lg:col-span-5 xl:pr-24 2xl:col-span-4 lg:p-0 before:content-[''] before:absolute before:inset-0 before:-mb-3.5 before:bg-white/40 before:rounded-2xl before:mx-5">
                    <div className="relative z-10 flex flex-col justify-center w-full h-full py-2 lg:py-32">
                        <div className="flex h-[55px] w-[55px] items-center justify-center rounded-[0.8rem] border border-primary/30">
                            {/* <div className="relative flex h-[50px] w-[50px] items-center justify-center rounded-[0.6rem] bg-white bg-gradient-to-b from-theme-1/90 to-theme-2/90">
                                <div className="relative h-[26px] w-[26px] -rotate-45 [&_div]:bg-white">
                                    <div className="absolute inset-y-0 left-0 my-auto h-[75%] w-[20%] rounded-full opacity-50" />
                                    <div className="absolute inset-0 m-auto h-[120%] w-[20%] rounded-full" />
                                    <div className="absolute inset-y-0 right-0 my-auto h-[75%] w-[20%] rounded-full opacity-50" />
                                </div>
                            </div> */}
                            <img data-placement="top" src="/logo_ne.png" alt="TaxGem"/>
                        </div>
                        <div className="mt-10">
                            <div className="text-2xl font-medium">Sign In</div>
                            {/* <div className="mt-2.5 text-slate-600">
                                Don't have an account?
                                <a className="font-medium text-primary" href="#">
                                    Sign Up
                                </a>
                            </div> */}
                            {/* <div role="alert" className="alert relative border rounded-md px-5 py-4 border-primary text-primary dark:border-primary my-7 flex items-center rounded-[0.6rem] border-primary/20 bg-primary/5 px-4 py-3 leading-[1.7]">
                                <div className="">
                                    <i data-tw-merge data-lucide="lightbulb" className="mr-2 h-7 w-7 fill-primary/10 stroke-[0.8]" />
                                </div>
                                <div className="ml-1 mr-8">
                                    Welcome to <span className="font-medium">TaxGem</span>
                                    demo! Simply click
                                    <span className="font-medium">Sign In</span> to explore
                                    and access our documentation.
                                </div>
                                <button data-tw-merge data-tw-dismiss="alert" type="button" aria-label="Close" className="py-2 px-3 absolute right-0 my-auto mr-2 btn-close text-primary"><i data-tw-merge data-lucide="x" className="stroke-[1] w-5 h-5" /></button>
                            </div> */}
                            <div className="mt-6">
                                <form onSubmit={handleSubmit}>
                                    <label data-tw-merge className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                        Email*
                                    </label>
                                    <input name="email" type="email" value={formData.email} onChange={handleChange} data-tw-merge  placeholder="meryl.streep@left4code.com" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 block rounded-[0.6rem] border-slate-300/80 px-4 py-3.5" />
                                    <label data-tw-merge className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right mt-4">
                                        Password*
                                    </label>
                                    <input type="password" name="password" value={formData.password} onChange={handleChange} data-tw-merge placeholder="************" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 block rounded-[0.6rem] border-slate-300/80 px-4 py-3.5" />
                                    {/* <div className="flex mt-4 text-xs text-slate-500 sm:text-sm">
                                        <div className="flex items-center mr-auto">
                                            <input data-tw-merge type="checkbox" className="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-20 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&[type='radio']]:checked:bg-primary [&[type='radio']]:checked:border-primary [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary [&[type='checkbox']]:checked:border-primary [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:not(:checked)]:dark:bg-darkmode-800/50 [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed [&:disabled:checked]:dark:bg-darkmode-800/50 mr-2.5 border" id="remember-me" />
                                            <label className="cursor-pointer select-none" htmlFor="remember-me">
                                                Remember me
                                            </label>
                                        </div>
                                        <a href="#">Forgot Password?</a>
                                    </div> */}
                                    <div className="mt-5 text-center xl:mt-8 xl:text-left">
                                        <button data-tw-merge className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary rounded-full w-full bg-gradient-to-r from-theme-1/70 to-theme-2/70 py-3.5 xl:mr-3" disabled={loading}>{loading ? 'Logging in...' : 'Sign In'}</button>
                                        {/* <button data-tw-merge className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 rounded-full mt-3 w-full bg-white/70 py-3.5">Sign Up</button> */}
                                    </div>
                                    <div className="mt-5 text-center xl:mt-8">
                                        Register here? <Link to="/plans" className="text-center">click here</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container fixed inset-0 grid h-screen w-screen grid-cols-12 pl-14 pr-12 lg:max-w-[1550px] xl:px-24 2xl:max-w-[1750px]">
                <div className="relative h-screen col-span-12 lg:col-span-5 2xl:col-span-4 z-20 after:bg-white after:hidden after:lg:block after:content-[''] after:absolute after:right-0 after:inset-y-0 after:bg-gradient-to-b after:from-white after:to-slate-100/80 after:w-[800%] after:rounded-[0_1.2rem_1.2rem_0/0_1.7rem_1.7rem_0] before:content-[''] before:hidden before:lg:block before:absolute before:right-0 before:inset-y-0 before:my-6 before:bg-gradient-to-b before:from-white/10 before:to-slate-50/10 before:bg-white/50 before:w-[800%] before:-mr-4 before:rounded-[0_1.2rem_1.2rem_0/0_1.7rem_1.7rem_0]" />
                <div className="h-full col-span-7 2xl:col-span-8 lg:relative before:content-[''] before:absolute before:lg:-ml-10 before:left-0 before:inset-y-0 before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:w-screen before:lg:w-[800%] after:content-[''] after:absolute after:inset-y-0 after:left-0 after:w-screen after:lg:w-[800%] after:bg-texture-white after:bg-fixed after:bg-center after:lg:bg-[25rem_-25rem] after:bg-no-repeat">
                    <div className="sticky top-0 z-10 flex-col justify-center hidden h-screen ml-16 lg:flex xl:ml-28 2xl:ml-36">
                        <div className="text-[2.6rem] font-medium leading-[1.4] text-white xl:text-5xl xl:leading-[1.2]">
                        <img data-placement="top" src="/logo_ne.png" alt="TaxGem" style={{ width: '150px'}}/>
                            Start your Journey with <br /> TaxGem 
                        </div>
                        <div className="mt-5 text-base leading-relaxed text-white/70 xl:text-lg">
                            Unlock the potential of TaxGem, where developers craft
                            meticulously structured, visually stunning dashboards with
                            feature-rich modules. Join us today to shape the future of your
                            application development.
                        </div>
                        <div className="flex flex-col gap-3 mt-10 xl:flex-row xl:items-center">
                            <div className="flex items-center">
                                <div className="image-fit zoom-in h-9 w-9 2xl:h-11 2xl:w-11">
                                    <img data-placement="top" title="Meryl Streep" src="/assets/images/user2-50x50.jpg" alt="TaxGem - Admin Dashboard Template" className="tooltip cursor-pointer rounded-full border-[3px] border-white/50" />
                                </div>
                                <div className="-ml-3 image-fit zoom-in h-9 w-9 2xl:h-11 2xl:w-11">
                                    <img data-placement="top" title="Cate Blanchett" src="/assets/images/user8-50x50.jpg" alt="TaxGem - Admin Dashboard Template" className="tooltip cursor-pointer rounded-full border-[3px] border-white/50" />
                                </div>
                                <div className="-ml-3 image-fit zoom-in h-9 w-9 2xl:h-11 2xl:w-11">
                                    <img data-placement="top" title="Tom Hanks" src="/assets/images/user3-50x50.jpg" alt="TaxGem - Admin Dashboard Template" className="tooltip cursor-pointer rounded-full border-[3px] border-white/50" />
                                </div>
                                <div className="-ml-3 image-fit zoom-in h-9 w-9 2xl:h-11 2xl:w-11">
                                    <img data-placement="top" title="Angelina Jolie" src="/assets/images/user4-50x50.jpg" alt="TaxGem - Admin Dashboard Template" className="tooltip cursor-pointer rounded-full border-[3px] border-white/50" />
                                </div>
                            </div>
                            <div className="text-base text-white/70 xl:ml-2 2xl:ml-3">
                                {/* Over 7k+ strong and growing! Your journey begins here. */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyLogin;