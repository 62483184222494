import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../utils/api_const";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { RAZORPAY_KEY_ID } from "../../actions/authAction";
import { fetchTaxServices } from "../../features/taxServiceSlice";
import { ToastContainer, toast } from 'react-toastify';

const SubscriptionDetails = () => {
    const [upgradeLoader, setUpgradeLoader] = useState(false);
    const [branchAvailable, setBranchAvailable] = useState(0);
    const [subscriptions, setSubscriptions] = useState([]);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const taxServices = useSelector((state) => state.taxService.taxServices);
    const dispatch = useDispatch();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isRenew = params.get('isrenew');

    useEffect(() => {
        dispatch(fetchTaxServices());
    }, [dispatch]);

    const fetchSubscriptions = async () => {
        try {
            // Replace with your API endpoint
            const response = await axios.get(`${API_URL}/subscription/plan`);
            setSubscriptions(response.data); // Assuming the data is returned directly as an array
            console.log(response);
        } catch (err) {
            console.error("Error fetching subscriptions:", err);
        }
    };

    const getCompanySubscription = async (company_id) => {
        try {
            const res = await axios.get(`${API_URL}/companies/subscription/data/${company_id}`);
            if (res.status === 200) {
                let company = JSON.parse(localStorage.getItem('company'));
                if (company) {
                    company.subscription = res.data;
                    setCurrentSubscription(res.data);
                    // Set the updated company object back into localStorage as a string
                    localStorage.setItem('company', JSON.stringify(company));
                }
                return res.data;
            }
        } catch (error) {
            console.error("Error fetching subscriptions:", error);
            return false;
        }
    }

    const loadRazorpay = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true);
            script.onerror = () => reject(false);
            document.body.appendChild(script);
        });
    };

    const upgradePlan = async (planId, planPrice) => {
        // Set the loading state to true
        setUpgradeLoader(true);

        const company = localStorage.getItem('company') ? JSON.parse(localStorage.getItem('company')) : null;

        // Check if the company data exists
        if (company) {



            try {
                const razorpayLoaded = await loadRazorpay();
                if (!razorpayLoaded) {
                    toast.error('Failed to load Razorpay SDK');
                    setUpgradeLoader(false);
                    return;
                }

                // Razorpay payment options
                const paymentOptions = {
                    key: RAZORPAY_KEY_ID, // Replace with your Razorpay Key ID
                    amount: planPrice, // Replace with the amount in the smallest currency unit (e.g., 100000 for ₹1000)
                    currency: 'INR',
                    name: 'Taxgem',
                    description: 'Upgrade Storage',
                    handler: async function (response) {
                        console.log('Payment Successful:', response);

                        // Make the API call to upgrade the subscription
                        try {
                            // Make the API call to upgrade the subscription
                            const res = await axios.post(`${API_URL}/companies/subscription/upgrade/${company.id}`, {
                                plan_id: planId,
                                payment_id: response.razorpay_payment_id,
                            });

                            // Check if the response status is 200 (success)
                            if (res.status === 200) {
                                console.log('Subscription upgraded successfully!', res.data);

                                // Simulate a delay before reloading the page to show the success state to the user
                                setTimeout(() => {
                                    setUpgradeLoader(false);  // Stop the loader
                                    window.location.reload(); // Reload the page to reflect the new subscription
                                }, 2000);
                            } else {
                                // Log an error message if the status is not 200
                                console.error('Failed to upgrade subscription', res.data);
                                setUpgradeLoader(false);  // Stop the loader on failure
                            }
                        } catch (error) {
                            // Handle any errors from the API call
                            console.error('Error upgrading subscription:', error);
                            setUpgradeLoader(false);  // Stop the loader on error
                        }
                    },
                    prefill: {
                        name: company.name, // Prefill with company name
                        email: company.email, // Prefill with company email
                    },
                    theme: {
                        color: '#5398E3', // Match your app's theme color
                    },
                };

                // Initialize Razorpay
                const rzp = new Razorpay(paymentOptions);
                rzp.on('payment.failed', function (response) {
                    console.error('Payment Failed:', response.error);
                    setUpgradeLoader(false);
                    toast.error('Payment failed. Please try again.');
                });

                // Open Razorpay modal
                rzp.open();
            } catch (error) {
                console.error('Error during payment initialization:', error);
                setUpgradeLoader(false);
                toast.error('Error initializing payment.');
            }
        } else {
            // If company data is missing from local storage
            console.error('Company data not found in localStorage');
            setUpgradeLoader(false);  // Stop the loader if company is null
        }
    };

    const convertDaysToReadableFormat = (days) => {
        if (days < 30) {
            return `${days} day${days > 1 ? 's' : ''}`;
        } else if (days < 365) {
            const months = Math.floor(days / 30);
            const remainingDays = days % 30;
            return `${months} month${months > 1 ? 's' : ''}${remainingDays ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
        } else {
            const years = Math.floor(days / 365);
            const remainingMonths = Math.floor((days % 365) / 30);
            const remainingDays = days % 365 % 30;
            return `${years} year${years > 1 ? 's' : ''}${remainingMonths ? ` ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}` : ''}${remainingDays ? ` ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''}`;
        }
    };

    useEffect(() => {
        const checkData = async () => {
            const company = JSON.parse(localStorage.getItem('company'));
            if (company) {
                const companyId = company.id;
                const usertype = localStorage.getItem('login_type');
                if (usertype == 'company') {
                    const subdata = await getCompanySubscription(company.id);
                    console.log('my subm data ', subdata);
                    setBranchAvailable(subdata.branches_count);
                }


                // // Dispatch the action and unwrap the result
                // dispatch(getScheduleReportsByCompanyId(companyId));
                // console.log('Result:', scheduleReports);
            }
        }
        fetchSubscriptions();
        checkData();
    }, [dispatch]);

    return (
        <div className="container">
            <ToastContainer />
            <div className="plans-page-style" style={{ background:"#fff"}}>
                
                <div className="main " style={{ marginTop: "30px", }}>
                    <table className="price-table">
                        <tbody>

                            <tr className="price-table-head">
                                <td > Particular</td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td key={index}>
                                        {data.name}

                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td />
                                {subscriptions && subscriptions.map((plan, index) =>
                                    <td key={index} className="price">
                                        ₹{(plan.price)}<br/>
                                        <button
                                            key={plan.id}
                                            onClick={() => {
                                                if (!currentSubscription?.subscription_id || currentSubscription?.subscription_id !== plan.id) {
                                                    upgradePlan(plan.id, (((plan.price+plan.registration_charge) * 1.18) * 100).toFixed(2));
                                                }
                                            }}
                                            disabled={currentSubscription?.subscription_id === plan.id && isRenew !== 1}
                                            className={`button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7 ${currentSubscription?.subscription_id === plan.id ? 'disabled:opacity-70 disabled:cursor-not-allowed' : ''}`}
                                        >
                                            {currentSubscription?.subscription_id === plan.id ? 'Current' : branchAvailable > plan.users ? 'Downgrade' : 'Upgrade'}
                                        </button>
                                        {/* <Link to={`/register?plan=${plan.id}`} class="button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7">Get Started</Link> */}
                                    </td>
                                )}

                            </tr>
                            <tr>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    Registration Charges (One Time)
                                </td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td>₹{data.registration_charge || 0}</td>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    Plan type
                                </td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td>{data.plan_type == 'Other' ? data.start_from + ' days' : (data.plan_type || '')}</td>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    Branches & Charges
                                </td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td>{data.branches_charge ? '1 Branch @ ₹' + data.branches_charge : <span className='text-danger'>X</span>}</td>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    24X7 Support
                                </td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td>{data.support_24x7 == 'Yes' ?
                                        <span className='text-success'>✔</span> : <span className='text-danger'>X</span>}</td>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    Regular Updates
                                </td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td><span className='text-success'>✔</span></td>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    Due Date Reminder
                                </td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td><span className='text-success'>✔</span></td>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    Custom & Secure Business data
                                </td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td><span className='text-success'>✔</span></td>
                                )}
                            </tr>
                            <tr>
                                <td>
                                    <a href="#wordpress-asset-updates" className="price-table-help">
                                        <i className="far fa-fw fa-question-circle" />
                                    </a>{" "}
                                    Security & Management Console
                                </td>
                                {subscriptions && subscriptions.map((data, index) =>
                                    <td><span className='text-success'>✔</span></td>
                                )}
                            </tr>
                            {taxServices?.map((taxData, ind) => (
                                <tr key={`tax-${ind}`}>
                                    <td>
                                        <a href="#wordpress-asset-updates" className="price-table-help">
                                            <i className="far fa-fw fa-question-circle" />
                                        </a>{" "}
                                        {taxData.service_name}
                                    </td>
                                    {subscriptions?.map((data, index) => {
                                        let selectedServices = [];
                                        try {
                                            selectedServices = JSON.parse(data.selected_services);
                                        } catch (e) {
                                            console.error('Error parsing selected_services:', e);
                                        }
                                        const isSelected = selectedServices?.includes(taxData.id);
                                        return (
                                            <td key={`sub-${ind}-${index}`}>
                                                {isSelected ? (
                                                    <span className='text-success'>✔</span>
                                                ) : (
                                                    <span className='text-danger'>X</span>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}

                            <tr>
                                <td><small>All rates are exclusive of GST<br />Terms & Condition apply</small></td>
                                {subscriptions?.map((plan, index) =>
                                    <td className="price">
                                        <button
                                            key={plan.id}
                                            onClick={() => {
                                                if (!currentSubscription?.subscription_id || currentSubscription?.subscription_id !== plan.id) {
                                                    upgradePlan(plan.id, (((plan.price+plan.registration_charge)* 1.18) * 100).toFixed(2));
                                                }
                                            }}
                                            disabled={currentSubscription?.subscription_id === plan.id && isRenew !== 1}
                                            className={`button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7 ${currentSubscription?.subscription_id === plan.id ? 'disabled:opacity-70 disabled:cursor-not-allowed' : ''}`}
                                        >
                                            {currentSubscription?.subscription_id === plan.id ? 'Current' : branchAvailable > plan.users ? 'Downgrade' : 'Upgrade'}
                                        </button>
                                        {/* <Link to={`/register?plan=${plan.id}`} class="button transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 rounded-full border-primary/50 px-7">Get Started</Link> */}
                                    </td>
                                )}

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionDetails;