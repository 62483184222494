import React, { useEffect, useRef, useState } from "react";
import TableHeader from "../../../utils/table_headings";
import { useDispatch, useSelector } from "react-redux";
import { deleteBranch, fetchBranchesByCompanyId } from "../../../features/branchSlice";
import { MoreVertical } from "lucide";
import { Link } from 'react-router-dom';
import { Delete, PenSquare } from "lucide-react";
import Swal from "sweetalert2";
import Switch from "react-switch";
import axios from 'axios';
import { API_URL } from "../../../utils/api_const";


const CompanyBranchTable = () => {
    
    const dispatch = useDispatch();
    const [branchAvailable, setBranchAvailable] = useState(0);
    const companyHeadings = ['Branch Name', 'Location', 'Users', 'Status', 'Actions'];
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRefs = useRef({});
    const { branches, status, error } = useSelector((state) => state.branches);

    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        Object.values(dropdownRefs.current).forEach(ref => {
            if (ref && !ref.contains(event.target)) {
                setOpenDropdownId(null);
            }
        });
    };

    useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        if (company) {
            const companyId = company.id;
            dispatch(fetchBranchesByCompanyId(companyId));
        }
    }, [dispatch]);

    const getCompanySubscription = async (company_id) => {
        try {
            const res = await axios.get(`${API_URL}/companies/subscription/data/${company_id}`);
            if (res.status === 200) {
                return res.data;
            }
        } catch (error) {
            console.error("Error fetching subscriptions:", error);
            return false;
        }
    }


    useEffect(() => {
        const checkData = async () => {
            const company = JSON.parse(localStorage.getItem('company'));
            if (company) {
                const companyId = company.id;
                const usertype = localStorage.getItem('login_type');
                if (usertype == 'company') {
                    const subdata = await getCompanySubscription(company.id);
                    console.log('my subm data ', subdata);
                    setBranchAvailable(subdata.branches_count);
                }
            }
        }
        checkData();
    }, [dispatch]);


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleStatusChange = async (checked, rowid) => {
        const newStatus = checked ? 1 : 0; // Set new status based on switch position
        const activeBranchesCount = branches.filter((branch) => branch.status === 1).length;

        // Check if activating a new branch would exceed the limit
        if (newStatus === 1 && activeBranchesCount >= branchAvailable) {
            console.error("Cannot activate more branches than the allowed limit.");
            alert(`You can only activate up to ${branchAvailable} branches.`);
            return; // Prevent further execution
        }

        try {
            // Make the POST request to update the status
            const response = await axios.post(`${API_URL}/companies/branches/update-status`, {
                id: rowid,         // Send the row id
                status: newStatus,  // Send the updated status
            });

            // Check for successful response
            if (response.status === 200) {
                const company = JSON.parse(localStorage.getItem('company'));
                if (company) {
                    const companyId = company.id;
                    dispatch(fetchBranchesByCompanyId(companyId));
                }
            console.log("Status updated successfully");
            } else {
            console.error("Failed to update status");
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
       
    };

    const handleDelete = (id) => () => {
        console.log(id);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteBranch(id));
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Successfully Deleted',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                // setScheduleReports(prevReports =>
                //     prevReports.filter(report => report.id !== id)
                // );
            }
        });
    }


    return (
        <table
            data-tw-merge=""
            className="w-full text-left border-b border-slate-200/60"
        >
            <TableHeader headings={companyHeadings} />
            <tbody>
                {branches.map((row, index) => (
                    <tr key={row.id} data-tw-merge="" className="[&_td]:last:border-b-0">
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            {index + 1}
                        </td>

                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <a className="whitespace-nowrap font-medium" href="">
                                {row.branch_name}
                            </a>
                        </td>

                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                {row.branch_location}
                            </div>
                        </td>
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                {row.BranchUsers?.length} Users
                            </div>
                        </td>
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600">
                            <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                <Switch
                                    onChange={(checked) => handleStatusChange(checked, row.id)} // Event when the switch is toggled
                                    checked={row.status == 1} // Current state of the switch
                                    offColor="#888" // Customize off color
                                    onColor="#0f0" // Customize on color
                                    uncheckedIcon={false} // Remove unchecked icon
                                    checkedIcon={false} // Remove checked icon
                                />
                            </div>
                        </td>

                        <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 relative border-dashed py-4 dark:bg-darkmode-600">
                            <div className="flex">
                                <Link to={`/company/branch/users/${row.id}`} className="mr-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-users"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" /><circle cx="9" cy="7" r="4" /><path d="M22 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                </Link>

                                <Link to={`/company/branch/edit/${row.id}`} state={{ branchData: row }} className="mr-4">
                                    <PenSquare />
                                </Link>

                                <Link onClick={handleDelete(row.id)}>
                                    <Delete />
                                </Link>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default CompanyBranchTable;
