import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/auth/login';
import Main from './pages/layouts/main';
import CompanyMain from './pages/layouts/company/main';
import { setUserFromLocalStorage } from './features/auth/authSlice';
import CompanyLogin from './pages/auth/companyLogin';
import { setCompanyFromLocalStorage } from './features/auth/companyAuthSlice';
import CompanyRegister from './pages/auth/companyRegister';
import { checkAuthType } from './actions/authAction';
import PlansSubscription from './pages/auth/plans';

const App = () => {
 
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state.companyAuth);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const userData = localStorage.getItem('user');
    const companyData = localStorage.getItem('company');
  
    if (userData && !companyData) {
      dispatch(setUserFromLocalStorage(JSON.parse(userData)));
    }
    if (companyData && !userData) {
      console.log(companyData);
      dispatch(setCompanyFromLocalStorage(JSON.parse(companyData)));
    }
  
    setLoading(false);
  }, [dispatch]);


  if (loading) {
    return (
      <div className="[&amp;.loading-page--before-hide]:h-screen [&amp;.loading-page--before-hide]:relative loading-page loading-page--before-hide [&amp;.loading-page--before-hide]:before:block [&amp;.loading-page--hide]:before:opacity-0 before:content-[''] before:transition-opacity before:duration-300 before:hidden before:inset-0 before:h-screen before:w-screen before:fixed before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:z-[60] [&amp;.loading-page--before-hide]:after:block [&amp;.loading-page--hide]:after:opacity-0 after:content-[''] after:transition-opacity after:duration-300 after:hidden after:h-16 after:w-16 after:animate-pulse after:fixed after:opacity-50 after:inset-0 after:m-auto after:bg-loading-puff after:bg-cover after:z-[61]"></div>
    ); // Add a loading spinner or message here
  }

  return (
    <Router>
      <Routes>
        
        <Route path="/register" element={<CompanyRegister />} />
        <Route path="/plans" element={<PlansSubscription/>}/>
        <Route path="/login" element={company?checkAuthType() == 'company'?<Navigate to="/company/dashboard"/>:<Navigate to="/branch/dashboard"/>:user?<Navigate to="/dashboard"/>:<CompanyLogin />} />
        <Route path="/admin/login" element={user?<Navigate to="/dashboard"/>: company?checkAuthType() == 'company'?<Navigate to="/company/dashboard"/>:<Navigate to="/branch/dashboard"/>:<Login />} />
        <Route 
          path="*" 
          element={
            user ? <Main /> 
            : company ? <CompanyMain /> 
            : <Navigate to={company ? "/login" : "/login"} /> 
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
