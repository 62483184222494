import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createCompany } from "../../../features/companySlice";
import axios from 'axios';
import { API_URL } from "../../../utils/api_const";

const CompanyAddStep1 = () => {

    const dispatch = useDispatch();
    const navigator = useNavigate();
    const status = useSelector((state) => state.companies.status);
    const error = useSelector((state) => state.companies.error);
    const [passwordError, setPasswordError] = useState('');

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        company_name: '',
        trade_name: '',
        additional_trade_name: '',
        company_type: '',
        pan_number: '',
        pan_image: '',
        tan_number: '',
        tan_image: '',
        doi: '',
        doi_image: '',
        register_office_address: '',
        cin_number: '',
        msme_reg: '',
        nature_of_business: ''
    });

    const [formDataError, setFormDataError] = useState({
        username: '',
        email: '',
        password: '',
        company_name: '',
        trade_name: '',
        additional_trade_name: '',
        company_type: '',
        pan_number: '',
        pan_image: '',
        tan_number: '',
        tan_image: '',
        doi: '',
        doi_image: '',
        register_office_address: '',
        cin_number: '',
        msme_reg: '',
        nature_of_business: ''
    });
    const handleChange = async (e) => {
        if (e.target.type === 'file') {
            const file = e.target.files[0];
            
            // Check if file size is greater than 1 MB (1 MB = 1,048,576 bytes)
            if (file && file.size > 1048576) {
                alert("File size should not exceed 1 MB");
                return;
            }
    
            setFormData({
                ...formData,
                [e.target.name]: file
            });
        } else {
            setFormDataError({
                ...formDataError,
                [e.target.name]: "",
            });
            if (e.target.name === 'pan_number') {
                const panValue = e.target.value.toUpperCase(); // Convert input to uppercase

                // Regex to validate PAN card format: 5 uppercase letters, 4 digits, 1 uppercase letter
                const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

                if (panValue.length <= 10) {
                    // Update formData with the uppercase PAN value
                    setFormData({
                        ...formData,
                        [e.target.name]: panValue, // Set the uppercase value in the form data
                    });

                    if (!panRegex.test(panValue) && panValue.length === 10) {
                        setFormDataError({
                            ...formDataError,
                            [e.target.name]: 'Invalid Pan Card Number'
                        })
                    }

                    try {
                        const response = await axios.post(`${API_URL}/companies/check-pan`, { pan_number: e.target.value });
                        if (response.data.exists) {
                            setFormDataError(prevErrors => ({
                                ...prevErrors,
                                pan_number: 'This PAN number is already registered with another company.'
                            }));
                        }
                    } catch (error) {
                        console.error('Error checking PAN number:', error);
                    }
                }
            }else if (e.target.name === 'tan_number') {
                const tannum = e.target.value.toUpperCase();
                setFormData({
                    ...formData,
                    [e.target.name]: tannum,
                });
            } else {
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                });
            }


        }
        if (e.target.name === 'password' || e.target.name === 'confirm_password') {
            setPasswordError('');
        }
    };

    // const handleChange = (e) => {
    //     const { name, value, type, files } = e.target;

    //     if (type === 'file') {
    //         setFormData({
    //             ...formData,
    //             [name]: files[0],
    //         });
    //     } else {
    //         let error = '';

    //         // Field-specific validation
    //         switch (name) {
    //             case 'email':
    //                 const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //                 if (!emailRegex.test(value)) {
    //                     error = 'Invalid email format';
    //                 }
    //                 break;

    //             case 'company_name':
    //             case 'username':
    //             case 'register_office_address':
    //             case 'trade_name':
    //                 if (value.trim() === '') {
    //                     error = `${name.replace('_', ' ')} cannot be empty`;
    //                 }
    //                 break;

    //             case 'cin':
    //                 const cinRegex = /^[A-Z]{1}\d{5}[A-Z]{2}\d{4}[A-Z]{3}$/;
    //                 if (!cinRegex.test(value)) {
    //                     error = 'Invalid CIN format';
    //                 }
    //                 break;

    //             case 'password':
    //                 if (value.length < 6) {
    //                     error = 'Password must be at least 6 characters long';
    //                 }
    //                 break;

    //             case 'confirm_password':
    //                 if (value !== formData.password) {
    //                     error = 'Passwords do not match';
    //                 }
    //                 break;

    //             case 'doi': // Date of Incorporation (Assuming format YYYY-MM-DD)
    //                 const doiRegex = /^\d{4}-\d{2}-\d{2}$/;
    //                 if (!doiRegex.test(value)) {
    //                     error = 'Invalid DOI format. Use YYYY-MM-DD';
    //                 }
    //                 break;

    //             case 'pan_number': {
    //                 const panValue = value.toUpperCase(); // Convert input to uppercase
    //                 const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    //                 if (panValue.length <= 10) {
    //                     setFormData({
    //                         ...formData,
    //                         [name]: panValue,
    //                     });
    //                     if (!panRegex.test(panValue) && panValue.length === 10) {
    //                         error = 'Invalid PAN card number';
    //                     }
    //                 }
    //                 break;
    //             }

    //             default:
    //                 break;
    //         }

    //         setFormDataError({
    //             ...formDataError,
    //             [name]: error, // If there's an error, set it. If not, reset the error.
    //         });

    //         // Update formData
    //         setFormData({
    //             ...formData,
    //             [name]: value,
    //         });
    //     }

    //     // Reset password error if editing password or confirm_password
    //     if (name === 'password' || name === 'confirm_password') {
    //         setPasswordError('');
    //     }
    // };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirm_password) {
            setPasswordError('Password does not Match');
            return;
        }

        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }

        try {
            const resultAction = await dispatch(createCompany(formDataObj));
            if (createCompany.fulfilled.match(resultAction)) {
                const companyId = resultAction.payload.id;
                navigator(`/companies/add/step2/${companyId}`);
            } else {
                console.error('Failed to create company:', resultAction.payload);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col p-5 box box--stacked">
                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                        <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                            <i
                                data-tw-merge=""
                                data-lucide="chevron-down"
                                className="w-5 h-5 mr-2 stroke-[1.3]"
                            />
                            Step 1: Company Information
                        </div>
                        <div className="mt-5">
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">User Name</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the User Name.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="username"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="User name"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                    {formDataError.name && (<span className="text-danger">{formDataError.name}</span>)}
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Email</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Unique Email Address.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={formData.email}
                                        placeholder="Email Address"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                    {formDataError.email && (<span className="text-danger">{formDataError.email}</span>)}
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Password</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Password.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        value={formData.password}
                                        placeholder="**********"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                    {formDataError.password && (<span className="text-danger">{formDataError.password}</span>)}
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Confirm Password</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Confirm Password.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="password"
                                        name="confirm_password"
                                        onChange={handleChange}
                                        placeholder="**********"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                    {passwordError && (
                                        <div className="text-danger text-sm mt-2">
                                            {passwordError}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Company Name</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the unique name of your Company.
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="company_name"
                                        onChange={handleChange}
                                        value={formData.company_name}
                                        placeholder="Company name"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Trade Name</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Trade Name
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="trade_name"
                                        value={formData.trade_name}
                                        onChange={handleChange}
                                        placeholder="Trade name"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                    {formDataError.trade_name && (<span className="text-danger">{formDataError.trade_name}</span>)}
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Additional Trade Name</div>
                                            {/* <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                    Required
                                                                </div> */}
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Additional Trade Name
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        onChange={handleChange}
                                        name="additional_trade_name"
                                        placeholder="Additional Trade name"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Company Type</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Select Company Type
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <select
                                        data-tw-merge=""
                                        id="category"
                                        onChange={handleChange}
                                        name="company_type"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                    >
                                        <option value=""> -- Select Status --</option>
                                        <option value="Individual">Individual</option>
                                        <option value="Hindu Undivided Family">Hindu Undivided Family</option>
                                        <option value="Partnership Firm">Partnership Firm</option>
                                        <option value="Company">Company</option>
                                        <option value="Association of Persons (AOP) or Body of Individuals (BOI)">Association of Persons (AOP) or Body of Individuals (BOI)</option>
                                        <option value="Local Authority">Local Authority</option>
                                        <option value="Artificial Judicial Body (not covered under any of the above-mentioned categories)">Artificial Judicial Body (not covered under any of the above-mentioned categories)</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">PAN Card</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter Pan Card Number & Upload Pan Card Image <span className="text-danger">(File Size Max: 1 MB)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="pan_number"
                                        maxLength={10}
                                        value={formData.pan_number}
                                        onChange={handleChange}
                                        placeholder="Pan Card Number"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                    {formDataError.pan_number && (<span className="text-danger">{formDataError.pan_number}</span>)}
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                        name="pan_image"
                                        accept="image/*"
                                        onChange={handleChange}
                                        placeholder="Pan Card Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">TAN Card</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter Tan Card Number & Upload Tan Card Image <span className="text-danger">(File Size Max: 1 MB)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="tan_number"
                                        value={formData.tan_number}
                                        onChange={handleChange}
                                        placeholder="Tan Card Number"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                        accept="image/*"
                                        name="tan_image"
                                        onChange={handleChange}
                                        placeholder="Tan Card Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">DOB / DOI</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Select Date of Birth & Upload Image <span className="text-danger">(File Size Max: 1 MB)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">

                                    <input
                                        data-tw-merge
                                        name="doi"
                                        onChange={handleChange}
                                        type="date" data-single-mode="true" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&amp;[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker mx-auto block w-56 mx-auto block w-56 datepicker mx-auto block w-56 mx-auto block w-56"
                                    />

                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                        accept="image/*"
                                        onChange={handleChange}
                                        name="doi_image"
                                        placeholder="Tan Card Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Registered Office Address</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Registered office Address
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        onChange={handleChange}
                                        name="register_office_address"
                                        placeholder="Registered Office Address"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">CIN</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the CIN Number
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="cin_number"
                                        onChange={handleChange}
                                        placeholder="CIN Number"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">GST Number</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter GST Number
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">

                                    <input
                                        data-tw-merge
                                        name="msme_reg"
                                        onChange={handleChange}
                                        type="text" data-single-mode="true" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&amp;[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker mx-auto block w-56 mx-auto block w-56 datepicker mx-auto block w-56 mx-auto block w-56"
                                    />

                                </div>
                                {/* <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                    <input
                                        data-tw-merge=""
                                        type="file"
                                        name="msme_image"
                                        placeholder="MSME Image"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div> */}
                            </div>
                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Nature of Business</div>
                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div>
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Enter the Nature of Business
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <input
                                        data-tw-merge=""
                                        type="text"
                                        name="nature_of_business"
                                        onChange={handleChange}
                                        placeholder="Nature of Business"
                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">

                    <button
                        type="submit"
                        data-tw-merge=""
                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                    >
                        <i
                            data-tw-merge=""
                            data-lucide="pen-line"
                            className="mr-2 h-4 w-4 stroke-[1.3]"
                        />
                        Save & Next
                    </button>
                </div>
            </form>
            {status === 'loading' && <div className="[&amp;.loading-page--before-hide]:h-screen [&amp;.loading-page--before-hide]:relative loading-page loading-page--before-hide [&amp;.loading-page--before-hide]:before:block [&amp;.loading-page--hide]:before:opacity-0 before:content-[''] before:transition-opacity before:duration-300 before:hidden before:inset-0 before:h-screen before:w-screen before:fixed before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:z-[60] [&amp;.loading-page--before-hide]:after:block [&amp;.loading-page--hide]:after:opacity-0 after:content-[''] after:transition-opacity after:duration-300 after:hidden after:h-16 after:w-16 after:animate-pulse after:fixed after:opacity-50 after:inset-0 after:m-auto after:bg-loading-puff after:bg-cover after:z-[61]"></div>}
        </>

    );
}
export default CompanyAddStep1;