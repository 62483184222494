import React from "react";
import { Route, Routes } from "react-router-dom";
import Subscription from "./subscription";
import AddSubscriptions from "./add_subscriptions";
import Storage from "./storage";
import AddStorage from "./add_storage";
import EditSubscription from "./edit_subscription";
import EditStorage from "./edit_storage";


const SubscriptionRoutes = () => {

    return (
        <Routes>
            <Route path="/plan" element={<Subscription/>} />
            <Route path="/plan/add" element={<AddSubscriptions/>} />
            <Route path="/storage" element={<Storage/>} />
            <Route path="/storage/add" element={<AddStorage/>} />
            <Route path="/storage/edit/:id" element={<EditStorage/>} />
            <Route path="plan/edit/:id" element={<EditSubscription/>}/>
        </Routes> 
    );
}


export default SubscriptionRoutes;