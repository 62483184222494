import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addBranchUser } from "../../../features/branchUserSlice";
import Swal from "sweetalert2";

const AddBranchUser = () => {
    const dispatch = useDispatch();
    const { branch_id } = useParams();
    // console.log(branch_id);
    const navigator = useNavigate();

    const [passwordError, setPasswordError] = useState('');

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        mobile_no: '',
        password: '',
    });

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.files[0]
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
        if (e.target.name === 'password' || e.target.name === 'confirm_password') {
            setPasswordError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password !== formData.confirm_password) {
            setPasswordError('Password does not Match');
            return;
        }

        const formDataObj = new FormData();
        for (let key in formData) {
            if(key != 'confirm_password'){
                formDataObj.append(key, formData[key]);
            }
        }
        console.log(formDataObj);
        try {
            console.log(branch_id);
            // Dispatch the action and wait for it to complete
            const resultAction = await dispatch(addBranchUser({branch_id, formData: formDataObj}));

            // Check if the action was successful and get the company ID
            if (addBranchUser.fulfilled.match(resultAction)) {
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Successfully Added',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });
                navigator(`/company/branch/users/${branch_id}`);
            } else {
                // Handle the error case
                console.error('Failed to create company:', resultAction.payload);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium group-[.mode--light]:text-white">
                            Add Branch User
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col p-5 box box--stacked">
                                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                                        <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                                            <i
                                                data-tw-merge=""
                                                data-lucide="chevron-down"
                                                className="w-5 h-5 mr-2 stroke-[1.3]"
                                            />
                                            User Information
                                        </div>
                                        <div className="mt-5">
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">User Name</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Enter the User Name.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="username"
                                                        onChange={handleChange}
                                                        placeholder="User name"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Email</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Enter the Unique Email Address.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="email"
                                                        name="email"
                                                        onChange={handleChange}
                                                        placeholder="Email Address"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Mobile Number</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Enter the Unique Mobile Number.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        required={true}
                                                        accept="[0-9]+"
                                                        name="mobile_no"
                                                        onChange={handleChange}
                                                        placeholder="1234567890"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        maxLength={10}
                                                        minLength={10}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Password</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Enter the Password.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="password"
                                                        name="password"
                                                        onChange={handleChange}
                                                        placeholder="**********"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 sm:flex xl:flex-row xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Confirm Password</div>
                                                            <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                                Required
                                                            </div>
                                                        </div>
                                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                                            Enter the Confirm Password.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="password"
                                                        name="confirm_password"
                                                        onChange={handleChange}
                                                        placeholder="**********"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {passwordError && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {passwordError}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">

                                    <button
                                        type="submit"
                                        data-tw-merge=""
                                        className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                                    >
                                        <i
                                            data-tw-merge=""
                                            data-lucide="pen-line"
                                            className="mr-2 h-4 w-4 stroke-[1.3]"
                                        />
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddBranchUser;