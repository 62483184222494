import React, { useEffect, useRef, useState } from "react";
import TableHeader from "../../../../utils/table_headings";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchTaxServices, deleteTaxService } from "../../../../features/taxServiceSlice";
import moment from "moment";
import { Shield } from 'lucide-react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const AnnualFillingTable = () => {
    const dispatch = useDispatch();
    const companyHeadings = ['Service Name', 'Service Type', 'Due Date', 'Actions'];
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const dropdownRefs = useRef({});
    const taxServices = useSelector((state) => state.taxService.taxServices);

    const toggleDropdown = (id) => {
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleClickOutside = (event) => {
        Object.values(dropdownRefs.current).forEach(ref => {
            if (ref && !ref.contains(event.target)) {
                setOpenDropdownId(null);
            }
        });
    };

    useEffect(() => {
        dispatch(fetchTaxServices({ service_type: 'annual_filling' }));
    }, [dispatch]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function getFrequencyText(row) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (row.frequency === 'monthly') {
            return `${row.day}th of Every Month`;
        } else if (row.frequency === 'quarterly') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            //     if (startMonthIndex !== -1) {
            //         const quarterMonths = [startMonthIndex, (startMonthIndex + 3) % 12, (startMonthIndex + 6) % 12, (startMonthIndex + 9) % 12];
            //         const quarterMonthNames = quarterMonths.map(index => months[index]);
            //         return `${row.day} of ${quarterMonthNames.join(', ')}`;
            //     } else {
            //         return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            //     }

            if (startMonthIndex !== -1) {
                // Define the quarter months based on the start month index
                const quarterMonths = [
                    startMonthIndex,
                    (startMonthIndex + 3) % 12,
                    (startMonthIndex + 6) % 12,
                    (startMonthIndex + 9) % 12
                ];
                let dayData = row.day;

                const quarterMonthNames = quarterMonths.map((monthIndex) => {
                    let date = moment().month(monthIndex).date(row.day); // Use the day from the row

                    // Adjust the date based on the specific conditions
                    if (date.month() === 3) { // If it's April (index 3)
                        if (row.day == '30') {
                            dayData = '31';
                        }
                        date = date.add(1, 'month'); // Add one month
                        console.log('Adjusted date after adding one month:', date.format('MMM DD, YYYY'));
                    }
                    if (row.day === '30') { // If the day is the 30th
                        date = date.add(1, 'day'); // Add one more day
                    }

                    return date.format('MMM DD');
                });



                return `${dayData} of ${quarterMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency === 'semi-annually') {
            const startMonthIndex = months.findIndex(month => month.toLowerCase() === row.start_from.toLowerCase());

            if (startMonthIndex !== -1) {
                const halfYearMonths = [startMonthIndex, (startMonthIndex + 6) % 12];
                const halfYearMonthNames = halfYearMonths.map(index => months[index]);
                return `${row.day} of ${halfYearMonthNames.join(', ')}`;
            } else {
                return `${row.day} of ${row.start_from}`; // Fallback if start_from isn't recognized
            }
        } else if (row.frequency == 'annually') {
            const date = moment(row.day);
            const day = date.subtract(1, 'day').format('DD'); // Day of the month
            const month = date.format('MMMM'); // Full month name
            return `${day} of Every ${month}`;
        } else {
            return '';
        }
    }

    const handleDelete = (id) => {
        const ok  = confirm('Are you sure to delete this filling');
        if(ok){
            dispatch(deleteTaxService(id));
        }
    }

    return (
        <table
            data-tw-merge=""
            className="w-full text-left border-b border-slate-200/60"
        >
            <TableHeader headings={companyHeadings} />
            <tbody>
                {taxServices.map((row, index) => (
                    <tr key={row.id} data-tw-merge="" className="[&_td]:last:border-b-0">
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            {index + 1}
                        </td>
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 w-80 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <div className="flex items-center">

                                <div>
                                    <a className="whitespace-nowrap font-medium" href="">
                                        {row.service_name}
                                    </a>
                                    <div className="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                                        {row.frequency}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <a className="whitespace-nowrap font-medium" href="">
                                {row.service_type && row.service_type == 'return_filling' ? "Perodic Filling" : "Annual Filling"}
                            </a>
                        </td>

                        <td
                            data-tw-merge=""
                            className="px-5 border-b dark:border-darkmode-300 border-dashed py-4 dark:bg-darkmode-600"
                        >
                            <a className="whitespace-nowrap font-medium" href="">
                                {getFrequencyText(row)}
                            </a>
                        </td>


                        <td data-tw-merge="" className="px-5 border-b dark:border-darkmode-300 relative border-dashed py-4 dark:bg-darkmode-600">
                            <div className="flex">
                                {row.editable == 1 ? (
                                    <Tippy content="This filling is managed by system. To update or remove, please contact your backend team." placement="top">
                                        <button className="btn btn-primary text-danger">
                                            <Shield />
                                        </button>
                                    </Tippy>
                                ) : (
                                    <>
                                       <Link to={`/tax-service/edit/${row.id}`} state={{ taxData: row }} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item">
                                            <Pencil />
                                        </Link>
                                        <Link onClick={() => handleDelete(row.id)} className="cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md hover:bg-slate-200/60 dark:bg-darkmode-600 dark:hover:bg-darkmode-400 dropdown-item text-danger">
                                            <Delete />
                                        </Link>
                                    </>
                                )}
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default AnnualFillingTable;
