import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { addStorageSubscription } from "../../features/subscriptionsSlice";

const AddStorage = () => {
    const dispatch = useDispatch();
    const { branch_id } = useParams();
    const navigator = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        size: '',
        type: 'MB',
        image: null,
        price: '',
    });

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.files[0]
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataObj = new FormData();
        for (let key in formData) {
            formDataObj.append(key, formData[key]);
        }
        console.log(formDataObj);

        const resultAction = await dispatch(addStorageSubscription(formDataObj));

        if (addStorageSubscription.fulfilled.match(resultAction)) {
            Swal.fire({
                icon: 'success',
                title: '',
                text: 'Successfully Added',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            navigator(`/subscriptions/storage`); // Navigate after successful submission
        } else {
            Swal.fire("Error!", resultAction.error.message, "error");
        }


    };

    return (
        <div className="container">
            <div className="grid grid-cols-12 gap-x-6 gap-y-10">
                <div className="col-span-12">
                    <div className="flex flex-col mt-4 gap-y-3 md:mt-0 md:h-10 md:flex-row md:items-center">
                        <div className="text-base font-medium">
                            Add Storage Item
                        </div>
                    </div>
                    <div className="mt-3.5 grid grid-cols-12 gap-x-6 gap-y-7 lg:gap-y-10 xl:grid-cols-10">
                        <div className="relative flex flex-col col-span-12 gap-y-7 lg:col-span-12 xl:col-span-12">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col p-5 box box--stacked">
                                    <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                                        <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                                            <i className="w-5 h-5 mr-2 stroke-[1.3]" />
                                            Information
                                        </div>

                                        {/* Name Field */}
                                        <div className="flex-col block pt-5 sm:flex xl:flex-row xl:items-center">
                                            <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                <div className="text-left">
                                                    <div className="flex items-center">
                                                        <div className="font-medium">Name</div>
                                                        <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                            Required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 w-full mt-3 xl:mt-0">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    onChange={handleChange}
                                                    placeholder="Name"
                                                    required
                                                    className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                                />
                                            </div>
                                        </div>

                                        {/* Size Field */}
                                        <div className="flex-col block pt-5 sm:flex xl:flex-row xl:items-center">
                                            <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                <div className="text-left">
                                                    <div className="flex items-center">
                                                        <div className="font-medium">Size</div>
                                                        <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                            Required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 w-full mt-3 xl:mt-0">
                                                <input
                                                    type="text"
                                                    name="size"
                                                    onChange={handleChange}
                                                    placeholder="Size"
                                                    required
                                                    className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                                />

                                            </div>
                                            <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                                <select name="type" onChange={handleChange} className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md">
                                                    <option>MB</option>
                                                    <option>GB</option>
                                                </select>

                                            </div>
                                        </div>

                                        {/* Type Field */}

                                        <div className="flex-col block pt-5 sm:flex xl:flex-row xl:items-center">
                                            <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                <div className="text-left">
                                                    <div className="flex items-center">
                                                        <div className="font-medium">Image</div>
                                                        <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                            Required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 w-full mt-3 xl:mt-0">
                                                <input
                                                    type="file"
                                                    name="image"
                                                    onChange={handleChange}
                                                    accept="image/*"
                                                    required
                                                    className="transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md"
                                                />
                                            </div>
                                        </div>

                                        {/* Price Field */}
                                        <div className="flex-col block pt-5 sm:flex xl:flex-row xl:items-center">
                                            <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                <div className="text-left">
                                                    <div className="flex items-center">
                                                        <div className="font-medium">Price</div>
                                                        <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                            Required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 w-full mt-3 xl:mt-0">
                                                <div className="flex items-center border rounded-md shadow-sm">
                                                    <span className="px-3 bg-gray-200 border-r border-slate-200 text-sm text-gray-700 rounded-l-md">₹</span>
                                                    <input
                                                        type="number"
                                                        name="price"
                                                        onChange={handleChange}
                                                        placeholder="Price"
                                                        required
                                                        className="w-full text-sm border-0 transition duration-200 ease-in-out rounded-r-md focus:ring-0"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-end mt-5">
                                            <button
                                                type="submit"
                                                className="px-4 py-2 text-white bg-primary rounded-md"
                                            >
                                                Add Item
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddStorage;
