import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCompanyById, updateCompany } from "../../features/companySlice";
import { API_URL } from "../../utils/api_const";
import Swal from "sweetalert2";
import { fetchTaxServices } from "../../features/taxServiceSlice";
import axios from "axios";

const CompanyFillings = () => {
    const { company_id } = useParams(); // Destructure company_id from useParams
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const status = useSelector((state) => state.companies.status);
    const error = useSelector((state) => state.companies.error);
    const [passwordError, setPasswordError] = useState('');
    const { singleCompany } = useSelector((state) => state.companies);
    const taxServices = useSelector((state) => state.taxService.taxServices);
    const [selectedServices, setSelectedServices] = useState([]);
    const [taxInfo, setTaxInfo] = useState([]);

    // Fetch tax info when component mounts or company_id changes
    useEffect(() => {
        const fetchTaxInfo = async () => {
            try {
                const response = await fetch(`${API_URL}/companies/fillings/${company_id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setTaxInfo(data);
            } catch (error) {
                console.log('Error fetching tax info:', error);
            }
        };

        if (company_id) {
            fetchTaxInfo();
        }
    }, [company_id]);

    // Update selected services based on taxInfo
    useEffect(() => {
        if (taxInfo) {
            const selected = taxInfo.map((item) => item.id);
            setSelectedServices(selected);
        }
    }, [taxInfo]);

    // Fetch tax services when component mounts
    useEffect(() => {
        dispatch(fetchTaxServices());
    }, [dispatch]);

    // Fetch company details when component mounts or company_id changes
    useEffect(() => {
        if (company_id) {
            dispatch(getCompanyById(company_id));
        }
    }, [dispatch, company_id]);

    const handleSelect = (serviceId, type) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to ${type} this filling?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${type} it!`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.post(`${API_URL}/companies/fillings`, {
                        id: company_id,
                        type,
                        service_id: serviceId
                    });
    
                    if (response.status === 200) {
                        Swal.fire(
                            'Success!',
                            `The filling has been ${type}d successfully.`,
                            'success'
                        );
    
                        // Update selectedServices state based on type
                        setSelectedServices((prevSelected) =>
                            type === 'add'
                                ? [...prevSelected, serviceId]
                                : prevSelected.filter((id) => id !== serviceId)
                        );
                    } else {
                        throw new Error('Failed to update filling');
                    }
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        'There was an issue updating the filling. Please try again later.',
                        'error'
                    );
                    console.error('Error updating tax filling:', error);
                }
            }
        });
    };
    

    const selectAllSubmissions = () => {
        if (taxServices) {
            const allIds = taxServices.map((service) => service.id);
            setSelectedServices((prevSelected) =>
                prevSelected.length === allIds.length ? [] : allIds // Toggle selection
            );
        }
    };

    return (
        <div className="box box--stacked p-4">
            <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                <div className="text-left">
                    <div className="flex items-center">
                        <div className="font-bold">Select Submissions</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                {/* <span
                    onClick={selectAllSubmissions}
                    className="badge m-2 p-3"
                    style={{ border: '1px solid', borderRadius: '20px', cursor: 'pointer' }}
                >
                    {selectedServices.length === taxServices.length ? 'Deselect All' : 'Select All'}
                </span> */}
                {taxServices && taxServices.map((service) => (
                    <span
                        key={service.id}
                        onClick={() => handleSelect(service.id, selectedServices.includes(service.id) ? 'remove' : 'add')}
                        className={`badge m-2 p-3 ${selectedServices.includes(service.id) ? 'bg-success text-white' : 'bg-light text-dark'}`}
                        style={{ border: `1px solid ${selectedServices.includes(service.id) ? 'green' : 'black'}`, borderRadius: '20px', cursor: 'pointer' }}
                    >
                        {service.service_name}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default CompanyFillings;
