import React from 'react';

const TableHeader = ({ headings }) => {
  return (
    <thead data-tw-merge="">
      <tr data-tw-merge="">
        <td
            data-tw-merge=""
            className="px-5 border-b dark:border-darkmode-300 w-5 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500"
        >
            #
        </td>
        {headings.map((heading, index) => (
          <td
            key={index}
            data-tw-merge=""
            className="px-5 border-b dark:border-darkmode-300 border-t border-slate-200/60 bg-slate-50 py-4 font-medium text-slate-500"
          >
            {heading}
          </td>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
