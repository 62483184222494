import React from "react";
import NewScheduleReport from "./new_schedule";
import { Route, Routes } from "react-router-dom";
import ScheduleReport from "./schedule_report";

const ScheduleRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ScheduleReport/>}/>
            <Route path="/add" element={<NewScheduleReport/>}/>
            <Route path="/update/:id" element={<NewScheduleReport/>}/>
        </Routes>
    );
}

export default ScheduleRoutes;