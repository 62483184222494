import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCompanyById } from "../../../features/companySlice";
import { saveDetails } from "../../../features/companyBankDirectorSlice";

const CompanyAddStep3 = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const [bankDetails, setBankDetails] = useState([{
        name: '',
        branch: '',
        account_no: '',
        ifsc: '',
        micr: ''
    }]);
    const [directorDetails, setDirectorDetails] = useState([{
        name: '',
        holding: '',
        din: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        pincode: '',
        ps: '',
        po: '',
        document: ''
    }]);
    const company = useSelector((state) => state.companies.singleCompany);
    const { bankDetailsData, directorDetailsdata, status, error } = useSelector((state) => state.bankNDirectors);

    const [companyId, setCompanyId] = useState('');
    // const [bankDetail, f] = useState([{
    //     name: '',
    //     branch: '',
    //     account_no: '',
    //     ifsc: '',
    //     micr: ''
    // }]);
    // const [directorDetail, setDirectorDetailData] = useState([{
    //     name: '',
    //     holding: '',
    //     din: '',
    //     email: '',
    //     phone: '',
    //     address: '',
    //     city: '',
    //     pincode: '',
    //     ps: '',
    //     po: '',
    //     document: ''
    // }]);


    const handleBankDetailChange = (index, e) => {
        const { name, value } = e.target;
        const updatedBankDetails = [...bankDetails];
        updatedBankDetails[index][name] = value;
        setBankDetails(updatedBankDetails);
    };

    const handleDirectorDetailChange = (index, e) => {
        const { name, value, type, files } = e.target; // Destructure type and files from the event target
    
        const updatedDirectorDetails = [...directorDetails];
    
        if (type === 'file') {
            // If the input is a file, use the first file from the files array
            updatedDirectorDetails[index][name] = files[0].path; // Store the file object
        } else {
            // For other input types, just set the value as usual
            updatedDirectorDetails[index][name] = value;
        }
    
        setDirectorDetails(updatedDirectorDetails);
    };
    
    // const handleDirectorDetailChange = (index, e) => {
    //     const { name, value, type } = e.target;
    //     const updatedDirectorDetails = [...directorDetail]; // Copying the current state

    //     if (type === 'file') {
    //         // If the input is a file, store the file object instead of the value
    //         updatedDirectorDetails[index][name] = e.target.files[0];
    //     } else {
    //         // For other types of inputs, store the value as usual
    //         updatedDirectorDetails[index][name] = value;
    //     }

    //     setDirectorDetailData(updatedDirectorDetails); // Update state
    // };



    useEffect(() => {
        if (id) {
            setCompanyId(id);
            dispatch(getCompanyById(id)); // Fetch company data by id
        }
    }, [dispatch, id]);


    useEffect(() => {
        if (company) {
            const bankd = company.BankDetails || [];
            const directd = company.DirectorDetails || [];

            // if(bankd.length > 0){
            //     setBankDetails([]);
            //     setBankDetails([
            //         ...bankDetails,
            //         { id: bankd.length+1 },
            //     ]);
            // }
            if (bankd.length > 0) {
                setBankDetails(bankd.map((data, index) => ({
                    id: data.id,
                    name: data.bank_name,
                    branch: data.bank_branch,
                    account_no: data.account_no,
                    ifsc: data.ifsc,
                    micr: data.micr
                })));
            }
            if (directd.length > 0) {
                setDirectorDetails(directd.map((director, index) => ({
                    id: director.id,
                    name: director.name,
                    holding: director.holding,
                    din: director.din,
                    email: director.email,
                    phone: director.phone,
                    address: director.address,
                    city: director.city,
                    pincode: director.pincode,
                    ps: director.ps,
                    po: director.po,
                    document: director.document,
                })));

            }
        }
    }, [company]);


    const addNewVariant = () => {
        setBankDetails([
            ...bankDetails,
            {
                name: '',
                branch: '',
                account_no: '',
                ifsc: '',
                micr: ''
            },
        ]);
    };


    const removeVariant = (index) => {
        // Use the index to filter out the item at that specific index
        setBankDetails(bankDetails.filter((_, i) => i !== index));
    };
    

    const addNewDirector = () => {
        setDirectorDetails([
            ...directorDetails,
            {
                name: '',
                holding: '',
                din: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                pincode: '',
                ps: '',
                po: '',
                document: ''
            },
        ]);
    };

    const removeDirector = (index) => {
        setDirectorDetails(directorDetails.filter((_, i) => i !== index));
        // setDirectorDetails(directorDetails.filter(detail => detail.id !== id));
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('companyId', companyId);
        formData.append('bankDetails', JSON.stringify(bankDetails)); // Send as JSON string
        formData.append('directorDetail', JSON.stringify(directorDetails)); // Send as JSON string

        // Dispatch the saveDetails action with formData
        dispatch(saveDetails(formData));

        // Navigate after submission
        navigator('/companies');
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="hidden" name="company_id" value={id} />
            <div className="flex flex-col p-5 box box--stacked">
                <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                    <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                        <i
                            data-tw-merge=""
                            data-lucide="chevron-down"
                            className="w-5 h-5 mr-2 stroke-[1.3]"
                        />
                        {company ? (<>{company.company_name}</>) : (<></>)}:  Bank Details
                    </div>
                    <div className="mt-5">
                        {bankDetails.map((detail, index) => (
                            <div key={detail.id} className="flex-col block pt-5 mt-5 first:mt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Bank Details {index + 1}</div>

                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Update Your Bank Details
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <div className="relative py-10 pl-5 pr-5 border rounded-md border-slate-200/80 bg-slate-50/80 dark:border dark:bg-transparent xl:pr-10">
                                        <a className="absolute top-0 right-0 mt-4 mr-4 text-slate-500" href="">
                                            <i data-tw-merge="" data-lucide="x" className="stroke-[1] w-5 h-5" />
                                        </a>
                                        <div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Bank Name
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">

                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            value={bankDetails[index]?.name}
                                                            name={`name`}
                                                            onChange={(e) => handleBankDetailChange(index, e)}
                                                            placeholder="Bank Name"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Branch
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            value={bankDetails[index]?.branch}
                                                            name={`branch`}
                                                            onChange={(e) => handleBankDetailChange(index, e)}
                                                            placeholder="Branch"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Account
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            name={`account_no`}
                                                            value={bankDetails[index]?.account_no}
                                                            onChange={(e) => handleBankDetailChange(index, e)}
                                                            placeholder="Account Number"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    IFSC
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            name={`ifsc`}
                                                            value={bankDetails[index]?.ifsc}
                                                            onChange={(e) => handleBankDetailChange(index, e)}
                                                            placeholder="IFSC Code"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    MICR
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            name={`micr`}
                                                            value={bankDetails[index]?.micr}
                                                            onChange={(e) => handleBankDetailChange(index, e)}
                                                            placeholder="MICR Number"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {index > 0 && (
                                                <div className="pt-2 mt-2 first:mt-0 pl-5 pr-5">
                                                    <button
                                                        data-tw-merge=""
                                                        className="btn-danger transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-primary text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 w-full border-dashed btn-danger"
                                                        onClick={() => removeVariant(index)}
                                                    >
                                                        <i data-tw-merge="" data-lucide="x" className="mr-2 h-4 w-4 stroke-[1.3]" />
                                                        Remove
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="pt-2 mt-2 first:mt-0 xl:ml-60 xl:pl-14">
                            <button
                                data-tw-merge=""
                                type="button"
                                className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 w-full py-3 border-slate-200/80 bg-slate-50/80"
                                onClick={addNewVariant}
                            >
                                <i data-tw-merge="" data-lucide="plus" className="mr-2 h-4 w-4 stroke-[1.3]" />
                                Add More
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="flex flex-col p-5 box box--stacked mt-5">
                <div className="rounded-[0.6rem] border border-slate-200/60 p-5 dark:border-darkmode-400">
                    <div className="flex items-center border-b border-slate-200/60 pb-5 text-[0.94rem] font-medium dark:border-darkmode-400">
                        <i
                            data-tw-merge=""
                            data-lucide="chevron-down"
                            className="w-5 h-5 mr-2 stroke-[1.3]"
                        />
                        {company ? (<>{company.company_name}</>) : (<></>)}:  Director Details
                    </div>
                    <div className="mt-5">
                        {directorDetails.map((detail, index) => (
                            <div key={detail.id} className="flex-col block pt-5 mt-5 first:mt-0 sm:flex xl:flex-row xl:items-center">
                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                    <div className="text-left">
                                        <div className="flex items-center">
                                            <div className="font-medium">Director Details {index + 1}</div>
                                            {/* <div className="ml-2.5 rounded-md border border-slate-200 bg-slate-100 px-2 py-0.5 text-xs text-slate-500 dark:bg-darkmode-300 dark:text-slate-400">
                                                Required
                                            </div> */}
                                        </div>
                                        <div className="mt-1.5 text-xs leading-relaxed text-slate-500/80 xl:mt-3">
                                            Update Company Director Details
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                    <div className="relative py-10 pl-5 pr-5 border rounded-md border-slate-200/80 bg-slate-50/80 dark:border dark:bg-transparent xl:pr-10">
                                        <a className="absolute top-0 right-0 mt-4 mr-4 text-slate-500" href="">
                                            <i data-tw-merge="" data-lucide="x" className="stroke-[1] w-5 h-5" />
                                        </a>
                                        <div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Name <span className="text-danger">*</span>
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">

                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            required={true}
                                                            value={directorDetails[index]?.name}
                                                            name={`name`}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="Director Name"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    DIN No. <span className="text-danger">*</span>
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">

                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            required={true}
                                                            value={directorDetails[index]?.din}
                                                            name={`din`}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="DIN Number"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Email <span className="text-danger">*</span>
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">

                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            required={true}
                                                            value={directorDetails[index]?.email}
                                                            name={`email`}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="Email Address"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Phone <span className="text-danger">*</span>
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">

                                                        <input
                                                            data-tw-merge=""
                                                            type="number"
                                                            required={true}
                                                            max={9999999999} // Maximum value (10 digits)
                                                            name={`phone`}
                                                            value={directorDetails[index]?.phone}

                                                            onChange={(e) => {
                                                                if (e.target.value.length <= 10) {
                                                                    handleDirectorDetailChange(index, e);  // Allow only if length is <= 10
                                                                }
                                                            }}
                                                            placeholder="Phone Number"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Holdings
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="number"
                                                            name={`holding`}
                                                            value={directorDetails[index]?.holding}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="Holding"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Address
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            value={directorDetails[index]?.address}
                                                            name={`address`}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="Address"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    City
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            value={directorDetails[index]?.city}
                                                            name={`city`}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="City"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    Pincode
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            name={`pincode`}
                                                            value={directorDetails[index]?.pincode}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="Pincode Number"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    PS
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            name={`ps`}
                                                            value={directorDetails[index]?.ps}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="Police Station"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">
                                                <label data-tw-merge="" className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right sm:w-20">
                                                    PO
                                                </label>
                                                <div className="flex items-center flex-1 xl:pr-20">
                                                    <div data-tw-merge="" className="flex group input-group flex-1">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            name={`po`}
                                                            value={directorDetails[index]?.po}
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="Post Office"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-tw-merge="" className="block sm:flex items-center group form-inline mt-5 first:mt-0">

                                                <div className="items-center flex-1 xl:pr-20">
                                                    <label data-tw-merge="" className="mb-2">
                                                        Directors Pan or Any Govt approved ID proof ( Upload JPG/PNG/PDF -  Max File Size: 1 MB )
                                                    </label>
                                                    <div data-tw-merge="" className="flex group input-group flex-1">

                                                        <input
                                                            data-tw-merge=""
                                                            type="file"
                                                            required={true}
                                                            name="document"
                                                            accept="jpg|png|pdf"
                                                            onChange={(e) => handleDirectorDetailChange(index, e)}
                                                            placeholder="Select Document"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {index > 0 && (
                                                <div className="pt-2 mt-2 first:mt-0 pl-5 pr-5">
                                                    <button
                                                        data-tw-merge=""
                                                        className="btn-danger transition duration-200 border shadow-sm inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-primary text-primary dark:border-primary [&:hover:not(:disabled)]:bg-primary/10 w-full border-dashed btn-danger"
                                                        onClick={() => removeDirector(index)}
                                                    >
                                                        <i data-tw-merge="" data-lucide="x" className="mr-2 h-4 w-4 stroke-[1.3]" />
                                                        Remove
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="pt-2 mt-2 first:mt-0 xl:ml-60 xl:pl-14">
                            <button
                                data-tw-merge=""
                                type="button"
                                className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 w-full py-3 border-slate-200/80 bg-slate-50/80"
                                onClick={addNewDirector}
                            >
                                <i data-tw-merge="" data-lucide="plus" className="mr-2 h-4 w-4 stroke-[1.3]" />
                                Add More
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-end gap-3 mt-1 md:flex-row mt-4">

                <button
                    type="submit"
                    data-tw-merge=""
                    className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary w-full rounded-[0.5rem] py-2.5 md:w-56"
                >
                    <i
                        data-tw-merge=""
                        data-lucide="pen-line"
                        className="mr-2 h-4 w-4 stroke-[1.3]"
                    />
                    Save
                </button>
            </div>
        </form>
    );

}

export default CompanyAddStep3;