import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { registerCompany } from "../../features/companySlice";
import { fetchTaxServices } from "../../features/taxServiceSlice";
import { API_URL } from "../../utils/api_const";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RAZORPAY_KEY_ID } from "../../actions/authAction";

const CompanyRegister = () => {
    const [passwordError, setPasswordError] = useState('');
    const [step, setStep] = useState(1);
    const taxServices = useSelector((state) => state.taxService.taxServices);
    const [selectedServices, setSelectedServices] = useState([]);

    const [formerror, setFormError] = useState({
        username: '',
        email: '',
        mobile_no: '',
        password: '',
        company_name: '',
        trade_name: '',
        additional_trade_name: '',
        company_type: '',
        pan_number: '',
        pan_image: '',
        tan_number: '',
        tan_image: '',
        doi: '',
        doi_image: '',
        register_office_address: '',
        cin_number: '',
        msme_reg: '',
        nature_of_business: ''
    });

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        mobile_no: '',
        password: '',
        company_name: '',
        trade_name: '',
        additional_trade_name: '',
        company_type: '',
        pan_number: '',
        pan_image: '',
        tan_number: '',
        tan_image: '',
        doi: '',
        doi_image: '',
        register_office_address: '',
        cin_number: '',
        msme_reg: '',
        nature_of_business: ''
    });

    const dispatch = useDispatch();
    const { company, loading, error, message } = useSelector((state) => state.companyAuth);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedPlanPrice, setPlanPrice] = useState(0);

    useEffect(() => {
        // Get the 'plan' query parameter
        const plan = searchParams.get('plan');

        // If 'plan' is not found, navigate to the '/plan' route
        if (!plan) {
            navigate('/plans');
        }
        setSelectedPlan(plan);
    }, [searchParams, navigate]);

    const fetchSubscriptions = async () => {
        try {
            // Replace with your API endpoint
            const response = await axios.get('https://app.taxgem.co.in/backend/api/subscription/plan');
            setSubscriptions(response.data);
        } catch (err) {
            console.error("Error fetching subscriptions:", err);
        }
    };
    useEffect(() => {
        fetchSubscriptions();
    }, []);

    useEffect(() => {
        if (selectedPlan && subscriptions.length > 0) {
            const single = subscriptions.find((e) => e.id == selectedPlan);

            if (single) {
                setPlanPrice(single.price * 100); // Convert price to paisa
            }
        }
    }, [subscriptions, selectedPlan]);

    const selectAllSubmissions = () => {
        taxServices.forEach(element => {
            setSelectedServices((prevSelected) =>
                prevSelected.includes(element.id)
                    ? prevSelected.filter((id) => id !== element.id) // Deselect
                    : [...prevSelected, element.id] // Select
            );
        });
    }

    const handleSelect = (serviceId) => {
        setSelectedServices((prevSelected) =>
            prevSelected.includes(serviceId)
                ? prevSelected.filter((id) => id !== serviceId) // Deselect
                : [...prevSelected, serviceId] // Select
        );
    };


    const handleChange = async (e) => {
        if (e.target.type === 'file') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.files[0]
            });
        } else {
            if (e.target.name == 'pan_number' || e.target.name == 'tan_number') {
                const upeerc = e.target.value.toUpperCase();
                setFormData({
                    ...formData,
                    [e.target.name]: upeerc
                });
            } else {
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value
                });
            }

            setFormError(prevErrors => ({
                ...prevErrors,
                [e.target.name]: ''
            }));
        }
        if (e.target.name === 'password' || e.target.name === 'confirm_password') {
            setPasswordError('');
        }
        if (e.target.name === 'pan_number') {

            if (e.target.value.length === 10) { // Basic check for PAN length
                try {
                    const response = await axios.post(`${API_URL}/companies/check-pan`, { pan_number: e.target.value });
                    if (response.data.exists) {
                        setFormError(prevErrors => ({
                            ...prevErrors,
                            pan_number: 'This PAN number is already registered with another company.'
                        }));
                    }
                } catch (error) {
                    console.error('Error checking PAN number:', error);
                }
            }
        }
    };

    const loadRazorpay = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true); // Resolve the promise when the script loads successfully
            script.onerror = () => reject(false); // Reject the promise if the script fails to load
            document.body.appendChild(script);
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (step != 4) {
            return;
        }

        if (formData.password !== formData.confirm_password) {
            setPasswordError('Password does not Match');
            return;
        }

        const formDataObj = new FormData();
        for (let key in formData) {
            if (formData[key] != '') {
                formDataObj.append(key, formData[key]);
            }
        }
        formDataObj.append('selected_service', JSON.stringify(selectedServices));
        formDataObj.append('plan_id', selectedPlan);

        for (let [key, value] of formDataObj.entries()) {
            console.log(`${key}: ${value}`);
        }

        // Load Razorpay and initialize payment
        try {
            const razorpayLoaded = await loadRazorpay();
            if (!razorpayLoaded) {
                toast.error('Failed to load Razorpay SDK');
                return;
            }

            const paymentOptions = {
                key: RAZORPAY_KEY_ID, // Replace with your Razorpay Key ID
                amount: selectedPlanPrice, // Replace with the amount in the smallest currency unit (e.g., 50000 for ₹500)
                currency: 'INR',
                name: 'Taxgem',
                description: 'Subscription Fee',
                handler: async function (response) {
                    console.log('Payment Successful:', response);
                    formDataObj.append('razorpay_payment_id', response?.razorpay_payment_id);
                    try {
                        const resultAction = await dispatch(registerCompany(formDataObj));
                        if (registerCompany.fulfilled.match(resultAction)) {
                            toast.success('Registration Successfully Done, Redirecting to Login');
                            setTimeout(() => {
                                navigate('/login');
                            }, 2000);
                        } else {
                            console.error('Failed to create company:', resultAction.payload);
                        }
                    } catch (error) {
                        console.error('Error during submission:', error);
                    }
                },
                prefill: {
                    name: formData.name,
                    email: formData.email,
                    contact: formData.phone,
                },
                theme: {
                    color: '#5398E3', // Match your app's primary color
                },
            };

            const rzp = new Razorpay(paymentOptions);
            rzp.on('payment.failed', function (response) {
                console.error('Payment Failed:', response.error);
                toast.error('Payment failed, please try again.');
            });

            rzp.open();
        } catch (error) {
            toast.error('Payment failed, please try again.');
            console.error('Error loading Razorpay:', error);
        }
    };



    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (step != 4) {
    //         return;
    //     }


    //     if (formData.password !== formData.confirm_password) {
    //         setPasswordError('Password does not Match');
    //         return;
    //     }

    //     const formDataObj = new FormData();
    //     for (let key in formData) {
    //         if (formData[key] != '') {
    //             formDataObj.append(key, formData[key]);
    //         }
    //     }
    //     formDataObj.append('selected_service', JSON.stringify(selectedServices));
    //     formDataObj.append('plan_id', selectedPlan);

    //     for (let [key, value] of formDataObj.entries()) {
    //         console.log(`${key}: ${value}`);
    //     }

    //     // If you need to log the selected services specifically
    //     // console.log('Selected Services:', selectedServices);
    //     try {
    //         // Dispatch the action and wait for it to complete
    //         const resultAction = await dispatch(registerCompany(formDataObj));

    //         // Check if the action was successful and get the company ID
    //         if (registerCompany.fulfilled.match(resultAction)) {
    //             console.log('workign');
    //             toast.success('Registration Successfully Done');
    //             navigate('/login');
    //         } else {
    //             // Handle the error case
    //             console.error('Failed to create company:', resultAction.payload);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }

    // };

    useEffect(() => {
        dispatch(fetchTaxServices());
    }, [dispatch]);


    const changeStep = (step) => {
        if (step == 2) {
            if (formData.company_name == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    company_name: 'Please Enter Your Company Name'
                }));
                return;
            }
            if (formData.username == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    username: 'Please Enter Owner Name'
                }));
                return;
            }
            if (formData.email == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    email: 'Please Enter Your Email Address'
                }));
                return;
            }
            if (formData.mobile_no == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    mobile_no: 'Please Enter Your Mobile Address'
                }));
                return;
            }
            const mobileRegex = /^[0-9]{10}$/; // Example for a 10-digit number

            if (!mobileRegex.test(formData.mobile_no)) {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    mobile_no: 'Please Enter a Valid Mobile Number'
                }));
                return;
            }
            if (formData.password == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    password: 'Please Enter Your Password'
                }));
                return;
            }

            if (formData.password !== formData.confirm_password) {
                setPasswordError('Password does not Match');
                return;
            }
        }
        if (step == 3) {

            if (formData.trade_name == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    trade_name: 'Please Enter Trade Name'
                }));
                return;
            }
            if (formData.company_type == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    company_type: 'Please Select Company Type'
                }));
                return;
            }
            const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
            const cinRegex = /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
            const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{3}$/;
            if (formData.pan_number == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    pan_number: 'Please Enter company Pan Number'
                }));
                return;
            } else if (!panRegex.test(formData.pan_number)) {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    pan_number: 'Invalid PAN number format'
                }));
                return;
            }

            if (formData.doi == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    doi: 'Please Select DOI / DOB'
                }));
                return;
            }

            if (formData.cin_number !== '' && !cinRegex.test(formData.cin_number)) {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    cin_number: 'Please Enter Correct CIN Number'
                }));
                return;
            }
            if (formData.register_office_address == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    register_office_address: 'Please Enter Register Office Address'
                }));
                return;
            }

            if (formData.msme_reg == '') {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    msme_reg: 'Please Enter company GST Number'
                }));
                return;
            } else if (!gstRegex.test(formData.msme_reg)) {
                setFormError(prevErrors => ({
                    ...prevErrors,
                    msme_reg: 'Please Enter Correct GST Number'
                }));
                return;
            }




        }
        setStep(step);
        console.log(step);
    }

    // console.log(taxServices);

    return (
        <div>
            <ToastContainer />
            <div className="container grid grid-cols-12 px-5 py-10 sm:px-10 sm:py-14 md:px-36 lg:h-screen lg:max-w-[1550px] lg:py-0 lg:pl-14 lg:pr-12 xl:px-24 2xl:max-w-[1750px]">
                <div className="relative z-50 h-full col-span-12 p-7 sm:p-14 bg-white rounded-2xl lg:bg-transparent lg:pr-10 lg:col-span-5 xl:pr-24 2xl:col-span-4 lg:p-0 before:content-[''] before:absolute before:inset-0 before:-mb-3.5 before:bg-white/40 before:rounded-2xl before:mx-5">
                    <div className="relative z-10 flex flex-col justify-center w-full h-full py-2 lg:py-32">
                        <div className="flex h-[55px] w-[55px] items-center justify-center rounded-[0.8rem] border border-primary/30">
                            {/* <div className="relative flex h-[50px] w-[50px] items-center justify-center rounded-[0.6rem] bg-white bg-gradient-to-b from-theme-1/90 to-theme-2/90">
                                <div className="relative h-[26px] w-[26px] -rotate-45 [&_div]:bg-white">
                                    <div className="absolute inset-y-0 left-0 my-auto h-[75%] w-[20%] rounded-full opacity-50" />
                                    <div className="absolute inset-0 m-auto h-[120%] w-[20%] rounded-full" />
                                    <div className="absolute inset-y-0 right-0 my-auto h-[75%] w-[20%] rounded-full opacity-50" />
                                </div>
                            </div> */}
                            <img data-placement="top" src="/logo.png" alt="TaxGem" />
                        </div>
                        <div className="mt-10">
                            <div className="text-2xl font-medium">Sign Up</div>
                            {/* <div className="mt-2.5 text-slate-600">
                                Don't have an account?
                                <a className="font-medium text-primary" href="#">
                                    Sign Up
                                </a>
                            </div> */}
                            <div role="alert" className="alert relative border rounded-md px-5 py-4 border-primary text-primary dark:border-primary my-7 flex items-center rounded-[0.6rem] border-primary/20 bg-primary/5 px-4 py-3 leading-[1.7]">
                                <div className="">
                                    <i data-tw-merge data-lucide="lightbulb" className="mr-2 h-7 w-7 fill-primary/10 stroke-[0.8]" />
                                </div>
                                <div className="ml-1 mr-8">
                                    Welcome to <span className="font-medium">TaxGem</span>
                                    demo! Simply click
                                    <span className="font-medium">Sign Up</span> to explore
                                    and access our documentation.
                                </div>
                                <button data-tw-merge data-tw-dismiss="alert" type="button" aria-label="Close" className="py-2 px-3 absolute right-0 my-auto mr-2 btn-close text-primary"><i data-tw-merge data-lucide="x" className="stroke-[1] w-5 h-5" /></button>
                            </div>
                            <div className="mt-6">
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    {/* <label data-tw-merge className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right">
                                        User Name*
                                    </label>
                                    <input name="username" type="text" value={formData.username} onChange={handleChange} data-tw-merge  placeholder="User Name" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 block rounded-[0.6rem] border-slate-300/80 px-4 py-3.5" />
                                    <label data-tw-merge className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right  mt-4">
                                        Email*
                                    </label>
                                    <input name="email" type="email" value={formData.email} onChange={handleChange} data-tw-merge  placeholder="meryl.streep@left4code.com" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 block rounded-[0.6rem] border-slate-300/80 px-4 py-3.5" />
                                    <label data-tw-merge className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right mt-4">
                                        Password*
                                    </label>
                                    <input type="password" name="password" value={formData.password} onChange={handleChange} data-tw-merge placeholder="************" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 block rounded-[0.6rem] border-slate-300/80 px-4 py-3.5" />
                                    <label data-tw-merge className="inline-block mb-2 group-[.form-inline]:mb-2 group-[.form-inline]:sm:mb-0 group-[.form-inline]:sm:mr-5 group-[.form-inline]:sm:text-right mt-4">
                                        Confirm Password*
                                    </label>
                                    <input type="password" name="confirm_password" value={formData.confirm_password} onChange={handleChange} data-tw-merge placeholder="************" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm shadow-sm placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 block rounded-[0.6rem] border-slate-300/80 px-4 py-3.5" />
                                 */}

                                    {step === 1 && (
                                        <>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Name of Company <span className="text-danger">*</span></div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="company_name"
                                                        value={formData.company_name}
                                                        onChange={handleChange}
                                                        placeholder="Company name"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formerror.company_name && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.company_name}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">User Name <span className="text-danger">*</span></div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-2">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="username"
                                                        value={formData.username}
                                                        onChange={handleChange}
                                                        placeholder="User name"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formerror.username && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.username}
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                            <div className="flex-col block pt-5 mt-2 first:mt-0 first:pt-0  xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Email Address <span className="text-danger">*</span></div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-2">
                                                    <input
                                                        data-tw-merge=""
                                                        type="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        placeholder="Email Address"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formerror.email && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.email}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-2 first:mt-0 first:pt-0  xl:items-center">
                                                <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Contact Number <span className="text-danger">*</span></div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-2">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        pattern="[0-9]+"
                                                        name="mobile_no"
                                                        maxLength={10}
                                                        minLength={10}
                                                        value={formData.mobile_no}
                                                        onChange={handleChange}
                                                        placeholder="Contact Number"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formerror.mobile_no && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.mobile_no}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-2 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Password <span className="text-danger">*</span></div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        placeholder="**********"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formerror.password && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.password}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Confirm Password <span className="text-danger">*</span></div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="password"
                                                        value={formData.confirm_password}
                                                        name="confirm_password"
                                                        onChange={handleChange}
                                                        placeholder="**********"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {passwordError && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {passwordError}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {step == 2 && (
                                        <>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Trade Name <span className="text-danger">*</span></div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="trade_name"
                                                        value={formData.trade_name}
                                                        onChange={handleChange}
                                                        placeholder="Trade name"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formerror.trade_name && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.trade_name}
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                            {/* <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0  xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Additional Trade Name</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        value={formData.additional_trade_name}
                                                        onChange={handleChange}
                                                        name="additional_trade_name"
                                                        placeholder="Additional Trade name"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Company Type <span className="text-danger">*</span></div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <select
                                                        data-tw-merge=""
                                                        id="category"
                                                        value={formData.company_type}
                                                        onChange={handleChange}
                                                        name="company_type"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                                                    >
                                                        <option value=""> -- Select Status --</option>
                                                        <option value="Individual">Individual</option>
                                                        <option value="* Public Limited Company">Public Limited Company</option>
                                                        <option value="Private Limited Company">Private Limited Company</option>
                                                        <option value="Partnerships">Partnerships</option>
                                                        <option value="LLP Registration">LLP Registration</option>
                                                        <option value="One Person Company">One Person Company</option>
                                                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                        <option value="Section 8 Company">Section 8 Company</option>
                                                        <option value="Association of Persons (AOP) or Body of Individuals (BOI)">Association of Persons (AOP) or Body of Individuals (BOI)</option>
                                                        <option value="Local Authority">Local Authority</option>
                                                        <option value="Artificial Judicial Body (not covered under any of the above-mentioned categories)">Artificial Judicial Body (not covered under any of the above-mentioned categories)</option>
                                                    </select>
                                                    {formerror.company_type && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.company_type}
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0  xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Company PAN Number <span className="text-danger">*</span></div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="sm:flex xl:flex-row">
                                                    <div className="flex-1 w-full mt-3 xl:mt-0">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            value={formData.pan_number}
                                                            name="pan_number"
                                                            onChange={handleChange}
                                                            placeholder="Pan Card Number"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                        {formerror.pan_number && (
                                                            <div className="text-danger text-sm mt-2">
                                                                {formerror.pan_number}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">TAN Card</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sm:flex xl:flex-row">
                                                    <div className="flex-1 w-full mt-3 xl:mt-0">
                                                        <input
                                                            data-tw-merge=""
                                                            type="text"
                                                            name="tan_number"
                                                            value={formData.tan_number}
                                                            onChange={handleChange}
                                                            placeholder="Tan Card Number"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div>
                                                   
                                                </div>
                                            </div> */}
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">DOB / DOI <span className="text-danger">*</span></div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sm:flex xl:flex-row">
                                                    <div className="flex-1 w-full mt-3 xl:mt-0">

                                                        <input
                                                            data-tw-merge
                                                            name="doi"
                                                            onChange={handleChange}
                                                            type="date" data-single-mode="true" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&amp;[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker mx-auto block w-56 mx-auto block w-56 datepicker mx-auto block w-56 mx-auto block w-56"
                                                        />
                                                        {formerror.doi && (
                                                            <div className="text-danger text-sm mt-2">
                                                                {formerror.doi}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* <div className="flex-1 w-full mt-3 xl:mt-0 ml-2">
                                                        <input
                                                            data-tw-merge=""
                                                            type="file"
                                                            onChange={handleChange}
                                                            name="doi_image"
                                                            placeholder="Tan Card Image"
                                                            className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                        />
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-100">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Registered Office Address <span className="text-danger">*</span></div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        onChange={handleChange}
                                                        name="register_office_address"
                                                        placeholder="Registered Office Address"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formerror.register_office_address && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.register_office_address}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">CIN</div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="cin_number"
                                                        onChange={handleChange}
                                                        placeholder="CIN Number"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                    {formerror.cin_number && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.cin_number}
                                                        </div>
                                                    )}
                                                </div>
                                            </div> */}
                                            <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">GST Number <span className="text-danger">*</span></div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">

                                                    <input
                                                        data-tw-merge
                                                        name="msme_reg"
                                                        placeholder="GST Number"
                                                        onChange={handleChange}
                                                        type="text" data-single-mode="true" className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&amp;[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&amp;:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 datepicker mx-auto block w-56 mx-auto block w-56 datepicker mx-auto block w-56 mx-auto block w-56"
                                                    />
                                                    {formerror.msme_reg && (
                                                        <div className="text-danger text-sm mt-2">
                                                            {formerror.msme_reg}
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                            {/* <div className="flex-col block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <div className="inline-block mb-2 pb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                    <div className="text-left">
                                                        <div className="flex items-center">
                                                            <div className="font-medium">Nature of Business</div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 w-full mt-3 xl:mt-0">
                                                    <input
                                                        data-tw-merge=""
                                                        type="text"
                                                        name="nature_of_business"
                                                        onChange={handleChange}
                                                        placeholder="Nature of Business"
                                                        className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 [&[type='file']]:border file:mr-4 file:py-2 file:px-4 file:rounded-l-md file:border-0 file:border-r-[1px] file:border-slate-100/10 file:text-sm file:font-semibold file:bg-slate-100 file:text-slate-500/70 hover:file:bg-200 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10"
                                                    />
                                                </div>
                                            </div> */}
                                        </>
                                    )}

                                    {(step == 3 || step == 4) && (
                                        <>
                                            <div className="inline-block mb-2 sm:mb-0 sm:mr-5 sm:text-right xl:mr-14 xl:w-60">
                                                <div className="text-left">
                                                    <div className="flex items-center">
                                                        <div className="font-bold">Select Submissions</div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="flex flex-wrap block pt-5 mt-5 first:mt-0 first:pt-0 xl:items-center">
                                                <span
                                                    key={`all`}
                                                    onClick={() => selectAllSubmissions()} // Use unique identifier for selection
                                                    className={`badge  m-2 p-3`}
                                                    style={{ border: `1px solid `, borderRadius: '20px', cursor: 'pointer' }} // Added cursor style for better UX
                                                >
                                                    Select All
                                                </span>
                                                {taxServices && taxServices.map((service, index) => (
                                                    <span
                                                        key={index}
                                                        onClick={() => handleSelect(service.id)} // Use unique identifier for selection
                                                        className={`badge  m-2 p-3 ${selectedServices.includes(service.id) ? 'bg-success text-white' : 'bg-light text-dark'}`}
                                                        style={{ border: `1px solid ${selectedServices.includes(service.id) ? 'green' : 'black'}`, borderRadius: '20px', cursor: 'pointer' }} // Added cursor style for better UX
                                                    >
                                                        {service.service_name}
                                                    </span>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                    {/* <div className="mt-5 text-center xl:mt-8 xl:text-left">
                                        {step == 1 ? <button onClick={changeStep} data-tw-merge className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary rounded-full w-full bg-gradient-to-r from-theme-1/70 to-theme-2/70 py-3.5 xl:mr-3" disabled={loading}>{loading ? 'Please Wait...' : 'Next'}</button>
                                        :   <>
                                            <button data-tw-merge className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary rounded-full w-full bg-gradient-to-r from-theme-1/70 to-theme-2/70 py-3.5 xl:mr-3" disabled={loading}>{loading ? 'Please Wait...' : 'Sign Up'}</button>
                                            <button onClick={changeStepBack} data-tw-merge className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 rounded-full mt-3 w-full bg-white/70 py-3.5">Back</button>
                                            </>
                                        }

                                    </div> */}
                                    <div className="mt-5 text-center xl:mt-8 xl:text-left">
                                        {step === 1 ? (
                                            <button
                                                onClick={() => changeStep(2)}
                                                data-tw-merge
                                                type="button"
                                                className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary rounded-full w-full bg-gradient-to-r from-theme-1/70 to-theme-2/70 py-3.5 xl:mr-3"
                                                disabled={loading}
                                            >
                                                {loading ? 'Please Wait...' : 'Next'}
                                            </button>
                                        ) : step === 2 ? (
                                            <>
                                                <button
                                                    onClick={() => changeStep(3)}
                                                    data-tw-merge
                                                    type="button"
                                                    className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary rounded-full w-full bg-gradient-to-r from-theme-1/70 to-theme-2/70 py-3.5 xl:mr-3"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Please Wait...' : 'Next'}
                                                </button>

                                                <button
                                                    onClick={() => changeStep(1)}
                                                    data-tw-merge
                                                    type="button"
                                                    className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 rounded-full mt-3 w-full bg-white/70 py-3.5"
                                                >
                                                    Back
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={() => changeStep(4)}
                                                    type="submit" // Only submit on step 3
                                                    data-tw-merge
                                                    className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary border-primary text-white dark:border-primary rounded-full w-full bg-gradient-to-r from-theme-1/70 to-theme-2/70 py-3.5 xl:mr-3"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Please Wait...' : 'Sign Up'}
                                                </button>
                                                <button
                                                    onClick={() => changeStep(2)}
                                                    data-tw-merge
                                                    type="button" // Set type to "button" to avoid submitting
                                                    className="transition duration-200 border shadow-sm inline-flex items-center justify-center px-3 font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed border-secondary text-slate-500 dark:border-darkmode-100/40 dark:text-slate-300 [&:hover:not(:disabled)]:bg-secondary/20 [&:hover:not(:disabled)]:dark:bg-darkmode-100/10 rounded-full mt-3 w-full bg-white/70 py-3.5"
                                                >
                                                    Back
                                                </button>
                                            </>
                                        )}
                                    </div>




                                    <div className="mt-5 text-center xl:mt-8">
                                        Login here? <Link to="/company/login" className="text-center">click here</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container fixed inset-0 grid h-screen w-screen grid-cols-12 pl-14 pr-12 lg:max-w-[1550px] xl:px-24 2xl:max-w-[1750px]">
                <div className="relative h-screen col-span-12 lg:col-span-5 2xl:col-span-4 z-20 after:bg-white after:hidden after:lg:block after:content-[''] after:absolute after:right-0 after:inset-y-0 after:bg-gradient-to-b after:from-white after:to-slate-100/80 after:w-[800%] after:rounded-[0_1.2rem_1.2rem_0/0_1.7rem_1.7rem_0] before:content-[''] before:hidden before:lg:block before:absolute before:right-0 before:inset-y-0 before:my-6 before:bg-gradient-to-b before:from-white/10 before:to-slate-50/10 before:bg-white/50 before:w-[800%] before:-mr-4 before:rounded-[0_1.2rem_1.2rem_0/0_1.7rem_1.7rem_0]" />
                <div className="h-full col-span-7 2xl:col-span-8 lg:relative before:content-[''] before:absolute before:lg:-ml-10 before:left-0 before:inset-y-0 before:bg-gradient-to-b before:from-theme-1 before:to-theme-2 before:w-screen before:lg:w-[800%] after:content-[''] after:absolute after:inset-y-0 after:left-0 after:w-screen after:lg:w-[800%] after:bg-texture-white after:bg-fixed after:bg-center after:lg:bg-[25rem_-25rem] after:bg-no-repeat">
                    <div className="sticky top-0 z-10 flex-col justify-center hidden h-screen ml-16 lg:flex xl:ml-28 2xl:ml-36">
                        <div className="text-[2.6rem] font-medium leading-[1.4] text-white xl:text-5xl xl:leading-[1.2]">
                            <img data-placement="top" src="/logo.png" alt="TaxGem" />
                            Start your Journey with <br /> TaxGem
                        </div>
                        <div className="mt-5 text-base leading-relaxed text-white/70 xl:text-lg">
                            Unlock the potential of TaxGem, where developers craft
                            meticulously structured, visually stunning dashboards with
                            feature-rich modules. Join us today to shape the future of your
                            application development.
                        </div>
                        <div className="flex flex-col gap-3 mt-10 xl:flex-row xl:items-center">
                            <div className="flex items-center">
                                <div className="image-fit zoom-in h-9 w-9 2xl:h-11 2xl:w-11">
                                    <img data-placement="top" title="Meryl Streep" src="/assets/images/user2-50x50.jpg" alt="TaxGem - Admin Dashboard Template" className="tooltip cursor-pointer rounded-full border-[3px] border-white/50" />
                                </div>
                                <div className="-ml-3 image-fit zoom-in h-9 w-9 2xl:h-11 2xl:w-11">
                                    <img data-placement="top" title="Cate Blanchett" src="/assets/images/user8-50x50.jpg" alt="TaxGem - Admin Dashboard Template" className="tooltip cursor-pointer rounded-full border-[3px] border-white/50" />
                                </div>
                                <div className="-ml-3 image-fit zoom-in h-9 w-9 2xl:h-11 2xl:w-11">
                                    <img data-placement="top" title="Tom Hanks" src="/assets/images/user3-50x50.jpg" alt="TaxGem - Admin Dashboard Template" className="tooltip cursor-pointer rounded-full border-[3px] border-white/50" />
                                </div>
                                <div className="-ml-3 image-fit zoom-in h-9 w-9 2xl:h-11 2xl:w-11">
                                    <img data-placement="top" title="Angelina Jolie" src="/assets/images/user4-50x50.jpg" alt="TaxGem - Admin Dashboard Template" className="tooltip cursor-pointer rounded-full border-[3px] border-white/50" />
                                </div>
                            </div>
                            <div className="text-base text-white/70 xl:ml-2 2xl:ml-3">
                                {/* Over 7k+ strong and growing! Your journey begins here. */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyRegister;