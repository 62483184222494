import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableHeader from "../../../utils/table_headings";
import moment from "moment";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { getScheduleReportsByCompanyId } from "../../../features/scheduleReportSlice";
import DataTable from "react-data-table-component";
import { Eye, Delete } from 'lucide-react';
import { deleteTaxReport, fetchTaxReports } from "../../../features/submitTaxReportSlice";

const monthMapping = {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
};

const formatPeriod = (period) => {
    const month = monthMapping[period.toLowerCase()];
    return month ? moment(month, 'MMMM').format('MMMM') : period;
};


const AdminReportTable = ({ taxReports = [], check_type }) => {
    
    const companyHeadings = ['Filling Name', 'Type', 'Period', 'Due Date', 'Submitted By','Submitted On', 'Actions'];
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [monthFilter, setMonthFilter] = useState("");
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(
        localStorage.getItem('selectedFiscalYear') || fiscalYears[fiscalYears.length - 1]
    );
    const isActive = (path) => location.pathname === path;

    const handleNavigate = (rowData) => () => {
        navigate('/report-detail', { state: { rowData } });
    };

    const handleDeleteReport = (id) => {
       const ok = confirm('Are you sure to delete this report?');
       if(ok){
            dispatch(deleteTaxReport(id))
            .unwrap()
            .then((data) => {

                console.log('done');
            })
            .catch((error) => {
            // Handle error if needed
            console.error('Failed to delete report:', error);
            });

            localStorage.setItem('selectedFiscalYear', selectedFiscalYear);
            const payload = {
                session: selectedFiscalYear
            };
            // Handle success if needed
            dispatch(fetchTaxReports(payload));
            
       }
    }

    
    // Check if scheduleReports is an array
    if (!Array.isArray(taxReports)) {
        console.error('scheduleReports is not an array:', taxReports);
        return <div>Error: Invalid data format</div>;
    }
    const filteredReports = taxReports.filter((report) => {
        const matchesServiceType = report.taxService?.service_type === check_type.replace(/-/g, '_');
        const matchesMonth = monthFilter ? moment(report.due_date).format("MMMM") === monthFilter : true;
        return matchesServiceType && matchesMonth;
    });

    const columns = [
        {
            name: '#',
            cell: (row, index) => index + 1,
            width: "5%"
        },
        {
            name: 'Filing Name',
            selector: (row) => row.taxService?.service_name || 'Unknown Service',
            sortable: true,
            width: "20%"
        },
        {
            name: 'Type',
            cell: (row) => row.taxService?.service_type === 'return_filling' ? "Periodic Fillings" : "Annual Fillings",
            sortable: true,
            width: "15%"
        },
        {
            name: 'Period',
            selector: (row) => row.scheduleReport?.session ?? row.branchSchedule?.session,
            sortable: true,
            width: "15%"
        },
        {
            name: 'Due Date',
            selector: (row) => row.due_date,
            sortable: true,
            width: "15%"
        },
        {
            name: 'Submitted By',
            cell: (row) => row.submitted_by === 'company' ? row.company?.company_name : row.submitted_by == 'undefined'?"Admin":row.branch?.branch_name,
            sortable: true,
            width: "15%"
        },
        {
            name: 'Submitted On',
            cell: (row) => moment(row.createdAt).local().format('DD MMMM, YYYY H:mm:ss'),
            sortable: true,
            width: "15%"
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                <button
                    onClick={handleNavigate(row)}
                    className="btn text-success cursor-pointer"
                >
                    <Eye/>
                </button>
                
                <button
                    onClick={() => handleDeleteReport(row.id)}
                    className="btn text-danger cursor-pointer ml-2"
                >
                    <Delete/>
                </button>
                </>
            ),
            width: "10%"
        }
    ];
    let loop = 1;
    return (
        <div>
            <div className="flex flex-col gap-4 p-5 sm:flex-row sm:items-center sm:gap-x-4">
                <div className="flex flex-col">
                    <label htmlFor="monthFilter" className="mb-2">Filter by Month:</label>
                    <select
                        id="monthFilter"
                        value={monthFilter}
                        onChange={(e) => setMonthFilter(e.target.value)}
                        className="disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 group-[.form-inline]:flex-1"
                    >
                        <option value="">All Months</option>
                        {Object.values(monthMapping).map((month) => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredReports}
                pagination
                highlightOnHover
                striped
                responsive
            />

        </div>
    );
}

export default AdminReportTable;