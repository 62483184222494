import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import companyAuthReducer from '../features/auth/companyAuthSlice';
import companyReducer from '../features/companySlice';
import companyTaxInfoReducer from '../features/companyTaxInfoSlice';
import bankNDirectorDetailsReducer from '../features/companyBankDirectorSlice';
import branchReducer from '../features/branchSlice';
import taxServiceReducer from '../features/taxServiceSlice';
import scheduleReportReducer from '../features/scheduleReportSlice';
import submitTaxReportReducer from '../features/submitTaxReportSlice';
import branchUserReducer from '../features/branchUserSlice';
import subscriptionReducer from '../features/subscriptionsSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    companyAuth: companyAuthReducer,
    companies: companyReducer,
    companyTaxInfo: companyTaxInfoReducer,
    bankNDirectors: bankNDirectorDetailsReducer,
    branches: branchReducer,
    taxService: taxServiceReducer,
    scheduleReports: scheduleReportReducer,
    submitTaxReport: submitTaxReportReducer,
    branchUsers: branchUserReducer,
    subscriptions: subscriptionReducer

    // Add other reducers here if needed
  },
});
