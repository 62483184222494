import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { signout } from '../../../../features/auth/companyAuthSlice';
import { getScheduleReportsByCompanyId } from '../../../../features/scheduleReportSlice';
import ScheduleReportRoutes from './schedule_report_route';
import { checkAuthType } from '../../../../actions/authAction';
import { BookOpen, BookOpenCheck, CalendarCheck, CalendarClock, CalendarRange, GitBranchPlus,Plus, LogOut, Dock, Worm, Container, Eye, ArrowRightLeft } from 'lucide-react';
import axios from 'axios';
import { API_URL } from '../../../../utils/api_const';

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { scheduleReports, status } = useSelector((state) => state.scheduleReports.scheduleReports);
    const [branchAvailable, setBranchAvailable] = useState(0);
    const isActive = (path) => location.pathname === path;
    const handleSignoutClick = () => {
        dispatch(signout());
    };

    const getCompanySubscription = async (company_id) => {
        try {
            const res = await axios.get(`${API_URL}/companies/subscription/data/${company_id}`);
            if (res.status === 200) {
                let company = JSON.parse(localStorage.getItem('company'));
                if (company) {
                    company.subscription = res.data;
                    // Set the updated company object back into localStorage as a string
                    localStorage.setItem('company', JSON.stringify(company));
                }
                return res.data;
            }
        } catch (error) {
            console.error("Error fetching subscriptions:", error);
            return false;
        }
    }

    useEffect(() => {
       const checkData = async () => {
            const company = JSON.parse(localStorage.getItem('company'));
            if (company) {
                const companyId = company.id;
                const usertype = localStorage.getItem('login_type');
                if(usertype == 'company'){
                    const subdata = await getCompanySubscription(company.id);
                    if(subdata){
                        console.log('my subm data ', subdata);
                        setBranchAvailable(subdata.branches_count);
                    }
                    
                }

             
                // Dispatch the action and unwrap the result
                dispatch(getScheduleReportsByCompanyId(companyId));
                console.log('Result:', scheduleReports);
            }
       }
       checkData();
    }, [dispatch]);


    console.log('Ho much branch available', branchAvailable);
    return (
        <>
            <div className="close-mobile-menu fixed ml-[275px] w-10 h-10 items-center justify-center xl:hidden z-50 [&.close-mobile-menu--mobile-menu-open]:flex hidden">
                <a className="ml-5 mt-5" href="">
                    <i
                        data-tw-merge=""
                        data-lucide="x"
                        className="stroke-[1] h-8 w-8 text-white"
                    />
                </a>
            </div>
            <div className="side-menu__content h-full box bg-white/[0.95] rounded-none xl:rounded-xl z-20 relative w-[275px] duration-300 transition-[width] group-[.side-menu--collapsed]:xl:w-[91px] group-[.side-menu--collapsed.side-menu--on-hover]:xl:shadow-[6px_0_12px_-4px_#0000000f] group-[.side-menu--collapsed.side-menu--on-hover]:xl:w-[275px] overflow-hidden flex flex-col">
                <div className="flex-none hidden xl:flex items-center z-10 px-5 h-[65px] w-[275px] overflow-hidden relative duration-300 group-[.side-menu--collapsed]:xl:w-[91px] group-[.side-menu--collapsed.side-menu--on-hover]:xl:w-[275px]">
                    <Link
                        className="flex items-center transition-[margin] duration-300 group-[.side-menu--collapsed.side-menu--on-hover]:xl:ml-0 group-[.side-menu--collapsed]:xl:ml-2"
                        to={`/${checkAuthType()}/dashboard`}
                    >
                        {/* <div className="flex h-[34px] w-[34px] items-center justify-center rounded-lg  from-theme-1 to-theme-2/80 transition-transform ease-in-out group-[.side-menu--collapsed.side-menu--on-hover]:xl:-rotate-180">
                            
                            <div className="relative h-[16px] w-[16px] -rotate-45 [&_div]:bg-white">
                                <div className="absolute inset-y-0 left-0 my-auto h-[75%] w-[21%] rounded-full opacity-50"></div>
                                <div className="absolute inset-0 m-auto h-[120%] w-[21%] rounded-full" />
                                <div className="absolute inset-y-0 right-0 my-auto h-[75%] w-[21%] rounded-full opacity-50"></div>
                            </div>
                        </div> */}
                        <div className="font-medium transition-opacity group-[.side-menu--collapsed.side-menu--on-hover]:xl:opacity-100 group-[.side-menu--collapsed]:xl:opacity-0 w-70">
                            <img data-placement="top" src="/logo_ne.png" alt="TaxGem" style={{ width: "85px", marginTop:'10px' }} />
                        </div>
                    </Link>
                    <Link
                        className="toggle-compact-menu ml-auto hidden h-[20px] w-[20px] items-center justify-center rounded-full border border-slate-600/40 transition-[opacity,transform] hover:bg-slate-600/5 group-[.side-menu--collapsed]:xl:rotate-180 group-[.side-menu--collapsed.side-menu--on-hover]:xl:opacity-100 group-[.side-menu--collapsed]:xl:opacity-0 3xl:flex"
                        href="#0"
                    >
                        <i
                            data-tw-merge=""
                            data-lucide="arrow-left"
                            className="h-3.5 w-3.5 stroke-[1.3]"
                        />
                    </Link>
                </div>
                <SimpleBar className="scrollable-ref w-full h-full z-20 px-5 overflow-y-auto overflow-x-hidden pb-3 [-webkit-mask-image:-webkit-linear-gradient(top,rgba(0,0,0,0),black_30px)] [&:-webkit-scrollbar]:w-0 [&:-webkit-scrollbar]:bg-transparent [&_.simplebar-content]:p-0 [&_.simplebar-track.simplebar-vertical]:w-[10px] [&_.simplebar-track.simplebar-vertical]:mr-0.5 [&_.simplebar-track.simplebar-vertical_.simplebar-scrollbar]:before:bg-slate-400/30">
                    <ul className="scrollable">
                        {/* BEGIN: First Child */}

                        <li className='mt-2 pt-2'>
                            <Link
                                to={`/${checkAuthType()}/dashboard`}
                                className={`side-menu__link ${isActive(`/${checkAuthType()}/dashboard`) ? 'side-menu__link--active' : ''}`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-layout-dashboard"><rect width="7" height="9" x="3" y="3" rx="1" /><rect width="7" height="5" x="14" y="3" rx="1" /><rect width="7" height="9" x="14" y="12" rx="1" /><rect width="7" height="5" x="3" y="16" rx="1" /></svg>
                                <div className="side-menu__link__title">Dashboard</div>
                            </Link>
                        </li>
                        {branchAvailable > 0 && checkAuthType() == 'company' ? <li className="side-menu__divider">Branch & Users</li> : ''}
                        {branchAvailable > 0 && checkAuthType() == 'company' ? <li className='mt-2 pt-2'>
                            <Link
                                to={`/company/branch`}
                                className={`side-menu__link ${isActive('/company/branch') ? 'side-menu__link--active' : ''}`}
                            >
                                <GitBranchPlus />
                                <div className="side-menu__link__title">Branches</div>
                            </Link>
                        </li> : ''}
                        {branchAvailable > 0 && checkAuthType() == 'company' ? <li className="side-menu__divider">Schedules</li> : ''}
                        {branchAvailable > 0 && checkAuthType() == 'company' ? <li className='mt-2 pt-2'>
                            <Link
                                to="/company/branch/schedule/add"
                                className={`side-menu__link ${isActive('/company/branch/schedule/add') ? 'side-menu__link--active' : ''}`}
                            >
                                <Plus />
                                <div className="side-menu__link__title">Add Schedule to Branch</div>
                            </Link>
                        </li> : ''}
                        {branchAvailable > 0 && checkAuthType() == 'company' ? <li className='mt-2 pt-2'>
                            <Link
                                to="/company/branch/schedule"
                                className={`side-menu__link ${isActive('/company/branch/schedule') ? 'side-menu__link--active' : ''}`}
                            >
                                <CalendarCheck />
                                <div className="side-menu__link__title">Scheduled Branch Report</div>
                            </Link>
                        </li> : ''}
                        <li className="side-menu__divider">Fillings</li>
                        <li className='mt-2 pt-2'>
                            <Link
                                to={`/${checkAuthType()}/tax/pending-filling`}
                                className={`side-menu__link ${isActive(`/${checkAuthType()}/tax/pending-filling`) ? 'side-menu__link--active' : ''}`}
                            >
                                <BookOpen />
                                <div className="side-menu__link__title">Pending Fillings</div>
                            </Link>
                        </li>
                        {/* <li className='mt-2 pt-2'>
                            <Link
                                to="/company/tax/return-filling"
                                className={`side-menu__link ${isActive('/company/tax/return-filling') ? 'side-menu__link--active' : ''}`}
                            >
                                <i
                                    data-tw-merge=""
                                    data-lucide="gauge-circle"
                                    className="stroke-[1] w-5 h-5 side-menu__link__icon"
                                />
                                <div className="side-menu__link__title">Periodic Fillings</div>
                            </Link>
                        </li> */}
                        <li className='mt-2 pt-2'>
                            <Link
                                to={`/${checkAuthType()}/tax/upcoming-filling`}
                                className={`side-menu__link ${isActive(`/${checkAuthType()}/tax/upcoming-filling`) ? 'side-menu__link--active' : ''}`}
                            >
                                <BookOpenCheck />
                                <div className="side-menu__link__title">Upcomming Fillings</div>
                            </Link>
                        </li>
                        {/* <ScheduleReportRoutes scheduleReports={scheduleReports} check_type="return_filling"/> */}


                        {/* <li className="side-menu__divider">Annual Fillings</li> */}
                        {/* <ScheduleReportRoutes scheduleReports={scheduleReports} check_type="annual_filling"/> */}

                        <li className="side-menu__divider">Reports</li>
                        <li className='mt-2 pt-2'>
                            <Link
                                to={`/${checkAuthType()}/report/return-filling`}
                                className={`side-menu__link ${isActive(`/${checkAuthType()}/report/return-filling`) ? 'side-menu__link--active' : ''}`}
                            >
                                <CalendarRange />
                                <div className="side-menu__link__title">Periodic Filling Report</div>
                            </Link>
                        </li>
                        <li className='mt-2 pt-2'>
                            <Link
                                to={`/${checkAuthType()}/report/annual-filling`}
                                className={`side-menu__link ${isActive(`/${checkAuthType()}/report/annual-filling`) ? 'side-menu__link--active' : ''}`}
                            >
                                <CalendarClock />
                                <div className="side-menu__link__title">Annual Filling Report</div>
                            </Link>
                        </li>
                        <li className="side-menu__divider">Other</li>
                        <li>
                            <Link to={`${checkAuthType()}/other/filling/view`} className={`side-menu__link`} >
                                <Eye />
                                <div className="side-menu__link__title">View Other Fillings</div>
                            </Link>
                        </li>
                        <li>
                            <Link to={`${checkAuthType()}/other/lic`} className={`side-menu__link`} >
                                <Dock />
                                <div className="side-menu__link__title">LIC</div>
                            </Link>
                        </li>
                        <li>
                            <Link to={`${checkAuthType()}/other/ppf`} className={`side-menu__link`}>
                                <Container />
                                <div className="side-menu__link__title">PPF</div>
                            </Link>
                        </li>

                        <li>
                            <Link to={`${checkAuthType()}/other/investment`} className={`side-menu__link`}>
                                <Worm />
                                <div className="side-menu__link__title">Investment</div>
                            </Link>
                        </li>

                        {checkAuthType() == 'company' &&(<li>
                            <Link to={`${checkAuthType()}/transactions`} className={`side-menu__link`}>
                                <ArrowRightLeft />
                                <div className="side-menu__link__title">Transaction Histroy</div>
                            </Link>
                        </li>)}


                        <li>
                            <Link to="#" className={`side-menu__link`} onClick={handleSignoutClick}>
                                <LogOut />
                                <div className="side-menu__link__title">Signout</div>
                            </Link>
                        </li>
                        {/* END: First Child */}
                    </ul>
                </SimpleBar>
            </div>
        </>

    );
}

export default Sidebar;